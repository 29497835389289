import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import reviewServices from "./reviewServices";

const initialState = {
  reviews: [],
  isReviewLoading: false,
  isReviewSuccess: false,
  isReviewError: false,
  reviewMessage: "",
};

export const getReviews = createAsyncThunk(
  "review/get",
  async (data, thunkAPI) => {
    try {
      return await reviewServices.getReviews(data);
    } catch (error) {
      const message = (error && error.response.message) || error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addReview = createAsyncThunk(
  "review/add",
  async (data, thunkAPI) => {
    try {
      const newData = await reviewServices.createReview(data);
      if (newData) {
        thunkAPI.dispatch(getReviews());

        return newData;
      } else {
        const message = "Somthing went wrong!!";

        return thunkAPI.rejectWithValue(message);
      }
    } catch (error) {
      const message = (error && error.response.message) || error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateReview = createAsyncThunk(
  "review/update",
  async (data, thunkAPI) => {
    try {
      return await reviewServices.updateReview(data);
    } catch (error) {
      const message = (error && error.response.message) || error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteReview = createAsyncThunk(
  "review/delete",
  async (data, thunkAPI) => {
    try {
      const newData = await reviewServices.deleteReview(data);

      if (newData) {
        thunkAPI.dispatch(getReviews());
        return newData;
      } else {
        const message = "Something went wrong";

        return thunkAPI.rejectWithValue(message);
      }
    } catch (error) {
      const message = (error && error.response.message) || error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const reviewSlice = createSlice({
  name: "review",
  initialState,
  reducers: {
    resetReviews: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReviews.pending, (state) => {
        state.isReviewLoading = true;
      })
      .addCase(getReviews.fulfilled, (state, action) => {
        state.isReviewLoading = false;
        state.isReviewSuccess = true;
        state.reviews = action.payload.reviews;
      })
      .addCase(getReviews.rejected, (state, action) => {
        state.isReviewLoading = false;
        state.isReviewError = true;
        state.reviewMessage = action.payload;
      })
      .addCase(addReview.pending, (state) => {
        state.isReviewLoading = true;
      })
      .addCase(addReview.fulfilled, (state, action) => {
        state.isReviewLoading = false;
        state.isReviewSuccess = true;
        state.isReviewError = false;
        state.reviews = action.payload.reviews;
      })
      .addCase(addReview.rejected, (state, action) => {
        state.isReviewLoading = false;
        state.isReviewError = true;
        state.isReviewSuccess = false;
        state.reviewMessage = action.payload;
      })
      .addCase(updateReview.pending, (state) => {
        state.isReviewLoading = true;
      })
      .addCase(updateReview.fulfilled, (state, action) => {
        state.isReviewLoading = false;
        state.isReviewError = false;
        state.isReviewSuccess = true;
        state.reviews = action.payload.reviews;
      })
      .addCase(updateReview.rejected, (state, action) => {
        state.isReviewLoading = false;
        state.isReviewError = true;
        state.isReviewSuccess = false;
        state.reviewMessage = action.payload;
      })
      .addCase(deleteReview.pending, (state) => {
        state.isReviewLoading = true;
      })
      .addCase(deleteReview.fulfilled, (state, action) => {
        state.isReviewLoading = false;
        state.isReviewError = false;
        state.isReviewSuccess = true;
      })
      .addCase(deleteReview.rejected, (state, action) => {
        state.isReviewLoading = false;
        state.isReviewError = true;
        state.isReviewSuccess = false;
        state.reviewMessage = action.payload;
      });
  },
});

export const { resetReviews } = reviewSlice.actions;
export default reviewSlice.reducer;
