import React from 'react';
import {Grid, List, ListItemButton, ListItemIcon, ListItemText, useTheme} from '@mui/material';

import {Link, useLocation} from 'react-router-dom';
import {MailIcon, UserCircleIcon, UsersIcon} from '@heroicons/react/solid';

const ProfileSideBar = () => {
	const location = useLocation();
	const theme = useTheme();
	const listData = [
		{
			text: 'Account',
			icon: <UserCircleIcon height={'18px'} color={theme.palette.secondary.main} />,
			path: 'account',
		},
		// {
		// 	text: 'Test Health',
		// 	icon: <DocumentReportIcon height={'18px'} color={theme.palette.secondary.main} />,
		// },
		{
			text: 'Addresses',
			icon: <MailIcon height={'18px'} color={theme.palette.secondary.main} />,
			path: 'addresses',
		},
		{
			text: 'Members',
			icon: <UsersIcon height={'18px'} color={theme.palette.secondary.main} />,
			path: 'members',
		},
	];

	const indexByRoute =
		listData.findIndex((x) => x.path === location.pathname.split('/')[2]) === -1
			? 0
			: listData.findIndex((x) => x.path === location.pathname.split('/')[2]);

	return (
		<Grid
			item
			xs={12}
			sx={{
				height: '500px',
				maxHeight: '500px',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				boxShadow: '3px 3px 20px rgba(0, 0, 0, 0.07)',
				borderRadius: 3,
				p: 2,
			}}
		>
			<List component='nav' aria-label='main mailbox folders'>
				{listData.map((item, index) => (
					<ListItemButton
						component={Link}
						to={item?.path}
						key={index}
						sx={{borderRadius: 2}}
						selected={indexByRoute === index}
						// onClick={(event) => handleListItemClick(event, index)}
					>
						<ListItemIcon>{item?.icon}</ListItemIcon>
						<ListItemText primary={item?.text} />
					</ListItemButton>
				))}
			</List>
		</Grid>
	);
};

export default ProfileSideBar;
