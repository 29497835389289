import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function Loader({open}) {
	return (
		<div>
			<Backdrop sx={{background: '#fff', zIndex: 800}} open={open}>
				<CircularProgress />
			</Backdrop>
		</div>
	);
}
