import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { checkUser } from "../users/userSlice";
import orderService from "./orderService";

const initialState = {
  orders: null,
  currentOrder: null,
  isOrderError: false,
  isOrderSuccess: false,
  isOrderLoading: false,
  isPaymentSuccess: false,
  orderMessage: "",
};

export const create = createAsyncThunk("order/create", async (id, thunkAPI) => {
  try {
    const data = await orderService.createOrder(id);

    if (data) {
      const { dispatch } = thunkAPI;
      dispatch(checkUser());

      return data;
    } else {
      const message = "Something went wrong!!";

      return thunkAPI.rejectWithValue(message);
    }
  } catch (error) {
    //const message =
    // (error.response && error.response.data && error.response.data.error.message) ||
    // error.message ||
    // error.toString();

    const message = (error && error.message) || error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const getAllOrders = createAsyncThunk(
  "order/getAll",
  async (_, thunkAPI) => {
    try {
      return await orderService.getOrders();
    } catch (error) {
      //const message =
      // (error.response && error.response.data && error.response.data.error.message) ||
      // error.message ||
      // error.toString();

      const message = (error && error.message) || error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getOrderById = createAsyncThunk("order/getById", async (data, thunkAPI) => {
  try {
    return await orderService.getOrder(data);
    // if (data) {
    // 	const {dispatch} = thunkAPI;
    // 	dispatch(checkuser());

    // 	return data;
    // } else {
    // 	const message = 'Something went wrong!!';

    // 	return thunkAPI.rejectWithValue(message);
    // }
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.error.message) ||
      error.message ||
      error.toString();

    // const message = (error && error.message) || error.toString();

    return thunkAPI.rejectWithValue(message);
  }
}
);

export const updateOrderAddress = createAsyncThunk("order/updateaddress", async (id, thunkAPI) => {
  // const {enqueueSnackbar} = useSnackbar();
  try {
    const data = await orderService.updateOrderAddress(id);

    if (data) {
      const { dispatch } = thunkAPI;
      dispatch(checkUser());

      // enqueueSnackbar("Address Updated Succefully", {variant: "success"});

      return data;
    } else {
      const message = "Something went wrong!!";
      // enqueueSnackbar("Could Not Updated Address", {variant: "success"});

      return thunkAPI.rejectWithValue(message);
    }
  } catch (error) {
    const message = (error && error.message) || error.toString();
    // enqueueSnackbar(error?.response?.data?.error, {variant: "error"});

    return thunkAPI.rejectWithValue(message);
  }
}
);

export const updateOrderPerson = createAsyncThunk("order/updateperson/:id", async (datas, thunkAPI) => {
  console.log('slice', datas);
  // const {enqueueSnackbar} = useSnackbar();
  try {
    const data = await orderService.updateOrderPerson(datas);

    if (data) {
      const { dispatch } = thunkAPI;
      dispatch(checkUser());
      // enqueueSnackbar("Person Updated Succefully", {variant: "success"});

      return data;
    } else {
      const message = "Something went wrong!!";
      // enqueueSnackbar("Could Not Updated Person", {variant: "error"});

      return thunkAPI.rejectWithValue(message);
    }
  } catch (error) {
    const message = (error && error.message) || error.toString();
    // enqueueSnackbar(error?.response?.data?.error, {variant: "error"});

    return thunkAPI.rejectWithValue(message);
  }
}
);

export const updateOrderKit = createAsyncThunk(
  "order/updatekit",
  async (postData, thunkAPI) => {
    try {
      const data = await orderService.updateOrderKit(postData);

      if (data) {
        const { dispatch } = thunkAPI;
        dispatch(checkUser());
        dispatch(getOrderById({ id: postData?.id }));

        return data;
      } else {
        const message = "Something went wrong!!";

        // return thunkAPI.rejectWithValue(message);
      }
    } catch (error) {
      const message = (error && error.message) || error.toString();

      // return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateOrderPayment = createAsyncThunk("order/updatepayment", async (postData, thunkAPI) => {
  // const {enqueueSnackbar} = useSnackbar();
  try {
    const data = await orderService.updateOrderPayment(postData);

    if (data) {
      const { dispatch } = thunkAPI;
      dispatch(checkUser());
      // enqueueSnackbar("Order Placed Successfully", {variant: "success"});
      return data;
    } else {
      const message = "Something went wrong!!";

      // enqueueSnackbar("Could not Place the Order", {variant: "error"});

      return thunkAPI.rejectWithValue(message);
    }
  } catch (error) {
    const message = (error && error.message) || error.toString();

    // enqueueSnackbar(error?.response?.data?.error, {variant: "error"});

    return thunkAPI.rejectWithValue(message);
  }
}
);

export const updateOrderCashOnDel = createAsyncThunk(
  "order/updateOrderCashOnDel",
  async (postData, thunkAPI) => {
    try {
      const data = await orderService.updateOrderCashOnDel(postData);

      if (data) {
        const { dispatch } = thunkAPI;
        dispatch(checkUser());
        return data;
      } else {
        const message = "Something went wrong!!";
        return thunkAPI.rejectWithValue(message);
      }
    } catch (error) {
      const message = (error && error.message) || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const cancelOrderUnderReview = createAsyncThunk(
  "order/cancel",
  async (options, thunkAPI) => {
    try {
      const data = await orderService.cancelOrder(options);

      if (data) {
        const { dispatch } = thunkAPI;
        // dispatch(checkUser());
        if (options.isRefreshCurrentOrder)
          dispatch(getOrderById({ id: options?.id }));
        dispatch(getAllOrders());
        return data;
      } else {
        const message = "Something went wrong!!";

        return thunkAPI.rejectWithValue(message);
      }
    } catch (error) {
      const message = (error && error.message) || error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    resetOrders: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(create.pending, (state) => {
        state.isOrderLoading = true;
      })
      .addCase(create.fulfilled, (state, action) => {
        state.isOrderLoading = false;
        state.isOrderSuccess = true;
        state.orders = action.payload.order;
      })
      .addCase(create.rejected, (state, action) => {
        state.isOrderLoading = false;
        state.isOrderError = true;
        state.isOrderSuccess = false;
        state.orderMessage = action.payload;
      })
      .addCase(getAllOrders.pending, (state) => {
        state.isOrderLoading = true;
      })
      .addCase(getAllOrders.fulfilled, (state, action) => {
        state.isOrderLoading = false;
        state.isOrderSuccess = true;
        state.orders = action.payload;
      })
      .addCase(getAllOrders.rejected, (state, action) => {
        state.isOrderLoading = false;
        state.isOrderError = true;
        state.isOrderSuccess = false;
        state.orderMessage = action.payload;
      })
      .addCase(getOrderById.pending, (state) => {
        state.isOrderLoading = true;
      })
      .addCase(getOrderById.fulfilled, (state, action) => {
        state.isOrderLoading = false;
        state.isOrderSuccess = true;
        state.currentOrder = action.payload?.order;
      })
      .addCase(getOrderById.rejected, (state, action) => {
        state.isOrderLoading = false;
        state.isOrderError = true;
        state.isOrderSuccess = false;
        state.orderMessage = action.payload;
      })
      .addCase(updateOrderAddress.pending, (state) => {
        state.isOrderLoading = true;
      })
      .addCase(updateOrderAddress.fulfilled, (state, action) => {
        state.isOrderLoading = false;
        state.isOrderSuccess = true;
      })
      .addCase(updateOrderAddress.rejected, (state, action) => {
        state.isOrderLoading = false;
        state.isOrderError = true;
        state.isOrderSuccess = false;
      })
      .addCase(updateOrderKit.pending, (state) => {
        state.isOrderLoading = true;
      })
      .addCase(updateOrderKit.fulfilled, (state, action) => {
        state.isOrderLoading = false;
        state.isOrderSuccess = true;
      })
      .addCase(updateOrderKit.rejected, (state, action) => {
        state.isOrderLoading = false;
        state.isOrderError = true;
        state.isOrderSuccess = false;
      })
      .addCase(updateOrderPerson.pending, (state) => {
        state.isOrderLoading = true;
      })
      .addCase(updateOrderPerson.fulfilled, (state, action) => {
        state.isOrderLoading = false;
        state.isOrderSuccess = true;
      })
      .addCase(updateOrderPerson.rejected, (state, action) => {
        state.isOrderLoading = false;
        state.isOrderError = true;
        state.isOrderSuccess = false;
      })
      .addCase(updateOrderPayment.pending, (state) => {
        state.isOrderLoading = true;
        state.isPaymentSuccess = false;
      })
      .addCase(updateOrderPayment.fulfilled, (state, action) => {
        state.isOrderLoading = false;
        state.isOrderSuccess = true;
        state.isPaymentSuccess = true;
      })
      .addCase(updateOrderPayment.rejected, (state, action) => {
        state.isOrderLoading = false;
        state.isOrderError = true;
        state.isOrderSuccess = false;
        state.isPaymentSuccess = false;
      })
      .addCase(cancelOrderUnderReview.pending, (state) => {
        state.isOrderLoading = true;
        state.isPaymentSuccess = false;
      })
      .addCase(cancelOrderUnderReview.fulfilled, (state, action) => {
        // state.currentOrder =
        //   state.currentOrder && state.currentOrder?._id === action.payload?.data?._id ? action.payload.data : null;
        state.isOrderLoading = false;
        state.isOrderSuccess = true;
      })
      .addCase(cancelOrderUnderReview.rejected, (state, action) => {
        state.isOrderLoading = false;
        state.isOrderError = true;
        state.isOrderSuccess = false;
      })
      .addCase(updateOrderCashOnDel.pending, (state) => {
        state.isOrderLoading = true;
        state.isPaymentSuccess = false;
      })
      .addCase(updateOrderCashOnDel.fulfilled, (state, action) => {
        state.isOrderLoading = false;
        state.isOrderSuccess = true;
        state.isPaymentSuccess = true;
      })
      .addCase(updateOrderCashOnDel.rejected, (state, action) => {
        state.isOrderLoading = false;
        state.isOrderError = true;
        state.isOrderSuccess = false;
        state.isPaymentSuccess = false;
      });
  },
});

export const { resetOrders } = orderSlice.actions;
export default orderSlice.reducer;
