import React from 'react';
import {Link} from 'react-router-dom';
import {Grid, IconButton, Typography, useTheme} from '@mui/material';
import {ChevronRightIcon} from '@heroicons/react/outline';

const RecommendationList = ({recommendationTitle, to, handleClick}) => {
	const theme = useTheme();

	return (
		<Grid
			component={Link}
			item
			container
			xs={12}
			sx={{
				boxShadow: '3px 3px 20px rgba(0, 0, 0, 0.07)',
				borderRadius: 3,
				py: 2,
				px: {xs: 1, sm: 4},
				mx: 'auto',
				background: '#fff',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				// flexDirection: {xs: 'column', sm: 'row'},
				cursor: 'pointer',
				flexWrap: 'nowrap',
				textDecoration: 'none',
				mt: 2,

				'&:hover': {
					transition: '0.2s ease-out',
					background: 'rgb(99, 187, 108, 0.05)',
				},
			}}
			to={to}
		>
			<Grid item>
				<Typography
					sx={{
						color: theme.palette.secondary.main,
						textTransform: 'uppercase',
						fontWeight: 900,
						fontSize: {xs: '1.1rem', sm: '1.3rem', md: '1.5rem'},
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						display: '-webkit-box',
						WebkitLineClamp: '1',
						WebkitBoxOrient: 'vertical',
					}}
				>
					{recommendationTitle}
				</Typography>
			</Grid>
			<Grid item sx={{display: 'flex', alignItems: 'center', gap: 4}}>
				<IconButton>
					<ChevronRightIcon height={'1.8rem'} color={theme.palette.common.greyDark} />
				</IconButton>
			</Grid>
		</Grid>
	);
};

export default RecommendationList;
