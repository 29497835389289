import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Navigate, Outlet, useNavigate} from 'react-router-dom';

const Auth = () => {
	const {token, AuthSuccessData, AuthFailData, organization} = useSelector((state) => state.auth);
	const navigate = useNavigate();
	const isLoggedIn = Boolean(token);

	useEffect(() => {
		if (Boolean(AuthSuccessData)) {
			if (AuthSuccessData?.actionStatusCode === 'VERIFICATION_REQUIRED') {
				navigate('/auth/accountstatus');
			}
			//   else if (AuthSuccessData?.actionStatusCode === "LOGIN_SUCCESS") {
			//   }
		} else if (Boolean(AuthFailData)) {
			navigate('/auth/mobilelogin');
		}

		return () => {};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (isLoggedIn){ 
		if(organization == "" || organization == null){
			return <Navigate to={'/profile'} replace />
		}
		return <Navigate to={'/'} replace />
	}

	return <Outlet />;
};

export default Auth;
