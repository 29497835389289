const GIC_LOGO =
	"https://res.cloudinary.com/dvlzuwb4x/image/upload/v1666859894/gic-media/LOGO/logo_euuqfv.png";

const GIC_LOGO_ALT =
	"https://res.cloudinary.com/dvlzuwb4x/image/upload/v1666859894/gic-media/LOGO/logo-alt_ahvj7r.png";

const LANDING_PAGE_VIDEO =
	"https://res.cloudinary.com/dvlzuwb4x/video/upload/v1667460692/gic-media/LANDING/GIC_V11_1__12.54.3_qft8k9.mp4";
const LANDING_PAGE_VIDEO_LOOP =
	"https://res.cloudinary.com/dvlzuwb4x/video/upload/v1666859845/gic-media/LANDING/GIC_DESKTOP_LOOP_dyum7h.mp4";
const LANDING_PAGE_MOBILE_VIDEO =
	"https://res.cloudinary.com/dvlzuwb4x/video/upload/v1666859844/gic-media/LANDING/GIC_MOBILE_LOOP_ev4r2b.mp4";

const LANDING_PAGE_BENEFIT_ICON_01 =
	"https://res.cloudinary.com/dvlzuwb4x/image/upload/v1666863681/gic-media/LANDING/BENEFITS/Maintains_diversity_lqkoar.svg";
const LANDING_PAGE_BENEFIT_ICON_02 =
	"https://res.cloudinary.com/dvlzuwb4x/image/upload/v1666863681/gic-media/LANDING/BENEFITS/Boost_digestion_iew3p5.svg";
const LANDING_PAGE_BENEFIT_ICON_03 =
	"https://res.cloudinary.com/dvlzuwb4x/image/upload/v1666863683/gic-media/LANDING/BENEFITS/Regulate_Immunity_qxgott.svg";
const LANDING_PAGE_BENEFIT_ICON_04 =
	"https://res.cloudinary.com/dvlzuwb4x/image/upload/v1666863683/gic-media/LANDING/BENEFITS/Relieve_Stress_tjrzth.svg";
const LANDING_PAGE_BENEFIT_ICON_05 =
	"https://res.cloudinary.com/dvlzuwb4x/image/upload/v1666863681/gic-media/LANDING/BENEFITS/Prevents_leaky_gut_b79cbg.svg";
const LANDING_PAGE_BENEFIT_ICON_06 =
	"https://res.cloudinary.com/dvlzuwb4x/image/upload/v1666863681/gic-media/LANDING/BENEFITS/Improve_heart_health_2_c1ervm.svg";
const LANDING_PAGE_BENEFIT_ICON_07 =
	"https://res.cloudinary.com/dvlzuwb4x/image/upload/v1666863681/gic-media/LANDING/BENEFITS/Diet_suggestions_itdqle.svg";
const LANDING_PAGE_BENEFIT_ICON_08 =
	"https://res.cloudinary.com/dvlzuwb4x/image/upload/v1666863683/gic-media/LANDING/BENEFITS/Recommended_lifestyle_changes_jqvxty.svg";

const ABOUT_PAGE_HERO =
	"https://res.cloudinary.com/dvlzuwb4x/image/upload/v1666888254/gic-media/MicrosoftTeams-image_1_cofehg.jpg";

const ABOUT_PAGE_HERO_NEW =
	"https://gutblobdev.blob.core.windows.net/gutlive/1391eaa4-a354-440e-b5d2-5cff275470a5_About_bg-gut_2_4_44.jpg";
const ABOUT_PAGE_MISSION =
	"https://res.cloudinary.com/dvlzuwb4x/image/upload/v1666862911/gic-media/ABOUT/holographic_pwtffj.jpg";
const ABOUT_PAGE_WHO =
	"https://res.cloudinary.com/dvlzuwb4x/image/upload/v1666862938/gic-media/ABOUT/innovative-dna-technologies-science-medicine-mixed-media_so4sc8.jpg";

const ABOUT_PAGE_TECH_01 =
	"https://res.cloudinary.com/dvlzuwb4x/image/upload/v1666965393/gic-media/ABOUT/TECHNOLOGIES/dna-genetic-engineering-biotechnology-with-scientist-rsquo-s-hands-disruptive-technology-remix_fhjwkw.jpg";
const ABOUT_PAGE_TECH_02 =
	"https://res.cloudinary.com/dvlzuwb4x/image/upload/v1666863110/gic-media/ABOUT/TECHNOLOGIES/02_firf4t.jpg";
const ABOUT_PAGE_TECH_03 =
	"https://res.cloudinary.com/dvlzuwb4x/image/upload/v1666863105/gic-media/ABOUT/TECHNOLOGIES/03_hdr8ts.jpg";
const ABOUT_PAGE_TECH_04 =
	"https://res.cloudinary.com/dvlzuwb4x/image/upload/v1666863106/gic-media/ABOUT/TECHNOLOGIES/04_csei9p.jpg";
const ABOUT_PAGE_TECH_05 =
	"https://res.cloudinary.com/dvlzuwb4x/image/upload/v1666863110/gic-media/ABOUT/TECHNOLOGIES/05_jfc4cm.jpg";
const ABOUT_PAGE_TECH_06 =
	"https://res.cloudinary.com/dvlzuwb4x/image/upload/v1666863127/gic-media/ABOUT/TECHNOLOGIES/06_o4ljkv.jpg";

const ABOUT_PAGE_CEO =
	"https://res.cloudinary.com/dvlzuwb4x/image/upload/v1666888360/gic-media/ABOUT/mukil_izipji.png";

const ABOUT_PAGE_SCIENTIFIC_01 =
	"https://res.cloudinary.com/dvlzuwb4x/image/upload/v1666911216/gic-media/ABOUT/SCIENTIFIC/MANOJ_wlxgh4_g5flyh.png";
const ABOUT_PAGE_SCIENTIFIC_02 =
	"https://res.cloudinary.com/dvlzuwb4x/image/upload/v1666911244/gic-media/ABOUT/SCIENTIFIC/MicrosoftTeams-image_3_ggpgl5.png";
const ABOUT_PAGE_SCIENTIFIC_03 =
	"https://res.cloudinary.com/dvlzuwb4x/image/upload/v1669700405/gic-media/ABOUT/SCIENTIFIC/venket_mv3yip.webp";

const ABOUT_PAGE_MEDICAL_01 =
	"https://res.cloudinary.com/dvlzuwb4x/image/upload/v1666888452/gic-media/ABOUT/MEDICAL/mohan_rl0a97.jpg";
const ABOUT_PAGE_MEDICAL_02 =
	"https://res.cloudinary.com/dvlzuwb4x/image/upload/v1666909029/gic-media/6769264_60111_lxuwae.jpg";
const ABOUT_PAGE_MEDICAL_03 =
	"https://res.cloudinary.com/dvlzuwb4x/image/upload/v1668583026/gic-media/ABOUT/MEDICAL/Kavitha_Seerangaiyan_yzzl7j.jpg";

const HOME_PAGE_VIDEO =
	"https://res.cloudinary.com/dvlzuwb4x/video/upload/v1666887891/gic-media/HOME/logo_reveal_green_1_iarc00.mp4";

const HOME_PAGE_TEST =
	"https://res.cloudinary.com/dvlzuwb4x/image/upload/v1666862387/gic-media/HOME/iStock-1359120362_se8o2p.jpg";
const HOME_PAGE_COLLECT_CONNECT_SCIENCE =
	"https://res.cloudinary.com/dvlzuwb4x/image/upload/v1666862277/gic-media/HOME/collect-connect_y3czob.png";

const FAQ_HERO =
	"https://res.cloudinary.com/dvlzuwb4x/image/upload/v1666888254/gic-media/MicrosoftTeams-image_1_cofehg.jpg";


const CONTACT_US =
	"https://gutblobdev.blob.core.windows.net/gutlive/aa8225f6-877b-4125-83f4-f0ed82a03a79_Contact_bg-gut_2_4_46.jpg";

const TESTIMONIAL_01 =
	"https://res.cloudinary.com/dvlzuwb4x/image/upload/v1668534998/gic-media/TESTIMONIALS/testimonial_01_clysps.webp";
const TESTIMONIAL_02 =
	"https://res.cloudinary.com/dvlzuwb4x/image/upload/v1668535001/gic-media/TESTIMONIALS/testimonial_02_jhdmrq.webp";
const TESTIMONIAL_03 =
	"https://res.cloudinary.com/dvlzuwb4x/image/upload/v1668534999/gic-media/TESTIMONIALS/testimonial_03_uwdedw.webp";
const TESTIMONIAL_04 =
	"https://res.cloudinary.com/dvlzuwb4x/image/upload/v1668535000/gic-media/TESTIMONIALS/testimonial_04_plbj7y.webp";
const TESTIMONIAL_05 =
	"https://res.cloudinary.com/dvlzuwb4x/image/upload/v1668535000/gic-media/TESTIMONIALS/testimonial_05_u2fs4b.webp";
const TESTIMONIAL_DUMMY =
	"https://res.cloudinary.com/dvlzuwb4x/image/upload/v1668670858/gic-media/24943342_66z_62_people_faces_09_21_htm1fv.jpg";

const image_constants = {
	GIC_LOGO,
	GIC_LOGO_ALT,
	LANDING_PAGE_VIDEO,
	LANDING_PAGE_VIDEO_LOOP,
	LANDING_PAGE_MOBILE_VIDEO,
	LANDING_PAGE_BENEFIT_ICON_01,
	LANDING_PAGE_BENEFIT_ICON_02,
	LANDING_PAGE_BENEFIT_ICON_03,
	LANDING_PAGE_BENEFIT_ICON_04,
	LANDING_PAGE_BENEFIT_ICON_05,
	LANDING_PAGE_BENEFIT_ICON_06,
	LANDING_PAGE_BENEFIT_ICON_07,
	LANDING_PAGE_BENEFIT_ICON_08,
	ABOUT_PAGE_HERO,
	ABOUT_PAGE_HERO_NEW,
	ABOUT_PAGE_MISSION,
	ABOUT_PAGE_WHO,
	ABOUT_PAGE_TECH_01,
	ABOUT_PAGE_TECH_02,
	ABOUT_PAGE_TECH_03,
	ABOUT_PAGE_TECH_04,
	ABOUT_PAGE_TECH_05,
	ABOUT_PAGE_TECH_06,
	ABOUT_PAGE_CEO,
	ABOUT_PAGE_SCIENTIFIC_01,
	ABOUT_PAGE_SCIENTIFIC_02,
	ABOUT_PAGE_SCIENTIFIC_03,
	ABOUT_PAGE_MEDICAL_01,
	ABOUT_PAGE_MEDICAL_02,
	ABOUT_PAGE_MEDICAL_03,
	HOME_PAGE_VIDEO,
	HOME_PAGE_TEST,
	HOME_PAGE_COLLECT_CONNECT_SCIENCE,
	FAQ_HERO,
	TESTIMONIAL_01,
	TESTIMONIAL_02,
	TESTIMONIAL_03,
	TESTIMONIAL_04,
	TESTIMONIAL_05,
	TESTIMONIAL_DUMMY,
	CONTACT_US
};

export default image_constants;
