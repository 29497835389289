import React from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import useBreakpoints from "../../../hooks/useBreakpoints";

const OrderedCard = ({ activeOrder }) => {
  console.log('activeOrder',activeOrder);
  const { isLargeScreen } = useBreakpoints();
  const theme = useTheme();
  return (
    <Grid
      item
      container
      xs={12}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ borderRadius: 1, p: { xs: 2, sm: 3, md: 4 } }}
    >
      <Grid
        item
        xs={4}
        sx={{
          width: { xs: "120px", sm: "150px", md: "200px" },
          //height: { xs: "100px", sm: "150px", md: "200px" },
          height: "auto",
          borderRadius: 4,
          mx: { xs: "auto", lg: 0 },
          overflow: "hidden",
        }}
      >
        <img
          src={activeOrder?.product?.imageUrl[0]}
          alt="productImage"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </Grid>

      <Grid
        item
        xs={8}
        sx={{
          width: { xs: "100%", lg: "70%" },
          height: "150px",
          borderRadius: 2,
          //   padding: "2rem",
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ textAlign: { xs: "center", lg: "center" } }}>
          <img
            src={activeOrder?.product?.imageKey}
            alt={activeOrder?.product?.name}
            style={{
              width: !isLargeScreen ? "80%" : "60%",
              height: "auto",
              objectFit: "cover",
            }}
          />

          <Typography
            sx={{
              fontSize: { xs: "1.1rem", sm: "1.3", md: "1.5" },
              py: 1,
              fontWeight: 400,
              color: theme.palette.grey[600],
            }}
          >
            Quantity: {activeOrder?.quantity}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default OrderedCard;
