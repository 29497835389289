import { MailIcon, UserCircleIcon, UsersIcon } from "@heroicons/react/solid";
import { Box, FormControl, MenuItem, Select, useTheme } from "@mui/material";
import React from "react";
import { Link, useLocation } from "react-router-dom";

const ProfileDropDown = () => {
  const theme = useTheme();
  const location = useLocation();
  const listData = [
    {
      text: "Account",
      icon: <UserCircleIcon height={"18px"} color={theme.palette.secondary.main} />,
      path: "account",
    },
    // {
    // 	text: 'Test Health',
    // 	icon: <DocumentReportIcon height={'18px'} color={theme.palette.secondary.main} />,
    // },
    {
      text: "Addresses",
      icon: <MailIcon height={"18px"} color={theme.palette.secondary.main} />,
      path: "addresses",
    },
    {
      text: "Members",
      icon: <UsersIcon height={"18px"} color={theme.palette.secondary.main} />,
      path: "members",
    },
  ];

  const indexByRoute =
    listData.findIndex((x) => x.path === location.pathname.split("/")[2]) === -1
      ? 0
      : listData.findIndex((x) => x.path === location.pathname.split("/")[2]);

  return (
    <Box my={2} width="100%">
      <FormControl fullWidth>
        <Select labelId="choose-tab" id="choose-tab" value={indexByRoute} size="small">
          {listData.map((item, index) => {
            return (
              <MenuItem key={index} component={Link} to={item?.path} value={index}>
                {item?.text}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default ProfileDropDown;
