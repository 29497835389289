import React from 'react';
import {Box, Container, Typography, useTheme} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import ActionButton from '../Common/Buttons/ActionButton';
import ResponsiveImage from '../Common/ResponsiveImage';
import {NotFoundImage} from '../../assets';
import {ArrowLeftIcon} from '@heroicons/react/outline';

export const NotFound = () => {
	const theme = useTheme();
	const navigate = useNavigate();

	return (
		<Box sx={{display: 'grid', placeContent: 'center', height: '100vh'}}>
			<Container>
				<Box sx={{display: {xs: 'none', sm: 'block'}}}>
					{/* <Animation404 /> */}
					<ResponsiveImage img={NotFoundImage} alt={'404'} overRideStyles={{height: '340px'}} />
				</Box>
				<Box textAlign='center'>
					<Typography variant='subtitle1' sx={{color: theme.palette.common.greyDark}}>
						Not Found!
					</Typography>
					<Typography variant='subtitle1' sx={{color: theme.palette.common.greyDark}}>
						The page you are looking for does not exsist.
					</Typography>
				</Box>
				<Box sx={{my: 4, textAlign: 'center'}}>
					<ActionButton onClick={() => navigate(-1)} startIcon={<ArrowLeftIcon height={'18px'} />}>
						Go Back
					</ActionButton>
				</Box>
			</Container>
		</Box>
	);
};
