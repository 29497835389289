import React from "react";
// import {useDispatch} from "react-redux";
import {useSelector} from "react-redux";
import {Navigate, useLocation} from "react-router";
// import {checkUser} from "../../../features/users/userSlice";
import Loader from "../../Common/Loader";

const ProtectedRoute = ({children}) => {
	const {isAuthLoading} = useSelector((state) => state.auth);
	const location = useLocation();
	// const dispatch = useDispatch();

	const isLoggedIn = Boolean(localStorage.getItem("token"));

	if (isAuthLoading) return <Loader open={isAuthLoading} />;

	if (!isLoggedIn) return <Navigate to="/auth/mobilelogin" replace state={{from: location}} />;

	return <>{children}</>;
};

export default ProtectedRoute;
