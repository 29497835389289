import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import contactUsService from "./contactUsService";

const initialState = {
  contactUs: null,
  currentContentManagement: null,
  isContentManagementError: false,
  isContentManagementSuccess: false,
  isContentManagementLoading: false,
  ContentManagementMessage: "",
};

export const getAllContactUs = createAsyncThunk(
  "contactUs/getall",
  async (_, thunkAPI) => {
    try {
      return await contactUsService.getContactUs();
    } catch (error) {
      const message = (error && error.message) || error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

const contentManagementSlice = createSlice({
  name: "contactUs",
  initialState,
  reducers: {
    resetContentManagement: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllContactUs.pending, (state) => {
        state.isContentManagementLoading = true;
      })
      .addCase(getAllContactUs.fulfilled, (state, action) => {
        state.isContentManagementLoading = false;
        state.isContentManagementSuccess = true;
        state.contactUs = action.payload.contactUs;
      })
      .addCase(getAllContactUs.rejected, (state, action) => {
        state.isContentManagementLoading = false;
        state.isContentManagementError = true;
        state.isContentManagementSuccess = false;
        state.ContentManagementMessage = action.payload;
      });
  },
});

export const { resetContentManagement } = contentManagementSlice.actions;
export default contentManagementSlice.reducer;
