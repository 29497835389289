import React from 'react';
import {Grid} from '@mui/material';
import OrderedCard from './Common/OrderedCard';

const CheckoutSummary = ({activeOrder}) => {
	return (
		<Grid item xs={12}>
			{/* <Typography
        sx={{
          color: theme.palette.grey[700],
          fontWeight: 700,
          fontSize: { xs: "1rem", sm: "1.2rem" },
          mb: 4,
        }}
      >
        PRODUCT SUMMARY
      </Typography> */}
			<OrderedCard activeOrder={activeOrder} />
		</Grid>
	);
};

export default CheckoutSummary;
