import React from "react";
import {Grid} from "@mui/material";
import RecommendationsList from "./RecommendationList";
import General from "./Common/General";
import FoodTailored from "./Common/FoodTailored";
import Lifestyle from "./Common/Lifestyle";

const RecommendationsLists = ({currentSectionTitle, sections, currentSection,commonFood, reportId}) => {
	const componentsArr = [];

	const renderSections = () => {
		for (const [key, value] of Object.entries(sections)) {
			const component = (
				<RecommendationsList
					key={key}
					recommendationTitle={value}
					to={{
						pathname: `/reports/${reportId || "demo"}/recommendations`,
						search: `?recommendationsPath=${key}`,
					}}
				/>
			);

			componentsArr.push(component);
		}
	};

	renderSections();

	const renderDefault = (
		<Grid item container xs={12}>
			<Grid item xs={12} mt={{xs: 2, md: 4}}>
				{componentsArr?.map((component) => component)}
			</Grid>
		</Grid>
	);

	if (Boolean(currentSectionTitle)) {
		//selective rendering of sections
		switch (currentSectionTitle) {
			case "General Recommendation":
				return <General generalDescList={currentSection} />;

			case "Lifestyle Recommendation":
				return <Lifestyle generalDescList={currentSection} />;

			case "Food Tailored To The Microbiome":
				return <FoodTailored foodData={currentSection} commonFood={commonFood} />;

			default:
				return renderDefault;
		}
	}

	return renderDefault;
};

export default RecommendationsLists;
