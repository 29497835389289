import React from "react";
import {
  Box,
  Divider,
  Grid,
  InputAdornment,
  Typography,
  useTheme,
} from "@mui/material";
import TextInputField from "../Common/Form/TextInputField";
import AnswersGrouped from "./AnswersGrouped";

const Questionaries = ({ questions }) => {
  const theme = useTheme();

  const styles = {
    width: { xs: "100%" },
    mx: { xs: "auto" },
    // [`& fieldset`]: {
    // 	borderRadius: 3,
    // },
  };

  return (
    <>
      <Grid
        item
        container
        xs={12}
        sx={{
          py: { xs: 4, sm: 6 },
          px: { xs: 4, sm: 6 },
          boxShadow: "3px 3px 20px rgba(0, 0, 0, 0.07)",
          borderRadius: 3,
          my: 4,
        }}
      >
        <Grid item xs={12} mb={4}>
          <Box textAlign="center" mb={2}>
            <Typography
              sx={{
                fontSize: {
                  xs: "0.9rem",
                  sm: "1.2rem",
                  color: theme.palette.secondary.main,
                  fontWeight: 600,
                },
              }}
            >
              Thank you for registering with GUT-iCARE!
            </Typography>
          </Box>
          <Typography
            sx={{
              fontSize: {
                xs: "0.775rem",
                sm: "0.975rem",
                color: theme.palette.secondary.main,
              },
            }}
          >
            GOOD HEALTH STARTS IN THE GUT. We are a team of scientific
            professionals committed to improving your health by evaluating the
            positive impact of gut microbiomes to your wellness. To serve you
            better, please take a moment to fill out this form which will help
            us to provide precise report scores.
          </Typography>
        </Grid>

        <Grid item container xs={12} columnSpacing={1}>
          <Grid item xs={12} md={6}>
            <TextInputField
              name="gender"
              label="Gender"
              type="text"
              margin="dense"
              size="small"
              styles={styles}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInputField
              name="residence"
              label="Your Residence"
              type="text"
              margin="dense"
              size="small"
              styles={styles}
              disabled
            />
          </Grid>
        </Grid>

        <Grid item container xs={12} columnSpacing={1}>
          <Grid container item xs={12} md={6} columnSpacing={1}>
            <Grid item xs={12} md={4}>
              <TextInputField
                name="age"
                label="Age"
                type="number"
                margin="dense"
                size="small"
                styles={styles}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInputField
                name="weight"
                label="Weight"
                type="number"
                margin="dense"
                size="small"
                styles={styles}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                      <Typography
                        sx={{ color: theme.palette.common.greyLight }}
                      >
                        kg
                      </Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInputField
                name="height"
                label="Height"
                type="number"
                margin="dense"
                size="small"
                styles={styles}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                      <Typography
                        sx={{ color: theme.palette.common.greyLight }}
                      >
                        cm
                      </Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInputField
              name="bmi"
              label="BMI Calculation"
              type="number"
              margin="dense"
              size="small"
              styles={styles}
              disabled
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        container
        xs={12}
        mt={2}
        // columnSpacing={6}
        sx={{
          pb: { xs: 2, sm: 6 },
          // px: {xs: 4, sm: 6},
          boxShadow: "3px 3px 20px rgba(0, 0, 0, 0.07)",
          borderRadius: 3,
          my: 4,
        }}
      >
        <Grid
          item
          xs={12}
          mb={1}
          sx={{ px: { xs: 2, sm: 6 }, pt: { xs: 2, sm: 6 } }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "1.2rem",
              textTransform: "uppercase",
              color: theme.palette.common.greyDark,
            }}
          >
            Additional Details
          </Typography>
          <Box mt={2}>
            <Divider />
          </Box>
        </Grid>

        {Boolean(questions) && questions?.length > 0 ? (
          <>
            {questions?.map(
              ({ _id, question, name, options, optionType }, index) => (
                <Questions
                  key={_id}
                  index={index}
                  name={_id}
                  values={options}
                  question={question}
                  optionType={optionType}
                />
              )
            )}
          </>
        ) : (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              pt: "2rem",
            }}
          >
            <Typography sx={{ fontSize: "1rem" }}>
              Kit Activation questions not available
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

const Questions = ({ question, name, values, index, optionType }) => {
  const validate = (value) => {
    let error;
    if (!Boolean(value)) error = "Required";
    return error;
  };

  return (
    <Grid
      item
      xs={12}
      md={6}
      // mt={2}
      sx={{
        pt: { xs: 2, sm: 6 },
        px: { xs: 4, sm: 6 },
      }}
    >
      <Typography
        sx={{
          fontSize: { xs: "1.2rem", md: "1.4rem" },
          fontWeight: 700,
          mb: 1,
        }}
      >
        <span style={{ color: "#ccc" }}>#{index + 1}</span>
        &nbsp;&nbsp;&nbsp;
        {question}
      </Typography>

      <AnswersGrouped
        qindex={index}
        options={values}
        type="radio"
        validate={validate}
        optionType={optionType}
      />

      {/* <TextInputField
        name={`${name}.textValue`}
        placeholder="Additional Information/If Other Please Specify"
        type="text"
        margin="dense"
        size="small"
        styles={styles}
      /> */}

      {/* <Dropdown name={name} options={values} styles={styles} size='small' /> */}
    </Grid>
  );
};

export default Questionaries;
