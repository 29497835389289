import axios from '../../axios-instance';

const getContentManagements = async (qStr) => {
	const response = await axios.get(`/api/content-management`);
	return response.data;
};

const getContentManagementById = async (data) => {
	const response = await axios.get(`/api/content-management/${data.id}`);
	return response.data;
};

const contentManagementService = {
	getContentManagements,
	getContentManagementById,
};

export default contentManagementService;
