import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import Tags from '../../Common/Tags';

const BlogImageCard = ({ date, title, styles, img, blogDescription, handleNavigation }) => {
	const theme = useTheme();
	return (
		<Box onClick={handleNavigation}
			sx={{
				height: { xs: '380px', md: '400px', lg: '420px', cursor: 'pointer' },
				backgroundImage: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #004612 100%), url(${img})`,
				backgroundPosition: 'top',
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover',
				borderRadius: 1,
				borderTopLeftRadius: 60,
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				...styles,
			}}
		>
			<Box textAlign={'right'} p={2}>
				{/* <Tags tagName={'Most Liked'} /> */}
			</Box>

			<Box p={2}>
				<Typography color={theme.palette.common.greyLight} sx={{ fontSize: { xs: '0.725rem' } }}>
					{date}
				</Typography>
				<Typography
					color={theme.palette.common.white}
					sx={{ fontSize: { xs: '1.2rem', md: '1.4rem' } }}
					dangerouslySetInnerHTML={{
						__html:
							`${title}`
					}}
				>
					{/* {title} */}
				</Typography>
				<Typography
					sx={{
						fontSize: {
							xs: '0.825rem',
						},
						color: theme.palette.common.greyLight,
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						display: '-webkit-box',
						WebkitLineClamp: '2',
						WebkitBoxOrient: 'vertical',
						mt: 1,
					}}
					dangerouslySetInnerHTML={{
						__html:
							`${blogDescription}`
					}}
				>
					{/* {blogDescription} */}
				</Typography>
			</Box>
		</Box>
	);
};

export default BlogImageCard;
