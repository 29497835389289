import {Box, Container, Divider, Grid, Typography, useTheme} from "@mui/material";
import React from "react";
import {useNavigate} from "react-router-dom";
import {threeBoxesBanner4} from "../assets";
import ActionButton from "../components/Common/Buttons/ActionButton";
import ScrollToTop from "../components/hoc/ScrollToTop";
import HalfBannerPage from "../PageTemplates/HalfBannerPage";

const Instructions = () => {
	const navigate = useNavigate();
	const theme = useTheme();
	const overlayComponent = (
		<Box>
			<Typography
				sx={{
					color: "#000",
					fontSize: {xs: "1.8rem", sm: "2.8rem", md: "2.8rem"},
					fontWeight: 600,
					// display: "flex",
					//   zIndex: 100,
				}}>
				{"INSTRUCTIONS"}
			</Typography>
			<Divider
				width={"40%"}
				light
				sx={{mx: "auto", color: "#fff", backgroundColor: "#fff", mb: 1}}
			/>
			<Typography
				sx={{
					color: "#000",
					fontSize: {xs: "0.7rem", sm: "0.9rem", md: "0.9rem"},
					fontWeight: 400,
					// display: "flex",
					//   zIndex: 100,
				}}>
				{"WHO CAN TAKE THE TEST?"}
			</Typography>
		</Box>
	);
	return (
		<HalfBannerPage overlayComponent={overlayComponent} bannerImg={threeBoxesBanner4}>
			<ScrollToTop />

			<Container>
				<Grid
					container
					justifyContent={"center"}
					alignItems={"center"}
					sx={{p: "4rem", textAlign: "center"}}
				>
					<Grid item xs={12} mb={5}>
						<Typography sx={{color: theme.palette.secondary.main, fontWeight: 600}} variant="h4">
							YOUR POOP (STOOL) SAYS ABOUT YOUR HEALTH
						</Typography>
					</Grid>
					<Grid item xs={12} mb={4}>
						<Typography sx={{fontSize: {xs: "1rem", md: "1.2rem"}}}>
							Gut wall take up the role of security guard and allows selective nutrients to pass
							into the blood stream, the released nutrients are absorbed by the body to maintain
							proper health. If beneficial microbes are threatened, imbalance between beneficial and
							harmful microbes occurs. This can lead to excessive production of compounds that
							damages the gut wall by harmful microbes. This damage gives an entry for the harmful
							microbes and toxins into the blood stream and thus leads to the development of several
							health disorders.
						</Typography>
					</Grid>
					{/* <Grid item xs={12} my={4}>
						<Divider width={"50%"} sx={{mx: "auto"}} />
					</Grid> */}
					{/* <Grid item xs={12} mb={5}>
						<Typography sx={{fontSize: {xs: "1rem", md: "1.2rem"}}}>
							If beneficial good microbes are threatened, imbalance occurs. Due to the microbial
							imbalance many potential bad microbes prompt to show and produce compounds that
							damages the gut wall. Despite, this damage gives an entry way for the viable bad
							microbes and toxins into the blood stream and thus leads to the development of several
							health disorders.
						</Typography>
					</Grid> */}
					<Grid
						item
						xs={12}
						mb={0}
						p={5}
						sx={{
							background: theme.palette.primary.dark,
							//backdropFilter: "blur(10px) saturate(100%) contrast(45%) brightness(130%)",
						}}
					>
						<Typography sx={{fontSize: {xs: "1rem", sm: "1.3rem"}, color: "#eee"}}>
							What should be done to identify this imbalance between beneficial and harmful
							microbes?
						</Typography>
						<Typography
							sx={{
								fontSize: {xs: "1.1rem", sm: "1.6rem"},
								color: "#fff",
								textTransform: "uppercase",
								fontWeight: 600,
							}}
						>
							Answer is your poop gutXplore home test kit!!
						</Typography>
					</Grid>
					<Grid item xs={12} mb={0} p={5}>
						<ActionButton onClick={() => navigate("/products")} styles={{mt: 0}}>
							PRODUCTS
						</ActionButton>
					</Grid>
					<Grid item xs={12} mb={10}>
						<Typography sx={{fontSize: {xs: "1rem", md: "1.2rem"}}}>
							Stool test is done to investigate your potential healthy (beneficial) microbes and the
							barrier functions in the gut. By the end of gutXplore analysis we will find your
							potential helpers and you find the missing health link that you were looking for.
							Based on your health score we advise you with customized diet and lifestyle
							recommendations to improve your microbial diversity and overall health.
						</Typography>
					</Grid>
				</Grid>
			</Container>
		</HalfBannerPage>
	);
};

export default Instructions;
