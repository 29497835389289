import React from "react";
import {Checkbox, FormControlLabel, Box, FormHelperText} from "@mui/material";
import {useField, useFormikContext} from "formik";

const CustomCheckBox = ({name, label, ...otherProps}) => {
	const {setFieldValue} = useFormikContext();
	const [field, meta] = useField(name);

	const handleChange = (event) => {
		const {checked} = event.target;
		setFieldValue(name, checked);
	};

	const configCheckbox = {
		...field,
		onChange: handleChange,
	};

	const configFormControl = {};

	if (meta && meta.touched && meta.error) {
		configFormControl.error = true;
	}

	return (
		<Box>
			<FormControlLabel {...configCheckbox} control={<Checkbox />} label={label} />
			{meta.error && <FormHelperText error={true}>{meta.error}</FormHelperText>}
		</Box>
	);
};

export default CustomCheckBox;
