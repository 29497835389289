import {
  Box,
  Button,
  Container,
  Fab,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
  CalendarIcon,
  ChatIcon,
  ChevronUpIcon,
  CollectionIcon,
  HeartIcon,
  ShareIcon,
  UserIcon,
} from "@heroicons/react/outline";

import { useDispatch, useSelector } from "react-redux";

import SectionPadding from "../../components/Common/SectionPadding";
import SidePanel from "../../components/Blogs/SidePanel";
import BlogDescription from "../../components/Blogs/BlogDescription";
import useBreakpoints from "../../hooks/useBreakpoints";
import ScrollTopButton from "../../components/Common/ScrollTopButton";
import RelatedPosts from "../../components/Blogs/RelatedPosts";
import ScrollToTop from "../../components/hoc/ScrollToTop";
// import SocialIcons from '../../components/Common/SocialIcons';
import data from "../../components/Blogs/data.json";
import { useEffect } from "react";
import { format } from "date-fns";
import {
  getBlog,
  getAllBlogs,
  resetBlog,
  getCategoryBlog
} from "../../features/blogs/blogSlice";
import '../../index.css'
// import data from '../../components/Blogs/data.json';

const BlogById = () => {
  const { isMediumScreen, isSmallScreen } = useBreakpoints();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const theme = useTheme();
  useEffect(() => {
    dispatch(getAllBlogs());
    return () => dispatch(resetBlog());
  }, [dispatch]);

  const { currentBlog, categoryBlogs } = useSelector((state) => state.blog);
  const { blogs, blogCategories } = useSelector((state) => state.blog);

  // eslint-disable-next-line eqeqeq
  const blog = data.blogs.filter(({ id }) => params.blogId == id);

  useEffect(() => {
    const data = { id: params.blogId };
    dispatch(getBlog(data));
    return () => dispatch(resetBlog());
  }, [dispatch, params.blogId]);

  useEffect(() => {
    if (!blogs) {
      dispatch(getAllBlogs());

    } else {
      dispatch(getCategoryBlog(currentBlog?.blog?.categoryKeyName));
    }
  }, [dispatch, blogs]);
  return (
    <>
      <ScrollToTop />
      <SectionPadding>
        <Container sx={{ position: "relative" }}>
          <Grid container sx={{ pb: { md: "4rem", marginTop: "2rem" } }}>
            <Grid item xs={12} md={7} sx={{ p: 1 }}>
              <Grid item container xs={12}>
                <Grid item xs={12}>
                  <Typography
                    variant="h2"
                    sx={{
                      textTransform: "capitalize",
                      color: theme.palette.secondary.main,
                      fontSize: { xs: "1.2rem", md: "2rem" },
                      fontWeight: 400,
                    }}
                    dangerouslySetInnerHTML={{
                      __html:
                        `${currentBlog?.blog?.title}`
                    }}
                  >
                    {/* {currentBlog?.blog?.title} */}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: { sm: "center" },
                      gap: 2,
                      my: 2,
                      flexDirection: { xs: "column", sm: "row" },
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 0.5,
                        fontSize: "0.725rem",
                      }}
                      color={theme.palette.common.greyDark}
                    >
                      <CalendarIcon
                        height={"18px"}
                        color={theme.palette.common.greyDark}
                      />
                      <span>
                        {currentBlog?.blog?.createdAt
                          ? format(
                            new Date(currentBlog?.blog?.createdAt),
                            "dd MMMM, yyyy"
                          )
                          : ""}
                      </span>
                    </Typography>
                    {/* <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 0.5,
                        fontSize: "0.725rem",
                      }}
                      color={theme.palette.common.greyDark}
                    >
                      <CollectionIcon
                        height={"18px"}
                        color={theme.palette.common.greyDark}
                      />
                      <span>{currentBlog?.blog?.category}</span>
                    </Typography> */}
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <BlogDescription
                    description={currentBlog?.blog?.description}
                    image={currentBlog?.blog?.imageurl}
                  />
                  {/* <BlogDescription description={currentBlog?.blog?.description} /> */}
                </Grid>

                {/* <Grid
									item
									xs={12}
									my={'2rem'}
									sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
								>
									<Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
										<Typography
											sx={{display: 'flex', alignItems: 'center', gap: 1, cursor: 'pointer'}}
										>
											<HeartIcon height={'18px'} />
											<span>63</span>
										</Typography>
										<Typography
											sx={{display: 'flex', alignItems: 'center', gap: 1, cursor: 'pointer'}}
										>
											<ChatIcon height={'18px'} />
											<span>Comments</span>
										</Typography>
									</Box>
									<Box>
										<Typography
											sx={{display: 'flex', alignItems: 'center', gap: 1, cursor: 'pointer'}}
										>
											<ShareIcon height={'18px'} />
											<span>Share</span>
										</Typography>
									</Box>
								</Grid> */}
                {/* <Grid
									item
									xs={12}
									mt={'2rem'}
									sx={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between',
										// border: '2px solid #ddd',
										// borderRadius: 1,
										// px: 2,
										// py: 4,
									}}
								>
									<Box sx={{ cursor: 'pointer' }}>
										<Button
											sx={{
												color: theme.palette.common.greyDark,
												display: 'flex',
												alignItems: 'center',
												textTransform: 'capitalize',
												gap: 2,
												'&:hover': {
													color: theme.palette.primary.main,
												},
											}}
											onClick={() => navigate(`${blog[0].id - 1}`)}
										>
											<ArrowNarrowLeftIcon height={'18px'} />
											<span>Previous Blog</span>
										</Button>
									</Box>
								</Grid> */}
                {/* <Grid
									item
									xs={12}
									mt={'2rem'}
									sx={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between',
										// border: '2px solid #ddd',
										// borderRadius: 1,
										// px: 2,
										// py: 4,
									}}
								>
									<Box sx={{cursor: 'pointer'}}>
										<Button
											sx={{
												color: theme.palette.common.greyDark,
												display: 'flex',
												alignItems: 'center',
												textTransform: 'capitalize',
												gap: 2,
												'&:hover': {
													color: theme.palette.primary.main,
												},
											}}
											onClick={() => navigate(`${blog[0].id - 1}`)}
										>
											<ArrowNarrowLeftIcon height={'18px'} />
											<span>Previous Blog</span>
										</Button>
									</Box>
									<Box sx={{cursor: 'pointer'}}>
										<Button
											sx={{
												color: theme.palette.common.greyDark,
												display: 'flex',
												textTransform: 'capitalize',
												alignItems: 'center',
												gap: 2,
												'&:hover': {
													color: theme.palette.primary.main,
												},
											}}
											onClick={() => navigate(`${blog[0].id}`)}
										>
											<span>Next Blog</span>
											<ArrowNarrowRightIcon height={'18px'} />
										</Button>
									</Box>
								</Grid> */}
              </Grid>
            </Grid>
            {/* // <Grid item xs={12} md={4} sx={{px: 4, py: 1}}>
							// 	<SidePanel />
							// </Grid> */}
            {/* {isMediumScreen && ( */}
            <Grid
              style={{ marginTop: isSmallScreen ? "6rem" : '5px', padding: '10px' }}
              item
              xs={12}
              md={5}
            // sx={{ pb: { marginTop: "5rem", paddingLeft: "10px" } }}
            >
              <Box textAlign="justify">
                <Typography className="landingNeedGuts"
                  paragraph={true}
                  sx={{ whiteSpace: "pre-line", mt: 2 }}
                  dangerouslySetInnerHTML={{
                    __html: `${currentBlog?.blog?.description}`,
                  }}
                >
                  {/* {dangerouslySetInnerHTML={{__html: thisIsMyCopy}}}
										{currentBlog?.blog?.description} */}
                </Typography>
              </Box>
            </Grid>
            {/* )} */}
          </Grid>
          <Box mt={"4rem"}>
            <RelatedPosts blogs={blogs?.blog} />
          </Box>
        </Container>
      </SectionPadding>
      <ScrollTopButton>
        <Fab
          size={"medium"}
          aria-label="scroll back to top"
          sx={{
            background: theme.palette.primary.main,
            "&:hover": {
              background: {
                xs: theme.palette.primary.main,
                md: theme.palette.primary.light,
              },
            },
          }}
        >
          <ChevronUpIcon style={{ height: "1.2rem", color: "#fff" }} />
        </Fab>
      </ScrollTopButton>
    </>
  );
};

export default BlogById;
