import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import { useDispatch } from "react-redux";
import DialogModal from "../../Common/DialogModal";
import ProfileForm from "./ProfileForm";
import { createPerson, getPersons, updatePerson } from "../../../features/profile/profileSlice";
import { format } from "date-fns";
import axios from "../../../axios-instance";
import { useLocation, useParams } from "react-router-dom";

import { updateOrderPerson } from "../../../features/orders/orderSlice";
const AddEditProfile = ({ handleClose, open, memberData }) => {
	const { pathname } = useLocation();
	const params = useParams();
	const dispatch = useDispatch();
	const minAge = 18;
	const maxDate = () => {
		var date = new Date();
		date.setFullYear(date.getFullYear() - minAge);
		const newDate = `${date.getMonth()}/${date.getDate()}/${date.getFullYear()}`;
		return newDate;
	};

	const validate = (values, props /* only available when using withFormik */) => {
		const errors = {};

		if (!values.firstName) {
			errors.firstName = "Required";
		}

		if (!values.lastName) {
			errors.lastName = "Required";
		}

		if (!values.gender) {
			errors.gender = "Required";
		}

		// if (values.dob) {
		// 	const currentDate = new Date(Date.now());
		// 	const placedDate = new Date(values.dob); // 86400000;
		// 	const diff = currentDate.getFullYear() - placedDate.getFullYear();
		// 	if (diff < minAge) errors.dob = "Age must be at least 18";
		// }

		if (!values.dob) {
			errors.dob = "Required";
		}

		if (!values.weight) {
			errors.weight = "Required";
		}

		if (!values.height) {
			errors.height = "Required";
		}

		//...

		return errors;
	};

	// const handlePostData = (values) => {
	//   if (memberData?.id === "") {
	//     const data = {
	//       firstName: values.firstName,
	//       lastName: values.lastName,
	//       gender: values.gender,
	//       dob: values.dob,
	//       height: values.height,
	//       weight: values.weight,
	//       // isDefault: values.default,
	//     };
	//     dispatch(createPerson(data));
	//   }

	//   if (memberData?.id !== "") {
	//     const data = {
	//       id: memberData?.id,
	//       firstName: values.firstName,
	//       lastName: values.lastName,
	//       gender: values.gender,
	//       dob: values.dob,
	//       height: values.height,
	//       weight: values.weight,
	//       // isDefault: values.default,
	//     };
	//     dispatch(updatePerson(data));
	//   }
	// };

	const handleAddUpdateMember = async (values) => {
		const data = {
			id: memberData?._id,
			body: {
				firstName: values.firstName,
				lastName: values.lastName,
				gender: values.gender,
				dob: values.dob,
				height: values.height,
				weight: values.weight,
			},
		};
		console.log("memberdataa", data);

		if (memberData?.id === "") {
			try {
				if (pathname?.includes("checkout")) {
					const url = "/api/persons";
					const response = await axios.post(url, data.body);
					if (response.statusText === "OK") {
						dispatch(updateOrderPerson({ id: response?.data?.person?._id, orderId: params?.id }));
						dispatch(getPersons());
					}
				} else {
					dispatch(createPerson(data.body));
				}
			} catch (error) {
				console.log(error);
			}
		} else dispatch(updatePerson(data));
	};

	// useEffect(() => {}, []);

	return (
		<Formik
			initialValues={{
				firstName: memberData?.firstName,
				lastName: memberData?.lastName,
				gender: memberData?.gender,
				dob: format(new Date(memberData?.dob || new Date()), "MM/dd/yyyy"),
				weight: memberData?.weight,
				height: memberData?.height,
				// default: memberData?.isDefault || false,
			}}
			validate={(values) => validate(values)}
			onSubmit={(values, { resetForm }) => {
				// handlePostData(values);
				handleAddUpdateMember(values);
				handleClose();
				resetForm();
			}}
		>
			{({ handleSubmit }) => (
				<Form>
					<DialogModal
						buttonOne={"Cancel"}
						buttonTwo={"Save"}
						handleClose={handleClose}
						handlePrimaryClick={handleSubmit}
						handleButtonOneClick={handleClose}
						open={open}
						title={"Add/Edit Profile"}
					>
						{/* <ProfileForm maxDate={maxDate} minAge={minAge} /> */}
						<ProfileForm />
					</DialogModal>
				</Form>
			)}
		</Formik>
	);
};

export default AddEditProfile;
