import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import axios from "../../../axios-instance";
import ActionButton from "../../../components/Common/Buttons/ActionButton";
import CircularProgress from "@mui/material/CircularProgress";
// import PdfGenerator from '../../pdf/PdfGenerator';

export default function ReportDownloadPDF({ reportId }) {
  const [pdfLink, setPdfLink] = useState();
  const [loading, isSetLoading] = useState(false);
  const downloadReportPDF = async () => {
    try {
      isSetLoading(true);
      const response = await axios.get(`api/reports/generatePdf/${reportId}`);
      if (response) {
        isSetLoading(false);
        var path2 = response?.data?.data.replace(/\\/g, "/");
        setPdfLink(path2);
        // window.open(`http://localhost:5000/${path2}`);
        window.open(`${process.env.REACT_APP_BACKEND_URL}/${path2}`);
        // window.open(`http://40.81.235.35:5001/${path2}`);
      }
    } catch (error) {
      isSetLoading(false);
    }
  };
  useEffect(() => {}, [pdfLink]);

  return (
    <Grid item xs={12} mt={1} style={{ textAlign: "center" }}>
      {/* <PdfGenerator /> */}
      {loading ? (
        <ActionButton disabled>
          <CircularProgress
            style={{ color: "#fff", width: "30px", height: "30px" }}
          />{" "}
          Downloading Report
        </ActionButton>
      ) : (
        <ActionButton onClick={downloadReportPDF}>Download Report</ActionButton>
      )}
    </Grid>
  );
}
