import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import PolarChartMobile from '../../Charts/PolarChartMobile';
import { useSelector } from "react-redux";

const desc =
	'You scored poorly in 2 out of 8 scores. It means your overall health is satisfactory, congrats! However, some of your bugs need some help to live their best lives (so they can help you live your best, healthiest life). ';

const MobileOverallHealthResult = ({ overallHealthReport = desc }) => {
	const { reportData, isReportLoading } = useSelector((state) => state.report);
	const theme = useTheme();
	const data = {
		'chartData': reportData?.kit?.report?.healthScores,
		'allowedEntities': ['Microbiome wellness', 'Gut layer wellness', 'Inflammatory bowel disease (IBD)', 'Heart wellness', 'Diabetic wellness', 'Weight regulation', 'Stress wellness', 'Constipation', 'Chronic fatigue', 'Liver wellness']
	};
	return (
		<>
			<Grid item xs={12} my={{ xs: 2, md: 4 }} mx={'auto'}>
				<PolarChartMobile data={data} />
			</Grid>
		</>
	);
};

export default MobileOverallHealthResult;
