import React from "react";
import { Avatar } from "@mui/material";

const CustomAvatar = ({ alt, src, fallback, styles, bgColor, ...otherProps }) => {
  return (
    <Avatar
      alt={alt}
      src={src}
      sx={{
        cursor: "pointer",
        width: { xs: 30, sm: 40, md: 50 },
        height: { xs: 30, sm: 40, md: 50 },
        backgroundColor: bgColor ? bgColor : "#3caa4847",
        ...styles,
      }}
      {...otherProps}
    >
      {fallback}
    </Avatar>
  );
};

export default CustomAvatar;
