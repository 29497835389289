import React from "react";
import {useTheme} from "@mui/material";
import DialogModal from "./DialogModal";
import {ExclamationIcon} from "@heroicons/react/outline";

const DeleteModal = ({handleClose, open, deleteText, handlePrimaryClick}) => {
	const theme = useTheme();
	return (
		<DialogModal
			btnSubmit="submit"
			buttonOne={"Cancel"}
			buttonTwo={"Delete"}
			handleButtonOneClick={handleClose}
			handleClose={handleClose}
			handlePrimaryClick={handlePrimaryClick}
			open={open}
			title={"Are You Sure?"}
			icon={<ExclamationIcon height={"80px"} color={theme.palette.error.main} />}
			content={deleteText}
		></DialogModal>
	);
};

export default DeleteModal;
