import React from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { CheckCircleIcon } from "@heroicons/react/outline";

const Lifestyle = ({ generalDescList }) => {
    const theme = useTheme();

    // function isUnique(data, index, newData) {
    // 	return arr.findIndex((x) => x.id === data.id) === newData;
    // }

    return (
        <Grid item xs={12} mt={5}>
            <Box mt={2} px={2} style={{ textAlign: "justify" }}>

                <b>LIFE STYLE RECOMMENDATION</b><br />
                <Typography mt={2} sx={{ alignItems: "center", gap: 2, color: "#000" }}>
                    A healthy gut is the foundation of overall well-being, the gut microbiome influences immune development, regulates immune responses, and helps maintain the integrity of <span style={{ color: "#000" }}>Gastrointestinal</span> (GI) tract.
                </Typography>
                <Typography mt={2} sx={{ alignItems: "center", gap: 2, color: "#000" }}>
                    <span style={{ color: "#000" }}> The GI tract starts </span>from the mouth to the anus, the food you eat goes on an incredible journey through your GI tract. It encounters twists, turns, and remarkable digestive processes along the way, ensuring that you extract the nutrients you need and eliminate the waste. It's a truly remarkable system that keeps your body nourished and happy!
                    But the ‘WAY OF OUR LIFESTYLE’ too had a lot of twists, turns and ended-up with ‘MODERNISED LIFE-STYLE’ where we started losing our GUT HEALTH.
                </Typography>
                <Typography mt={2} sx={{ textAlign: "center", gap: 2, fontWeight: "800", color: "#000" }}>
                    “Take care of your gut, and it will take care of you”
                </Typography>
                <Typography mt={2} sx={{ alignItems: "center", gap: 2, color: "#000" }}>
                    Tips: Slight changes in our life-style bring the good vibe to our gut health. Take a note of our          lifestyle recommendations.

                </Typography>
                <Typography mt={2} sx={{ gap: 2, fontWeight: "800" }}>
                    Habitual impact
                </Typography>
                <Typography mt={2} sx={{ gap: 2, paddingLeft: "2%" }}>
                    <ul>
                        <li>The most missed habits in our bucket list is getting enough exposure to the ‘SUNLIGHT’. Spending at least 10 minutes of your time under ‘DIRECT’ sunlight increases vitamin D absorption and energy level.</li>
                        <li>Maintain BMI (Body Mass Index) - being overweight cause several health issues like heart disease, high blood pressure, high cholesterol and type 2 diabetes.</li>
                        <li>Consuming liquor, coffee, artificial sugars, carbonated drinks and cigarettes can interfere with the digestive system, and leads to problems like stomach ulcers, heartburn, obesity, etc.</li>
                        <li>Consume healthy meal and snacks on a ‘regular intervals’, it helps to keep your digestive system on track.</li>
                        <li>Chew food well before swallowing, it helps in less bloating, fullness and satisfaction of food consumption. </li>
                        <li>We all have to AGREE, that we have a less knowledge of ‘Antibiotics and Steroids’ usage. On the counter self-medication habits would kill your beneficial bacteria up to 65% within 2 days. </li>
                    </ul>
                </Typography>
                <Typography mt={2} sx={{ gap: 2, fontWeight: "800" }}>
                    Meal pattern
                </Typography>
                <Typography mt={2} sx={{ gap: 2, paddingLeft: "2%" }}>
                    <ul>
                        <li>Eat variety of foods to ensure a balanced diet like;
                            <ul style={{ paddingLeft: "3%" }}>
                                <li>400 g of fruits and vegetables.</li>
                                <li>48 g of Whole grains.</li>
                                <li>60 to of pulses.</li>
                                <li>141 g of protein rich Poultry, fish or meat.</li>
                                <li>300 g of Dairy products.</li>
                                <li>Healthy oils 2 to 3 table spoons (Optional).</li></ul></li>
                        <li>Ensure moderate use of edible oils, animal foods and ghee/ butter.</li>
                        <li>Consume high fiber foods such as whole grains, vegetables, legumes, and fruits. It improves your digestive health and maintains a healthy gut.</li>
                        <li>Avoid excessive intake of ice-cream, chocolates, carbonated drinks and frozen foods, they disturb healthy gut microbiome.</li>
                        <li>Try making your own fermented foods which is rich in natural probiotics (Example: yogurt, kefir, fermented vegetables and sauerkraut) that strengthens your gut health.</li>
                    </ul>
                </Typography>
                <Typography mt={2} sx={{ gap: 2, fontWeight: "800" }}>
                    Sleeping cycle
                </Typography>
                <Typography mt={2} sx={{ gap: 2, paddingLeft: "2%" }}>
                    <ul>
                        <li>Try to get at least 7 hours of sleep daily, lack of sleep leads to stress, depression, obesity and digestive disorders.</li>
                        <li>Digestive Discomfort: Eating a large meal shortly before bedtime can lead to indigestion, acid reflux, or heartburn. These discomforts can make it difficult to fall asleep and can disrupt sleep throughout the night.</li>
                        <li>Sleep Disruptions: Digesting a heavy meal requires energy and can increase metabolic activity, potentially interfering with the body's ability to enter a deep and restful sleep. This can lead to fragmented or poor-quality sleep.</li>
                        <li>Reduce caffeine consumption to 1–2 cups before noon. It has been scientifically shown that coffee drinking helps to balance beneficial  gut bacteria, but excessive intake known to have negative impacts in sleep quality.
                        </li>
                    </ul>
                </Typography>
                <Typography mt={2} sx={{ gap: 2, fontWeight: "800" }}>
                    Stress management
                </Typography>
                <Typography mt={2} sx={{ gap: 2, paddingLeft: "2%" }}>
                    <ul>
                        <li>Stress management is an art of overall health benefits. Practicing yoga, meditation and greeting your day leads to have a happy mood and thus positively impact your stress level.  </li>
                        <li>Do a simple breathing technique called ‘box breathing' (in for 4 counts, hold for 4 counts, exhale for 4 counts, hold for 4 counts). It is a powerful and effective way to reduce stress.</li>
                        <li>Try tapping on fore head in the middle of eyebrows, it is another method to reduce stress.
                        </li>
                    </ul>
                </Typography>
                <Typography mt={2} sx={{ gap: 2, fontWeight: "800" }}>
                    Being active
                </Typography>
                <Typography mt={2} sx={{ gap: 2, paddingLeft: "2%" }}>
                    <ul>
                        <li>Follow regular exercise minimum 150 minutes a week, It strengthens gastrointestinal (GI) tract muscles and regulates the digestion process. </li>
                        <li>The proper digestion increases your energy level. </li>
                        <li>Avoiding sedentary (lazy) lifestyle helps to improve over-all health.</li>
                        <li>Try not to jump from couch to marathon race. Start with ‘slow to steady’, in which increases your success rate in maintaining optimal health conditions.</li>
                        <li>Enjoy doing your own needs without blaming.
                        </li>
                    </ul>
                </Typography>
            </Box>
        </Grid >
    );
};

export default Lifestyle;
