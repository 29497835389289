import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Link,
  Typography,
  useTheme,
  Button
} from "@mui/material";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { LogoAlt, googlePlayStoreImage, appleStorageImage } from "../../../assets";
import EmailSubscribe from "./EmailSubscribe";
import SocialIcons from "../SocialIcons";
import PoliciesModal from "../TandC/PoliciesModal";
import { useDispatch, useSelector } from "react-redux";
import { getAllProducts } from "../../../features/products/productSlice";
// import SocialIcons from './SocialIcons';
import { Google, Apple } from "@mui/icons-material";

import {
  getAllContentManagements,
  resetContentManagement,
} from "../../../features/contentManagement/contentManagementSlice";

import { getAllContactUs } from "../../../features/contactUs/contactUsSlice";

const links = [
  "terms and conditions",
  "shipment policy",
  "Privacy policy",
  "Refund policy",
];

const data = [
  {
    id: 1,
    title: "Useful information",
    links: [
      {
        id: 1,
        toLink: "/benefits",
        text: "Gut Health",
      },
      {
        id: 2,
        toLink:
          "/reports/demo/recommendations?recommendationsPath=foodRecommendation",
        text: "Diet recommendations",
      },
      {
        id: 3,
        toLink: "/reports/demo",
        text: "Demo Report",
      },
      {
        id: 4,
        toLink: "/faqs",
        text: "FAQ",
      },
    ],
  },
];

const Footer = ({ handleScrollToSection }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const [defaultValue, setDefaultValue] = useState(0);
  const [openTandC, setOpenTandC] = useState(false);

  const { products } = useSelector((state) => state.product);
  const { ContentManagements, footerContentManagements } = useSelector(
    (state) => state.contentManagement
  );
  const { contactUs } = useSelector((state) => state.contactUs);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllProducts());
    dispatch(getAllContactUs());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getAllProducts());
    dispatch(getAllContactUs());
    dispatch(getAllContentManagements());
  }, []);
  useEffect(() => {
    dispatch(getAllProducts());
    dispatch(getAllContactUs());
    dispatch(getAllContentManagements());
  }, [location]);
  useEffect(() => {
    dispatch(getAllContentManagements());
    return () => dispatch(resetContentManagement());
  }, [dispatch]);

  const handlePoliciesClick = (e, index, data) => {
    if (data?.type === 'privacy_policy') {
      navigate('/privacypolicy');
    } else {
      setDefaultValue(index);
      setOpenTandC(true);
    }
  };

  return (
    <Grid
      container
      sx={{
        background: `linear-gradient(to right bottom, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
        position: "relative",
        zIndex: 10,
      }}
    >
      <Container>
        <Grid item xs={12} mt={{ xs: 0, md: -15 }}>
          <EmailSubscribe />
        </Grid>

        <Grid item xs={12} textAlign={"center"}>
          <img
            src={LogoAlt}
            alt="logo"
            style={{
              height: "auto",
              width: "250px",
            }}
          />
        </Grid>

        <Grid
          item
          container
          xs={12}
          justifyContent={{ xs: "center", sm: "unset" }}
          alignItems={{ xs: "center", sm: "unset" }}
          sx={{
            display: "grid",
            gridTemplateColumns: {
              sm: "repeat(auto-fill, minmax(250px, 1fr))",
            },

            gridAutoRows: "auto",
            gridGap: "1rem",
            my: 6,
            justifyItems: { lg: "center" },
          }}
        >
          {data.map(({ id, title, links }) => (
            <Grid item xs={12} key={id}>
              <Typography
                variant="h6"
                sx={{ fontWeight: 600, color: theme.palette.grey[300] }}
              >
                {title}
              </Typography>
              <Box width="100%" mt={1.5}>
                {links.map(({ id, text, toLink, linkedId }) => (
                  <Link
                    key={id}
                    component={RouterLink}
                    to={toLink}
                    sx={{
                      textDecoration: "none",
                      color: "#ddd",
                      display: "block",
                      cursor: "pointer",
                      py: 0.4,
                      transition: ".2s ease-out",
                      "&:hover": {
                        color: "#fff",
                        transition: ".2s ease-out",
                      },
                    }}
                  >
                    {text}
                  </Link>
                ))}
              </Box>
            </Grid>
          ))}

          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{ fontWeight: 600, color: theme.palette.grey[300] }}
            >
              About US
            </Typography>
            <Box width="100%" mt={1.5}>
              <Link
                component={RouterLink}
                to={`/about`}
                sx={{
                  textDecoration: "none",
                  color: "#ddd",
                  display: "block",
                  cursor: "pointer",
                  py: 0.4,
                  transition: ".2s ease-out",
                  "&:hover": {
                    color: "#fff",
                    transition: ".2s ease-out",
                  },
                }}
                onClick={handleScrollToSection}
              >
                Technology
              </Link>
              <Link
                component={RouterLink}
                to={`/careers/apply`}
                sx={{
                  textDecoration: "none",
                  color: "#ddd",
                  display: "block",
                  cursor: "pointer",
                  py: 0.4,
                  transition: ".2s ease-out",
                  "&:hover": {
                    color: "#fff",
                    transition: ".2s ease-out",
                  },
                }}
              >
                Careers
              </Link>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{ fontWeight: 600, color: theme.palette.grey[300] }}
            >
              Order Kit
            </Typography>
            <Box width="100%" mt={1.5}>
              {products?.products?.map((product) => (
                <Link
                  key={product?._id}
                  component={RouterLink}
                  to={`/products/${product?._id}`}
                  sx={{
                    textDecoration: "none",
                    color: "#ddd",
                    display: "block",
                    cursor: "pointer",
                    py: 0.4,
                    transition: ".2s ease-out",
                    "&:hover": {
                      color: "#fff",
                      transition: ".2s ease-out",
                    },
                  }}
                >
                  {product?.name}
                </Link>
              ))}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{ fontWeight: 600, color: theme.palette.grey[300] }}
            >
              {/* {contactUS.title} */}
              Contact
            </Typography>
            <Box width="100%" mt={1.5}>
              {contactUs?.map(({ _id, email, mobile_number }) => (
                <>
                  <Link
                    key={_id}
                    // component={RouterLink}
                    // to={toLink}
                    sx={{
                      textDecoration: "none",
                      color: "#ddd",
                      display: "block",
                      cursor: "pointer",
                      py: 0.4,
                      transition: ".2s ease-out",
                      "&:hover": {
                        color: "#fff",
                        transition: ".2s ease-out",
                      },
                    }}
                  >
                    {email}
                  </Link>
                  <Link
                    key={_id}
                    // component={RouterLink}
                    // to={toLink}
                    sx={{
                      textDecoration: "none",
                      color: "#ddd",
                      display: "block",
                      cursor: "pointer",
                      py: 0.4,
                      transition: ".2s ease-out",
                      "&:hover": {
                        color: "#fff",
                        transition: ".2s ease-out",
                      },
                    }}
                  >
                    {mobile_number}
                  </Link>
                </>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Grid
        item
        container
        p={4}
        xs={12}
        justifyContent={{ xs: "center", sm: "unset" }}
        alignItems={{ xs: "center", sm: "unset" }}
        sx={{
          display: "grid",
          gridTemplateColumns: { sm: "repeat(auto-fill, minmax(500px, 1fr))" },

          gridAutoRows: "auto",
          gridGap: "2rem",

          justifyItems: { lg: "center" },
        }}
      >
        <Grid
          item
          xs
          my="auto"
          sx={{
            display: "flex",
            gap: 2,
            flexDirection: { xs: "column", sm: "row" },
            textAlign: { xs: "center", sm: "unset" },
          }}
        >
          {footerContentManagements?.map((data, i) => (
            <Link
              key={i}
              // component={RouterLink}
              to={"#"}
              sx={{
                textTransform: "capitalize",
                textDecoration: "none",
                color: "#ddd",
                cursor: "pointer",
                transition: ".2s ease-out",
                "&:hover": {
                  color: "#fff",
                  transition: ".2s ease-out",
                },
              }}
              onClick={(e) => {
                handlePoliciesClick(e, i, data);
              }}
            >
              {data.name}
            </Link>
          ))}
        </Grid>


        <Grid
          item
          xs
          my="auto"
          sx={{ textAlign: { xs: "center", sm: "unset" } }}
        >
          <Typography sx={{ color: theme.palette.common.white }}>
            @2022 All Rights Reserved
          </Typography>
        </Grid>

        <Grid
          item
          xs
          my="auto"
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: { xs: "center", sm: "unset" },
          }}
        >
          <SocialIcons />
        </Grid>
        <Grid>
          <Button
            onClick={() => {
              window.open('https://play.google.com/store/apps/details?id=com.guticare&hl=en&gl=US', '_blank');
            }}
          >
            <img
              src={googlePlayStoreImage}
              // src="https://gutblobdev.blob.core.windows.net/gutlive/7358199e-c466-44b1-8d09-fd522b6f5260_googlePlay.561c3348%20(2)_10_12_1.png"
              alt="Google Play"
              style={{ marginRight: 8, height: 40 }}
            />
          </Button>

          <Button
            onClick={() => {
              window.open('https://apps.apple.com/app/your-app-id', '_blank');
            }}
          >
            <img
              src={appleStorageImage}
              // src="https://gutblobdev.blob.core.windows.net/gutlive/2967a215-2d46-492f-bb92-e4f6c82f9b9c_appleStore.61c0c555_10_12_2.png"
              alt="Google Play"
              style={{ marginRight: 8, height: 40 }} // Adjust the styling as needed
            />
          </Button>
        </Grid>
      </Grid>
      <PoliciesModal
        open={openTandC}
        handleClose={() => setOpenTandC(false)}
        defaultValue={defaultValue}
      />
    </Grid>
  );
};

export default Footer;
