import { Divider, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import ScrollToTop from "../components/hoc/ScrollToTop";
import Advisors from "../components/static/aboutus/Advisors";
// import AboutHero from "../components/static/aboutus/AboutHero";
import Microbiome from "../components/static/aboutus/Microbiome";
import OurMission from "../components/static/aboutus/OurMission";
// import OurScientificAdvisors from "../components/static/aboutus/OurScientificAdvisors";
import OurTeam from "../components/static/aboutus/OurTeam";
import WhoAreWe from "../components/static/aboutus/WhoAreWe";
import HalfBannerPage from "../PageTemplates/HalfBannerPage";

// import { advisors, medical } from "../shared/mocks/advisors.js";
import image_constants from "../shared/utils/images_constantsV2";
import axios from "../axios-instance";

import Loader from "../components/Common/Loader";


const AboutUs = ({ scrollRef }) => {
	const theme = useTheme();
	const [firstCard, setfirstCard] = useState([]);
	const [ourTeam, setourTeam] = useState([]);
	const [scientificAdvisors, setScientificAdvisors] = useState([]);
	const [teamMembers, setTeamMembers] = useState([]);
	const [medicalAdvisors, setMedicalAdvisors] = useState([]);
	const [executiveMembers, setExecutiveMembers] = useState([]);
	const [boardOfDirectoryMembers, setboardOfDirectoryMembers] = useState([]);
	const [sliderDetails, setSliderDetails] = useState([]);
	const [loader, setLoader] = useState(false);
	useEffect(() => {
		getAboutContentCardDetails();
		getAboutOurTeamDetails();
		getAboutScientificAdvisorsDetails();
		getAboutMedicalAdvisorsDetails();
		getAboutSliderDetails();
		getAboutTeamMembersDetails();
		getAboutExecutiveDetails();
		getAboutBoardOfDirectoryDetails();
	}, []);
	const getAboutContentCardDetails = () => {
		setLoader(true);
		axios.get("/api/admin/about/content-card")
			.then((res) => {
				if (res) {
					setfirstCard(res?.data?.contentCard);
					setLoader(false);
				}
			})
			.catch((error) => console.log(error));
		setLoader(false);
	};
	const getAboutOurTeamDetails = () => {
		setLoader(true);
		axios.get("/api/admin/about/member")
			.then((res) => {
				if (res) {
					setourTeam(res?.data?.member);
					setLoader(false);
				}
			})
			.catch((error) => console.log(error));
		setLoader(false);
	};
	const getAboutScientificAdvisorsDetails = () => {
		setLoader(true);
		axios.get("/api/admin/about/scientific-advisor")
			.then((res) => {
				if (res) {
					setScientificAdvisors(res?.data?.scientificAdvisor);
					setLoader(false);
				}
			})
			.catch((error) => console.log(error));
		setLoader(false);
	};

	const getAboutMedicalAdvisorsDetails = () => {
		setLoader(true);
		axios.get("/api/admin/about/medical-advisor")
			.then((res) => {
				if (res) {
					setMedicalAdvisors(res?.data?.medicalAdvisor);
					setLoader(false);
				}
			})
			.catch((error) => console.log(error));
		setLoader(false);
	};

	const getAboutSliderDetails = () => {
		setLoader(true);
		axios.get("/api/admin/about/slider-card")
			.then((res) => {
				if (res) {
					setSliderDetails(res?.data?.sliderCard);
					setLoader(false);
				}
			})
			.catch((error) => console.log(error));
		setLoader(false);
	};

	const getAboutTeamMembersDetails = () => {
		setLoader(true);
		axios.get("/api/admin/about/team-member")
			.then((res) => {
				if (res) {
					setTeamMembers(res?.data?.teammember);
					setLoader(false);
				}
			})
			.catch((error) => console.log(error));
		setLoader(false);
	};

	const getAboutExecutiveDetails = () => {
		setLoader(true);
		axios.get("/api/admin/about/executive-member")
			.then((res) => {
				if (res) {
					console.log('executive-member', res?.data);
					setExecutiveMembers(res?.data?.executiveTeamMember);
					setLoader(false);
				}
			})
			.catch((error) => console.log(error));
		setLoader(false);
	};
	const getAboutBoardOfDirectoryDetails = () => {
		setLoader(true);
		axios.get("/api/admin/about/bod-member")
			.then((res) => {
				if (res) {
					console.log('bod-member', res?.data);
					setboardOfDirectoryMembers(res?.data?.bodTeamMember);
					setLoader(false);
				}
			})
			.catch((error) => console.log(error));
		setLoader(false);
	};

	var firstContent;
	var secondContent;
	if (firstCard?.length > 0) {
		firstContent = firstCard[0];
		secondContent = firstCard[1];
	} else {
		console.log('Array is empty.');
	}
	if (loader) {
		return <Loader />
	}
	return (
		<HalfBannerPage
			overlayTitle={"ABOUT US"}
			overlaySubTitle={"Our path is purpose driven"}
			bannerImg={image_constants.ABOUT_PAGE_HERO_NEW}
		>
			{firstContent ?
				<>
					<ScrollToTop />

					<OurMission firstContent={firstContent} />
					<WhoAreWe secondContent={secondContent} bgColor={theme.palette.grey[100]} />
					<Microbiome data={sliderDetails} scrollRef={scrollRef} />
					<OurTeam ourTeam={ourTeam} />
					{/* <OurScientificAdvisors /> */}
					<Advisors title={"Board Of Directors"} data={boardOfDirectoryMembers} />
					<Advisors title={"Executive Team"} data={executiveMembers} />
					<Advisors teamMembers={teamMembers} title={<SectionTitle title={"Team"} />} data={teamMembers} />
					<Advisors scientificAdvisors={scientificAdvisors} title={<SectionTitle title={"Scientific"} />} data={scientificAdvisors} />
					<Advisors title={<SectionTitle title={"Medical"} />} data={medicalAdvisors} />
				</> : <Loader open={true} />
			}
		</HalfBannerPage>
	);
};

const SectionTitle = ({ title }) => {
	console.log('titletitle', title);
	return (
		<>
			{/* <Typography sx={{ fontSize: { xs: "1rem", sm: "1.8rem" } }}>
				<strong style={{ color: '#1A5735' }}>{title}</strong >  {title === "Team" ? "Members" : "Advisors"}
			</Typography> */}
			<Typography sx={{ fontSize: { xs: "1rem", sm: "1.8rem" } }}>
				<strong style={{ color: '#1A5735' }}>{title}</strong> {(() => {
					if (title === "Team" || title === "Executive" || title === "Board of Directory") {
						return "Members";
					} else {
						return "Advisors";
					}
				})()}
			</Typography>


			<Divider width="7%" sx={{ mx: "auto", mt: 2, fontWeight: 700, borderBottomWidth: "thick" }} />
		</>
	);
};

export default AboutUs;
