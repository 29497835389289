import React, {useState} from "react";
import {DotsVerticalIcon} from "@heroicons/react/solid";
import {Box, Divider, Grid, IconButton, Typography, useTheme} from "@mui/material";
import {getAge, getDate} from "../../../shared/utils/ageCalc";
import CustomAvatar from "../../Common/CustomAvatar";
import {Actions} from "../Address/Actions";
import {useDispatch} from "react-redux";
import {deletePerson} from "../../../features/profile/profileSlice";
import useBreakpoints from "../../../hooks/useBreakpoints";
import AddEditProfile from "./AddEditProfile";
import DeleteModal from "../../Common/DeleteModal";

const MemberView = ({person, hideActions, elevate}) => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const [deleted, setDeleted] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const openAction = Boolean(anchorEl);
	const {isMediumScreen} = useBreakpoints();

	//Action
	const handleClickAction = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseAction = () => {
		setAnchorEl(null);
	};

	//Modal
	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const onDelete = () => {
		dispatch(deletePerson({id: person?._id}));
		setDeleted(false);
	};
	return (
		<>
			<Grid
				item
				xs={12}
				container
				px={{xs: 3, sm: 4}}
				py={{xs: 3, sm: 2}}
				mb={2}
				sx={{
					boxShadow: elevate ? "3px 3px 20px rgba(0, 0, 0, 0.07)" : null,
					borderRadius: 3,
					// borderLeft: `6px solid ${theme.palette.primary.light}`,
				}}
			>
				<Grid item container xs={12} alignItems={"center"} sx={{position: "relative"}}>
					{!hideActions && (
						<Box sx={{position: "absolute", right: {xs: -15, sm: 0}, top: {xs: -4, sm: 0}}}>
							<IconButton onClick={(event) => handleClickAction(event)}>
								<DotsVerticalIcon height={"18px"} />
							</IconButton>
							<Actions
								anchorEl={anchorEl}
								open={openAction}
								handleClose={handleCloseAction}
								handleClickOpen={handleClickOpen}
								setDeleted={setDeleted}
							/>
						</Box>
					)}
					{isMediumScreen && (
						<Grid item xs={2.5} sm={2} md={1.5}>
							<CustomAvatar />
						</Grid>
					)}
					<Grid item xs={"auto"} sm={10} md={5.5}>
						<Typography sx={{fontSize: "1.05rem", textTransform: "uppercase", fontWeight: 600}}>
							{person?.firstName} {person?.lastName}
						</Typography>
						<Typography
							sx={{
								fontSize: "0.9rem",
								textTransform: "uppercase",
								fontWeight: 600,
								color: theme.palette.grey[500],
							}}
						>
							{person?.gender} | {person?.dob && getAge(person?.dob)}{" "}
							<span style={{textTransform: "capitalize"}}>years</span>
						</Typography>
					</Grid>
					{!isMediumScreen && (
						<Grid xs={12} sx={{my: "1rem"}}>
							<Divider />
						</Grid>
					)}
					<Grid item xs={12} md={5}>
						<Typography sx={{fontSize: "1rem", color: theme.palette.grey[500]}}>
							DOB : {person?.dob && getDate(person?.dob)}
						</Typography>
						<Typography sx={{fontSize: "1rem", color: theme.palette.grey[500]}}>
							Weight : {person?.weight} Kgs
						</Typography>
						<Typography sx={{fontSize: "1rem", color: theme.palette.grey[500]}}>
							Height : {person?.height} cm
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			<AddEditProfile memberData={person} handleClose={handleClose} open={open} />
			<DeleteModal
				deleteText={"Do you really want to delete this member? This process cannot be undone."}
				handlePrimaryClick={onDelete}
				open={deleted}
				handleClose={() => setDeleted(false)}
			/>
		</>
	);
};

export default MemberView;
