import React, { useEffect } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Slide,
	styled,
	Tab,
} from "@mui/material";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import useBreakpoints from "../../../hooks/useBreakpoints";
import TabPanelWrapper from "../TabPanelWrapper";
import { infoData } from "./tandc.data";
import Policies from "./Policies";

import { useDispatch, useSelector } from 'react-redux';

import { getAllContentManagements, resetContentManagement } from '../../../features/contentManagement/contentManagementSlice';

const StyledTabs = styled((props) => (
	<Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))(({ theme }) => ({
	"& .MuiTabs-indicator": {
		display: "flex",
		justifyContent: "center",
		backgroundColor: "rgba(26, 87, 53, 0.01)",
	},
	"& .MuiTabScrollButton-root": {
		background: "#eee",
	},
	[`& .${tabsClasses.scrollButtons}`]: {
		"&.Mui-disabled": { opacity: 0.3 },
	},
	// '& .MuiTabs-indicatorSpan': {
	// 	maxWidth: 70,
	// 	width: '100%',
	// 	backgroundColor: theme.palette.secondary.main,
	// },
}));

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
	textTransform: "capitalize",
	fontWeight: theme.typography.fontWeightRegular,
	fontSize: "0.975rem",
	// marginRight: {xs: 0, sm: theme.spacing(1)},
	transition: "0.4s ease-out",
	color: "rgba(0, 0, 0, 0.4)",
	"&.Mui-selected": {
		transition: "0.4s ease-out",
		backgroundColor: "rgba(26, 87, 53, 0.08)",
		color: theme.palette.secondary.main,
		borderRadius: "5px",
	},
	"&.Mui-focusVisible": {
		borderRadius: "20px 20px 0px 0px",
	},
	"&:last-child": {
		marginRight: theme.spacing(0),
	},
}));

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		"aria-controls": `full-width-tabpanel-${index}`,
	};
}

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const PoliciesModal = ({ open, handleClose, defaultValue }) => {
	const { ContentManagements } = useSelector((state) => state.contentManagement);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getAllContentManagements());
		return () => dispatch(resetContentManagement());
	}, [dispatch]);

	const { isMediumScreen, isSmallScreen } = useBreakpoints();

	const [value, setValue] = React.useState(Number(defaultValue) || 0);

	const handleValueChange = (event, newValue) => {
		setValue(newValue);
	};

	const updateContentManagements = ContentManagements && ContentManagements?.contentManagement.filter(item => item.type !== 'privacy_policy');
	useEffect(() => {
		setValue(+defaultValue || 0);
	}, [defaultValue]);
	return (
		<Dialog
			TransitionComponent={Transition}
			onClose={handleClose}
			aria-labelledby="customized-dialog-title"
			open={open}
			fullScreen={!isMediumScreen}
			maxWidth={"lg"}
			keepMounted
			fullWidth>
			<DialogTitle id="customized-dialog-title" onClose={handleClose}>
				<StyledTabs
					value={value}
					onChange={handleValueChange}
					variant="scrollable"
					scrollButtons={isSmallScreen ? false : true}
					allowScrollButtonsMobile>
					{updateContentManagements?.map((item, index) => (
						<StyledTab label={item.name} key={item.id} {...a11yProps(index)} />
					))}

				</StyledTabs>
			</DialogTitle>
			<DialogContent dividers>
				{updateContentManagements?.map((data, index) => (
					<TabPanelWrapper p={3} value={value} index={index} key={index}>
						<Policies data={data} />
					</TabPanelWrapper>
				))}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Close</Button>
			</DialogActions>
		</Dialog>
	);
};

export default PoliciesModal;
