import React from "react";
import {Box, Button, Grid, useTheme} from "@mui/material";
import {useNavigate} from "react-router-dom";
// import HeroSvgAnimation from "../../Common/SVG/HeroSvgAnimation";
import {motion} from "framer-motion";
import image_constants from "../../../shared/utils/images_constantsV2";

const HomeHero = () => {
	const navigate = useNavigate();
	const theme = useTheme();

	return (
		<Grid
			// columnSpacing={2}
			container
			sx={{
				height: "100vh",
				width: "100%",
				// backgroundImage: 'linear-gradient(to right bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))',
				// padding: "0 5%",
				position: "relative",
				display: "flex",
				alignItems: "center",
				// justifyContent: 'center',
				zIndex: -2,
			}}
			component={motion.div}
			initial="offscreen"
			whileInView="onscreen"
			viewport={{once: true, amount: 0.8}}>
			{/* <Box
				sx={{
					opacity: 0.2,
					position: 'absolute',
					width: {xs: '250%', sm: '150%', lg: '1200px'},
					top: {xs: '50%', lg: -120},
					left: {xs: '50%', lg: -120},
					transform: {xs: 'translate(-50%, -50%)', lg: 'none'},
				}}
			>
				<HeroSvgAnimation />
			</Box> */}
			<Box
				sx={{
					// backgroundImage: "linear-gradient(to right bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))",
					position: "absolute",
					width: "100%",
					height: "100%",
					zIndex: -2,
				}}
			/>
			<video
				autoPlay
				loop
				muted
				playsInline
				preload="auto"
				poster=""
				style={{
					position: "fixed",
					right: 0,
					bottom: 0,
					zIndex: -8,
					width: "100%",
					height: "100%",
					objectFit: "cover",
				}}
				// src={"https://res.cloudinary.com/prism-scale/video/upload/v1665296486/SETUP_1_wuuwth.mp4"}
				src="/homeMenu.mp4"
			/>
			<Grid
				item
				xs={12}
				display={"flex"}
				justifyContent={{xs: "center", lg: "center"}}
				mt="auto"
				mb={{xs: "30%", sm: "18%"}}
				sx={{pt: 8}}>
				<Box mx={2} sx={{width: {xs: "100%", lg: "100%"}}} textAlign={{xs: "center", lg: "center"}}>
					{/* <Typography
            variant="h2"
            sx={{
              textTransform: "capitalize",
              color: "#fff",
              fontSize: { xs: "1.5rem", sm: "1.6rem", md: "2rem" },
              fontWeight: 400,
            }}
          >
            Feel your Connection With Your Gut
          </Typography>
          <Typography
            variant="body2"
            color={theme.palette.common.greyLight}
            sx={{ fontSize: { xs: "0.725rem", sm: ".8rem", md: "1rem" } }}
          >
            Knowing who you are and how your body functions is a sense of security!!
          </Typography> */}
					<Box
						mt={2}
						sx={{
							display: "flex",
							//   alignItems: "",
							gap: 2,
							justifyContent: {xs: "center", lg: "center"},
							flexDirection: {xs: "row", sm: "row"},
						}}>
						<Button
							// disableElevation
							fullWidth={false}
							sx={{
								py: {xs: 1, md: 2},
								px: {xs: 3, md: 6},
								background: "rgba(255,255,255,0.8)",
								textTransform: "capitalize",
								fontSize: {xs: "0.925rem", md: "1rem"},
								// color: theme.palette.secondary.main,
								color: "#000",

								"&:hover": {
									background: "rgba(255,255,255,1)",
									color: theme.palette.secondary.main,
								},
							}}
							onClick={() => navigate(`/reports/demo`)}>
							Demo Report
						</Button>

						<Button
							// disableElevation
							fullWidth={false}
							sx={{
								py: {xs: 1, md: 2},
								px: {xs: 3, md: 6},
								background: "rgba(255,255,255,0.8)",
								textTransform: "capitalize",
								fontSize: {xs: "0.925rem", md: "1rem"},
								// color: theme.palette.secondary.main,
								color: "#000",
								// ml: {xs: 0, sm: 2, md: 4},
								// mt: {xs: 2, sm: 0},
								"&:hover": {
									color: theme.palette.secondary.main,
									background: "rgba(255,255,255,1)",
								},
							}}
							onClick={() => navigate(`/orders`)}>
							Activate Kit
						</Button>
					</Box>
				</Box>
			</Grid>
		</Grid>
	);
};

export default HomeHero;
