import React from "react";
import { Box, Grid, Link, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getDate } from "../../shared/utils/ageCalc";
import MinimalButton from "../../components/Common/Buttons/MinimalButton";

const statusColors = {
	initialized: "#000",
	order_placed: "#3730a3",
	accepted: "#15803d",
	cancelled: "#b91c1c",
	kit_activated: "#7e22ce",
	processing: "#86198f",
	completed: "#3f6212",
};

const CareerCardView = ({
	orderId,
	username,
	kitId,
	status,
	productName,
	handleNavigation,
	placedAt,
}) => {
	const navigate = useNavigate();

	const theme = useTheme();

	const renderDate = (dateString) => {
		const date = getDate(dateString, true);

		return (
			<Box
				sx={{ display: { xs: "flex", md: "inherit" }, justifyContent: { xs: "center", md: "inherit" } }}>
				<Typography>{date.split("at")[0]}</Typography>
				{/* <Typography sx={{ fontWeight: 700, color: "#979797" }}>{`(${date.split("at")[1]
					})`}</Typography> */}
			</Box>
		);
	};

	return (
		<Grid
			item
			container
			xs={12}
			sx={{
				backgroundColor: "#fff",
				borderRadius: 3.5,
				boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;",
				mb: 2,
				overflow: "hidden",
			}}>
			<Grid
				item
				xs={12}
				sm={6}
				md={3}
				textAlign={{ xs: "center", md: "left" }}
				my="auto"
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					color: "#fff",
					background: `linear-gradient(to right bottom, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
					padding: 4,
					//   overflow: "hidden",
					height: { xs: "auto", sm: "100%" },
				}}>
				<Typography textTransform={"uppercase"} sx={{ fontWeight: 700 }}>
					Node JS Developer
				</Typography>
			</Grid>

			<Grid
				container
				item
				xs={12}
				sm={6}
				md={7}
				justifyContent={'space-around'}
				textAlign={{ xs: "center", md: "left" }}
				my="auto"
				p={2}>
				<Grid item xs={12} md={12} textAlign="center" mb={2}>
					<Typography variant='h6'>
						We have looking for mid level Nodejs Developer
					</Typography>
				</Grid>
				<Grid item xs={12} md={3} textAlign="center" my="auto">
					<Typography>
						<span style={{ fontWeight: 700 }}>Location:</span> Coimbatore
					</Typography>
				</Grid>

				<Grid item xs={12} md={3} textAlign="center" my="auto">
					<Typography
						textTransform={"uppercase"}
						sx={{ fontWeight: 600 }}>
						Full Time
					</Typography>
				</Grid>
				<Grid item xs={12} md={3} textAlign="center" my="auto">
					<span style={{ fontWeight: 700 }}>Posted:</span> {renderDate(placedAt)}
				</Grid>
			</Grid>
			<Grid
				item
				xs={12}
				sm={6}
				md={2}
				textAlign={{ xs: "center", md: "left" }}
				my="auto"

			>
				<MinimalButton
					buttonText={"APPLY NOW"}
					onClick={() => navigate(`/careers/apply`)}
				/>
			</Grid>
		</Grid>
	);
};

export default CareerCardView;
