import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid } from "@mui/material";
import ProductCard from "../../components/Products/ProductCard";
import SectionPadding from "../../components/Common/SectionPadding";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import {
  getAllProducts,
  resetProducts,
} from "../../features/products/productSlice";
import TitleWithBackBtn from "../../components/Common/TitleWithBackBtn";
import Loader from "../../components/Common/Loader";
import DialogModal from "../../components/Common/DialogModal";
import { create } from "../../features/orders/orderSlice";
import { useState } from "react";
import ScrollToTop from "../../components/hoc/ScrollToTop";

const Products = () => {
  const navigate = useNavigate();
  const { currentUser } = useSelector((state) => state.user);
  const { products, isProductLoading } = useSelector((state) => state.product);
  const dispatch = useDispatch();

  const isActiveOrder =
    currentUser &&
    currentUser?.activeOrder !== undefined &&
    currentUser?.activeOrder?.product;
  const [open, setOpen] = useState(false);
  const [productId, setsetProductId] = useState("");

  useEffect(() => {
    dispatch(getAllProducts());

    return () => dispatch(resetProducts());
  }, [dispatch]);

  if (isProductLoading) return <Loader open={isProductLoading} />;
  return (
    <>
      <ScrollToTop />

      <SectionPadding>
        <Container>
          <Grid container mt={"2rem"}>
            <Grid item xs={12}>
              <TitleWithBackBtn title={"Our Products"} />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                mt: "3rem",
                pb: { md: "8rem" },
                pt: "1rem",
                display: "grid",
                gridTemplateColumns: {
                  sm: "repeat(auto-fill, minmax(300px, 1fr))",
                },

                gridAutoRows: "auto",
                gridGap: "1rem",
              }}
            >
              {products && products?.products?.map(
                ({
                  _id,
                  description,
                  imageKey,
                  name,
                  price,
                  imageTitle,
                  rating,
                  actualPrice,
                  offerText,
                }) => (
                  <ProductCard
                    key={_id}
                    productId={_id}
                    price={price}
                    productImage={imageTitle}
                    productImageTitle={imageKey}
                    offerText={offerText}
                    rating={rating}
                    productDescription={description}
                    handleNavigation={() => navigate(`${_id}`)}
                    isActiveOrder={isActiveOrder}
                    setOpen={setOpen}
                    setsetProductId={setsetProductId}
                    actualPrice={actualPrice}
                  />
                )
              )}
            </Grid>
          </Grid>
        </Container>
      </SectionPadding>
      <DialogModal
        buttonOne={"EXISTING ORDER"}
        buttonTwo={"NEW ORDER"}
        title={
          "You already have an item in checkout. Do you wish to replace that and place a new order? or continue checking out existing item?"
        }
        handleClose={() => setOpen(false)}
        open={open}
        handlePrimaryClick={() => {
          const data = {
            productId,
            quantity: "1",
          };
        
          dispatch(create(data)).then((result) => {
            setOpen(false);
            navigate(`/checkout/${result?.payload?.order}`);
          })
          // dispatch(create(data));
          // setOpen(false);
          // navigate("/checkout");
        }}
        // handleButtonOneClick={() => {
        //   navigate("/checkout");
        //   setOpen(false);
        // }}
        content={null}
      />
    </>
  );
};

export default Products;
