import React, { useState } from "react";
import {
  FormControlLabel,
  FormControl,
  Box,
  FormHelperText,
  Radio,
  RadioGroup,
  FormLabel,
  Checkbox,
} from "@mui/material";
import { useField, useFormikContext } from "formik";
import TextInputField from "../Common/Form/TextInputField";

const AnswersGrouped = ({
  qindex,
  label,
  options,
  labelText,
  type,
  validate,
  optionType,
  ...otherProps
}) => {
  const { setFieldValue, values, errors } = useFormikContext();
  const name = `questions[${qindex}]`;
  const error = errors[`${name}.selectedValue`];
  //   console.log("qwerty", error);
  const [field, meta] = useField(`${name}.selectedValue`);
  const [chronicDis, setChronicDis] = useState([]);
  const [ques4, setQues4] = useState([]);
  const [ques9, setQues9] = useState([]);
  const [ques18, setQues18] = useState([]);

  const styles = {
    width: { xs: "100%" },
    mx: { xs: "auto" },
  };

  const onChangeCheckbox = (e, name) => {
    let newVal = [...ques4];
    let value = e.target.value;
    let isChecked = e.target.checked;
    setQues4((prev) =>
      isChecked ? [...prev, value] : prev.filter((val) => val !== value)
    );

    if (isChecked) {
      newVal.push(value);
    } else if (ques4.includes(e.target.value)) {
      newVal = newVal.filter((val) => val !== value);
    }
    setFieldValue(e.currentTarget.name, newVal.join(","));
  };

  const onChangeCheckbox9 = (e, name) => {
    let newVal = [...ques9];
    let value = e.target.value;
    let isChecked = e.target.checked;
    setQues9((prev) =>
      isChecked ? [...prev, value] : prev.filter((val) => val !== value)
    );

    if (isChecked) {
      newVal.push(value);
    } else if (ques9.includes(e.target.value)) {
      newVal = newVal.filter((val) => val !== value);
    }
    setFieldValue(e.currentTarget.name, newVal.join(","));
  };

  const onChangeCheckbox18 = (e, name) => {
    let newVal = [...ques18];
    let value = e.target.value;
    let isChecked = e.target.checked;
    setQues18((prev) =>
      isChecked ? [...prev, value] : prev.filter((val) => val !== value)
    );

    if (isChecked) {
      newVal.push(value);
    } else if (ques18.includes(e.target.value)) {
      newVal = newVal.filter((val) => val !== value);
    }
    setFieldValue(e.currentTarget.name, newVal.join(","));
  };

  const handleCheckbox = (e) => {
    let newVal = [...chronicDis];
    let value = e.target.value;
    let isChecked = e.target.checked;
    setChronicDis((prev) =>
      isChecked ? [...prev, value] : prev.filter((val) => val !== value)
    );

    if (isChecked) {
      newVal.push(value);
    } else if (chronicDis.includes(e.target.value)) {
      newVal = newVal.filter((val) => val !== value);
    }
    setFieldValue(e.currentTarget.name, newVal.join(","));
  };

  return (
    <FormControl component="fieldset" error={Boolean(error)}>
      <FormLabel>{labelText}</FormLabel>
      {console.log("optionTypesss", optionType)}
      {optionType === "checkbox" ? (
        options?.map((item, index) => {
          return (
            <FormControlLabel
              name={`${name}.checkboxValue`}
              value={item?.value}
              onChange={(event) => {
                name === "questions[4]"
                  ? onChangeCheckbox(event, `${name}.checkboxValue`)
                  : name === "questions[9]"
                  ? onChangeCheckbox9(event, `${name}.checkboxValue`)
                  : name === "questions[18]"
                  ? onChangeCheckbox18(event, `${name}.checkboxValue`)
                  : onChangeCheckbox(event, `${name}.checkboxValue`);
              }}
              control={<Checkbox size="small" styles={styles} />}
              label={item?.value}
            />
          );
        })
      ) : (
        <RadioGroup
          name={`${name}.selectedValue`}
          // validate={validate}
          value={JSON.stringify(values?.questions[qindex]?.selectedValue)}
          onChange={(event) => {
            setFieldValue(
              event.currentTarget.name,
              JSON.parse(event.currentTarget.value)
            );
          }}
        >
          {options?.map((item, index) => {
            return (
              <Box key={index}>
                <FormControlLabel
                  value={JSON.stringify(item)}
                  control={<Radio />}
                  label={item?.value}
                />
                {item?.isCustomRequired &&
                  values?.questions[qindex]?.selectedValue?.value ===
                    item?.value && (
                    <TextInputField
                      name={`${name}.customValue`}
                      placeholder="Additional Information/If Other Please Specify"
                      type="text"
                      margin="dense"
                      size="small"
                      styles={styles}
                    />
                  )}

                {item?.isCheckboxRequired &&
                  values?.questions[qindex]?.selectedValue?.value ===
                    item?.value &&
                  item?.checkboxValue?.map((checkboxVal, index) => (
                    <>
                      <br />
                      <FormControlLabel
                        name={`${name}.checkboxValue`}
                        value={checkboxVal}
                        onChange={handleCheckbox}
                        control={<Checkbox size="small" styles={styles} />}
                        label={checkboxVal}
                      />
                    </>
                  ))}
                {item?.isCheckboxRequired &&
                  values?.questions[qindex]?.selectedValue?.value ===
                    item?.value &&
                  chronicDis.includes("Others") && (
                    <TextInputField
                      name={`${name}.customValue`}
                      placeholder="Additional Information/If Other Please Specify"
                      type="text"
                      margin="dense"
                      size="small"
                      styles={styles}
                    />
                  )}
              </Box>
            );
          })}
          {error && meta.touched && (
            <FormHelperText error={true}>{error}</FormHelperText>
          )}
        </RadioGroup>
      )}
    </FormControl>
  );
};

export default AnswersGrouped;
