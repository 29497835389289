import React from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import CustomAvatar from "../../Common/CustomAvatar";

const BenefitItem = ({ title, subTitle, subTitle2, description, linkid, img }) => {
	const theme = useTheme();
	return (
		<Grid
			item
			xs={12}
			id={linkid}
			sx={{
				boxShadow: "3px 3px 20px rgba(0, 0, 0, 0.07)",
				borderRadius: 3,
				p: 4,
				mt: 14,
				"&:first-child": { mt: 6 },
			}}
		>
			<Box
				sx={{
					boxShadow: "3px 3px 20px rgba(0, 0, 0, 0.07)",
					backgroundColor: "#30814c",
					borderRadius: 3,
					p: 2,
					mt: -10,
					display: "flex",
					alignItems: "center",
					width: "60%",
					mx: "auto",
				}}
			>
				<Grid container sx={{ display: "flex", alignItems: "center", mx: "auto" }}>
					<Grid item md={3} lg={2}>
						{" "}
						<CustomAvatar
							alt={"benefits"}
							src={img}
							styles={{
								backgroundColor: "#30814c",
								width: 60,
								height: 60,
								p: 1.5,
							}}
						/>
					</Grid>
					<Grid item md={9} lg={10}>
						<Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
							<Typography
								variant="h6"
								sx={{
									// color: theme.palette.secondary.main,
									color: "#fff",
									fontWeight: 700,
									fontSize: { xs: "1.2rem", md: "1.5rem" },

									// gap: 1,
								}}
								dangerouslySetInnerHTML={{
									__html: `${title}`,
								}}
							>
								{/* <span>{title}</span> */}
							</Typography>
							{subTitle !== null && (
								<Typography
									variant="body1"
									sx={{ color: "#fff", fontSize: "1rem", lineHeight: "18px" }}
									dangerouslySetInnerHTML={{
										__html: `${subTitle}`,
									}}
								>
									{/* {subTitle} */}
								</Typography>
							)}
						</Box>
					</Grid>
				</Grid>
			</Box>
			<Box sx={{ mt: 2.5 }}>
				{subTitle2 !== null && (
					<Box>
						<Typography variant="subtitle2" sx={{ fontSize: "1.1rem", fontWeight: 700 }}
							dangerouslySetInnerHTML={{
								__html: `${subTitle2}`,
							}}
						>
							{/* {subTitle2} */}
						</Typography>
					</Box>
				)}
				<Box mt={1} textAlign="justify">
					<Typography
						variant="body1"
						sx={{
							color: theme.palette.common.greyDark,
							fontSize: { xs: "0.9rem", md: "1.2rem" },
						}}
						dangerouslySetInnerHTML={{
							__html: `${description}`,
						}}
					>
						{/* {description} */}
					</Typography>
				</Box>
			</Box>
		</Grid>
	);
};

export default BenefitItem;
