import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { useSelector } from "react-redux";
import result_10 from "../../assets/images/result_10.png";
import result_10_2 from "../../assets/images/result_10_2.png";
import result_5_2 from "../../assets/images/result_5_2.png";
import result_5 from "../../assets/images/result_5.png";
import result_4 from "../../assets/images/result_4.png";
import result_3 from "../../assets/images/result_3.png";

const PolarChartResult = ({ goodValues }) => {
	var score = goodValues.length;
	var imageIcon = result_10;
	const { reportData, isReportLoading } = useSelector((state) => state.report);
	const theme = useTheme();
	const data = {
		'chartData': reportData?.kit?.report?.healthScores,
		'allowedEntities': ['Microbiome wellness', 'Gut layer wellness', 'Inflammatory bowel disease (IBD)', 'Heart wellness', 'Diabetic wellness', 'Weight regulation', 'Stress wellness', 'Constipation', 'Chronic fatigue', 'Liver wellness']
	};
	return (
		<>

			<Grid item container xs={12} sx={{ mt: 4 }}>
				<Grid item xs={12} mb={1}>
					<Typography
						sx={{
							fontWeight: 600,
							fontSize: '1.2rem',
							textTransform: 'uppercase',
							color: theme.palette.common.greyDark,
						}}
					>
						RESULT
					</Typography>
				</Grid>
				<Grid item xs={12}>

					{score >= 6 && (<p style={{
						color: ' #4da24d', lineHeight: '1.5', fontWeight: '800',
						fontSize: '1.2rem', padding: '15px 0px'
					}}>DOING WELL<img src={result_10} style={{ verticalAlign: "middle", paddingLeft: '10px' }} /><img src={result_10_2} style={{ verticalAlign: "middle", paddingLeft: '5px' }} /></p>)}
					{score == 5 && (<p style={{
						color: ' #ecec08', lineHeight: '1.5', fontWeight: '800',
						fontSize: '1.2rem', padding: '15px 0px'
					}} >NEED FINE-TUNING<img src={result_5} style={{ verticalAlign: "middle", paddingLeft: '10px' }} /><img src={result_5_2} style={{ verticalAlign: "middle", paddingLeft: '5px' }} /></p>)}
					{score == 4 && (<p style={{
						color: '#ff8100', lineHeight: '1.5', fontWeight: '800',
						fontSize: '1.2rem', padding: '15px 0px'
					}} >TAKE NOTICE<img src={result_4} style={{ verticalAlign: "middle", paddingLeft: '10px' }} /></p>)}
					{score <= 3 && (<p style={{
						color: ' #ff0000', lineHeight: '1.5', fontWeight: '800',
						fontSize: '1.2rem', padding: '15px 0px'
					}} >REQUIRE ATTENTION<img src={result_3} style={{ verticalAlign: "middle", paddingLeft: '10px' }} /></p>)}

					<Typography sx={{ fontWeight: 400 }} textAlign={'justify'}>
						{score == 10 && ("You scored 10 out of 10. It means you are doing well, continue ongoing diet and lifestyle standards to maintain your good bacteria that maintains your health")}
						{(score >= 6 && score <= 9) && (`You scored ${score} out of 10. It means your overall health is satisfactory, However, some of your good bacteria need some help, so help them to attain your best healthiest life.`)}
						{(score == 5) && (`You scored ${score} out of 10. It means your overall health is not as expected, some of your good bacteria are in a bad level and it needs fine-tuning ASAP, so change your life-style slightly to attain your best healthiest life.`)}
						{(score == 4) && (`You scored ${score} out of 10. It means your overall health looks alarming, keeping your tiny good bacteria healthy helps you to attain a win-win situation, so pay more attention to help them to attain your best healthiest life.`)}
						{(score <= 3) && (`You scored ${score} out of 10. It means your overall health is critical, definitely scores have to be improved. If left unattended it may detour your overall health.`)}
					</Typography>
				</Grid>
			</Grid >
		</>
	);
};

export default PolarChartResult;
