import React from "react";
import { Container, Divider, Grid, Typography, useTheme } from "@mui/material";
import SectionPadding from "../../Common/SectionPadding";
import ResponsiveImage from "../../Common/ResponsiveImage";
import useBreakpoints from "../../../hooks/useBreakpoints";
import image_constants from "../../../shared/utils/images_constantsV2";

const OurTeam = ({ ourTeam }) => {

	return (
		<SectionPadding overRideStyles={{ paddingY: { xs: "2rem", sm: "2rem" } }}>
			<Container>
				<Grid container>
					<Grid item xs={12} textAlign="center">
						<Typography
							variant="h3"
							sx={{
								textTransform: "capitalize",
								fontSize: { xs: "1.2rem", md: "2rem" },
								fontWeight: 400,
							}}>
							our <strong style={{ color: '#1A5735' }}>team</strong>
						</Typography>
						<Divider
							width="7%"
							sx={{ mx: "auto", mt: 2, fontWeight: 700, borderBottomWidth: "thick" }}
						/>
					</Grid>
					<Grid item xs={12}>
						<CEO ourTeam={ourTeam} />
					</Grid>
				</Grid>
			</Container>
		</SectionPadding>
	);
};

const CEO = ({ ourTeam }) => {
	console.log('ourTeam', ourTeam);
	const theme = useTheme();
	const { isMediumScreen } = useBreakpoints();
	const colorMain = theme.palette.secondary.main;
	const textColorDark = theme.palette.common.greyDark;

	return (
		<Grid
			item
			container
			xs={12}
			mt={{ xs: 3, sm: 6 }}
			alignItems="center"
			justifyContent={"center"}
			direction={{ xs: "column-reverse", md: "row" }}
			sx={{
				boxShadow: `rgba(0, 0, 0, 0.1) -4px 9px 25px -6px`,
				borderRadius: 3,
				background: "#fff",
				//   height: "100%",
				//   maxHeight: "100%",
			}}>
			{ourTeam?.map((data, i) => (
				<>
					<Grid item container xs={12} md={7}>

						<Grid item xs={12} textAlign={{ xs: "justify", sm: "left" }} p={{ xs: 4, sm: 5, md: 10 }}>
							{isMediumScreen && (
								<>
									<Typography
										variant="h5"
										sx={{
											// textTransform: "uppercase",
											fontSize: { xs: "1.2rem", md: "1.8rem" },
											// fontWeight: 400,
											// color: colorMain,
											textAlign: { xs: "center", sm: "left" },
										}}
										dangerouslySetInnerHTML={{
											__html: `${data?.name}`
										}}
									>
										{/* {data?.name} */}
									</Typography>
									<Typography
										variant="subtitle1"
										sx={{
											// textTransform: "uppercase",
											// color: textColorDark,
											textAlign: { xs: "center", sm: "left" },
											fontSize: { xs: "0.9rem", md: "1.4rem" },
										}}
										dangerouslySetInnerHTML={{
											__html: `${data?.designation}`
										}}
									>
										{/* {data?.
											designation} */}
									</Typography>
								</>
							)}
							<Typography
								variant="body1"
								// color={theme.palette.grey[600]}
								sx={{ fontSize: { xs: "0.9rem", sm: "1.1rem" }, mt: 2 }}
								textAlign="justify"
								dangerouslySetInnerHTML={{
									__html: `${data?.description}`
								}}
							>
								{/* {data.description} */}
							</Typography>
						</Grid>


					</Grid>
					<Grid item xs={12} md={5} style={{ padding: '50px' }}>
						<ResponsiveImage
							alt="ceo"
							img={data.imageurl}
							width={"100%"}
							overRideStyles={{
								borderRadius: isMediumScreen && "8px",
								objectFit: "cover",
							}}
						/>
					</Grid>
				</>
			))}
			{ourTeam.map((data, i) => (
				!isMediumScreen && (
					<Grid item container xs={12} md={7}>
						<Grid item xs={12} textAlign={{ xs: "justify", sm: "left" }} p={{ xs: 4, sm: 5, md: 10 }}>
							<Typography
								variant="h5"
								sx={{
									// textTransform: "uppercase",
									fontSize: { xs: "1.2rem", md: "1.8rem" },
									// fontWeight: 400,
									// color: colorMain,
									textAlign: { xs: "center", sm: "left" },
								}}
								dangerouslySetInnerHTML={{
									__html: `${data?.name}`
								}}
							>
								{/* {data?.name} */}
							</Typography>
							<Typography
								variant="subtitle1"
								sx={{
									// textTransform: "uppercase",
									// color: textColorDark,
									textAlign: { xs: "center", sm: "left" },
									fontSize: { xs: "0.9rem", md: "1.4rem" },
								}}

								dangerouslySetInnerHTML={{
									__html: `${data?.designation}`
								}}>
								{/* {data?.position} */}
							</Typography>
						</Grid>
					</Grid>
				)
			))}
		</Grid>
	);
};

export default OurTeam;
