import React, { useEffect } from "react";
import { Container, Grid, Typography, useTheme } from "@mui/material";

import SectionPadding from "../../components/Common/SectionPadding";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrderById, resetOrders } from "../../features/orders/orderSlice";
import Loader from "../../components/Common/Loader";
import Details from "../../components/Checkout/Common/Details";
import PaymentSummary from "../../components/Checkout/PaymentSummary";
import NoDataView from "../../components/Common/NoDataView";
import MemberView from "../../components/GicProfile/Members/MemberView";
import CheckoutSummary from "../../components/Checkout/CheckoutSummary";
import PaymentDetails from "../../components/Checkout/PaymentDetails";
import OrderStatusButtons from "../../components/Orders/OrderStatusButtons";
import KitInfo from "../../components/Orders/KitInfo";
import QuestionAnswer from "../../components/Orders/QuestionAnswer";
import TitleWithBackBtn from "../../components/Common/TitleWithBackBtn";
import ScrollToTop from "../../components/hoc/ScrollToTop";

const statusColors = {
  initialized: "#000",
  order_placed: "#3730a3",
  accepted: "#15803d",
  cancelled: "#b91c1c",
  kit_activated: "#7e22ce",
  processing: "#86198f",
  completed: "#3f6212",
};

const OrderById = () => {
  const params = useParams();
  const theme = useTheme();
  const navigate = useNavigate();

  const { currentOrder, isOrderLoading } = useSelector((state) => state.order);
  const dispatch = useDispatch();

  const isKitActive =
    currentOrder?.order_status === "kit_activated" ||
    currentOrder?.order_status === "processing" ||
    currentOrder?.order_status === "completed";

  useEffect(() => {
    dispatch(getOrderById({ id: params.orderId }));

    return () => dispatch(resetOrders());
  }, [dispatch, params.orderId]);

  if (isOrderLoading) return <Loader open={isOrderLoading} />;

  const PersonDetails = () => {
    return (
      <>
        <MemberView person={currentOrder?.person} hideActions />
      </>
    );
  };


  const AddressDetails = () => {
    return (
      <>
        <Typography>
          {currentOrder?.address?.line1}, {currentOrder?.address?.line2},{" "}
          {currentOrder?.address?.state}, {currentOrder?.address?.city},{" "}
          {currentOrder?.address?.pincode}
        </Typography>
      </>
    );
  };

  const date = new Date(currentOrder?.placedAt).getDay();
  const time = new Date(currentOrder?.placedAt).getTime();
  return (
    <SectionPadding
      overRideStyles={{ mb: "6rem", mt: { sm: "2rem", md: "3rem" } }}
    >
      <ScrollToTop />

      <Container>
        <Grid container my={{ xs: "2rem", sm: 0 }}>
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            justifyContent={{ xs: "center", md: "space-between" }}
          >
            <Grid item xs={12} sm={6}>
              <TitleWithBackBtn
                title={"Order summary"}
                subTitle={
                  //   <>
                  //     <span style={{ fontSize: "1rem" }}>Order ID :</span> {currentOrder?._id}
                  //   </>
                  `#${currentOrder?.kit
                    ? currentOrder.kit.linkedId
                    : currentOrder?._id
                  }`
                }
              />
            </Grid>
            <OrderStatusButtons
              orderId={currentOrder?._id}
              status={currentOrder?.order_status}
              placedAt={currentOrder?.placedAt}
              currentOrder={currentOrder}
            />
          </Grid>
          {!Boolean(currentOrder?._id) && (
            <Grid item xs={12} sx={{ my: 8, py: 4 }}>
              <NoDataView
                message={
                  "No ACTIVE ORDER Found for check out. Please place an order by clicking the below button."
                }
                btnClickHandler={() => navigate("/products")}
                btnText={"PRODUCTS"}
                showBtn
              />
            </Grid>
          )}

          {Boolean(currentOrder?._id) && (
            <Grid
              container
              item
              xs={12}
              mt={4}
              justifyContent={{ md: "space-between" }}
            >
              <Grid item container xs={12} md={7.8}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    boxShadow: "3px 3px 20px rgba(0, 0, 0, 0.07)",
                    borderRadius: 3,
                  }}
                >
                  <CheckoutSummary activeOrder={currentOrder} />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{
                    boxShadow: "3px 3px 20px rgba(0, 0, 0, 0.07)",
                    borderRadius: 3,
                    p: { xs: 2, sm: 4 },
                    mt: 2,
                  }}
                >
                  <Details
                    title={"PERSON DETAILS"}
                    data={<PersonDetails />}
                    isViewOnly
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{
                    boxShadow: "3px 3px 20px rgba(0, 0, 0, 0.07)",
                    borderRadius: 3,
                    p: { xs: 2, sm: 4 },
                    mt: 2,
                  }}
                >
                  <Details
                    title={"ADDRESS DETAILS"}
                    data={<AddressDetails />}
                    isViewOnly
                  />
                </Grid>
              </Grid>
              <Grid item container xs={12} md={4}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    boxShadow: "3px 3px 20px rgba(0, 0, 0, 0.07)",
                    borderRadius: 3,
                    p: { xs: 4 },
                    mt: { xs: 2, md: 0 },
                  }}
                >
                  <Grid item xs={12} sx={{ height: "100%", maxHeight: "100%" }}>
                    <Typography
                      sx={{
                        color: theme.palette.grey[500],
                        fontWeight: 700,
                        fontSize: { xs: "0.6rem", sm: "0.8rem" },
                      }}
                    >
                      Order status
                    </Typography>
                    <Typography
                      sx={{
                        color: statusColors[currentOrder?.order_status],
                        fontWeight: 900,
                        fontSize: { xs: "1rem", sm: "1.4rem" },
                      }}
                    >
                      {currentOrder?.order_status
                        ?.replaceAll("_", " ")
                        .toUpperCase()}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    boxShadow: "3px 3px 20px rgba(0, 0, 0, 0.07)",
                    borderRadius: 3,
                    p: { xs: 4 },
                    mt: { xs: 2, md: 2 },
                  }}
                >
                  <PaymentSummary isViewOnly currentUser={currentOrder} />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    boxShadow: "3px 3px 20px rgba(0, 0, 0, 0.07)",
                    borderRadius: 3,
                    p: { xs: 4 },
                    mt: { xs: 2, md: 2 },
                  }}
                >
                  <PaymentDetails order={currentOrder} />
                </Grid>
              </Grid>
              {isKitActive && (
                <>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      boxShadow: "3px 3px 20px rgba(0, 0, 0, 0.07)",
                      borderRadius: 3,
                      p: { xs: 4 },
                      mt: { xs: 2, md: 2 },
                    }}
                  >
                    <Details
                      title={"KIT DETAILS"}
                      data={<KitInfo currentOrder={currentOrder} />}
                      isViewOnly
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sx={{
                      boxShadow: "3px 3px 20px rgba(0, 0, 0, 0.07)",
                      borderRadius: 3,
                      p: { xs: 4 },
                      mt: { xs: 2, md: 2 },
                    }}
                  >
                    <Details
                      title={"ADDITIONAL DETAILS"}
                      data={
                        <QuestionAnswer
                          questionaries={currentOrder?.kit?.questionaries}
                        />
                      }
                      isViewOnly
                    />
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </Grid>
      </Container>
    </SectionPadding>
  );
};

export default OrderById;
