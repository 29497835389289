import axios from "../../axios-instance";
import Swal from "sweetalert2";

const createPerson = async (data) => {
  const response = await axios.post(`/api/persons`, data);
  return response.data;
};

const updatePerson = async (data) => {
  const response = await axios.put(`/api/persons/${data.id}`, data.body);
  return response.data;
};

const deletePerson = async (data) => {
  const response = await axios.delete(`/api/persons/${data.id}`);
  return response.data;
};

const getAllPersons = async () => {
  const response = await axios.get(`/api/persons`);
  return response.data;
};

const createAddress = async (data) => {
  const response = await axios.post(`/api/addresses`, data);
  return response.data;
};

const updateAddress = async (data) => {
  const response = await axios.put(`/api/addresses/${data.id}`, data.body);
  return response.data;
};

const deleteAddress = async (data) => {
  const response = await axios.delete(`/api/addresses/${data.id}`);
  return response.data;
};

const getAllAddress = async () => {
  const response = await axios.get(`/api/addresses/`);
  return response.data;
};

const updateProfile = async (data) => {
  const response = await axios.put(`/api/profile/${data.user_id}`, data);
  return response.data;
};

const personaldetails = async (data) => {
  const response = await axios.post(`/api/profile/personaldetails`, data);
  if (response.data.message === "Personal details updated successfully!") {
    Swal.fire({
      position: "top-middle",
      icon: "success",
      text: "Personal details updated successfully!",
      showConfirmButton: false,
      timer: 1500,
    });
  }
};

const changepassword = async (data) => {
  const response = await axios.post(`/api/profile/changepassword`, data);
  if (response.data.message === "Password  updated successfully!") {
    Swal.fire({
      position: "top-middle",
      icon: "success",
      text: "Password  updated successfully!",
      showConfirmButton: false,
      timer: 1500,
    });
  }
};

const profileService = {
  createPerson,
  updatePerson,
  deletePerson,
  createAddress,
  updateAddress,
  deleteAddress,
  getAllPersons,
  getAllAddress,
  updateProfile,
  personaldetails,
  changepassword,
};

export default profileService;
