import React, { useEffect, useRef, useState } from "react";
import {
    Box, Grid, Link,
    Tooltip,
    tooltipClasses, Typography, useTheme, styled, Zoom
} from "@mui/material";
import OverlapRadialProgress from './OverlapRadialProgress'
import useBreakpoints from "../../../hooks/useBreakpoints";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import TestResultData from "../TestHealth/TestResultData";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

const ReportAccordionSummary = ({ subDocsArr, category = "main" }) => {
    const [expanded, setExpanded] = React.useState(false);


    const firstLevels = ['gut_wellness', 'your_wellness'];
    const secondLevels = ['gut_layer_wellness', 'microbiome_wellness', 'immune_wellness', 'metabolic_wellness', 'mental_wellness', 'pathophysiological_wellness'];
    const fourthLevels = ['clostridium', 'ulcerative_colitis', 'crohns_disease', 'ibs-diarrhoea', 'ibsconstipation', 'ibsmixed'];
    const theme = useTheme();
    const { isSmallScreen } = useBreakpoints();

    const Accordion = styled((props) => (
        <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
        // boxShadow: '3px 3px 20px rgba(0, 0, 0, 0.07)',
        //boxShadow: '3px 3px 20px rgba(0, 0, 0, 0.07)',
        borderRadius: '12px',
        // paddingTop: "4px",
        // paddingBottom: "4px",
        border: "1px solid #EFEFEF",
        // marginBottom: '16px',
        marginTop: '14px',
        background: '#fff',
        overflow: "hidden",

        // "&:not(:last-child)": {
        //     borderBottom: 0,
        // },
        '& .Mui-expanded': {
            backgroundColor: "#EFEFEF",
        },
        "&:before": {
            display: "none",
        },
        '&:hover': {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
    }));


    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary
            expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "1.5rem" }} />}
            {...props}
        />
    ))(({ theme }) => ({
        background: '#fff',

        flexDirection: "row",
        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(90deg)",
        },
        "& .MuiAccordionSummary-content": {
            marginLeft: theme.spacing(1),
        },


    }));

    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: theme.spacing(2),
        border: "1px solid rgba(0, 0, 0, .125)",
        border: "none",
        backgroundColor: "#FFF!important",
        '&:hover': {
            backgroundColor: "#FFF!important",
        },

    }));


    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);

            setTimeout(() => {
                const element = document.getElementById(`${panel}_content_div`);
                element.scrollIntoView();
            }, 500)

        };

    const toolTipInfo = (report) => {
        return (
            report?.children?.length > 0 ? (
                <Box sx={{ p: 1 }}>
                    {report?.children?.map((child, index) => (
                        <Typography key={index}>- {(child?.healthSection?.anotherDisplayName ? child?.healthSection?.anotherDisplayName : child?.healthSection?.displayName)}</Typography>
                    ))}
                </Box>
            ) : (
                ""
            )
        );
    }

    return (

        <>
            {subDocsArr?.map((report, key) => {
                return (
                    <>
                        <Accordion
                            expanded={expanded === report?.healthSection?.name}
                            onChange={handleChange(report?.healthSection?.name)}
                            key = {report?.healthSection?.name}
                        >
                            <AccordionSummary
                                aria-controls="panel1bh-content"
                            >
                                <div id={`${report.healthSection.name}_content_div`} style={{ width: "100%" }}>
                                    <Tooltip title={toolTipInfo(report)} placement="top" TransitionComponent={Zoom} arrow>
                                        <Grid style={{
                                            marginRight: '5%',
                                            display: 'flex',
                                            width: '100%'
                                        }}  >
                                            {
                                                category == "main" && (
                                                    <Typography
                                                        sx={{
                                                            color: "#01815a",
                                                            textTransform: "uppercase",
                                                            fontWeight: 700,
                                                            fontSize: { xs: "1.1rem", sm: "1.3rem", md: "1.6rem" },
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            display: "-webkit-box",
                                                            WebkitLineClamp: "1",
                                                            WebkitBoxOrient: "vertical",
                                                            width: "85%",
                                                        }}>
                                                        {(report?.healthSection?.anotherDisplayName ? report?.healthSection?.anotherDisplayName : report?.healthSection?.displayName)}
                                                    </Typography>
                                                )
                                            }
                                            {
                                                category == "sub" && (
                                                    <Typography
                                                        sx={{
                                                            color: (secondLevels.includes(report?.healthSection?.name) ? "#146f7c" : fourthLevels.includes(report?.healthSection?.name) ? "#312fb1" : '#910000'),
                                                            textTransform: "uppercase",
                                                            fontWeight: 700,
                                                            fontSize: { xs: "1rem", sm: "1rem", md: "1.2rem" },
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            display: "-webkit-box",
                                                            WebkitLineClamp: "1",
                                                            WebkitBoxOrient: "vertical",
                                                            width: "80%",
                                                            lineHeight: "2.2",
                                                        }}>
                                                        {(report?.healthSection?.anotherDisplayName ? report?.healthSection?.anotherDisplayName : report?.healthSection?.displayName)}
                                                    </Typography>
                                                )
                                            }

                                            <Box>
                                                <OverlapRadialProgress
                                                    progress={report?.score}
                                                    size={!isSmallScreen ? 60 : 40}
                                                    progressColor={theme.palette.secondary.main}
                                                    progressSize={{ xs: "1.2rem", sm: "1.4rem" }}
                                                    showInProgress={!isSmallScreen}
                                                />
                                            </Box>

                                        </Grid>
                                    </Tooltip>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails style={{
                                borderBottom: (firstLevels.includes(report?.healthSection?.name) ? "8px solid rgba(1, 129, 90,.25)" : secondLevels.includes(report?.healthSection?.name) ? "8px solid rgba(20, 111, 124,.25)" : fourthLevels.includes(report?.healthSection?.name) ? "8px solid rgba(49, 47, 177, .25)" : '8px solid rgba(145, 0, 0, .25)'),
                            }}>
                                <TestResultData
                                    title={(report?.healthSection?.progressBarName ? report?.healthSection?.progressBarName : report?.healthSection?.displayName)}
                                    reportDescription={report?.healthSection?.description}
                                    resultDescription={report?.healthSection?.result}
                                    bacterias={report?.bacteriaList}
                                    status={report?.scoreLevel?.anotherDisplayName ? report?.scoreLevel?.anotherDisplayName : report?.scoreLevel?.displayName}
                                    score={report?.score}
                                    tableData = {report?.healthSection?.tableData}
                                    additionalContent = {report?.healthSection?.additionalContent}
                                />
                                {
                                    report?.children && report?.children.length != 0 ?
                                        (<Grid item xs={12} mb={1}>
                                            <Typography
                                                sx={{
                                                    fontWeight: 600,
                                                    fontSize: "1.2rem",
                                                    textTransform: "uppercase",
                                                    color: theme.palette.common.greyDark,
                                                }}>
                                                SUB SCORES
                                            </Typography>
                                            <ReportAccordionSummary subDocsArr={report?.children} category="sub" />
                                        </Grid>)
                                        : ''
                                }
                            </AccordionDetails>
                        </Accordion>
                    </>
                );
            })}
        </>
    )
}

export default ReportAccordionSummary