import React from 'react';
import {styled, TextField} from '@mui/material';
import {useField} from 'formik';

const TextFieldWrapper = styled(TextField)({
	'& .MuiOutlinedInput-root': {
		borderRadius: 3,
		backgroundColor: '#fff',
		'& fieldset': {
			border: '1px solid #f1f1f1',
		},
		'& input[type=number]': {
			MozAppearance: 'textfield',
		},
		'& input[type=number]::-webkit-outer-spin-button': {
			WebkitAppearance: 'none',
			margin: 0,
		},
		'& input[type=number]::-webkit-inner-spin-button': {
			WebkitAppearance: 'none',
			margin: 0,
		},
	},
});

const TextInputField = ({name, styles, ...otherProps}) => {
	const [field, meta] = useField(name);

	// console.log(meta);

	const configTextField = {
		...field,
		...otherProps,
		fullWidth: true,
		variant: 'outlined',
	};

	if (meta && meta.touched && meta.error) {
		configTextField.error = true;
		configTextField.helperText = meta.error;
	}

	return (
		<TextFieldWrapper
			// size={{ xs: "small", sm: "medium", md: "large" }}
			{...configTextField}
			sx={{...styles}}
			inputProps={{
				autoComplete: 'new-password',
			}}
		/>
	);
};

export default TextInputField;
