import axios from "../../axios-instance";

const createOrder = async (data) => {
  const response = await axios.post(`/api/orders/initialize`, data);
  return response.data;
};

const updateOrderPerson = async (data) => {
  const response = await axios.put(`/api/orders/updateperson/${data.orderId}`, data);
  return response.data;
};

const updateOrderKit = async (data) => {
  const response = await axios.put(`/api/orders/updatekit/${data?.id}`, data);

  return response.data;
};

const updateOrderAddress = async (data) => {
  console.log('service data', data);
  const response = await axios.put(`/api/orders/updateaddress/${data.orderId}`, data);

  return response.data;
};

const initOrderPayment = async (id) => {
  const response = await axios.post(`/api/payments/init/${id}`);

  return response.data;
};

const updateOrderPayment = async (data) => {
  const response = await axios.put(`/api/orders/updatePayment/${data?.id}`, data);

  return response.data;
};

const updateOrderCashOnDel = async (data) => {
  const response = await axios.put("/api/orders/updateCashOnDel", data);

  return response.data;
};

const getOrders = async () => {
  const response = await axios.get(`/api/orders`);
  return response.data;
};

const getOrder = async (data) => {
  const response = await axios.get(`/api/orders/${data.id}`);
  return response.data;
};

const cancelOrder = async (data) => {
  const response = await axios.delete(`/api/orders/${data.id}`);
  return response.data;
};

// const cancelOrder = async (data) => {
// 	const response = await axios.get(`/api/orders/${data.id}`);
// 	return response.data;
// };

const orderService = {
  createOrder,
  getOrders,
  getOrder,
  updateOrderPerson,
  updateOrderAddress,
  updateOrderPayment,
  initOrderPayment,
  updateOrderKit,
  cancelOrder,
  updateOrderCashOnDel,
};

export default orderService;
