import React from "react";
import { Formik, Form } from "formik";
import DialogModal from "../../Common/DialogModal";
import AddressForm from "./AddressForm";
import {
  createAddress,
  getAddresses,
  updateAddress,
} from "../../../features/profile/profileSlice";
import { useDispatch } from "react-redux";
import axios from "../../../axios-instance";
import { updateOrderAddress } from "../../../features/orders/orderSlice";
import { useLocation } from "react-router-dom";

const validate = (values, props /* only available when using withFormik */) => {
  const errors = {};
  const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z-]+$/;

  if (!values.alias) {
    errors.alias = "Required";
  }

  if (!values.address1) {
    errors.address1 = "Required";
  }

  if (!values.address2) {
    errors.address2 = "Required";
  }

  if (!values.phone) {
    errors.phone = "Required";
  }
  //  else if (!/^\d{10}$/i.test(values.phone)) {
  //   errors.phone = "Invalid Phone Number";
  // }

  if (!values.state) {
    errors.state = "Required";
  }

  if (!values.pincode) {
    errors.pincode = "Required";
  } else if (!/^\d{6}$|^\d{6}$/i.test(values.pincode)) {
    errors.pincode = "Invalid Pincode, Pincode should be of 6 digits";
  }

  if (!values.city) {
    errors.city = "Required";
  } else if (values !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(values.city)) {
    errors.city = "Only Alphabets should be allowed";
  }
  //...

  return errors;
};

const AddEditAddress = ({ open, handleClose, addressData }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const handlePostData = async (values) => {
    const data = {
      id: addressData?._id,
      body: {
        line1: values.address1,
        line2: values.address2,
        alias: values.alias,
        phone: values.phone,
        city: values.city,
        pincode: values.pincode,
        state: values.state,
      },
    };

    if (addressData?._id === "") {
      //   dispatch(createAddress(data.body));
      try {
        if (pathname?.includes("checkout")) {
          const url = "/api/addresses";
          const response = await axios.post(url, data.body);
          if (response.statusText === "OK") {
            dispatch(updateOrderAddress({ id: response?.data?.address?._id }));
            dispatch(getAddresses());
          }
        } else {
          dispatch(createAddress(data.body));
        }
      } catch (error) {
        console.log(error);
      }
    } else dispatch(updateAddress(data));
  };

  return (
    <Formik
      initialValues={{
        alias: addressData?.alias,
        address1: addressData?.line1,
        address2: addressData?.line2,
        phone: addressData?.phone,
        city: addressData?.city,
        state: addressData?.state,
        pincode: addressData?.pincode,
        default: addressData?.isDefault,
      }}
      validate={(values) => validate(values)}
      onSubmit={(values, { resetForm }) => {
        handlePostData(values);
        resetForm();
        handleClose();
      }}
    >
      {({ handleSubmit }) => (
        <Form>
          <DialogModal
            buttonOne={"Cancel"}
            buttonTwo={"Save"}
            handleClose={handleClose}
            handlePrimaryClick={handleSubmit}
            handleButtonOneClick={handleClose}
            btnSubmit="submit"
            open={open}
            title={"Add/Edit Billing Address"}
          >
            <AddressForm />
          </DialogModal>
        </Form>
      )}
    </Formik>
  );
};

export default AddEditAddress;
