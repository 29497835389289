import React, {useEffect} from 'react';
import {Container, Grid, Typography} from '@mui/material';
import {useSelector} from 'react-redux';
import SectionPadding from '../Common/SectionPadding';
import Loader from '../../components/Common/Loader';
import {useState} from 'react';

const AccountStatus = () => {
	const [statusMessage, setStatusMessage] = useState();
	const [statusMessage1, setStatusMessage1] = useState();
  const { isAuthLoading, AuthSuccessData, AuthFailData } = useSelector((state) => state.auth);
  
	useEffect(() => {
		if (Boolean(AuthSuccessData)) {
			setStatusMessage(AuthSuccessData.message);
			if(AuthSuccessData?.message1){
				setStatusMessage1(AuthSuccessData.message1);
			}
			// dispatch(checkUser());
		}

		if (Boolean(AuthFailData)) {
			if (Boolean(AuthFailData.error)) {
				setStatusMessage(AuthFailData.error);
			} else setStatusMessage('Something went wrong!!');
		}
	}, [AuthSuccessData, AuthFailData]);

	if (isAuthLoading) return <Loader open={isAuthLoading} />;

	return (
		<SectionPadding>
			<Container>
				<Grid container>
					<Grid item xs={12} textAlign='center' p={20}>
						<Typography sx={{fontSize: '1.8rem'}}>{statusMessage}</Typography>
						<Typography sx={{fontSize: '1rem'}}>{statusMessage1}</Typography>
					</Grid>
				</Grid>
			</Container>
		</SectionPadding>
	);
};

export default AccountStatus;
