import React, { useState } from "react";
import { Box, Button, Grid, Rating, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router";
import { ShoppingBagIcon } from "@heroicons/react/outline";
import useBreakpoints from "../../hooks/useBreakpoints";
import { useDispatch } from "react-redux";
import { create } from "../../features/orders/orderSlice";
import ResponsiveImage from "../Common/ResponsiveImage";
import { motion } from "framer-motion";

const ProductCard = ({
  productName,
  rating,
  price,
  productImage,
  productDescription,
  handleNavigation,
  productId,
  productImageTitle,
  isActiveOrder,
  setOpen,
  setsetProductId,
  actualPrice,
  offerText
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { isSmallScreen } = useBreakpoints();
  const [zoomed, setZoomed] = useState();
  let formattedRating;
  if (rating % 1 === 0) {
    formattedRating = rating.toFixed(0);
  } else {
    formattedRating = rating.toFixed(1);
  };
  return (
    <>
      <Box
        component={motion.div}
        whileHover={{ scale: 1.12 }}
        onHoverStart={(e) => {
          setZoomed(true);
        }}
        onHoverEnd={(e) => {
          setZoomed(false);
        }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20,
        }}
        sx={{
          p: { xs: 1, sm: 2 },
          backgroundColor: "#fff",
          borderRadius: 2,
          position: "relative",
          boxShadow: !zoomed
            ? "rgba(0, 0, 0, 0.1) 0px 4px 12px"
            : "rgba(0, 0, 0, 0.1) 0px 4px 12px",
          //   transition: "0.2s ease-out",
          zIndex: zoomed ? 70 : 0,

          //   transform: zoomed ? "translateY(-1.5rem) scale(1.01)" : "none",
        }}
      >
        <Grid
          item
          xs={12}
          textAlign="center"
          // p={{xs: 1, sm: 2.5}}
          // py={{sm: 6}}
          sx={{
            cursor: "pointer",
            // background:
            // 	'linear-gradient(to right bottom, rgba(255,255,255) 50%, rgba(21, 126, 81, 0.1) 50%)',
            position: "relative",
            overflow: "hidden",
            width: "100%",
            height: "380px",
            maxHeight: "380px",
            borderRadius: 2,
          }}
        >
          <ResponsiveImage
            alt={productImageTitle}
            img={productImageTitle}
            width={isSmallScreen ? "200px" : "160px"}
          />
          <img
            src={productImage}
            alt="product"
            style={{
              width: "100%",
              height: "100%",

              // height: isSmallScreen ? '250px' : '200px',
              transition: "0.4s ease-out",
              objectFit: "cover",
              // transform: zoomed ? 'scale(1.3)' : 'scale(1)',
            }}
            onClick={handleNavigation}
          />
        </Grid>

        <Grid
          item
          xs={12}
          onClick={handleNavigation}
          sx={{ cursor: "pointer" }}
          px={2}
          mt={2}
        >
          <Grid item xs={12} onClick={handleNavigation}>
            {/* <Typography
						sx={{
							background: `linear-gradient(to right bottom, ${theme.palette.primary.main} 26.53%, ${theme.palette.secondary.main} 79.58%)`,
							backgroundClip: 'text',
							textFillColor: 'transparent',
							textTransform: 'capitalize',
							fontSize: {xs: '1.1rem', sm: '1.4rem'},
							fontWeight: 600,
							mt: 2,
							cursor: 'pointer',
						}}
					>
						{productName}
					</Typography> */}
            {/* {isSmallScreen &&
              <ResponsiveImage
                alt={productName}
                img={productImage}
                width={isSmallScreen ? "200px" : "160px"}
              />
            } */}
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Rating
                value={formattedRating}
                readOnly
                size="small"
                sx={{ color: theme.palette.primary.main }}
              />
              <span>({formattedRating})</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: {
                  xs: "0.875rem",
                  md: "0.925rem",
                },
                color: theme.palette.common.greyDark,
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
                // textAlign: "justify"
              }}
              dangerouslySetInnerHTML={{
                __html: `${productDescription}`,
              }}
            >
              {/* {productDescription} */}
            </Typography>
          </Grid>
        </Grid>

        <Grid item container xs={12} mt={4} px={2}>
          <Grid
            item
            xs={12}
            sm={4}
            my="auto"
            textAlign={{ xs: "center", sm: "left" }}
          >
            <Typography
              sx={{
                fontSize: { xs: "1.1rem", sm: "1.3rem", md: "1.5rem" },
                fontWeight: 600,
                color: theme.palette.primary.main,
              }}
            >

              ₹{price}/-
            </Typography>

          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            my="auto"
            textAlign={{ xs: "center", sm: "left" }}
          >
            <Typography
              style={{ textDecoration: 'line-through' }}
              sx={{
                // fontSize: { xs: "1.4rem", sm: "1.5rem", md: "2rem" },
                // fontWeight: 600,
                color: theme.palette.common.greyDark,
              }}
            >{actualPrice != price ? ` ₹${actualPrice}/-` : ''}
              {/* ₹{actualPrice}/- */}
            </Typography>

          </Grid>
          <Grid
            item
            xs={12}
            sm={5}
            textAlign={{ xs: "center", sm: "right" }}
            my="auto"
            mt={{ xs: 1, sm: 0 }}
          >
            {/* <Button
              variant="contained"
              sx={{
                color: "#fff",
                background: theme.palette.primary.main,
                textTransform: "capitalize",
              }}
              startIcon={<ShoppingBagIcon height={"18px"} />}
              onClick={() => {

                const data = {
                  productId,
                  quantity: "1",
                };
                dispatch(create(data));
                navigate("/checkout");

              }}
              disableElevation
            >
              Order Kit
            </Button> */}
            <Button
              variant="contained"
              sx={{
                color: "#fff",
                background: theme.palette.primary.main,
                textTransform: "capitalize",
              }}
              startIcon={<ShoppingBagIcon height={"18px"} />}
              onClick={() => {
                // if (isActiveOrder) {
                //   setOpen(true);
                //   setsetProductId(productId);
                // } else {
                const data = {
                  productId,
                  quantity: "1",
                };
                dispatch(create(data)).then((result) => {
                  // Handle the result if needed
                  console.log('Action completed with result:', result?.payload?.order);
                  navigate(`/checkout/${result?.payload?.order}`);
                });
                // const ddd = dispatch(create(data));
                // console.log('ddd', ddd);

                // }
              }}
              disableElevation
            >
              Order Kit
            </Button>
          </Grid>
        </Grid>

        {offerText &&
          <Grid item container xs={12} px={2}>
            <Grid
              item
              xs={12}
              sm={12}
              my="auto"
              textAlign={{ xs: "center", sm: "left" }}
            >
              <Typography
                // sx={{
                //   // fontSize: { xs: "1.1rem", sm: "1.3rem", md: "1.5rem" },
                //   fontWeight: 600,
                //   color: theme.palette.primary.main,
                // }}
                dangerouslySetInnerHTML={{
                  __html: `${offerText}`,
                }}
              >
              </Typography>

            </Grid>

          </Grid>
        }

      </Box>
    </>
  );
};

export default ProductCard;
