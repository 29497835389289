import axios from "../../axios-instance";

const getProducts = async (qStr) => {
  const response = await axios.get(`/api/products`);
  return response.data;
};

const getProductById = async (data) => {
  const response = await axios.get(`/api/products/${data.id}`);
  return response.data;
};

const productService = {
  getProducts,
  getProductById,
};

export default productService;
