import React from "react";
import {Box} from "@mui/material";

const SectionPadding = ({children, overRideStyles, ...otherProps}) => {
	return (
		<Box
			sx={{
				pt: {xs: "3.5rem", sm: "5rem"},
				pb: {xs: "5rem", sm: "6rem"},
				...overRideStyles,
			}}
			{...otherProps}>
			{children}
		</Box>
	);
};

export default SectionPadding;
