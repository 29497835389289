import { Alert, Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { resetNewPassword } from "../../features/auth/authSlice";
import { resetUserPassword } from "../../features/users/userSlice";
import ActionButton from "../Common/Buttons/ActionButton";
import TextInputField from "../Common/Form/TextInputField";
import SectionPadding from "../Common/SectionPadding";

const validate = (values, props /* only available when using withFormik */) => {
  const errors = {};

  if (!values.newPassword) {
    errors.newPassword = "Required";
  } else if (values.newPassword.length < 6) {
    errors.newPassword = "Password should contain 6 or above charaters";
  }

  if (!values.confirmNewPassword) {
    errors.confirmNewPassword = "Required";
  } else if (values.confirmNewPassword.length < 6) {
    errors.confirmNewPassword = "Password should contain 6 or above charaters";
  }

  if (values.newPassword !== values.confirmNewPassword) {
    errors.confirmNewPassword = "Passwords do not match";
  }
  //...

  return errors;
};

const ResetPassword = () => {
  const [status, setstatus] = useState();
  const { isUserLoading, successData, errorData } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();
  const [disable, setDisable] = useState(false);
  const params = useParams();

  const styles = {
    width: { xs: "100%" },
    mx: { xs: "auto" },
    // [`& fieldset`]: {
    // 	borderRadius: 3,
    // },
  };

  useEffect(() => {
    if (Boolean(successData)) {
      // setStatusMessage("Email has been verified successfully!");
      // setStatusDetail("Please login to continue.");
      setstatus({
        primaryTxt:
          "Your password has been reset successfully. Please continue to login.",
        severity: "success",
        // secondaryTxt: "Please login to continue.",
        // icon: <CheckCircleIcon height={"11rem"} color="#166534" />,
      });
    }

    if (Boolean(errorData)) {
      // setStatusMessage("Something went wrong. Please try again later.");
      // setStatusDetail("Error details : " + errorData?.error);
      setstatus({
        primaryTxt:
          errorData?.error || "Something went wrong. Please try again later.",
        severity: "error",
        // secondaryTxt: "Error details : " + errorData?.error,
        // icon: <XCircleIcon height={"11rem"} color="#991b1b" />,
      });
    }
  }, [successData, errorData]);

  return (
    <SectionPadding
      overRideStyles={{
        paddingTop: { xs: "3rem", sm: "12rem" },
        paddingBottom: 0,
      }}
    >
      <Grid
        style={{ marginTop: 0 }}
        container
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ my: 20, px: 3 }}
      >
        <Grid
          item
          container
          xs={12}
          sm={6}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Formik
            initialValues={{ newPassword: "", confirmNewPassword: "" }}
            validate={(values) => validate(values)}
            onSubmit={(values, { resetForm }) => {
              const data = {
                token: params?.resettoken,
                password: values.newPassword,
              };
              console.log('data', data);
              dispatch(resetUserPassword(data));
              setDisable(true);
              resetForm();
            }}
          >
            {({ handleSubmit, isValid, dirty, values }) => (
              <Form>
                <Grid
                  item
                  container
                  xs={12}
                  justifyContent={"center"}
                  spacing={8}
                >
                  <Grid item xs={12}>
                    <Grid item xs={12} textAlign={{ xs: "center" }}>
                      <Typography variant={"h5"}>RESET PASSWORD</Typography>
                    </Grid>
                    <Grid item xs={12} textAlign={{ xs: "center" }}>
                      <Typography>Enter your New Password.</Typography>
                    </Grid>
                    {Boolean(status) && (
                      <Grid item xs={12} sx={{ mt: 5 }}>
                        <Alert severity={status?.severity}>
                          {status?.primaryTxt}
                        </Alert>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item xs={12} textAlign={{ xs: "center" }}>
                      <TextInputField
                        name="newPassword"
                        label="Enter New Password"
                        type={"password"}
                        margin="dense"
                        size="small"
                        // disabled={disable}
                        styles={styles}
                      />
                    </Grid>

                    <Grid item xs={12} textAlign={{ xs: "center" }}>
                      <TextInputField
                        name="confirmNewPassword"
                        label="Confirm New Password"
                        type={"password"}
                        margin="dense"
                        size="small"
                        // disabled={disable}
                        styles={styles}
                      />
                    </Grid>

                    <Grid item xs={12} my={2} textAlign="center">
                      <ActionButton
                        onClick={handleSubmit}
                        disabled={!(isValid && dirty)}
                      >
                        Update New password
                      </ActionButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </SectionPadding>
  );
};

export default ResetPassword;
