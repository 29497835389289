import {Box, Container, Divider, Grid} from "@mui/material";
import React from "react";
import {Outlet} from "react-router-dom";
import SectionPadding from "../components/Common/SectionPadding";
import TitleWithBackBtn from "../components/Common/TitleWithBackBtn";
import ProfileDropDown from "../components/GicProfile/Common/ProfileDropDown";
import ProfileSideBar from "../components/GicProfile/Common/ProfileSideBar";
import ScrollToTop from "../components/hoc/ScrollToTop";
import useBreakpoints from "../hooks/useBreakpoints";

const ProfileV2 = () => {
	const {isMediumScreen} = useBreakpoints();

	return (
		<SectionPadding>
			<ScrollToTop />

			<Container>
				<TitleWithBackBtn title={"My Profile"} />
				<Box sx={{my: {xs: "0.5rem", sm: "1rem"}}}>
					<Divider />
				</Box>
				<Grid item container columnSpacing={2} sx={{mt: {xs: 0, sm: "2rem"}}}>
					<Grid item xs={12} md={3}>
						{isMediumScreen ? <ProfileSideBar /> : <ProfileDropDown />}
					</Grid>
					<Grid
						item
						xs={12}
						md={9}
						sx={{
							// boxShadow: "3px 3px 20px rgba(0, 0, 0, 0.07)",
							borderRadius: 3,
							pl: {xs: 0, md: 2},
						}}
					>
						<Outlet />
					</Grid>
				</Grid>
			</Container>
		</SectionPadding>
	);
};

export default ProfileV2;
