import { ChevronUpIcon } from "@heroicons/react/outline";
import { Box, Fab, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import ScrollTopButton from "../components/Common/ScrollTopButton";
import ScrollToTop from "../components/hoc/ScrollToTop";
import AnalysisWork from "../components/static/homepage/AnalysisWork";
import CollectConnectScience from "../components/static/homepage/CollectConnectScience";
import HomeHero from "../components/static/homepage/HomeHero";
import TrendingBlogs from "../components/static/homepage/TrendingBlogs";
import WhyGIC from "../components/static/homepage/WhyGIC";
import axios from "../axios-instance";

const Homepage = () => {
	const theme = useTheme();
	const [homeContent, setHomeContent] = useState([]);
	const [homeContentCard, setHomeContentCard] = useState([]);
	const [loader, setLoader] = useState(false);
	useEffect(() => {
		getHomeContentDetails();
		getHomeContentCardDetails();
	}, []);
	const getHomeContentDetails = () => {
		setLoader(true);
		axios.get("/api/admin/homecontent")
			.then((res) => {
				if (res) {
					setHomeContent(res?.data?.homeContent);
					setLoader(false);
				}
			})
			.catch((error) => console.log(error));
		setLoader(false);
	};


	const getHomeContentCardDetails = () => {
		setLoader(true);
		axios.get("/api/admin/homecontentcard")
			.then((res) => {
				if (res) {
					setHomeContentCard(res?.data?.homeContentCard);
					setLoader(false);
				}
			})
			.catch((error) => console.log(error));
		setLoader(false);
	};
	var gutContent;
	var testContent;
	if (homeContent.length > 0) {
		gutContent = homeContent[0];
		testContent = homeContent[1]
	} else {
		console.log('Array is empty.');
	}


	if (loader) {
		return <div>Loading</div>
	}

	return (
		<>
			<ScrollToTop />

			<HomeHero />
			<Box sx={{ backgroundColor: "#fff" }}>
				{gutContent ? <WhyGIC gutContent={gutContent} /> : ''}
				{testContent ? <AnalysisWork testContent={testContent} /> : ''}
				<CollectConnectScience homeContentCard={homeContentCard} />
				<TrendingBlogs />
			</Box>
			<ScrollTopButton>
				<Fab
					size={"medium"}
					aria-label="scroll back to top"
					sx={{
						background: theme.palette.primary.main,
						"&:hover": {
							background: { xs: theme.palette.primary.main, md: theme.palette.primary.light },
						},
					}}
				>
					<ChevronUpIcon style={{ height: "1.2rem", color: "#fff" }} />
				</Fab>
			</ScrollTopButton>
		</>
	);
};

export default Homepage;
