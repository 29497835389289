import React, {useEffect} from 'react';
import {Box} from '@mui/material';
import {useSelector, useDispatch} from 'react-redux';
import MembersGrouped from './Common/MembersGrouped';
import {getPersons, resetProfile} from '../../features/profile/profileSlice';
import Loader from '../Common/Loader';
import {Form, Formik} from 'formik';
import DialogModal from '../Common/DialogModal';
import {updateOrderPerson} from '../../features/orders/orderSlice';

const MembersList = ({currentUser, handleClose, changeMembers}) => {
	const {persons, isProfileLoading} = useSelector((state) => state.profile);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getPersons());

		return () => dispatch(resetProfile());
	}, [dispatch]);

	if (isProfileLoading) return <Loader open={isProfileLoading} />;

	return (
		<Formik
			initialValues={{pickedMember: currentUser?.activeOrder?.person}}
			onSubmit={(values) => {
				dispatch(updateOrderPerson({personid: values.pickedMember}));
				handleClose();
			}}
		>
			{({handleSubmit}) => (
				<Form>
					<DialogModal
						buttonOne={'Cancel'}
						buttonTwo={'Save'}
						handleClose={handleClose}
						handleButtonOneClick={handleClose}
						btnSubmit='submit'
						handlePrimaryClick={handleSubmit}
						open={changeMembers}
						title={'Please select the member of your choice to order the kit'}
					>
						<Box>
							<MembersGrouped
								labelText={`Order will shipped to the selected member`}
								name='pickedMember'
								options={persons}
							/>
						</Box>
					</DialogModal>
				</Form>
			)}
		</Formik>
	);
};

export default MembersList;

//
