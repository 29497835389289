import axios from "../../axios-instance";

const register = async (userData) => {
	const response = await axios.post("/api/auth/signup", userData);

	return response.data;
};


const emailLogin = async (userData) => {
	const response = await axios.post("/api/auth/login", userData);
	if (Boolean(response.data.data)) localStorage.setItem("token", response?.data?.data?.token);
	if (Boolean(response.data.data)) localStorage.setItem("userOrganization", response?.data?.data?.organization);

	return response.data;
};

const logout = async () => {
	localStorage.removeItem("token");
	localStorage.removeItem("userOrganization");
	return true;
};

const authService = {
	register,
	emailLogin,
	logout,
};

export default authService;
