import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import {
  getAddresses,
  resetProfile,
} from "../../../features/profile/profileSlice";
import { updateOrderAddress, getOrderById } from "../../../features/orders/orderSlice";
import Loader from "../../Common/Loader";

export default function AddressSelect({ addressId, orderId }) {
  const { addresses, isProfileLoading } = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  const [address, setAddress] = React.useState(addressId);

  const handleChange = (event) => {
    setAddress(event.target.value);
  };

  const handleSelectMember = async (e, id) => {
    await dispatch(updateOrderAddress({ id: id, orderId }));
    await dispatch(getOrderById({ id: orderId }));
    // dispatch(updateOrderAddress({ id: id }));
  };

  React.useEffect(() => {
    dispatch(getAddresses());

    // return () => dispatch(resetProfile());
  }, [dispatch]);

  if (isProfileLoading) {
    return <Loader open={true} />;
  }

  return (
    <Box sx={{ minWidth: 120, mb: 4 }}>
      <FormControl sx={{ width: "40%" }} size="small">
        <InputLabel id="change-address-select">Change Address</InputLabel>
        <Select
          labelId="change-address-select"
          id="address-select"
          defaultValue={addressId}
          value={address}
          label="Change address"
          onChange={handleChange}
        >
          {addresses?.map((item) => (
            <MenuItem
              key={item?._id}
              selected={item?._id === address}
              value={item?._id}
              onClick={(e) => handleSelectMember(e, item?._id)}
              sx={{ textTransform: "capitalize" }}
            >
              {item?.alias}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
