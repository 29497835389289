import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Close, PaymentRounded } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { Razorpay } from "../../shared/utils/razorpay";
import Loader from "../Common/Loader";
import { useNavigate } from "react-router-dom";
import { getOrderById } from "../../features/orders/orderSlice";
import MinimalButton from "../Common/Buttons/MinimalButton";
import PromoCodeList from "./PromoCodeList";
import instance from "../../axios-instance";
import { checkUser } from "../../features/users/userSlice";
import {
  updateOrderPayment,
  updateOrderCashOnDel,
} from "../../features/orders/orderSlice";

const PaymentSummary = ({ currentUser, isViewOnly, order, access }) => {
  const [isLoading, setisLoading] = useState(false);
  const [promoError, setpromoError] = useState(false);
  const [cashOnDel, setcashOnDel] = useState(false);
  const [promoErrorMsg, setpromoErrorMsg] = useState("");
  const [promo, setpromo] = useState();
  const navigate = useNavigate();
  const activeOrder = currentUser && currentUser;
  const theme = useTheme();
  const dispatch = useDispatch();
  const handlePromoApply = () => {
    instance
      .put(`/api/orders/applypromo/${activeOrder?._id}`, { promoCode: promo })
      .then((res) => {
        console.log('res', res.data);
        if (res.data.success == false) {
          setpromoError(true);
          setpromoErrorMsg(res.data.message);
        } else {
          setpromoError(false);
          dispatch(checkUser());
          dispatch(getOrderById({ id: activeOrder?._id }));
        }
      })
      .catch((error) => console.log(error));
  };
  console.log('activeOrderactiveOrder', activeOrder);
  const handlePlaceOrder = async () => {
    setisLoading(true);
    Razorpay(
      activeOrder?._id,
      activeOrder?.user?.userName,
      activeOrder?.user?.email,
      activeOrder?.user?.phone,
      activeOrder?.product?.name,
      await dispatch,
      setisLoading,
      () => {
        dispatch(getOrderById({ id: activeOrder?._id }));
        // navigate(`/orders/${activeOrder?._id}`);
        navigate(`/paymentSuccess/${activeOrder?._id}`);
      }
    );
  };
  const handleConfirmOrderWithoutPay = async () => {
    const rz_response = {
      razorpay_payment_id: "123",
      razorpay_order_id: activeOrder?._id,
      razorpay_signature: "1234",
      id: activeOrder?._id
    };
    setisLoading(true);
    await dispatch(updateOrderPayment(rz_response));
    setisLoading(false);
    // redirect();
    await dispatch(getOrderById({ id: activeOrder?._id }));
    navigate(`/orders/${activeOrder?._id}`);
  };
  const handleConfirmOrderCashOnDel = async () => {
    const rz_response = {
      razorpay_order_id: activeOrder?._id,
    };
    setisLoading(true);
    await dispatch(updateOrderCashOnDel(rz_response));
    setisLoading(false);
    // redirect();
    await dispatch(getOrderById({ id: activeOrder?._id }));
    navigate(`/orders/${activeOrder?._id}`);
  };
  const handleCashOnDel = (e) => {
    setcashOnDel(e.target.checked);
  };
  //useEffect(()=>{
  //as
  // },[cashOnDel])
  return (
    <>
      <Grid
        item
        xs={12}
        sx={{ height: !isViewOnly ? "100%" : "100%", maxHeight: "100%" }}
      >
        <Typography
          sx={{
            color: theme.palette.grey[700],
            fontWeight: 700,
            fontSize: { xs: "1rem", sm: "1.2rem" },
          }}
        >
          PAYMENT SUMMARY
        </Typography>
        {promoError && (
          <Grid item xs={12}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    // setOpen(false);
                  }}
                >
                  <Close onClick={() => setpromoError(false)} fontSize="inherit" />
                </IconButton>
              }
            >
              {promoErrorMsg}
            </Alert>
          </Grid>
        )}

        <Grid
          item
          container
          xs={12}
          direction="row"
          justifyContent={{ md: "space-between" }}
          sx={{
            mt: 2,
          }}
        >
          {activeOrder?.order_status == "initialized" && (
            <Grid item xs={12}>
              <TextField
                sx={{ marginBottom: 1 }}
                fullWidth
                size="small"
                placeholder="Promo code"
                value={promo}
                onChange={(e) => setpromo(e.target.value)}
              ></TextField>
              <MinimalButton
                disabled={!Boolean(promo)}
                onClick={handlePromoApply}
                maxWidth={true}
                buttonText="APPLY"
              />
            </Grid>
          )}
          <Grid item xs={12} sx={{ mt: 3 }}>
            <PricingData
              title={"Sub Total"}
              value={activeOrder?.product?.price}
            />
            <PricingData
              title={"Discount (promo code)"}
              value={activeOrder?.discount}
            />
            {Boolean(activeOrder?.promoCode?.code) && (
              <Typography
                sx={{
                  fontSize: "0.8rem",
                  fontStyle: "italic",
                  color: "green",
                  paddingLeft: "10px",
                }}
              >
                promocode '{activeOrder?.promoCode?.code}' has been applied!
              </Typography>
            )}
            {/* <PricingData title={"Tax"} value={activeOrder?.product?.tax} />
            <PricingData title={"Shipping"} value={activeOrder?.shipmentPrice} /> */}
            <PricingData
              title={
                <span>
                  Total{" "}
                  <span style={{ fontSize: "0.7rem", fontStyle: "italic" }}>
                    (Tax included)
                  </span>
                </span>
              }
              value={`INR ${activeOrder?.totalPrice}/-`}
              // value={`INR 100/-`}
              color={theme.palette.grey[300]}
            />
            {!isViewOnly &&
              activeOrder?.user?.enableCod &&
              activeOrder?.user?.enableCod == true && (
                <FormGroup sx={{ mt: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={cashOnDel}
                        onChange={handleCashOnDel}
                      />
                    }
                    label="Cash On Delivery"
                  />
                </FormGroup>
              )}
          </Grid>

          {!isViewOnly && activeOrder?.totalPrice != 0 && !cashOnDel && (
            <Grid
              item
              xs={12}
              sx={{ mt: 2, display: "flex", alignItems: "flex-end" }}
            >
              <span
                style={{
                  cursor: "not-allowed",
                  display: "block",
                  width: "100%",
                }}
              >
                <Button
                  startIcon={<PaymentRounded />}
                  variant="contained"
                  fullWidth
                  sx={{ py: { xs: 2 }, color: "#fff" }}
                  disableElevation
                  onClick={() => {
                    handlePlaceOrder();
                  }}
                  disabled={
                    typeof activeOrder?.person !== "undefined" &&
                      typeof activeOrder?.address !== "undefined"
                      ? false
                      : true
                  }
                >
                  Payment
                </Button>
                {!access.isAccessible && (
                  <Box>
                    <Alert severity="error" sx={{ mt: 2 }}>
                      {access.message}
                    </Alert>
                  </Box>
                )}
              </span>
            </Grid>
          )}
          {!isViewOnly && cashOnDel && (
            <Grid
              item
              xs={12}
              sx={{ mt: 2, display: "flex", alignItems: "flex-end" }}
            >
              <span
                style={{
                  cursor: "not-allowed",
                  display: "block",
                  width: "100%",
                }}
              >
                <Button
                  variant="contained"
                  fullWidth
                  sx={{ py: { xs: 2 }, color: "#fff" }}
                  disableElevation
                  disabled={
                    typeof activeOrder?.person !== "undefined" &&
                      typeof activeOrder?.address !== "undefined"
                      ? false
                      : true
                  }
                  onClick={() => {
                    handleConfirmOrderCashOnDel();
                  }}
                >
                  Confirm your Order
                </Button>
              </span>
            </Grid>
          )}
          {!isViewOnly && activeOrder?.totalPrice == 0 && !cashOnDel && (
            <Grid
              item
              xs={12}
              sx={{ mt: 2, display: "flex", alignItems: "flex-end" }}
            >
              <span
                style={{
                  cursor: "not-allowed",
                  display: "block",
                  width: "100%",
                }}
              >
                <Button
                  variant="contained"
                  fullWidth
                  sx={{ py: { xs: 2 }, color: "#fff" }}
                  disableElevation
                  onClick={() => {
                    handleConfirmOrderWithoutPay();
                  }}
                >
                  Confirm your Order
                </Button>
              </span>
            </Grid>
          )}
          {!isViewOnly && <PromoCodeList />}
        </Grid>
      </Grid>
      <Loader open={isLoading} />
    </>
  );
};

const PricingData = ({ title, value, color }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        p: 1,
        mt: 0.5,
        background: color,
      }}
    >
      <Typography>{title}</Typography>
      <Typography>{value}</Typography>
    </Box>
  );
};

export default PaymentSummary;
