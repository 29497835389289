import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  useScrollTrigger,
  useTheme,
} from "@mui/material";
import { ChevronDoubleRightIcon } from "@heroicons/react/outline";
import TitleBodySection from "../../Common/TitleBodySection";
import useBreakpoints from "../../../hooks/useBreakpoints";
// import { benefits } from "../../../shared/mocks/benefits.js";
import BenefitItem from "./BenefitItem";
import HalfBannerPage from "../../../PageTemplates/HalfBannerPage";
import { threeBoxesBanner3 } from "../../../assets";
import axios from "../../../axios-instance";

const Benefits = ({ window }) => {
  const theme = useTheme();
  const { isMediumScreen } = useBreakpoints();
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [benefits, setBenefits] = useState([]);
  const [loader, setLoader] = useState(false);

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleListItemClick = (event, index, link) => {
    console.log('link', link);
    setSelectedIndex(index);
    const anchor = (event.target.ownerDocument || document).querySelector(
      `#${link}`
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: "center",
      });
    }
  };

  useEffect(() => {
    getBenefitDetails();
  }, []);

  const getBenefitDetails = () => {
    setLoader(true);
    axios
      .get("/api/admin/benefits")
      .then((res) => {
        if (res) {
          setBenefits(res?.data?.benefits);
          setLoader(false);
        }
      })
      .catch((error) => console.log(error));
    setLoader(false);
  };

  const lists = (
    <List>
      {benefits.map(({ _id, title, linkid }, index) => (
        <ListItemButton
          selected={selectedIndex === index}
          onClick={(event) => handleListItemClick(event, index, linkid)}
          key={_id}
          // component={NavLink}
          // to={`${link}`}
          sx={{
            "&.active": {
              // background: theme.palette.secondary.main,
            },
            borderRadius: 2,
          }}
        // onClick={() => setOpen(false)}
        >
          <ListItemText
            primary={<div dangerouslySetInnerHTML={{ __html: title }} />}
            // primary={title}
            primaryTypographyProps={{
              sx: {
                fontSize: "1rem",
                textTransform: "capitalize",
                fontWeight: 400,
                color: "#000",
                "&.active": {
                  color: "#fff",
                },
              },
            }}
          />
        </ListItemButton>
      ))}
    </List>
  );

  const benefitsComponent = (
    <Container sx={{ mt: "50px" }}>
      <Grid container>
        {isMediumScreen && (
          <Grid item container columnSpacing={2}>
            <Grid
              item
              xs={3}
              sx={{ pb: { xs: "4rem", md: "6rem" }, position: "relative" }}
            >
              <Box
                sx={{
                  height: "460px",
                  maxHeight: "460px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  boxShadow: "3px 3px 20px rgba(0, 0, 0, 0.07)",
                  borderRadius: 3,
                  p: 2,
                  mt: 6,
                }}
              >
                {lists}
              </Box>
            </Grid>

            <Grid item xs={9}>
              <Grid item container xs={12} pb={{ xs: "4rem", md: "8rem" }}>
                {benefits.map(
                  ({
                    title,
                    subTitle,
                    subTitle2,
                    description,
                    linkid,
                    _id,
                    imageurl,
                  }) => (
                    <BenefitItem
                      key={_id}
                      title={title}
                      subTitle={subTitle}
                      subTitle2={subTitle2}
                      description={description}
                      linkid={linkid}
                      trigger={trigger}
                      img={imageurl}
                    />
                  )
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
        {!isMediumScreen && (
          <>
            {benefits.map(({ title, subTitle, subTitle2, description, id }) => (
              <TitleBodySection title={title} subTitle={subTitle} key={id}>
                {subTitle2 !== null && (
                  <Box>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: "0.975rem" }}
                      dangerouslySetInnerHTML={{
                        __html: `${subTitle2}`,
                      }}
                    >
                      {/* {subTitle2} */}
                    </Typography>
                  </Box>
                )}
                <Box mt={1}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: theme.palette.common.greyDark,
                      fontSize: { xs: "0.9rem", md: "0.975rem" },
                      textAlign: "justify",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: `${description}`,
                    }}
                  >
                    {/* {description} */}
                  </Typography>
                </Box>
              </TitleBodySection>
            ))}
          </>
        )}
      </Grid>
    </Container>
  );

  const benefitsOverlayComponent = (
    <Grid
      container
      xs={12}
      mt={{ xs: 2, md: 5 }}
      sx={{ textAlign: { xs: "center", md: "left" } }}
      px={"6%"}
    >
      <Grid item xs={12}>
        <Typography
          sx={{
            textTransform: "capitalize",
            color: theme.palette.secondary.light,
            fontSize: { xs: "1.2rem", md: "2.2rem" },
            fontWeight: 700,
          }}
        >
          WE HELP YOU TO FIND WHAT YOUR GUT NEED
        </Typography>
      </Grid>

      <Grid item container xs={12} py={0} pt={0}>
        {/* <Box>
            <Typography
              variant="h4"
              sx={{
                color: theme.palette.secondary.main,
                textTransform: "capitalize",
                fontSize: { xs: "1.1rem", md: "1.4rem" },
                fontWeight: 700,
              }}
            >
              Benefits of test
            </Typography>
          </Box> */}
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            sx={{
              color: theme.palette.secondary.light,
              fontSize: { xs: "1rem", md: "1.2rem" },
            }}
          >
            Imagine living life filled with health benefits with very little
            effort!!
          </Typography>
        </Grid>
        <Grid item xs={12} p={2} sx={{ textAlign: "left" }}>
          <Typography
            variant="body1"
            sx={{
              color: theme.palette.common.greyLight,
              fontSize: { xs: "0.85rem", md: "1.1rem" },
              textAlign: 'justify'
            }}
          >
            <span style={{ color: theme.palette.primary.main, marginRight: "15px", position: 'relative', top: '5px' }}            >
              <ChevronDoubleRightIcon
                height={"20px"}
                color={theme.palette.primary.main}
              />
            </span>
            <span> At GUT-iCARE, we dive into a powerful way to showcase your health .</span>

            <br />
            <span style={{ color: theme.palette.primary.main, marginRight: "15px", position: 'relative', top: '5px' }}            >
              <ChevronDoubleRightIcon
                height={"20px"}
                color={theme.palette.primary.main}
              />
            </span>
            Our reports will accurately pinpoint how much of the scientifically
            proven beneficial microbiome you have
            <br />
            <span style={{ color: theme.palette.primary.main, marginRight: "15px", position: 'relative', top: '5px' }}            >
              <ChevronDoubleRightIcon
                height={"20px"}
                color={theme.palette.primary.main}
              />
            </span>
            We evaluate your microbiome report by comparing it with our in-house
            biomarkers for any imbalance
            <br />
            <span style={{ color: theme.palette.primary.main, marginRight: "15px", position: 'relative', top: '5px' }}            >
              <ChevronDoubleRightIcon
                height={"20px"}
                color={theme.palette.primary.main}
              />
            </span>
            Then, we prescribe a personalized healthy diet plan to restore the
            imbalance and establish a healthy gut microbiome
            <br />
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <HalfBannerPage
      overlayComponent={benefitsOverlayComponent}
      bannerImg={threeBoxesBanner3}
    // overlayTitle={"BENEFITS"}
    // overlaySubTitle={"Our path is purpose driven."}
    >
      {benefitsComponent}
    </HalfBannerPage>
  );
};

export default Benefits;
