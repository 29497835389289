import {Grid, Slider, styled, Tooltip, Typography, useTheme} from '@mui/material';
import React from 'react';

const iOSBoxShadow =
	'0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const BacteriaSlider = styled(Slider)(({theme}) => ({
	height: 2,
	padding: '15px 0',

	'& .MuiSlider-thumb': {
		height: 20,
		width: 20,
		backgroundColor: '#fff',
		boxShadow: iOSBoxShadow,
		'&:focus, &:hover, &.Mui-active': {
			boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
			// Reset on touch devices, it doesn't add specificity
			'@media (hover: none)': {
				boxShadow: iOSBoxShadow,
			},
		},
	},

	'& .MuiSlider-track': {
		border: 'none',
		background: 'none',
	},

	'& .MuiSlider-rail': {
		opacity: 1,
		backgroundImage: 'linear-gradient(105deg, red , orange, yellow, green)',
		height: 16,
	},
}));

const BacteriaTile = ({bacName, progress, status}) => {
	const theme = useTheme();
	return (
		<Tooltip title={bacName} placement='top' arrow>
			<Grid
				item
				container
				xs={12}
				sx={{p: 2, boxShadow: '3px 3px 20px rgba(0, 0, 0, 0.07)', borderRadius: 3}}
			>
				<Grid item xs={12} textAlign='center'>
					<Typography
						sx={{
							fontSize: '1.3rem',
							fontWeight: 700,
							lineHeight: 1,
							// textTransform: 'capitalize',
							fontStyle: 'italic',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							display: '-webkit-box',
							WebkitLineClamp: '2',
							WebkitBoxOrient: 'vertical',
						}}
					>
						{bacName}
					</Typography>
				</Grid>
				<Grid item xs={12} textAlign='center'>
					<Typography
						sx={{
							fontSize: '2.2rem',
							fontWeight: 900,
							color: theme.palette.secondary.main,
							lineHeight: 1.2,
						}}
					>
						{progress}%
					</Typography>
				</Grid>
				<Grid item xs={12} textAlign='center'>
					<Typography variant='caption' sx={{lineHeight: 1, textTransform: 'uppercase'}}>
						{status}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<BacteriaSlider aria-label='bac slider' valueLabelDisplay='off' value={progress} />
				</Grid>
			</Grid>
		</Tooltip>
	);
};

export default BacteriaTile;
