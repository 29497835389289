import axios from "../../axios-instance";

const getReport = async (data) => {
	const response = await axios.get(`api/reports/${data.id}`);

	return response.data;
};

const reportService = {
	getReport,
};

export default reportService;
