import React from "react";
import { Box, Grid, Link, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getDate } from "../../shared/utils/ageCalc";
import MinimalButton from "../Common/Buttons/MinimalButton";

const statusColors = {
	initialized: "#000",
	order_placed: "#3730a3",
	accepted: "#15803d",
	cancelled: "#b91c1c",
	kit_activated: "#7e22ce",
	processing: "#86198f",
	completed: "#3f6212",
};

const OrderCardView = ({
	orderId,
	username,
	kitId,
	status,
	productName,
	handleNavigation,
	placedAt,
}) => {
	const navigate = useNavigate();

	const theme = useTheme();

	const renderDate = (dateString) => {
		const date = getDate(dateString, true);

		return (
			<Box
				sx={{ display: { xs: "flex", md: "inherit" }, justifyContent: { xs: "center", md: "inherit" } }}>
				<Typography sx={{ fontWeight: 700 }}>{date.split("at")[0]}</Typography>
				<Typography sx={{ fontWeight: 700, color: "#979797" }}>{`(${date.split("at")[1]
					})`}</Typography>
			</Box>
		);
	};
	console.log('test');

	return (
		<Grid
			item
			container
			xs={12}
			//   my={"auto"}
			//   p={4}
			sx={{
				backgroundColor: "#fff",
				// borderLeft: "5px solid #14532d",
				borderRadius: 3.5,
				boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;",
				mb: 2,
				overflow: "hidden",
			}}>
			<Grid
				item
				xs={12}
				sm={6}
				md={3}
				textAlign={{ xs: "center", md: "left" }}
				my="auto"
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					color: "#fff",
					background: `linear-gradient(to right bottom, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
					padding: 4,
					//   overflow: "hidden",
					height: { xs: "auto", sm: "100%" },
				}}>
				<Typography
					component={Link}
					onClick={handleNavigation}
					sx={{ cursor: "pointer", color: "#fff" }}>
					{/* <span style={{fontWeight: 700}}>order id: </span> */}
					{Boolean(kitId) ? kitId : orderId}
				</Typography>
				<Typography textTransform={"uppercase"} sx={{ fontWeight: 700 }}>
					{username}
				</Typography>
			</Grid>

			<Grid
				container
				item
				xs={12}
				sm={6}
				md={9}
				textAlign={{ xs: "center", md: "left" }}
				my="auto"
				p={4}>
				<Grid item xs={12} md={3} textAlign="center" my="auto">
					<Typography sx={{ cursor: "pointer" }}>{productName}</Typography>
					{Boolean(kitId) && (
						<Typography>
							<span style={{ fontWeight: 700 }}>kit id:</span> {kitId}
						</Typography>
					)}
				</Grid>

				<Grid item xs={12} md={3} textAlign="center" my="auto">
					<Typography
						textTransform={"uppercase"}
						sx={{ fontWeight: 900, color: statusColors[status] }}>
						{status.replace("_", " ")}
					</Typography>
				</Grid>
				<Grid item xs={12} md={3} textAlign="center" my="auto">
					{renderDate(placedAt)}
				</Grid>

				<Grid item xs={12} md={3} textAlign="center" my="auto">
					{/* {Boolean(status) && status.toLowerCase() === "order_placed" && (
						<MinimalButton
							onClick={() => navigate(`/reports/${orderId}`)}
							buttonText={"VIEW REPORT"}
							btnColor={"green"}
						/>
					)} */}

					{Boolean(status) && status.toLowerCase() === "accepted" && (
						<MinimalButton
							onClick={() => navigate(`/activatekit/${orderId}`)}
							buttonText={"ACTIVATE KIT"}
							btnColor={"blue"}
						/>
					)}

					{/* {Boolean(status) && status.toLowerCase() === "processing" && (
						<MinimalButton
							buttonText={"VIEW ORDER"}
							onClick={() => navigate(`/orders/${orderId}`)}
						/>
					)} */}

					{Boolean(status) && status.toLowerCase() === "completed" && (
						<MinimalButton
							onClick={() => navigate(`/reports/${orderId}`)}
							buttonText={"VIEW REPORT"}
							btnColor={"green"}
						/>
					)}

					{["order_placed", "processing", "kit_activated", "cancelled"].includes(status) && (
						<MinimalButton
							buttonText={"VIEW ORDER"}
							onClick={() => navigate(`/orders/${orderId}`)}
						/>
					)}
				</Grid>
			</Grid>
			{/* <Grid item xs={12} md={2} textAlign='center' my='auto'>
				<Typography>{status}</Typography>
			</Grid> */}
		</Grid>
	);
};

export default OrderCardView;
