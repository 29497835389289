import {Grid} from "@mui/material";
import React from "react";
import FoodCard from "./FoodCard";

const FoodList = ({foodItems}) => {
	return (
		<Grid
			container
			sx={{
				display: "grid",
				gridTemplateColumns: {sm: "repeat(auto-fill, minmax(200px, 1fr))"},

				gridAutoRows: "auto",
				gridGap: "1.4rem",
			}}
		>
			{foodItems.map((food) => (
				<FoodCard
					key={food?._id}
					url={food?.image}
					name={food?.displayName}
					desc={food?.benefits}
				/>
			))}
		</Grid>
	);
};

export default FoodList;
