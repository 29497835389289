import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userService from "./userService";

const initialState = {
  successData: null,
  errorData: null,
  isUserLoading: false,
  currentUser: null,
  redirect: "",
  token:
    Boolean(localStorage.getItem("token")) &&
    localStorage.getItem("token") !== "undefined"
      ? localStorage.getItem("token")
      : null,
};

// Verify User Email
export const verifyUserEmail = createAsyncThunk(
  "user/verify/email",
  async (token, thunkAPI) => {
    try {
      return await userService.verifyEmail(token);
    } catch (error) {
      //const message =
      // (error.response && error.response.data && error.response.data.error.message) ||
      // error.message ||
      // error.toString();

      const message = (error && error.response.data.error) || error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Reset User Password
export const resetUserPassword = createAsyncThunk(
  "user/password/reset",
  async (data, thunkAPI) => {
    try {
      return await userService.resetPassword(data);
    } catch (error) {
      // const message =
      // 	(error.response && error.response.data && error.response.data.error.message) ||
      // 	error.message ||
      // 	error.toString();

      const message = (error && error.response.data) || error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Forgot Password
export const forgotPassword = createAsyncThunk(
  "user/password/forgot",
  async (email, thunkAPI) => {
    try {
      const data = await userService.forgotPassword(email);
      if (data) {
        //   const { dispatch } = thunkAPI;
        //   dispatch(checkuser());
        return data;
      }

      const message = "Verification Failed";

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const message = (error && error.response.data) || error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const checkUser = createAsyncThunk(
  "user/myaccount",
  async (_, thunkAPI) => {
    try {
      const data = await userService.checkUser();
      return data;
    } catch (error) {
      const message =
        (error && error?.response?.data?.error) || error.toString();

      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
      }

      return thunkAPI.rejectWithValue(message);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUser: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyUserEmail.pending, (state) => {
        state.isUserLoading = true;
      })
      .addCase(verifyUserEmail.fulfilled, (state, action) => {
        state.isUserLoading = false;
        state.successData = action.payload;
      })
      .addCase(verifyUserEmail.rejected, (state, action) => {
        state.isUserLoading = false;
        state.errorData = action.payload;
      })
      .addCase(resetUserPassword.pending, (state) => {
        state.isUserLoading = true;
      })
      .addCase(resetUserPassword.fulfilled, (state, action) => {
        state.isUserLoading = false;
        state.successData = action.payload;
        state.errorData = null;
      })
      .addCase(resetUserPassword.rejected, (state, action) => {
        state.isUserLoading = false;
        state.errorData = action.payload;
        state.successData = null;
      })
      .addCase(forgotPassword.pending, (state) => {
        state.isUserLoading = true;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.isUserLoading = false;
        state.successData = action.payload;
        state.errorData = null;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.isUserLoading = false;
        state.errorData = action.payload;
        state.successData = null;
      })
      .addCase(checkUser.pending, (state) => {
        state.isUserLoading = true;
      })
      .addCase(checkUser.fulfilled, (state, action) => {
        state.isUserLoading = false;
        state.successData = action.payload;
        state.currentUser = action.payload?.user;
        state.errorData = null;
      })
      .addCase(checkUser.rejected, (state, action) => {
        state.isUserLoading = false;
        state.errorData = action.payload;
        state.successData = null;
        state.redirect = "/auth/mobilelogin";
        state.token = null;
      });
  },
});

export const { resetUser } = userSlice.actions;
export default userSlice.reducer;
