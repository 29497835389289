import React from "react";
import {Box, Grid, Typography, useTheme} from "@mui/material";
import {CheckCircleIcon} from "@heroicons/react/outline";

const General = ({generalDescList}) => {
	const theme = useTheme();

	// function isUnique(data, index, newData) {
	// 	return arr.findIndex((x) => x.id === data.id) === newData;
	// }

	return (
		<Grid item xs={12} mt={5}>
			<Box mt={2} px={2}>
				{generalDescList?.map(({description, id}, index) => (
					<Typography key={index} mt={2} sx={{display: "flex", alignItems: "center", gap: 2}}>
						<span>
							<CheckCircleIcon height={"25px"} color={theme.palette.primary.main} />
						</span>
						<span>{description}</span>
					</Typography>
				))}
			</Box>
		</Grid>
	);
};

export default General;
