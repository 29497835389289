import React from 'react';
import {PencilIcon, TrashIcon} from '@heroicons/react/outline';
import {ListItemIcon, Menu, MenuItem} from '@mui/material';

export const Actions = ({
	anchorEl,
	open,
	handleClose,
	deleteHandle,
	handleClickOpen,
	setDeleted,
}) => {
	// const theme = useTheme();
	const list = [
		{
			name: 'Edit',
			icon: <PencilIcon height={'16px'} />,
			clicked: () => handleClickOpen(),
		},
		{
			name: 'Delete',
			icon: <TrashIcon height={'16px'} />,
			clicked: () => {
				setDeleted(true);
			},
		},
	];
	return (
		<Menu
			id='basic-menu'
			anchorEl={anchorEl}
			open={open}
			onClose={handleClose}
			MenuListProps={{
				'aria-labelledby': 'basic-button',
			}}
		>
			{list.map((item, index) => (
				<MenuItem
					key={index}
					onClick={() => {
						item.clicked();
						handleClose();
					}}
				>
					<ListItemIcon>{item.icon}</ListItemIcon>
					{item.name}
				</MenuItem>
			))}
		</Menu>
	);
};
