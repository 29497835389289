import React, { useEffect, useState } from 'react';
import { ArrowNarrowRightIcon } from '@heroicons/react/outline';
import { Box, Container, Grid, Typography, useTheme } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';

// import { useNavigate } from 'react-router';
import { PsysicianWithPatient } from '../../assets';
import BlogPostCard from '../../components/Blogs/Common/BlogPostCard';
import ActionButton from '../../components/Common/Buttons/ActionButton';
import SectionPadding from '../../components/Common/SectionPadding';
import BlogsHeader from '../../components/Blogs/BlogsHeader';
import ScrollToTop from "../../components/hoc/ScrollToTop";
import axios from "../../axios-instance";
import { useParams, useNavigate } from "react-router-dom";
import Loader from '../../components/Common/Loader';

// import data from '../../components/Blogs/data.json';
import { getAllBlogs, resetBlog } from '../../features/blogs/blogSlice';

const Blogs = () => {
	const navigate = useNavigate();
	const { blogs } = useSelector((state) => state.blog);
	const dispatch = useDispatch();
	const params = useParams();
	const [loader, setLoader] = useState(true);
	const [categoryBlogs, setCategoryBlogs] = useState([]);

	useEffect(() => {
		getAllCategoryBlogs();

		dispatch(getAllBlogs());
		return () => dispatch(resetBlog());
	}, [dispatch]);



	const getAllCategoryBlogs = () => {
		setLoader(true);
		axios.get(`/api/blogs/category/${params.categoryblogId}`)
			.then((res) => {
				console.log('res', res?.data?.categoryBlogs);
				if (res) {
					setCategoryBlogs(res?.data?.categoryBlogs);
					setLoader(false);
				}
			})
			.catch((error) => console.log(error));
		setLoader(false);
	};

	console.log('categoryBlogs', categoryBlogs);
	console.log('params', params.categoryblogId);
	// if (loader) return <Loader open={true} />;
	return (
		<>
			<ScrollToTop />
			<Box style={{ marginTop: '100px' }}>
				{/* <SectionPadding>
				<Container>
					<BlogsHeader blogs={blogs?.blog} />
				</Container>
			</SectionPadding> */}
				<Container>
					{categoryBlogs.length ? <Grid container sx={{ pb: { md: '6rem' } }}>
						<Grid item xs={12} textAlign='center' mb={{ xs: 2, md: 4 }}>
							<Typography
								variant='h2'
								sx={{
									textTransform: 'capitalize',
									fontSize: { xs: '1.2rem', md: '2rem' },
									fontWeight: 400,
								}}
							>
								Latest Blogs
							</Typography>
							<Typography
								variant='body2'
								sx={{ fontSize: { xs: '0.725rem', md: '1.1rem' } }}
							>
								Explore our blog for insightful articles
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							sx={{
								display: 'grid',
								gridTemplateColumns: { sm: 'repeat(auto-fill, minmax(280px, 1fr))' },
								gridAutoRows: 'auto',
								gridGap: '2rem',
								my: 2,
							}}
						>
							{categoryBlogs && categoryBlogs.map(({ description, createdAt, category, thumbnailImage, _id, title }) => (
								<BlogPostCard
									key={_id}
									blogDescription={description}
									blogTitle={title}
									postDate={createdAt}
									category={category}
									img={thumbnailImage}
									handleNavigation={() => navigate(`/blogs/${_id}`)}
								/>
							))}
						</Grid>
						{/* <Grid item xs={12} my={{xs: 3, md: 6}} textAlign='center'>
						<ActionButton endIcon={<ArrowNarrowRightIcon height={'18px'} />}>
							View more
						</ActionButton>
					</Grid> */}
					</Grid> : <Grid container sx={{ pb: { md: '6rem' } }}>
						<Grid item xs={12} textAlign='center' mb={{ xs: 2, md: 4 }}>
							<Typography
								variant='h2'
								sx={{
									textTransform: 'capitalize',
									fontSize: { xs: '1.2rem', md: '2rem' },
									fontWeight: 400,
								}}
							>
								No Blogs Data!
							</Typography>
							
						</Grid>
						<Grid
							item
							xs={12}
							sx={{
								display: 'grid',
								gridTemplateColumns: { sm: 'repeat(auto-fill, minmax(280px, 1fr))' },
								gridAutoRows: 'auto',
								gridGap: '2rem',
								my: 2,
							}}
						>
							{categoryBlogs && categoryBlogs.map(({ description, createdAt, category, imageurl, _id, title }) => (
								<BlogPostCard
									key={_id}
									blogDescription={description}
									blogTitle={title}
									postDate={createdAt}
									category={category}
									img={imageurl}
									handleNavigation={() => navigate(`/blogs/${_id}`)}
								/>
							))}
						</Grid>
						{/* <Grid item xs={12} my={{xs: 3, md: 6}} textAlign='center'>
						<ActionButton endIcon={<ArrowNarrowRightIcon height={'18px'} />}>
							View more
						</ActionButton>
					</Grid> */}
					</Grid>}
					
				</Container>
			</Box>
		</>
	);
};

export default Blogs;
