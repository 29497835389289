import React from 'react';
// import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
// import InstagramIcon from '@mui/icons-material/Instagram';
// import TwitterIcon from '@mui/icons-material/Twitter';
import { LinkedIn, FacebookRounded, Instagram, Twitter } from '@mui/icons-material';
import { Box, useTheme, Link } from '@mui/material';

const SocialIcons = () => {
	const theme = useTheme();

	const socialIcons = [
		{
			item: (
				<FacebookRounded
					sx={{
						fontSize: { xs: '1.5rem', md: '1.8rem' },
						color: theme.palette.common.white,
						cursor: 'pointer',
						transition: '.2s ease-out',
						'&:hover': {
							transition: '.2s ease-out',
							color: '#3b5998',
						},
					}}
				/>
			),
			link: 'https://www.facebook.com/guticare?mibextid=ZbWKwL',
		},
		{
			item: (
				<Instagram
					sx={{
						fontSize: { xs: '1.5rem', md: '1.8rem' },
						color: theme.palette.common.white,
						cursor: 'pointer',
						transition: '.2s ease-out',
						'&:hover': {
							transition: '.2s ease-out',
							color: '#c32aa3',
						},
					}}
				/>
			),
			link: `https://instagram.com/guticarepvt?igshid=YTQwZjQ0NmI0OA==`,
		},
		{
			item: (
				<Twitter
					sx={{
						fontSize: { xs: '1.5rem', md: '1.8rem' },
						color: theme.palette.common.white,
						cursor: 'pointer',
						transition: '.2s ease-out',
						'&:hover': {
							transition: '.2s ease-out',
							color: '#1da1f2',
						},
					}}
				/>
			),
			link: 'https://twitter.com/guticare',
		},
		{
			item: (
				<LinkedIn
					sx={{
						fontSize: { xs: '1.5rem', md: '1.8rem' },
						color: theme.palette.common.white,
						cursor: 'pointer',
						transition: '.2s ease-out',
						'&:hover': {
							transition: '.2s ease-out',
							color: '#0A66C2',
						},
					}}
				/>
			),
			link: 'https://www.linkedin.com/company/guticare/',
		},
	];

	return (
		<>
			{/* <p style={{ fontSize: '18px', color: '#fff' }}>Follow us:</p> */}
			{socialIcons.map(({ item, link }, index) => (
				<Box
					component={Link}
					underline='none'
					href={link}
					sx={{ cursor: 'pointer' }}
					key={index}
					target='_blank'
				>
					{item}
				</Box>
			))}
		</>
	);
};

export default SocialIcons;
