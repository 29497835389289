import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import service from "./kitActivationService";

const initialState = {
  questions: [],
  isQuestionError: false,
  isQuestionSuccess: false,
  isQuestionLoading: false,
  questionsMessage: "",
};

export const getAllQuestions = createAsyncThunk(
  "questions/getall",
  async (_, thunkAPI) => {
    try {
      return await service.getQuestions();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.error.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

const kitActivationSlice = createSlice({
  name: "kitActivationQuestions",
  initialState,
  reducers: {
    resetQuestions: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllQuestions.pending, (state) => {
        state.isQuestionLoading = true;
      })
      .addCase(getAllQuestions.fulfilled, (state, action) => {
        console.log('getAllQuestionsaction',action);
        state.isQuestionSuccess = true;
        state.isQuestionError = false;
        state.questions = action?.payload?.data;
        state.isQuestionLoading = false;
      })
      .addCase(getAllQuestions.rejected, (state, action) => {
        state.isQuestionSuccess = false;
        state.isQuestionError = true;
        state.questions = [];
        state.isQuestionLoading = false;
        state.questionsMessage = action.payload;
      });
  },
});

export const { resetQuestions } = kitActivationSlice.actions;
export default kitActivationSlice.reducer;
