import React from "react";
import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { SwiperSlide } from "swiper/react";
import SectionPadding from "../../Common/SectionPadding";
import Carousel from "../../Common/Carousel";
import ResponsiveImage from "../../Common/ResponsiveImage";
import image_constants from "../../../shared/utils/images_constantsV2";

const data = [
  {
    id: 1,
    title: "we continue to revolutionize technology to enhance your QoL",
    desc: "GUT-iCARE has a mission to help people by bridging science and Quality of Life (QoL). We are committed to improving your healthier living by adjusting your lifestyle.",
    boxDesc:
      "GUT-iCARE advanced DNA sequencing and computational analysis have an exceptional platform to trace your good and nasty bugs inside the gut. The gutxplore packages enable your beneficial microbes and their contribution to your QoL.",
    img: image_constants.ABOUT_PAGE_TECH_01,
  },
  {
    id: 2,
    title: "First are best because they are beginning",
    desc: "To obtain quality result, adequate sample size and integrity is needed. GUT-iCARE sample collection tube contains specialised buffer that keep intact of microbial genetic materials such as DNA and RNA of your samples.",
    boxDesc:
      "Further this carefully transported to our laboratory desk via GUT-iCARE logistic partner, whereas we kick-start puzzling your health beneficial microbiomes.",
    img: image_constants.ABOUT_PAGE_TECH_02,
  },
  {
    id: 3,
    title: "Detach DNA to reconnect",
    desc: "Do you know who is your history teller? Indeed YES!! that is your genetic material DNA (genetic code) which hides in every cell of your body and is same for your gut microbiomes too.",
    boxDesc:
      "GUT-iCARE gives more importance to extract high throughput microbial DNA without compromising the sample integrity and the purity. Picturize a tree before your eyes!!",
    img: image_constants.ABOUT_PAGE_TECH_03,
  },
  {
    id: 4,
    title: "Process to combine things in a particular order",
    desc: "Recent advancement in high-throughput sequencing and machine learning algorithms along with our backed science stretched us to select a robust technique for sequencing microbial long DNA fragments to correlate with your health history.",
    boxDesc:
      " We at GUT-iCARE employ fourth generation DNA sequencing technology to exploit this scientific knowledge to identify microbes at the species level and understand their symbiotic relationship in the gut.",
    img: image_constants.ABOUT_PAGE_TECH_04,
  },
  {
    id: 5,
    title: "Analysis and interpretation of data using a unique database",
    desc: "The DNA reads will be mapped against publicly available database to reveal the presence of bugs in your sample. To map the beneficial bugs, the samples are compared with in-house database that is carefully picked and built by the scientists of GUT-iCARE.",
    boxDesc:
      "Further Gut-iCare novel gut test scores generated by AI (artificial intelligence) pipeline that estimates an accurate health score. Our easy to understand digitalized scores will help you to discover the beneficial residents (Microbes) and manage them in a good count.",
    img: image_constants.ABOUT_PAGE_TECH_05,
  },
  {
    id: 6,
    title: "customized diet plan",
    desc: "We believe that the gut microbiome in every individual is unique. Therefore, the same diet plan may not be applicable to building a healthy body for everyone. We propose the best diet plan course to put forward a healthy gut and improve overall health.",
    boxDesc:
      " Diet on call assistance is provided free of cost and accessible to each of our customer after getting their microbiome scores. We provide you with additional information and on our websites or by calling our Customer Response Centre.",
    img: image_constants.ABOUT_PAGE_TECH_06,
  },
];

const Microbiome = ({ scrollRef, data }) => {
  const theme = useTheme();

  // states
  //...

  // handler functions
  //...

  // Colors

  const colorMain = theme.palette.secondary.main;
  return (
    <Box id="technology" ref={scrollRef}>
      <SectionPadding
        overRideStyles={{
          paddingY: { xs: "2rem", sm: "4rem" },
          marginTop: "2rem",
        }}
      >
        <Container>
          <Grid container>
            <Grid item xs={12} textAlign={"center"}>
              <Typography
                variant="h2"
                sx={{
                  textTransform: "capitalize",
                  color: colorMain,
                  fontSize: { xs: "1.2rem", md: "2rem" },
                  fontWeight: 400,
                }}
              >
                FINE TUNED TECHNOLOGY FINGERPRINTS YOUR MICROBIOME
              </Typography>
              <Divider
                width="7%"
                sx={{
                  mx: "auto",
                  mt: 2,
                  fontWeight: 700,
                  borderBottomWidth: "thick",
                }}
              />
            </Grid>
            <Grid item xs={12} mt={{ xs: 3, md: 0 }} p={{ xs: 0, sm: 3 }}>
              <Carousel
                slidesPerView={1}
                spaceBetween={45}
                navigation={false}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                style={{ padding: "20px" }}
              >
                {data.map(
                  ({
                    heading,
                    description,
                    overlayDescription,
                    imageurl,
                    id,
                  }) => (
                    <SwiperSlide key={id}>
                      <ComponentToSlide
                        heading={heading}
                        description={description}
                        overlayDescription={overlayDescription}
                        imageurl={imageurl}
                      />
                    </SwiperSlide>
                  )
                )}
              </Carousel>
            </Grid>
          </Grid>
        </Container>
      </SectionPadding>
    </Box>
  );
};

const ComponentToSlide = ({
  heading,
  description,
  overlayDescription,
  imageurl,
}) => {
  const theme = useTheme();
  return (
    <Grid
      item
      container
      xs={12}
      mb={4}
      alignItems={"center"}
      justifyContent={"center"}
      sx={{
        boxShadow: `rgba(0, 0, 0, 0.1) -4px 9px 25px -6px`,
        p: { xs: 2, sm: 4 },
        borderRadius: "15px",
      }}
    >
      <Grid
        item
        xs={12}
        md={6}
        my="auto"
        textAlign={{ xs: "center", md: "left" }}
      >
        <ResponsiveImage img={imageurl} alt="microbiome" width="500" />
      </Grid>
      <Grid item container xs={12} md={6} pl={{ xs: 0, md: 2 }}>
        <Grid item xs={12}>
          <Box>
            <Typography
              variant="subtitle1"
              sx={{
                textTransform: "capitalize",
                color: theme.palette.secondary.main,
                fontSize: { xs: "0.9rem", md: "1.2rem" },
                fontWeight: 700,
              }}
              dangerouslySetInnerHTML={{
                __html: `${heading}`
              }}
            >
              {/* {heading} */}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="body1"
              color={theme.palette.common.greyDark}
              sx={{ fontSize: { xs: "0.725rem", md: "1rem" } }}
              style={{ textAlign: "justify" }}
              dangerouslySetInnerHTML={{
                __html: `${description}`
              }}
            >
              {/* {description} */}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} ml={{ xs: 0, lg: -28 }} mt={{ xs: 2, md: 1 }}>
          <Box
            sx={{
              p: { xs: 2, md: 4 },
              background:
                "linear-gradient(90deg, rgba(60, 170, 72, 0.8) 26.53%, rgba(26, 87, 53, 0.8) 79.58%)",
              backdropFilter: "blur(4px)",
              borderRadius: 1,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                textAlign: "justify",
                color: "#fff",
                fontSize: { xs: ".725rem", md: "1rem" },
              }}
              dangerouslySetInnerHTML={{
                __html: `${overlayDescription}`
              }}
            >
              {/* {overlayDescription} */}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Microbiome;
