import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import contentManagementService from "./contentManagementService";

const initialState = {
  ContentManagements: null,
  currentContentManagement: null,
  isContentManagementError: false,
  isContentManagementSuccess: false,
  isContentManagementLoading: false,
  ContentManagementMessage: "",
  footerContentManagements: [],
};

export const getAllContentManagements = createAsyncThunk(
  "contentManagement/getall",
  async (_, thunkAPI) => {
    try {
      return await contentManagementService.getContentManagements();
    } catch (error) {
      //const message =
      // (error.response && error.response.data && error.response.data.error.message) ||
      // error.message ||
      // error.toString();

      const message = (error && error.message) || error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getContentManagement = createAsyncThunk(
  "contentManagement/getById",
  async (data, thunkAPI) => {
    try {
      return await contentManagementService.getContentManagementById(data);
    } catch (error) {
      // const message =
      // 	(error.response && error.response.data && error.response.data.error.message) ||
      // 	error.message ||
      // 	error.toString();

      const message = (error && error.message) || error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

const contentManagementSlice = createSlice({
  name: "ContentManagements",
  initialState,
  reducers: {
    resetContentManagement: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllContentManagements.pending, (state) => {
        state.isContentManagementLoading = true;
      })
      .addCase(getAllContentManagements.fulfilled, (state, action) => {
        state.isContentManagementLoading = false;
        state.isContentManagementSuccess = true;
        state.ContentManagements = action.payload;
        state.footerContentManagements = action.payload.contentManagement;
      })
      .addCase(getAllContentManagements.rejected, (state, action) => {
        state.isContentManagementLoading = false;
        state.isContentManagementError = true;
        state.isContentManagementSuccess = false;
        state.ContentManagementMessage = action.payload;
      })
      .addCase(getContentManagement.pending, (state) => {
        state.isContentManagementLoading = true;
      })
      .addCase(getContentManagement.fulfilled, (state, action) => {
        state.isContentManagementLoading = false;
        state.isContentManagementSuccess = true;
        state.currentContentManagement = action.payload;
      })
      .addCase(getContentManagement.rejected, (state, action) => {
        state.isContentManagementLoading = false;
        state.isContentManagementError = true;
        state.isContentManagementSuccess = false;
        state.ContentManagementMessage = action.payload;
      });
  },
});

export const { resetContentManagement } = contentManagementSlice.actions;
export default contentManagementSlice.reducer;
