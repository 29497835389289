import orderService from "../../features/orders/orderService";
import { updateOrderPayment } from "../../features/orders/orderSlice";
import { getPaymentGateway } from "../../features/paymentgateway/paymentGatewaySlice";
import paymentGateway from "../../features/paymentgateway/paymentGatewayService";


function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export const Razorpay = async (orderId, userName, email, phone, orderName, dispatch, setisLoading, redirect) => {
  // const {currentPaymentGateway} = useSelector((state) => state.product);
  // useEffect(() => {
  //   dispatch(getPaymentGateway());
  // }, [dispatch]);

  const paymentDetails = await paymentGateway.getDefaultPayment();
  //dispatch(getPaymentGateway());
  // const currentPaymentGateway = [];

  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
  if (!res) {
    alert("Razorpay SDK failed to load. Are you online?");
    return;
  }

  const response = await orderService.initOrderPayment(orderId);
  setisLoading(false);
  const data = response.data;
  const options = {
    key: (paymentDetails.paymentGateway[0] ? paymentDetails.paymentGateway[0].apiKey : process.env.RAZORPAY_KEY_ID),
    currency: data.currency,
    amount: data.amount.toString(),
    order_id: data.id,
    name: orderName,
    description: "",
    handler: function ({ razorpay_payment_id, razorpay_order_id, razorpay_signature }) {
      const rz_response = {
        razorpay_payment_id,
        razorpay_order_id,
        razorpay_signature,
        id: orderId
      };
      setisLoading(true);
      dispatch(updateOrderPayment(rz_response));
      setisLoading(false);
      redirect();
    },
    prefill: {
      name: userName,
      email: email,
      phone_number: phone,
    },
  };
  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
};
