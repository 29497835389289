import {Suspense} from "react";

import Loader from "./Loader";

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

const Loadable = (Component) => (props) =>
	(
		<Suspense fallback={<Loader open={true} />}>
			<Component {...props} />
		</Suspense>
	);

export default Loadable;
