import axios from '../../axios-instance';

const getDefaultPayment = async (data) => {
	const response = await axios.get(`/api/payment-gateway/default`);
	return response.data;
};

const paymentGatewayService = {
	getDefaultPayment,
};

export default paymentGatewayService;
