import React, { useEffect, useState } from 'react';
import { ArrowNarrowRightIcon } from '@heroicons/react/outline';
import { Box, Container, Grid, Typography, useTheme } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router';
import { PsysicianWithPatient } from '../../assets';
import BlogPostCard from '../../components/Blogs/Common/BlogPostCard';
import ActionButton from '../../components/Common/Buttons/ActionButton';
import SectionPadding from '../../components/Common/SectionPadding';
import BlogsHeader from '../../components/Blogs/BlogsHeader';
import ScrollToTop from "../../components/hoc/ScrollToTop";
import Loader from '../../components/Common/Loader';

// import data from '../../components/Blogs/data.json';
import { getAllBlogs, resetBlog, getBlogCategories } from '../../features/blogs/blogSlice';

const CategoryBlogs = () => {
    const navigate = useNavigate();
    const { blogs, blogCategories, isBlogLoading } = useSelector((state) => state.blog);
    const dispatch = useDispatch();
    const theme = useTheme();

    useEffect(() => {
        dispatch(getAllBlogs());
        dispatch(getBlogCategories());
        return () => dispatch(resetBlog());
    }, [dispatch]);
    console.log('blogCategories', blogCategories);

    const handleClick = (id) => {
        console.log('_id', id);
        navigate(`/categoryblogs/${id}`)
    };
    if (isBlogLoading) return <Loader open={true} />;
    return (
        <>
            <ScrollToTop />
            <Box style={{ marginTop: '100px' }}>

                <Container>
                    <Grid container sx={{ pb: { md: '6rem' } }}>
                        <Grid item xs={12} textAlign='center' mb={{ xs: 2, md: 4 }}>
                            <Typography
                                variant='h2'
                                sx={{
                                    textTransform: 'capitalize',
                                    fontSize: { xs: '1.2rem', md: '2rem' },
                                    fontWeight: 400,
                                }}
                            >
                                Category Blogs
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: { sm: 'repeat(auto-fill, minmax(280px, 1fr))' },
                                gridAutoRows: 'auto',
                                gridGap: '2rem',
                                my: 2,
                            }}
                        >
                            {blogCategories?.blogCategory && blogCategories?.blogCategory?.map((data, id) => (
                                // console.log('data', data.imageurl)
                                < Box onClick={() => handleClick(data?._id)}
                                    style={{ borderRadius: '5px', backgroundColor: '#f5f5f5' }} sx={{ p: { xs: 1, sm: 2 } }}>


                                    {/* <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            width: { xs: "120px", sm: "150px", md: "200px" },
                                            //height: { xs: "100px", sm: "150px", md: "200px" },
                                            height: "auto",
                                            borderRadius: "10px",
                                            // mx: { xs: "auto", lg: 0 },
                                            // overflow: "hidden",
                                        }}
                                    >
                                        <img
                                            src={data?.imageurl}
                                            alt="productImage"
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                            }}
                                        />
                                    </Grid> */}
                                    <Grid
                                        item
                                        xs={12}
                                        textAlign="center"
                                        // p={{xs: 1, sm: 2.5}}
                                        // py={{sm: 6}}
                                        sx={{
                                            cursor: "pointer",
                                            // background:
                                            // 	'linear-gradient(to right bottom, rgba(255,255,255) 50%, rgba(21, 126, 81, 0.1) 50%)',
                                            position: "relative",
                                            overflow: "hidden",
                                            width: "100%",
                                            height: "200px",
                                            maxHeight: "380px",
                                            borderRadius: 2,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}
                                    >
                                        <img
                                            src={data?.imageurl}
                                            alt="product"
                                            style={{
                                                // width: "100%",
                                                maxWidth: "300px",
                                                maxHeight: "100%",

                                                // height: "100%",
                                                transition: "0.4s ease-out",
                                                // objectFit: "fill",
                                                backgroundSize: "cover",
                                                backgroundPosition: "center center",
                                            }}
                                        />
                                    </Grid>


                                    < Typography
                                        sx={{
                                            // color: theme.palette.secondary.main,
                                            fontSize: { xs: '0.975rem', sm: '1.2rem' },
                                            // fontWeight: 600,
                                            cursor: 'pointer',
                                            padding: '10px',
                                            textAlign: 'center',
                                            // margin: '10px'
                                        }}
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                `${data?.blogCategoryName}`
                                        }}
                                    >
                                        {/* {data?.blogCategoryName} */}
                                    </Typography>
                                </Box>
                            ))}

                        </Grid>
                    </Grid>
                </Container >
            </Box >
        </>
    );
};

export default CategoryBlogs;
