import React, {useEffect} from 'react';
import {Box} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {getAddresses} from '../../features/profile/profileSlice';
import AddressGrouped from './Common/AddressGrouped';
import Loader from '../Common/Loader';
import {Form, Formik} from 'formik';
import DialogModal from '../Common/DialogModal';
import {updateOrderAddress} from '../../features/orders/orderSlice';

const AddressesList = ({currentUser, handleClose, changeAddress}) => {
	const {addresses, isProfileLoading} = useSelector((state) => state.profile);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getAddresses());
	}, [dispatch]);

	if (isProfileLoading) return <Loader open={isProfileLoading} />;

	return (
		<Formik
			initialValues={{pickedAddress: currentUser?.activeOrder?.address}}
			onSubmit={(values) => {
				dispatch(updateOrderAddress({addressid: values.pickedAddress}));
				handleClose();
			}}
		>
			{({handleSubmit}) => (
				<Form>
					<DialogModal
						buttonOne={'Cancel'}
						buttonTwo={'Save'}
						btnSubmit='submit'
						handleClose={handleClose}
						handlePrimaryClick={handleSubmit}
						handleButtonOneClick={handleClose}
						open={changeAddress}
						title={'Please select the address for the product to be shipped to'}
					>
						<Box>
							<AddressGrouped
								labelText={`Order Will shipped to the selected address`}
								name='pickedAddress'
								options={addresses}
							/>
						</Box>
					</DialogModal>
				</Form>
			)}
		</Formik>
	);
};

export default AddressesList;

//'Please select the address for the product to be shipped to'
