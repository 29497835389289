import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import blogService from "./blogService";

const initialState = {
  blogs: null,
  blogCategories: null,
  currentBlog: null,
  categoryBlogs: null,
  isBlogError: false,
  isBlogSuccess: false,
  isBlogLoading: false,
  blogMessage: "",
};

export const getAllBlogs = createAsyncThunk("blog/getall", async (_, thunkAPI) => {
  try {
    return await blogService.getBlogs();
  } catch (error) {
    const message = (error && error.message) || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getBlog = createAsyncThunk("blog/getById", async (data, thunkAPI) => {
  try {
    return await blogService.getBlogById(data);
  } catch (error) {
    const message = (error && error.message) || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getCategoryBlog = createAsyncThunk("blog/getCategoryBlogs", async (data, thunkAPI) => {
  try {
    return await blogService.getCategoryBlogs(data);
  } catch (error) {
    const message = (error && error.message) || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getBlogCategories = createAsyncThunk("blog/blogCategories", async (data, thunkAPI) => {
  try {
    return await blogService.getAllCategoryBlogs(data);
  } catch (error) {
    const message = (error && error.message) || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const blogSlice = createSlice({
  name: "blogs",
  initialState,
  reducers: {
    resetBlog: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBlogs.pending, (state) => {
        state.isBlogLoading = true;
      })
      .addCase(getAllBlogs.fulfilled, (state, action) => {
        state.isBlogLoading = false;
        state.isBlogSuccess = true;
        state.blogs = action.payload;
      })
      .addCase(getAllBlogs.rejected, (state, action) => {
        state.isBlogLoading = false;
        state.isBlogError = true;
        state.isBlogSuccess = false;
        state.blogMessage = action.payload;
      })
      .addCase(getBlog.pending, (state) => {
        state.isBlogLoading = true;
      })
      .addCase(getBlog.fulfilled, (state, action) => {
        state.isBlogLoading = false;
        state.isBlogSuccess = true;
        state.currentBlog = action.payload;
      })
      .addCase(getBlog.rejected, (state, action) => {
        state.isBlogLoading = false;
        state.isBlogError = true;
        state.isBlogSuccess = false;
        state.blogMessage = action.payload;
      })
      .addCase(getCategoryBlog.pending, (state) => {
        state.isBlogLoading = true;
      })
      .addCase(getCategoryBlog.fulfilled, (state, action) => {
        console.log('getCategoryBlog', action);
        state.isBlogLoading = false;
        state.isBlogSuccess = true;
        state.categoryBlogs = action.payload;
      })
      .addCase(getCategoryBlog.rejected, (state, action) => {
        state.isBlogLoading = false;
        state.isBlogError = true;
        state.isBlogSuccess = false;
        state.blogMessage = action.payload;
      })
      .addCase(getBlogCategories.pending, (state) => {
        state.isBlogLoading = true;
      })
      .addCase(getBlogCategories.fulfilled, (state, action) => {
        console.log('getCategoryBlog', action);
        state.isBlogLoading = false;
        state.isBlogSuccess = true;
        state.blogCategories = action.payload;
      })
      .addCase(getBlogCategories.rejected, (state, action) => {
        state.isBlogLoading = false;
        state.isBlogError = true;
        state.isBlogSuccess = false;
        state.blogMessage = action.payload;
      });
  },
});

export const { resetBlog } = blogSlice.actions;
export default blogSlice.reducer;
