import axios from '../../axios-instance';

const getContactUs = async () => {
	const response = await axios.get(`/api/admin/contactUs`);
	return response.data;
};

const contactUsService = {
	getContactUs,
};

export default contactUsService;
