import React from "react";
import { Container, Grid, Typography } from "@mui/material";
import SectionPadding from "../../Common/SectionPadding";
import AdvisorCard from "./advisor/AdvisorCard";
import useBreakpoints from "../../../hooks/useBreakpoints";
import Carousel from "../../Common/Carousel";
import { SwiperSlide } from "swiper/react";

const Advisors = ({ title, data }) => {
	return (
		<SectionPadding sx={{ paddingBottom: { xs: "10px", md: "5rem" }, mt: 2 }} style={{ backgroundColor: "#fff" }}>
			<Container>
				<Grid container >

					{title === "Executive Team" || title === "Board Of Directors" ? <Grid
						item
						xs={12}
						sx={{
							textAlign: "center",
						}}>
						<Typography
							sx={{
								textTransform: "capitalize",
								fontSize: { xs: "1.2rem", md: "2rem" },
								fontWeight: 700,
								color: "rgb(26, 87, 53)"
							}}>
							{title}
						</Typography>
					</Grid>
						:
						<Grid
							item
							xs={12}
							sx={{
								textAlign: "center",
							}}>
							<Typography
								sx={{
									textTransform: "capitalize",
									fontSize: { xs: "1.2rem", md: "2rem" },
									fontWeight: 700,
								}}>
								{title}
							</Typography>
						</Grid>}

					{data.length >= 3 ? <Grid
						item
						xs={12}
						sx={{
							// display: "grid",
							gridTemplateColumns: { sm: "repeat(auto-fill, minmax(250px, 1fr))" },
							// gridAutoRowrs: "auto",
							gridGap: "1.5rem",
							mt: "4rem",
						}}
					>

						<Carousel
							showArr={true}
							centeredSlides={true}
							pagination={false}
							// autoplay={{
							// 	delay: 2500,
							// 	disableOnInteraction: false,
							// 	pauseOnMouseEnter: true,
							// }}
							autoplay={true}
							slidesPerView={1}
							spaceBetween={20}
							breakpoints={{
								600: {
									slidesPerView: 1,
								},
								1060: {
									slidesPerView: 3,
								},
							}}
						>

							{data?.map((item, index) => (
								<SwiperSlide>
									<AdvisorCard style={{ padding: '20px' }} data={item} key={index} />
								</SwiperSlide>
							))}
						</Carousel>

					</Grid> :
						<Grid
							item
							xs={12}
							sx={{
								display: "grid",
								gridTemplateColumns: { sm: "repeat(auto-fill, minmax(250px, 1fr))" },
								gridAutoRowrs: "auto",
								gridGap: "1.5rem",
								mt: "4rem",
								padding: '0px 80px'
							}}>
							{data.map((item, index) => (
								<AdvisorCard data={item} key={index} />
							))}
						</Grid>
					}


				</Grid>
			</Container>
		</SectionPadding>
	);
};

export default Advisors;
