import axios from "../../axios-instance";

const getFaqs = async (qStr) => {
  const response = await axios.get(`/api/faq`);
  return response.data;
};

const geFaqById = async (data) => {
  const response = await axios.get(`/api/faq/${data.id}`);
  return response.data;
};

const faqService = {
  getFaqs,
  geFaqById,
};

export default faqService;
