import React, { useState } from "react";
import {
  Button,
  Grid,
  InputAdornment,
  Select,
  Tooltip,
  useTheme,
} from "@mui/material";
import TextInputField from "../../Common/Form/TextInputField";
import { BadgeCheckIcon } from "@heroicons/react/solid";

const UpdateDetails = ({ currentUser }) => {
  const theme = useTheme();
  const styles = {
    width: { xs: "100%", sm: "100%" },
    mx: { xs: "auto" },
  };

  return (
    <>
      <Grid container columnSpacing={1}>
        <Grid item xs={12}>
          <TextInputField
            name="userName"
            label="User Name"
            type="text"
            margin="dense"
            size="small"
            styles={styles}
          />
        </Grid>

        <Grid item container xs={12} columnSpacing={1}>
          <Grid item xs={2}>
          <TextInputField
            name="countryCode"
            label="Code"
            type="text"
            margin="dense"
            size="small"
            styles={styles}
            disabled
            value={currentUser?.countryCode}
          />
          </Grid>
          <Grid item xs={10}>
            <TextInputField
              name="phone"
              label="Mobile Number"
              disabled
              type="text"
              margin="dense"
              size="small"
              styles={styles}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {currentUser?.isPhoneVerified && (
                      <Tooltip title={"VERIFIED"} placement="right">
                        <BadgeCheckIcon
                          height={"24px"}
                          color={theme.palette.primary.dark}
                        />
                      </Tooltip>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {/* {!currentUser?.isPhoneVerified && (
									<Grid item xs={12} md={2} my='auto'>
										<Button>Verify Phone</Button>
									</Grid>
								)} */}
        </Grid>
        <Grid item container xs={12} columnSpacing={1}>
          <Grid item xs={12}>
            <TextInputField
              name="email"
              label="Email"
              type="text"
              margin="dense"
              size="small"
              styles={styles}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {currentUser?.isEmailVerified && (
                      <Tooltip title={"VERIFIED"} placement="right">
                        <BadgeCheckIcon
                          height={"24px"}
                          color={theme.palette.primary.dark}
                        />
                      </Tooltip>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default UpdateDetails;
