import React, {useState} from "react";
import {Grid} from "@mui/material";
import TextInputField from "../../Common/Form/TextInputField";

import StateDropdown from "../Common/StateDropdown";

import _states from "../../../shared/mocks/states.json";
import { useFormikContext } from "formik";

const AddressForm = () => {
	const {values} = useFormikContext();
	// const [state, setState] = useState();

	// useEffect(() => {
	// 	const getStatesFromIndia = () => {
	// 		const options = {
	// 			method: "GET",
	// 			url: "https://andruxnet-world-cities-v1.p.rapidapi.com/",
	// 			params: {query: "paris", searchby: "city"},
	// 			headers: {
	// 				"X-RapidAPI-Key": "b3e0d070afmsh543a7751331578fp14f00bjsnf76fcf609fcd",
	// 				"X-RapidAPI-Host": "andruxnet-world-cities-v1.p.rapidapi.com",
	// 			},
	// 		};

	// 		axios
	// 			.request(options)
	// 			.then(function (response) {
	// 				console.log(response.data);
	// 			})
	// 			.catch(function (error) {
	// 				console.error(error);
	// 			});
	// 	};

	// 	getStatesFromIndia();
	// }, []);

	const styles = {
		width: {xs: "100%"},
		mx: {xs: "auto"},
		// [`& fieldset`]: {
		// 	borderRadius: 3,
		// },
	};

	return (
		<Grid item container xs={12} columnSpacing={1}>
			<Grid item xs={12}>
				<TextInputField
					name="alias"
					label="Address Name"
					placeholder="Choose A Name for your Address"
					type="text"
					margin="dense"
					size="small"
					styles={styles}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextInputField
					name="address1"
					label="Address Line 1"
					placeholder="Door/Flat.no, Area, Cross"
					type="text"
					margin="dense"
					size="small"
					styles={styles}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextInputField
					name="address2"
					label="Address Line 2"
					placeholder="Street/Block, House Name"
					type="text"
					margin="dense"
					size="small"
					styles={styles}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextInputField
					name="phone"
					label="Phone"
					type="number"
					margin="dense"
					size="small"
					styles={styles}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextInputField
					name="city"
					label="City"
					type="text"
					margin="dense"
					size="small"
					styles={styles}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextInputField
					name="pincode"
					label="Pincode"
					placeholder="6 digits PIN code"
					type="number"
					margin="dense"
					size="small"
					styles={styles}
				/>
			</Grid>
			{/* <Grid item xs={12} md={6}>
				<TextInputField
					name="state"
					label="State"
					type="text"
					margin="dense"
					size="small"
					styles={styles}
				/>
			</Grid> */}
			<Grid item xs={12} md={6}>
				<StateDropdown
					name="state"
					label="State"
					margin="dense"
					size="small"
					styles={styles}
					options={_states}
				/>
			</Grid>
		</Grid>
	);
};

export default AddressForm;
