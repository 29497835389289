import React, {useEffect} from 'react';
import {Container, Grid} from '@mui/material';
import {useNavigate} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import SectionPadding from '../../components/Common/SectionPadding';
import OrderCardView from '../../components/Orders/OrderCardView';
import TitleWithBackBtn from '../../components/Common/TitleWithBackBtn';
import {getAllOrders, resetOrders} from '../../features/orders/orderSlice';
import NoDataView from '../../components/Common/NoDataView';
import Loader from '../../components/Common/Loader';
import ScrollToTop from '../../components/hoc/ScrollToTop';

const Orders = () => {
	const navigate = useNavigate();

	const {orders, isOrderLoading} = useSelector((state) => state.order);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getAllOrders());

		return () => dispatch(resetOrders());
	}, [dispatch]);

	if (isOrderLoading) return <Loader open={isOrderLoading} />;

	return (
		<SectionPadding>
			<ScrollToTop />

			<Container>
				<Grid container my={"2rem"}>
					<Grid item xs={12} mb={2}>
						<TitleWithBackBtn title={"Your Order history"} />
					</Grid>

					{Boolean(orders?.orders) && orders?.orders.length > 0 ? (
						<>
							{orders?.orders?.map((order) => (
								<OrderCardView
									key={order?._id}
									Activa
									kitId={order?.kit?.linkedId}
									orderId={order?._id}
									productName={order?.product?.name}
									status={order?.order_status}
									username={`${order?.person?.firstName} ${order?.person?.lastName}`}
									handleNavigation={() => navigate(`${order?._id}`)}
									placedAt={order?.placedAt}
								/>
							))}
						</>
					) : (
						<Grid item xs={12}>
							<NoDataView
								message={
									"No Orders found. Please visit products page by clicking below button and place orders."
								}
								showBtn
								disabled={!orders?.orders && orders?.orders?._id !== ""}
								btnText={"PRODUCTS"}
								btnClickHandler={() => navigate("/products")}
							/>
						</Grid>
					)}
				</Grid>
			</Container>
		</SectionPadding>
	);
};

export default Orders;
