import { Close, AlternateEmail, Phone, Place } from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Box,
  Collapse,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
  Select, MenuItem, FormControl, InputLabel, ListItemIcon,
} from "@mui/material";
import { Form, Formik, ErrorMessage, Field } from "formik";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "../axios-instance";
import ActionButton from "../components/Common/Buttons/ActionButton";
import TextInputField from "../components/Common/Form/TextInputField";
import SectionPadding from "../components/Common/SectionPadding";
import ScrollToTop from "../components/hoc/ScrollToTop";
import HalfBannerPage from "../PageTemplates/HalfBannerPage";
import image_constants from "../shared/utils/images_constantsV2";
import { getAllCountries } from "../features/countries/countrySlice";

const validate = (values, props /* only available when using withFormik */) => {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = "Required";
  }
  // else if (
  //   !/^(?=[a-zA-Z0-9._]{5,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/i.test(
  //     values.firstName
  //   )
  // ) 
  // {
  //   errors.firstName = "Please provide valid First Name";
  // }

  if (!values.lastName) {
    errors.lastName = "Required";
  }

  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.phone) {
    errors.phone = "Required";
  }
  if (!values.countryCode) {
    errors.countryCode = 'Required';
  }
  // else if (!/^\d{10}$/i.test(values.phone)) {
  //   errors.phone = "Invalid Phone Number";
  // }

  if (!values.message) {
    errors.message = "Required";
  }

  //...

  return errors;
};

const Contact = () => {
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const [contactUsData, setContactUsData] = useState();
  const [contactUsHeading, setContactUsHeading] = useState();
  const [responseMessage, setResponseMessage] = useState({
    isError: false,
    info: "",
  });
  const { countries } = useSelector((state) => state.countries);

  useEffect(() => {
    getContactUsDetails();
  }, []);

  useEffect(() => {
    dispatch(getAllCountries());
  }, [dispatch]);
  const getContactUsDetails = () => {
    axios
      .get("/api/admin/contactUs")
      .then((res) => {
        if (res) {
          res?.data?.contactUs.map((data, i) => (
            setContactUsHeading(data?.title2)
          ))
          console.log('setContactUsHeading', res?.data?.contactUs);
          setContactUsData(res.data);
        }
        // if (res.data.success == false) {
        // 	setpromoError(true);
        // 	setpromoErrorMsg(res.data.message);
        // } else {
        // 	setpromoError(false);
        // 	dispatch(checkUser());
        // }
      })
      .catch((error) => console.log(error));
  };

  const contactUsBanner = (
    <Stack>
      <Typography variant="h2" sx={{ color: "white", fontWeight: 700 }}>
        CONTACT US
      </Typography>
    </Stack>
  );

  const styles = {
    width: { xs: "100%" },
    mx: { xs: "auto" },
    // [`& fieldset`]: {
    // 	borderRadius: 3,
    // },
  };

  const sxStyle = {
    boxShadow: "3px 3px 20px rgba(0, 0, 0, 0.07)",
    borderRadius: 3,
    height: "100%",
    p: "30px",
    display: "flex",
    // justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  };

  const sxIconStyle = {
    mt: "-55px",
    mb: "10px",
  };
  return (
    <HalfBannerPage
      overlayComponent={contactUsBanner}
      bannerImg={image_constants.CONTACT_US}
    >
      <ScrollToTop />

      <SectionPadding>
        <Container>
          {contactUsData?.contactUs?.map((data, i) => (
            <>
              <Box key={i}>
                <Typography variant="h4" sx={{ fontWeight: 700 }}>
                  {data.title1}
                </Typography>
              </Box>
              <Grid container columnSpacing={2} my={8}>
                <Grid item xs={12} sm={3}>
                  <Box sx={sxStyle}>
                    <Box sx={sxIconStyle}>
                      <Avatar sx={{ p: "25px", backgroundColor: "green" }}>
                        <AlternateEmail fontSize="medium" />
                      </Avatar>
                    </Box>
                    <Typography variant="subtitle1" sx={{ fontSize: "1.4rem" }}>
                      {data.email}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Box sx={sxStyle}>
                    <Box sx={sxIconStyle}>
                      <Avatar sx={{ p: "25px", backgroundColor: "green" }}>
                        <Phone fontSize="medium" />
                      </Avatar>
                    </Box>
                    <Typography variant="subtitle1" sx={{ fontSize: "1.4rem" }}>
                      {data.mobile_number}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box sx={sxStyle}>
                    <Box sx={sxIconStyle}>
                      <Avatar sx={{ p: "25px", backgroundColor: "green" }}>
                        <Place fontSize="medium" />
                      </Avatar>
                    </Box>
                    <Typography variant="subtitle1" sx={{ fontSize: "1rem" }}
                      dangerouslySetInnerHTML={{
                        __html: `${data.address}`,
                      }}
                    >
                      {/* {data.address} */}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </>
          ))}

          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              phone: "",
              message: "",
              countryCode: ""
            }}
            validate={(values) => validate(values)}
            onSubmit={async (values, { resetForm }) => {
              try {
                const data = {
                  firstName: values.firstName,
                  lastName: values.lastName,
                  email: values.email,
                  phone: values.phone,
                  message: values.message,
                  countryCode: values.countryCode
                };
                const response = await axios.post("/api/enquiries", data);

                if (response?.statusText === "OK") {
                  setOpen(true);
                  setResponseMessage({
                    isError: !Boolean(response?.data?.data),
                    info: response?.data?.message,
                  });
                }

                resetForm();
              } catch (error) {
                console.log(error);
              }
            }}
          >
            {({ handleSubmit }) => (
              <Form>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h5" sx={{ fontWeight: 700 }}
                      dangerouslySetInnerHTML={{
                        __html: `${contactUsHeading}`,
                      }}
                    >
                      {/* {contactUsHeading} */}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} mt={2}>
                    <Collapse in={open}>
                      {responseMessage?.isError &&
                        responseMessage?.info &&
                        responseMessage?.info.length > 0 ? (
                        <Grid item xs={12}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setOpen(false);
                                }}
                              >
                                <Close fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {responseMessage?.info}
                          </Alert>
                        </Grid>
                      ) : !responseMessage?.isError &&
                        responseMessage?.info &&
                        responseMessage?.info.length > 0 ? (
                        <Grid item xs={12}>
                          <Alert
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setOpen(false);
                                }}
                              >
                                <Close fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {responseMessage?.info}
                          </Alert>
                        </Grid>
                      ) : null}
                    </Collapse>
                  </Grid>

                  <Grid item container columnSpacing={2} xs={12}>
                    <Grid item container spacing={2} xs={12} md={6} my={2}>
                      <Grid item xs={12} sm={6}>
                        <TextInputField
                          name="firstName"
                          label="First Name"
                          type="text"
                          margin="dense"
                          styles={styles}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextInputField
                          name="lastName"
                          label="Last Name"
                          type="text"
                          margin="dense"
                          styles={styles}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextInputField
                          name="email"
                          label="Email"
                          type="email"
                          margin="dense"
                          styles={styles}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} textAlign={{ xs: "center", sm: "left" }}>
                        <FormControl sx={{ width: "100%", marginTop: '8px' }} size="small">
                          <InputLabel id="countryCode">Code</InputLabel>
                          <Field
                            style={{ padding: '8px' }}
                            className="countryCodeSelect"
                            as={Select}
                            id="countryCode"
                            name="countryCode"
                            label="Code"
                          // onChange={handleChange}
                          >
                            {countries?.map((item) => (
                              <MenuItem
                                key={item?.number}
                                value={item?.number}
                              >
                                <div style={{ display: 'flex', alignItems: 'center', height: '20px', justifyContent: "space-evenly" }} className="dhanabal">
                                  <ListItemIcon>
                                    <Avatar alt="Remy Sharp" src={item?.image} />
                                  </ListItemIcon>
                                  {item?.number}
                                </div>
                              </MenuItem>
                            ))}
                          </Field>
                        </FormControl>
                        <ErrorMessage name="countryCode" component="div" style={{ color: '#f87171', fontSize: "0.75rem" }} className="error" />
                      </Grid>
                      <Grid item xs={12} sm={8} textAlign={{ xs: "center", sm: "left", }}>
                        <TextInputField
                          name="phone"
                          label="Phone"
                          type="number"
                          margin="dense"
                          styles={styles}
                        // InputProps={{
                        //   startAdornment: (
                        //     <InputAdornment position="start">
                        //       +91 |{" "}
                        //     </InputAdornment>
                        //   ),
                        // }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextInputField
                          name="message"
                          label="Message"
                          type="text"
                          margin="dense"
                          styles={styles}
                          multiline
                          rows={4}
                        />
                      </Grid>
                      <Grid item xs={12} mt={1}>
                        <ActionButton type="submit" onClick={handleSubmit}>
                          Submit
                        </ActionButton>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} my={2}>
                      <iframe
                        // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1958.098033138647!2d77.0012855581501!3d11.023911430755353!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba8582f03214555%3A0x694147ca0906a6ee!2sPSG%20College%20of%20Technology!5e0!3m2!1sen!2sin!4v1667314187200!5m2!1sen!2sin"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.1851515936946!2d77.00208311480294!3d11.024731092153335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe895d3e347984cb8!2zMTHCsDAxJzI5LjAiTiA3N8KwMDAnMTUuNCJF!5e0!3m2!1sen!2sin!4v1667367253360!5m2!1sen!2sin"
                        width={"100%"}
                        height={"450"}
                        style={{ border: 0 }}
                        allowFullScreen
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        title="location-map"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
          {/* <Stack spacing={2} my={2}>
            <Stack direction={"row"} alignItems="center" spacing={1}>
              <LocationMarkerIcon height={"30px"} />
              <Typography>
                GUT-iCARE PVT LIMITED, our principal place of business is at PSG STEP BioNEST, PSG College of
                Technology, J Block, 4th floor, Peelamedu, Coimbatore- 641004
              </Typography>
            </Stack>
            <Stack direction={"row"} alignItems="center" spacing={2}>
              <Stack direction={"row"} alignItems="center" spacing={1}>
                <Email />
                <Typography>info@guticare.com</Typography>
              </Stack>
              <Stack direction={"row"} alignItems="center" spacing={1}>
                <Phone />
                <Typography>+91 94422 59590</Typography>
              </Stack>
            </Stack>
          </Stack> */}
        </Container>
      </SectionPadding>
    </HalfBannerPage>
  );
};

export default Contact;
