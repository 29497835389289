import {Box, Grid, Typography} from '@mui/material';
import React from 'react';
import {BlogDummyImage} from '../../assets';
import ResponsiveImage from '../Common/ResponsiveImage';

const BlogDescription = ({image, description}) => {
	return (
		<Grid item container xs={12}>
			<ResponsiveImage img={image} overRideStyles={{width: '100%', height: '100%'}} />
			<Box textAlign='justify'>
				{/* <Typography paragraph={true} sx={{whiteSpace: 'pre-line', mt: 2}}>
					{description}
				</Typography> */}
			</Box>
		</Grid>
	);
};

export default BlogDescription;
