import axios from '../../axios-instance';

const getReviews = async (data) => {
	const response = await axios.get(`/api/reviews/${data.id}`);
	return response.data;
};

const createReview = async (data) => {
	const response = await axios.post(`/api/reviews`, data);
	return response.data;
};

const updateReview = async (data) => {
	const response = await axios.post(`/api/reviews/${data.id}`, data);
	return response.data;
};

const deleteReview = async (data) => {
	const response = await axios.delete(`/api/reviews/${data.id}`);
	return response.data;
};

const reviewServices = {
	getReviews,
	createReview,
	updateReview,
	deleteReview,
};

export default reviewServices;
