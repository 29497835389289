import React from 'react';
import {
	Box,
	Drawer,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	useTheme,
} from '@mui/material';

import {
	BookOpenIcon, ShoppingBagIcon,
	CollectionIcon,
	HomeIcon,
	TemplateIcon,
	UserGroupIcon,
	XIcon,
	PhoneIcon
} from '@heroicons/react/outline';

import ActionButton from '../Common/Buttons/ActionButton';
import { NavLink } from 'react-router-dom';

const links = [
	// {id: 1, link: 'home', icon: <HomeIcon style={{color: '#000', height: '1.2rem'}} />},
	{
		id: 1,
		path: '/home',
		link: 'home',
		icon: <HomeIcon style={{ color: '#3CAA48', height: '1.2rem' }} />,
	},
	{
		id: 2,
		path: '/about',
		link: 'about',
		icon: <UserGroupIcon style={{ color: '#3CAA48', height: '1.2rem' }} />,



	},
	{
		id: 3,
		path: '/categoryblogs',
		link: 'blogs',
		icon: <TemplateIcon style={{ color: '#3CAA48', height: '1.2rem' }} />,
	},
	{
		id: 4,
		path: '/products',
		link: 'products',
		icon: <CollectionIcon style={{ color: '#3CAA48', height: '1.2rem' }} />,
	},
	{
		id: 5,
		path: '/orders',
		link: 'orders',
		icon: <ShoppingBagIcon style={{ color: '#3CAA48', height: '1.2rem' }} />,
	},
	{
		id: 6,
		path: '/benefits',
		link: 'benefits',
		icon: <BookOpenIcon style={{ color: '#3CAA48', height: '1.2rem' }} />,
	},
	{
		id: 7,
		path: '/contactus',
		link: 'contact us',
		icon: <PhoneIcon style={{ color: '#3CAA48', height: '1.2rem' }} />,
	},
];

const SideDrawer = ({ open, setOpen, showLoginButton }) => {
	const theme = useTheme();

	const isLoggedIn = Boolean(localStorage.getItem('token'));

	const lists = (
		<List>
			{links.map(({ id, link, icon, path }) => (
				<ListItemButton
					key={id}
					component={NavLink}
					to={path}
					sx={{
						transition: '0.3s ease-out',

						'&:hover': {
							background: theme.palette.secondary.main,
							color: '#fff',
						},

						'&.active': {
							background: theme.palette.secondary.main,
							color: '#fff',
						},
						borderRadius: 2,
					}}
					onClick={() => setOpen(false)}
				>
					<ListItemIcon>{icon}</ListItemIcon>
					<ListItemText
						primary={link}
						primaryTypographyProps={{ sx: { fontSize: '1rem', textTransform: 'capitalize' } }}
					/>
				</ListItemButton>
			))}
		</List>
	);

	return (
		<Drawer anchor={'left'} open={open} onClose={() => setOpen(false)}>
			<Box textAlign={'right'} p={2} sx={{ cursor: 'pointer' }}>
				<XIcon style={{ color: '#000', height: '1.2rem' }} onClick={() => setOpen(false)} />
			</Box>
			<Box py={2} width={{ xs: '100vw', sm: '280px' }} textAlign='center' role='presentation'>
				{lists}

				{!isLoggedIn && (
					<>
						{showLoginButton && (
							<Box mt={6} mx={8}>
								<ActionButton
									fullWidth
									component={NavLink}
									to='/auth/mobilelogin'
									onClick={() => setOpen(false)}
								>
									Login
								</ActionButton>
							</Box>
						)}
					</>
				)}
			</Box>
		</Drawer>
	);
};

export default SideDrawer;
