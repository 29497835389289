import {Button} from "@mui/material";
import React from "react";
import useBreakpoints from "../../../hooks/useBreakpoints";

const colors = {
	red: {
		bg: "#be000012",
		text: "#B72136",
		hover: "#be00003b",
	},
	green: {
		bg: "#3caa4814",
		text: "#007B55",
		hover: "#3caa4847",
	},
	blue: {
		bg: "#D0F2FF",
		text: "#0C53B7",
		hover: "#74CAFF",
	},
};

const MinimalButton = ({
	buttonText,
	btnColor,
	maxWidth,
	startIcon,
	hideTextOnXs,
	...otherProps
}) => {
	const {isMediumScreen} = useBreakpoints();
	const buttonColor =
		Boolean(btnColor) && Boolean(colors[btnColor]) ? colors[btnColor] : colors.green;
	const showIconOnly = !isMediumScreen && hideTextOnXs;
	return (
		<Button
			variant="contained"
			fullWidth={maxWidth}
			startIcon={!showIconOnly && startIcon}
			sx={{
				backgroundColor: buttonColor.bg,
				transition: "0.2s ease-out",
				color: buttonColor.text,
				fontWeight: 700,
				py: 1.2,
				"&:hover": {
					backgroundColor: buttonColor.hover,
				},
			}}
			disableElevation
			{...otherProps}>
			{!showIconOnly ? buttonText : startIcon}
		</Button>
	);
};

export default MinimalButton;
