import React, { useState } from "react";
import { AnimatePresence } from "framer-motion";

import CardList from "./CardList";
import ModalView from "./ModalView";
import { Modal } from "@mui/material";

const AdvisorCard = ({ data }) => {
	const [open, setOpen] = useState(false);

	const openModal = () => {
		setOpen(true);
	};

	const closeModal = () => {
		setOpen(false);
	};
	;
	return (
		<>
			{/* LISTS */}
			<CardList data={data} open={openModal} position={data?.position} designation={data?.designation}/>

			<AnimatePresence>
				{open && (
					<Modal
						open={open}
						onClose={closeModal}
						aria-labelledby="keep-mounted-modal-title"
						aria-describedby="keep-mounted-modal-description"
					>
						<ModalView data={data} close={closeModal} />
					</Modal>
				)}
			</AnimatePresence>
		</>
	);
};

export default AdvisorCard;
