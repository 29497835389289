import axios from "../../axios-instance";

const verifyEmail = async (token) => {
  const response = await axios.put(`/api/auth/verifyemail/${token}`);
  return response.data;
};

const resetPassword = async (data) => {
  const response = await axios.put(`/api/auth/resetpassword/${data.token}`, {
    password: data?.password,
  });
  return response.data;
};
const forgotPassword = async (data) => {
  const response = await axios.post(`/api/auth/forgotpassword`, data);

  return response.data;
};

const checkUser = async () => {
  const response = await axios.get(`/api/auth/myaccount`);
  return response.data;
};

const userService = {
  verifyEmail,
  resetPassword,
  forgotPassword,
  checkUser,
};

export default userService;
