import { Form, Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { addReview, getReviews } from "../../features/reviews/reviewSlice";
import DialogModal from "../Common/DialogModal";
import ReviewForm from "./ReviewForm";

const validate = (values, props /* only available when using withFormik */) => {
  const errors = {};

  if (!values.review) {
    errors.review = "Required";
  }

  if (!values.rating && values.rating === 0) {
    errors.rating = "Required";
  }

  return errors;
};

const ReviewCreate = ({ open, handleClose, reviewData }) => {
  const dispatch = useDispatch();

  const handlePostData = async (values) => {
    const data = {
      review: values.review,
      rating: values.rating,
      product: reviewData?.productId,
    };
    try {
      await dispatch(addReview(data));
      handleClose();
      await dispatch(getReviews({ id: reviewData?.productId }));
    } catch (error) {
      console.error('Error:', error);
    }
  };
  return (
    <Formik
      initialValues={{ review: "", rating: 1 }}
      validate={(values) => validate(values)}
      onSubmit={(values, { resetForm }) => {
        handlePostData(values);
        resetForm();
        handleClose();
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => (
        <Form>
          <DialogModal
            buttonOne={"Cancel"}
            buttonTwo={"Post Review"}
            title={"Add Review"}
            handleClose={handleClose}
            open={open}
            handlePrimaryClick={handleSubmit}
            handleButtonOneClick={handleClose}
            content={null}
            fullWidth
          >
            <ReviewForm />
          </DialogModal>
        </Form>
      )}
    </Formik>
  );
};

export default ReviewCreate;
