import {Box, Divider, Typography} from "@mui/material";
import React from "react";

//overlay component will be given priority over 'overlayTitle' and 'overlaySubTitle'.
//In case no overlayContent, defaultOverlayContent will be rendered with 'overlayTitle' and 'overlaySubTitle' (or default title and subtitle)
const HalfBannerPage = ({
	bannerHeight = "450px",
	bannerImg,
	overlayComponent,
	overlayTitle,
	overlaySubTitle,
	children,
}) => {
	const title = overlayTitle ?? "TITLE HERE";
	const subTitle = overlaySubTitle ?? "Sub title here";
	const defaultOverlayComponent = (
		<Box>
			<Typography
				sx={{
					color: "#fff",
					fontSize: {xs: "1.8rem", sm: "2.8rem", md: "2.8rem"},
					fontWeight: 600,
					// display: "flex",
					//   zIndex: 100,
				}}>
				{title}
			</Typography>
			<Divider
				width={"40%"}
				light
				sx={{mx: "auto", color: "#fff", backgroundColor: "#fff", mb: 1}}
			/>
			<Typography
				sx={{
					color: "#fff",
					fontSize: {xs: "1rem", sm: "1rem", md: "1.2rem"},
					fontWeight: 400,
					// display: "flex",
					//   zIndex: 100,
				}}>
				{subTitle}
			</Typography>
		</Box>
	);

	const fixedbannerOptions = {
		background: bannerImg ? `url(${bannerImg})` : "#333",
		backgroundPosition: "center",
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
		position: "fixed",
		width: "100%",
		height: bannerHeight,
		zIndex: -100,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	};

	return (
		<Box sx={{paddingTop: {xs: "5rem", sm: "6rem"}}}>
			<Box sx={fixedbannerOptions}>
				<Box sx={{position: "relative", overflow: "hidden", zIndex: 10, textAlign: "center"}}>
					{overlayComponent ?? defaultOverlayComponent}
				</Box>
			</Box>
			<Box sx={{backgroundColor: "#fff", marginTop: bannerHeight, py: 4}}>{children}</Box>
		</Box>
	);
};

export default HalfBannerPage;
