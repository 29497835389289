import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'swiper/css/bundle';
import './index.css';

import 'react-quill/dist/quill.snow.css';
import 'quill/dist/quill.snow.css';

import {SnackbarProvider} from 'notistack';
import {BrowserRouter as Router} from 'react-router-dom';
import {Provider} from 'react-redux';
import {store} from './store';

import {ThemeProvider} from '@mui/material';
import {globalTheme} from './muiGlobalTheme';
import MotionLazyContainer from './components/Common/MotionLazyContainer';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Provider store={store}>
		<Router>
			<ThemeProvider theme={globalTheme}>
				<SnackbarProvider
					maxSnack={3}
					preventDuplicate
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					autoHideDuration={2000}
				>
					<MotionLazyContainer>
						<App />
					</MotionLazyContainer>
				</SnackbarProvider>
			</ThemeProvider>
		</Router>
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
