import React from "react";

const ResponsiveImage = ({ img, alt, width, overRideStyles }) => {
  return (
    <img
      src={img}
      alt={alt}
      style={{
        height: "auto",
        maxWidth: "100%",
        margin: "0 auto",
        ...overRideStyles,
      }}
      width={width}
      loading="lazy"
    />
  );
};

export default ResponsiveImage;
