import React, { useEffect, useState } from "react";
import { SaveIcon, XIcon } from "@heroicons/react/solid";
import {
  Alert,
  Box,
  Divider,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import useBreakpoints from "../../../hooks/useBreakpoints";
import MinimalButton from "../../Common/Buttons/MinimalButton";
import UpdateDetails from "./UpdateDetails";
import UpdatePassword from "./UpdatePassword";
import UpdateUserImage from "./UpdateUserImage";
import { checkUser } from "../../../features/users/userSlice";
import { getAllOrganization } from "../../../features/organization/organizationSlice";
import {
  updateProfile,
  personaldetails,
} from "../../../features/profile/profileSlice";
import Swal from "sweetalert2";
import axios from "../../../axios-instance";

import Loader from "../../Common/Loader";
import TextInputField from "../../Common/Form/TextInputField";

const validate = (values, props /* only available when using withFormik */) => {
  const errors = {};

  if (!values.userName) {
    errors.userName = "Required";
  }

  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.phone) {
    errors.phone = "Required";
  } 
  // else if (!/^\d{10}$/i.test(values.phone)) {
  //   errors.phone = "Invalid Phone Number";
  // }

  //...

  return errors;
};

const Account = () => {
  const { isMediumScreen } = useBreakpoints();
  const { currentUser, isUserLoading } = useSelector((state) => state.user);
  const { organization } = useSelector((state) => state.organization);
  const dispatch = useDispatch();
  const [orgVal, setOrgVal] = useState("");
  const [orgSuccessMsg, setOrgSuccessMsg] = useState(false);
  const [orgErrorMsg, setOrgErrorMsg] = useState(false);

  const styles = {
    width: { xs: "100%", sm: "100%" },
    mx: { xs: "auto" },
  };
  useEffect(() => {
    dispatch(checkUser());
    setOrgVal(
      currentUser && currentUser.organization != "" && currentUser.organization
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllOrganization());
  }, [dispatch]);

  if (isUserLoading) {
    <Loader open={isUserLoading} />;
  }

  return (
    <>
      {/* {(orgVal == "" || orgVal == null) && (
        <Alert severity="error">PLEASE UPDATE ORGANIZATION</Alert>
      )} */}
      <Grid item container xs={12} sx={{ mt: 2 }}>
        {isMediumScreen && (
          <Grid
            container
            item
            xs={12}
            alignItems={"center"}
            sx={{
              backgroundColor: "#fff",
              boxShadow: "3px 3px 20px rgba(0, 0, 0, 0.07)",
              borderRadius: 3,
              p: 2,
              mb: 2,
            }}
          >
            <Grid item xs={9}>
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  px: 2,
                  fontWeight: 500,
                  color: "#005249",
                }}
              >
                Account
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid item container xs={12}>
          <Grid
            item
            container
            alignItems={"center"}
            xs={12}
            sx={{
              backgroundColor: "#fff",
              boxShadow: "3px 3px 20px rgba(0, 0, 0, 0.07)",
              borderRadius: 3,
              p: { xs: 2, sm: 4 },
              mb: 2,
            }}
          >
            <Grid item xs={12} sx={{ mb: 4 }}>
              <Typography>Personal Details</Typography>
              <Box sx={{ mt: 2 }}>
                <Divider color="#f1f1f1" />
              </Box>
            </Grid>

            <Formik
              initialValues={{
                phone: currentUser?.phone,
                userName: currentUser?.userName,
                email: currentUser?.email,
                imageurl: currentUser?.imageurl || null,
              }}
              onSubmit={async (values) => {
                if (currentUser?.phone == "" || currentUser?.userName == null) {
                  setOrgErrorMsg(true);
                  setTimeout(() => {
                    setOrgErrorMsg(false);
                  }, 2000);
                } else {
                  const imageurlType = typeof values.imageurl;
                  if (imageurlType === "string") {
                    const data = {
                      phonenumber: values.phone,
                      email: values.email,
                      userName: values.userName,
                    };
                    await dispatch(personaldetails(data));
                  } else {
                    const formData = new FormData();
                    formData.append("imageurl", values.imageurl);
                    formData.append("phonenumber", values.phone);
                    formData.append("email", values.email);
                    formData.append("userName", values.userName);
                    await dispatch(personaldetails(formData));
                  }

                  dispatch(checkUser());
                  setOrgSuccessMsg(true);
                  setTimeout(() => {
                    setOrgSuccessMsg(false);
                  }, 2000);
                }
              }}
              validate={(values) => validate(values)}
            >
              {({ handleSubmit }) => (
                <Form>
                  <Grid item xs={12} container spacing={2}>
                    <Grid item xs={12} md={4} mb={{ xs: 2, md: 0 }}>
                      <UpdateUserImage />
                    </Grid>

                    <Grid item xs={12} md={8}>
                      <UpdateDetails currentUser={currentUser} />
                    </Grid>
                  </Grid>

                  <Grid item container xs={12} sx={{ mt: 4 }}>
                      <MinimalButton
                        maxWidth={true}
                        buttonText={"UPDATE"}
                        startIcon={<SaveIcon height={"20px"} />}
                        hideTextOnXs
                        onClick={handleSubmit}
                      />

                    {/* <Grid item xs={6} sx={{ pl: { xs: 1, sm: 2 } }}>
                      <MinimalButton
                        btnColor={"red"}
                        maxWidth={true}
                        buttonText={"CANCEL"}
                        startIcon={<XIcon height={"20px"} />}
                        hideTextOnXs
                        //onClick={handleClickOpen}
                      />
                    </Grid> */}
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
          <Grid
            item
            container
            alignItems={"center"}
            xs={12}
            sx={{
              backgroundColor: "#fff",
              boxShadow: "3px 3px 20px rgba(0, 0, 0, 0.07)",
              borderRadius: 3,
              p: { xs: 2, sm: 4 },
            }}
          >
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Typography>Other details</Typography>
              <Box sx={{ mt: 2 }}>
                <Divider color="#f1f1f1" />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Formik
                initialValues={{ organization: "" }}
                onSubmit={async (values) => {
                  if (orgVal == "" || orgVal == null) {
                    setOrgErrorMsg(true);
                    setTimeout(() => {
                      setOrgErrorMsg(false);
                    }, 2000);
                  } else {
                    const users = await dispatch(
                      updateProfile({
                        user_id: currentUser._id,
                        organization: orgVal,
                      })
                    );
                    dispatch(checkUser());
                    setOrgSuccessMsg(true);
                    setTimeout(() => {
                      setOrgSuccessMsg(false);
                    }, 2000);
                  }

                  setOrgVal(orgVal);
                }}
              >
                {({ handleSubmit }) => (
                  <Form>
                    <Grid item container xs={12} columnSpacing={1}>
                      <Grid item xs={12} md={2}>
                        <label>Organization</label>
                      </Grid>
                      <Grid item xs={12} md={10}>
                        <select
                          fullWidth
                          name="organization"
                          label="Organization"
                          value={orgVal}
                          style={{
                            padding: "15px",
                            width: "100%",
                            borderRadius: "4px",
                            border: "1px solid rgb(226 226 226)",
                            font: "inherit",
                            letterSpacing: "inherit",
                            color: "currentColor",
                          }}
                          onChange={(e) => {
                            setOrgVal(e.target.value);
                          }}
                        >
                          <option value="" disabled>
                            Choose a Organization
                          </option>
                          {organization &&
                            organization.map((option) => (
                              <option key={option._id} value={option._id}>
                                {option.name}
                              </option>
                            ))}
                        </select>
                      </Grid>

                      <Grid item xs={12} textAlign="center" mt={4}>
                        {Boolean(orgSuccessMsg) && (
                          <Typography
                            sx={{
                              fontSize: "1rem",
                              fontStyle: "italic",
                              color: "green",
                              paddingLeft: "10px",
                            }}
                          >
                            Successfully updated
                          </Typography>
                        )}
                        {Boolean(orgErrorMsg) && (
                          <Typography
                            sx={{
                              fontSize: "1rem",
                              fontStyle: "italic",
                              color: "red",
                              paddingLeft: "10px",
                            }}
                          >
                            Please select Organization
                          </Typography>
                        )}

                        {/* <Button
													variant='contained'
													sx={{color: '#fff', py: {sm: 1.5}}}
													onClick={handleSubmit}
													fullWidth
													disableElevation
												>
													Update Password
												</Button> */}
                        <MinimalButton
                          maxWidth={true}
                          buttonText={"UPDATE"}
                          startIcon={<SaveIcon height={"20px"} />}
                          onClick={handleSubmit}
                          hideTextOnXs
                        />
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
          <Grid
            item
            container
            alignItems={"center"}
            xs={12}
            sx={{
              backgroundColor: "#fff",
              boxShadow: "3px 3px 20px rgba(0, 0, 0, 0.07)",
              borderRadius: 3,
              p: { xs: 2, sm: 4 },
            }}
          >
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Typography>Update password</Typography>
              <Box sx={{ mt: 2 }}>
                <Divider color="#f1f1f1" />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <UpdatePassword />
            </Grid>
          </Grid>
          {/* <Box width={"100%"}>
            <NoDataView message={`COMING SOOOOOOOOON!`} />
          </Box> */}
        </Grid>
      </Grid>
    </>
  );
};

export default Account;
