import React from "react";
import { Box, CircularProgress, circularProgressClasses, Grid, Typography, useTheme } from "@mui/material";

const OverlapRadialProgress = ({
  progress,
  size,
  showName,
  progressColor,
  progressSize,
  showInProgress,
  showInProgressSize,
}) => {
  const theme = useTheme();
  return (
    <Grid
      item
      xs={12}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: { xs: "center", md: "flex-end" },
        my: "auto",
        // flexDirection: {xs: 'column', sm: 'row'},
      }}
    >
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          variant="determinate"
          value={100}
          sx={{
            color: "#E2E8F0",
          }}
          thickness={5}
          size={size}
        />
        <CircularProgress
          variant="determinate"
          value={progress}
          thickness={5}
          size={size}
          sx={{
            color: theme.palette.primary.main.dark,
            position: "absolute",
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: "round",
            },
          }}
        />
        {showInProgress && (
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="body1"
              component="div"
              color="text.secondary"
              sx={{
                fontSize: showInProgressSize,
                color: theme.palette.secondary.main,
                fontWeight: 700,
              }}
            >
              {progress}%
            </Typography>
          </Box>
        )}
      </Box>
      <Box sx={{ ml: { xs: 2, md: 0 } }} textAlign={{ xs: "center", md: "left" }}>
        {!showInProgress && (
          <Typography
            sx={{
              color: progressColor,
              fontSize: progressSize,
              ml: { xs: 0, md: 2 },
              fontWeight: 900,
              lineHeight: 1,
            }}
          >
            {progress}%
          </Typography>
        )}

        {showName && (
          <Typography
            sx={{
              color: "#fff",
              fontSize: { xs: "0.9rem" },
              ml: { xs: 0, md: 2 },
            }}
          >
            Overall health
          </Typography>
        )}
      </Box>
    </Grid>
  );
};

export default OverlapRadialProgress;

/*
<Box sx={{position: 'relative', display: 'inline-flex'}}>
  <Box sx={{position: 'relative'}}>
    <CircularProgress
      variant='determinate'
      value={100}
      sx={{
        color: '#eee',
      }}
      thickness={3.8}
      size={160}
    />
    <CircularProgress
      variant='determinate'
      value={results?.gaugeData.value}
      thickness={3.8}
      size={160}
      sx={{
        color: theme.palette.secondary.light,
        position: 'absolute',
        left: 0,
        [`& .${circularProgressClasses.circle}`]: {
          strokeLinecap: 'round',
        },
      }}
    />
  </Box>
  <Box
    sx={{
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Typography variant='body1' component='div' color='text.secondary'>
      {`${results?.gaugeData.value}%`}
    </Typography>
  </Box>
</Box>

*/
