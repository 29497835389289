import React, { useState } from "react";
import {
  Box,
  Container,
  Grid,
  Rating,
  Typography,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import SectionPadding from "../../components/Common/SectionPadding";
import ProductData from "../../components/Products/ProductData";
import ProductTabs from "../../components/Products/ProductTabs/ProductTabs";
import TabPanelWrapper from "../../components/Common/TabPanelWrapper";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {
  getProduct,
  resetProducts,
} from "../../features/products/productSlice";
import { ChevronDoubleRightIcon } from "@heroicons/react/outline";
import Loader from "../../components/Common/Loader";
import ReviewCard from "../../components/Reviews/ReviewCard";
import { getReviews } from "../../features/reviews/reviewSlice";
import ResponsiveImage from "../../components/Common/ResponsiveImage";
import useBreakpoints from "../../hooks/useBreakpoints";
import DialogModal from "../../components/Common/DialogModal";
import { create } from "../../features/orders/orderSlice";
import { NotFound } from "../../components/hoc/NotFound";
import TitleWithBackBtn from "../../components/Common/TitleWithBackBtn";
import { EditRounded } from "@mui/icons-material";
import ReviewCreate from "../../components/Reviews/ReviewCreate";
import MinimalButton from "../../components/Common/Buttons/MinimalButton";
import NoDataView from "../../components/Common/NoDataView";
import ScrollToTop from "../../components/hoc/ScrollToTop";

import "../../index.css"

const ProductById = () => {
  const navigate = useNavigate();
  const [openReview, setOpenReview] = useState(false);
  const params = useParams();
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const { isSmallScreen, isLargeScreen } = useBreakpoints();
  const { currentProduct, isProductLoading } = useSelector(
    (state) => state.product
  );
  const { currentUser } = useSelector((state) => state.user);
  const { reviews, isReviewLoading } = useSelector((state) => state.review);
  console.log("reviewssss", reviews)
  const dispatch = useDispatch();

  const isLoggedIn = Boolean(localStorage.getItem("token"));

  const isActiveOrder =
    currentUser &&
    currentUser?.activeOrder !== undefined &&
    currentUser?.activeOrder?.product;

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const data = { id: params.productId };
    dispatch(getProduct(data));
    dispatch(getReviews(data));

    return () => dispatch(resetProducts());
  }, [dispatch, params.productId]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const showActiveOrderCheck = () => {
    return (
      <DialogModal
        buttonOne={"EXISTING ORDER"}
        buttonTwo={"NEW ORDER"}
        title={
          "You already have an item in checkout. Do you wish to replace that and place a new order? or continue checking out existing item?"
        }
        handleClose={() => setOpen(false)}
        open={open}
        handlePrimaryClick={() => {
          const data = {
            productId: params.productId,
            quantity: "1",
          };
          dispatch(create(data)).then((result) => {
            setOpen(false);
            navigate(`/checkout/${result?.payload?.order}`);
          });
        }}
        // handleButtonOneClick={() => {
        //   navigate("/checkout");
        //   setOpen(false);
        // }}
        content={null}
      />
    );
  };

  let formattedRating;
  if (currentProduct?.product?.rating % 1 === 0) {
    formattedRating = currentProduct?.product?.rating.toFixed(0);
  } else {
    formattedRating = currentProduct?.product?.rating.toFixed(1);
  };

  if (isProductLoading && isReviewLoading)
    return <Loader open={isProductLoading || isReviewLoading} />;
  console.log('currentProduct', currentProduct);
  if (currentProduct?.product?._id === params?.productId) {
    return (
      <>
        <SectionPadding>
          <ScrollToTop />

          <Container>
            <Grid container mt={"2rem"}>
              <Grid item xs={12} mb="2rem">
                <TitleWithBackBtn title={"Product Details"} />
              </Grid>
              {!isLargeScreen && (
                <Grid item xs={12} textAlign="center">
                  <ResponsiveImage
                    alt={currentProduct?.product?.name}
                    img={currentProduct?.product?.imageKey}
                    width={isSmallScreen ? "380px" : "240px"}
                  />

                  <Typography
                    color={theme.palette.primary.light}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontWeight: "bold",
                      justifyContent: { xs: "center", lg: "flex-start" },
                    }}
                  >
                    <Rating name="read-only" value={formattedRating} readOnly precision={0.5} />
                    <span>{formattedRating}</span>
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "1.8rem", md: "2rem" },
                      fontWeight: 600,
                      color: theme.palette.common.greyDark,
                      my: 2,
                    }}
                  >
                    ₹{currentProduct?.product?.price}/-
                  </Typography>
                  {currentProduct?.product?.offerText &&
                    <Grid item container xs={12} >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        textAlign={{ xs: "center", sm: "left" }}
                      >
                        <Typography
                          sx={{
                            fontSize: { xs: "1rem", sm: "1rem", md: "1rem" },
                            marginBottom: "15px",
                            // fontWeight: 600,
                            // color: theme.palette.primary.main,
                          }}
                          dangerouslySetInnerHTML={{
                            __html: `${currentProduct?.product?.offerText}`,
                          }}
                        >
                        </Typography>

                      </Grid>

                    </Grid>
                  }
                </Grid>

              )}

              <ProductData
                productId={currentProduct?.product?._id}
                productDescription={currentProduct?.product?.description}
                productName={currentProduct?.product?.name}
                // productRating={currentProduct?.product?.productRating}
                productRating={currentProduct?.product?.rating}
                images={currentProduct?.product?.imageUrl}
                price={currentProduct?.product?.price}
                actualPrice={currentProduct?.product?.actualPrice}
                offerText={currentProduct?.product?.offerText}
                imageTitle={currentProduct?.product?.imageKey}
                isActiveOrder={isActiveOrder}
                setOpen={setOpen}
              />
              <Grid item xs={12} my={{ xs: 6 }}>
                <ProductTabs
                  value={value}
                  onChange={handleChange}
                  allowScrollButtonsMobile
                  scrollButtons
                  variant="scrollable"
                />
                {/* <TabPanelWrapper otherStyles={{p: 2}} value={value} index={0}>
								Description
							</TabPanelWrapper> */}
                <TabPanelWrapper otherStyles={{ p: 2 }} value={value} index={0}>
                  <Typography sx={{ fontSize: "1.4rem", fontWeight: 'bold' }}>
                    gutXplore stool collection kit includes
                  </Typography>

                  <Box mt={2}>
                    <Typography
                      className="additionalInfo"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        fontSize: "1.2rem",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: `${currentProduct?.product?.additionalInfo}`,
                      }}
                    ></Typography>
                    {/* {currentProduct?.product?.additionalInfo?.split(",").map((data, index) => (
											<Typography
												key={index}
												sx={{display: "flex", alignItems: "center", gap: 1, fontSize: "1.2rem"}}
												dangerouslySetInnerHTML={{
													__html:
													  `${data}`
												  }}
											>
												
											</Typography>
										))} */}
                  </Box>
                </TabPanelWrapper>
                <TabPanelWrapper otherStyles={{ p: 2 }} value={value} index={1}>
                  {isLoggedIn && (
                    <>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography
                          sx={{
                            gap: 1,
                            fontSize: "1.6rem",
                          }}

                        >Customer review's</Typography>
                        <Box my={4} textAlign="left">

                        </Box>
                        <Box my={4} textAlign="right">
                          <MinimalButton
                            endIcon={<EditRounded />}
                            onClick={() => setOpenReview(true)}
                            buttonText={"Write A Review"}
                          />
                        </Box>
                      </div>
                    </>

                  )}
                  {currentProduct?._id === reviews?.product ? (
                    <>
                      {reviews?.map((review) => (
                        <ReviewCard
                          key={review?._id}
                          review={review?.review}
                          rating={review?.rating}
                          reviewId={review?._id}
                          username={review?.user?.userName}
                        />
                      ))}
                    </>
                  ) : (
                    <NoDataView message={"No Reviews Yet"} />
                  )}
                </TabPanelWrapper>
              </Grid>
            </Grid>
          </Container>
        </SectionPadding>
        {showActiveOrderCheck()}
        {console.log('openReview', openReview)}
        <ReviewCreate
          open={openReview}
          handleClose={() => setOpenReview(false)}
          reviewData={{
            productId: currentProduct?.product?._id,
            review: "",
            rating: 1,
          }}
        />
      </>
    );
  } else return <Loader />;
};

export default ProductById;
