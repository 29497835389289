import React, {useState} from "react";
import {Grid, styled, Typography, useTheme} from "@mui/material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import FoodList from "./FoodList";

const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
	// boxShadow: '3px 3px 20px rgba(0, 0, 0, 0.07)',
	border: "1px solid rgba(0, 0, 0, 0.07)",
	borderRadius: 8,
	overflow: "hidden",

	"&:not(:last-child)": {
		borderBottom: 0,
	},
	"&:before": {
		display: "none",
	},
}));

const AccordionSummary = styled((props) => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: "0.9rem"}} />}
		{...props}
	/>
))(({theme}) => ({
	backgroundColor: `rgba(255, 255, 255,1)`,
	flexDirection: "row",
	"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
		transform: "rotate(90deg)",
	},
	"& .MuiAccordionSummary-content": {
		marginLeft: theme.spacing(1),
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
	padding: theme.spacing(2),
	borderTop: "1px solid rgba(0, 0, 0, .125)",
	border: "none",
}));

const FoodTailored = ({foodData, commonFood}) => {
	const theme = useTheme();
	const [expanded, setExpanded] = useState(null);

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	var groupBy = function (arr, propertyName) {
		return arr?.reduce(function (acc, item) {
			const key = item[propertyName];
			if (!acc[key]) acc[key] = [];
			acc[key]?.push(item);
			return acc;
		}, {});
	};
	// const foodCategory = groupBy(foodData, "category");
	// console.log(foodCategory);

	const loopCategory = () => {
		const foodCategory = groupBy(foodData, "category");
		const componentsArr = [];

		if (Boolean(foodCategory)) {
			for (const [key, value] of Object.entries(foodCategory)) {
				const component = (
					<Grid item xs={12} key={key}>
						<Accordion expanded={expanded === `panel${key}`} onChange={handleChange(`panel${key}`)}>
							<AccordionSummary aria-controls={`panel${key}d-content`} id={`panel${key}d-header`}>
								<Typography
									sx={{
										color: theme.palette.secondary.main,
										textTransform: "uppercase",
										fontWeight: 900,
										fontSize: {xs: "1.1rem", sm: "1.3rem"},
										overflow: "hidden",
										textOverflow: "ellipsis",
										display: "-webkit-box",
										WebkitLineClamp: "1",
										WebkitBoxOrient: "vertical",
									}}>
									{key}
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<FoodList foodItems={value} />
							</AccordionDetails>
						</Accordion>
					</Grid>
				);
				componentsArr.push(component);
			}
		}
		return componentsArr;
	};

	const loopCategoryCommonFood = () => {
		const foodCategory = groupBy(commonFood, "category");
		const componentsArr = [];

		if (Boolean(foodCategory)) {
			for (const [key, value] of Object.entries(foodCategory)) {
				const component = (
					<Grid item xs={12} key={key}>
						<Accordion expanded={expanded === `panel${key}`} onChange={handleChange(`panel${key}`)}>
							<AccordionSummary aria-controls={`panel${key}d-content`} id={`panel${key}d-header`}>
								<Typography
									sx={{
										color: theme.palette.secondary.main,
										textTransform: "uppercase",
										fontWeight: 900,
										fontSize: {xs: "1.1rem", sm: "1.3rem"},
										overflow: "hidden",
										textOverflow: "ellipsis",
										display: "-webkit-box",
										WebkitLineClamp: "1",
										WebkitBoxOrient: "vertical",
									}}>
									{key}
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<FoodList foodItems={value} />
							</AccordionDetails>
						</Accordion>
					</Grid>
				);
				componentsArr.push(component);
			}
		}
		return componentsArr;
	};

	return (
		<Grid item container spacing={1} xs={12} mt={{xs: 2, sm: 6}}>
			{loopCategory()}
			{loopCategoryCommonFood()}
		</Grid>
	);
};

export default FoodTailored;
