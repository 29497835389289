import React from 'react';
import {Container, Grid, Typography} from '@mui/material';
import SectionPadding from '../Common/SectionPadding';
import ActionButton from '../Common/Buttons/ActionButton';
import {Link} from 'react-router-dom';

const OrderStatus = ({icon, primaryTxt, secondaryTxt, showButton, buttonText, to}) => {
	return (
		<SectionPadding>
			<Container>
				<Grid container>
					<Grid item xs={12} textAlign='center'>
						{icon}
					</Grid>

					<Grid item xs={12} textAlign='center'>
						<Typography sx={{fontSize: '1.6rem'}}>{primaryTxt}</Typography>
						<Typography sx={{fontSize: '1.1rem'}}>{secondaryTxt}</Typography>
					</Grid>

					{showButton && (
						<Grid item xs={12} textAlign='center' mt={4}>
							<ActionButton component={Link} to={to}>
								{buttonText}
							</ActionButton>
						</Grid>
					)}
				</Grid>
			</Container>
		</SectionPadding>
	);
};

export default OrderStatus;
