import React, { useEffect } from "react";
import { Box, Container, Grid, Typography, useTheme } from "@mui/material";
import SectionPadding from "../../Common/SectionPadding";
import ActionButton from "../../Common/Buttons/ActionButton";
import { banner3, HomePageSlider03, HomePageSlider04 } from "../../../assets";
import Carousel from "../../Common/Carousel";
import { SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";

const WhyGIC = ({ gutContent }) => {
	const theme = useTheme();
	const itemsSX = {
		boxShadow: `rgba(0, 0, 0, 0.1) -4px 9px 25px -6px`,
		//backdropFilter: "blur(10px) saturate(100%) contrast(45%) brightness(130%)",
		borderRadius: 2,
		p: 2,
		backgroundColor: "rgba(255,255,255,1)",
	};
	return (
		<SectionPadding
			overRideStyles={{
				// backgroundImage: 'linear-gradient(to right bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))',
				backgroundImage: `linear-gradient(to right bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), url(${banner3})`,
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
				// backgroundAttachment: "fixed",
			}}>
			<Container>
				<Grid container alignItems={"center"}>
					<Grid item xs={12} sx={{ display: "flex", justifyContent: "center", mb: "25px" }}>
						<Typography
							variant="h3"
							sx={{
								// textTransform: "capitalize",
								fontSize: { xs: "1.2rem", md: "1.8rem" },
								// fontWeight: 400,
								color: theme.palette.secondary.main,
							}}
							dangerouslySetInnerHTML={{
								__html: `${gutContent.title}`
							}}
						>
							{/* {gutContent.title} */}
						</Typography>
					</Grid>
					<Grid container item xs={12} sx={itemsSX} alignItems={"center"}>
						{" "}
						<Grid
							item
							xs={12}
							md={6}
							textAlign={{ xs: "center", md: "left", display: "flex", alignItems: "center" }}>
							<Box p={2}>
								<Typography
									variant="body2"
									color={"#000"}
									sx={{
										fontSize: { xs: "1rem", md: "1.2rem" },
										mt: 2,
										fontWeight: 600,
									}}
									dangerouslySetInnerHTML={{
										__html: `${gutContent.heading}`
									}}
									>
									{/* {gutContent.heading} */}
								</Typography>
								<Typography
									variant="body2"
									color={"#111"}
									sx={{ fontSize: { xs: "0.8rem", md: "1rem" }, mt: 2 }}
									dangerouslySetInnerHTML={{
										__html: `${gutContent.content}`
									}}
									>
									{/* {gutContent.content} */}
								</Typography>
							</Box>
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
							textAlign={{ xs: "center", md: "left" }}
							mt={{ xs: 2, md: 0 }}
							p={2}>
							<Carousel
								// showArr={true}
								slidesPerView={1}
								spaceBetween={15}
								pagination={false}
								autoplay={{
									delay: 2500,
									disableOnInteraction: false,
									pauseOnMouseEnter: true,
								}}
								loop={true}
								className="whyGic">
								{Object.keys(gutContent?.imageurl).map(key => (
									<SwiperSlide >
										<Box sx={{ width: "100%", height: "320px", maxHeight: "320px" }}>
											<img src={gutContent?.imageurl[key]} alt={"slider04"}
												style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: 3 }}
											/>

										</Box>
									</SwiperSlide>
								))}

								{/* <SwiperSlide>
									<Box sx={{ width: "100%", height: "320px", maxHeight: "320px" }}>
										<img
											src={gutContent?.imageurl}
											alt={"slider04"}
											style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: 3 }}
										/>
									</Box>
								</SwiperSlide> */}
							</Carousel>
						</Grid>
					</Grid>
					<Grid item xs={12} sx={{ display: "flex", justifyContent: "center", mb: "25px", mt: 3 }}>
						<ActionButton styles={{ mt: 2 }} component={Link} to={"/about"}>
							Know More
						</ActionButton>
					</Grid>
				</Grid>
			</Container>
		</SectionPadding>
	);
};

export default WhyGIC;
