import React, { useEffect, useState } from "react";
import { Grid, Typography, useTheme } from '@mui/material';
import data from '../data.json';
import { PsysicianWithPatient } from '../../../assets';
import Article from './Article';
import axios from "../../../axios-instance";
import { useNavigate } from "react-router-dom";

import BlogPostCard from '../Common/BlogPostCard';


const BlogGridTemplate = () => {
	const [blogs, setBlogsData] = useState([]);
	const [trendingBlogs, setTrendingBlogs] = useState([]);
	const [loader, setLoader] = useState(false);
	const theme = useTheme();
	const navigate = useNavigate();

	useEffect(() => {
		getAllBlogs();
		getTrendingBlogs();
	}, []);

	const getAllBlogs = () => {
		setLoader(true);
		axios.get("/api/blogs")
			.then((res) => {
				if (res) {
					setBlogsData(res?.data?.blog);
					setLoader(false);
				}
			})
			.catch((error) => console.log(error));
		setLoader(false);
	};
	const getTrendingBlogs = () => {
		setLoader(true);
		axios.get("/api/blogs/trendingBlogs")
			.then((res) => {
				if (res) {
					setTrendingBlogs(res?.data?.trendingBlogs);
					setLoader(false);
				}
			})
			.catch((error) => console.log(error));
		setLoader(false);
	};

	console.log('trendingBlogs', trendingBlogs);
	return (
		<Grid
			item
			container
			sx={{ display: 'flex', flexDirection: { xs: 'column-reverse', sm: 'column', md: 'row' } }}
		>

			{/* {trendingBlogs.length ?
				<Grid
					item
					sx={{
						flex: 0.75,
						my: { xs: 2, md: 0 },
						mr: { md: 2 },
					}}
				>
					<Article
						img={trendingBlogs[0]?.imageurl}
						title={trendingBlogs[0]?.title}
						description={trendingBlogs[0]?.description}
					/>


				</Grid> : <Typography
					style={{ width: '100%', textAlign: 'center' }}
					variant="h3"
					sx={{
						textTransform: "capitalize",
						fontSize: { xs: "1.2rem", sm: "1.5rem", md: "2rem" },
						fontWeight: 400,
						color: theme.palette.secondary.main,
					}}>
					No Trending Blogs!
				</Typography>} */}
			{/* <Grid
				item
				container
				sx={{
					flex: 1,
					display: 'grid',
					// gridTemplateColumns: {xs: 'repeat(1, 1fr)', md: 'repeat(auto-fill, minmax(300px, 1fr))'},
					gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' },
					gridAutoRows: 'auto',
					gridGap: '1rem',
				}}
			>

				{trendingBlogs?.map((data, i) => (
					<Grid item key={i} sx={{}}>
						<Article img={data?.imageurl} title={data?.title} description={data?.description} />
					</Grid>
				))
					.slice(1, 4)}

				
			</Grid> */}

			<Grid
				item
				xs={12}
				sx={{
					display: 'grid',
					gridTemplateColumns: { sm: 'repeat(auto-fill, minmax(280px, 1fr))' },
					gridAutoRows: 'auto',
					gridGap: '2rem',
					my: 2,
				}}
			>
				{trendingBlogs && trendingBlogs.map(({ description, createdAt, category, imageurl, _id, title }) => (
					<BlogPostCard
						key={_id}
						blogDescription={description}
						blogTitle={title}
						postDate={createdAt}
						category={category}
						img={imageurl}
						handleNavigation={() => navigate(`/blogs/${_id}`)}
					/>
				))}
			</Grid>
		</Grid>
	);
};

export default BlogGridTemplate;
