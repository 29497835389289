import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  getPersons,
  resetProfile,
} from "../../../features/profile/profileSlice";
import { updateOrderPerson, getOrderById } from "../../../features/orders/orderSlice";
import Loader from "../../Common/Loader";

export default function MemberSelect({ personId, orderId }) {
  const { persons, isProfileLoading } = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  const [member, setMember] = React.useState(personId);

  const handleChange = (event) => {
    setMember(event.target.value);
  };

  const handleSelectMember = async (e, id) => {
    await dispatch(updateOrderPerson({ id: id, orderId }));
    await dispatch(getOrderById({ id: orderId }));
  };

  React.useEffect(() => {
    dispatch(getPersons());
    // return () => dispatch(resetProfile());
  }, [dispatch]);

  // if (isProfileLoading) {
  //   return <Loader open={true} />;
  // }
  console.log('orderId', orderId);
  return (
    <Box sx={{ minWidth: 120, mb: 4 }}>
      <FormControl sx={{ width: "40%" }} size="small">
        <InputLabel id="change-member-select">Change Member</InputLabel>
        <Select
          labelId="change-member-select"
          id="member-select"
          value={member}
          label="Change Member"
          onChange={handleChange}
        >
          {persons?.map((item) => (
            <MenuItem
              key={item?._id}
              selected={item?._id === member}
              value={item?._id}
              onClick={(e) => handleSelectMember(e, item?._id)}
              sx={{ textTransform: "capitalize" }}
            >
              {item?.firstName} {item?.lastName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
