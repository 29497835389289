import { Grid, Typography } from "@mui/material";
import React from "react";

const QuestionAnswer = ({ questionaries }) => {
  return (
    <Grid item container xs={12} columnSpacing={4}>
      {questionaries?.map(({ _id, question: { question }, selectedAnswer, customAnswer, checkboxAnswer }, index) => (
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            pt: { xs: 2, sm: 6 },
          }}
          key={_id}
        >
          <Typography
            sx={{
              fontSize: { xs: "1.2rem" },
              fontWeight: 700,
              mb: 1,
            }}
          >
            <span style={{ color: "#ccc" }}>#{index + 1}</span>
            &nbsp;&nbsp;&nbsp;
            {question} 
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "1rem" },
              fontWeight: 700,
              mb: 1,
            }}
          >
            <span style={{ color: "#ccc" }}>Selected Answer</span>
            &nbsp;&nbsp;&nbsp;
            {(checkboxAnswer != "" && selectedAnswer == "") ? checkboxAnswer : selectedAnswer}
          </Typography>
          {Boolean(checkboxAnswer) && selectedAnswer == "Yes" && (
            <>
              <Typography
                sx={{
                  fontSize: { xs: "1rem" },
                  fontWeight: 700,
                  mb: 1,
                }}
              >
                <span style={{ color: "#ccc" }}>Selected Values</span>
                &nbsp;&nbsp;&nbsp;
                {checkboxAnswer} 
              </Typography>
            </>
          )}
          {Boolean(customAnswer) && (
            <>
              <Typography
                sx={{
                  fontSize: { xs: "1rem" },
                  fontWeight: 700,
                  mb: 1,
                }}
              >
                <span style={{ color: "#ccc" }}>Custom Answer</span>
                &nbsp;&nbsp;&nbsp;
                {customAnswer} 
              </Typography>
            </>
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default QuestionAnswer;
