import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { XCircleIcon } from "@heroicons/react/outline";
import useBreakpoints from "../../../../hooks/useBreakpoints";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: { xs: "90%", md: 1100 },
	margin: { xs: "1rem", sm: 0 },
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
	borderRadius: 5,
};

const ModalView = ({ data, close }) => {
	console.log('bbbb', data);
	const { isXSmallScreen } = useBreakpoints();
	const modalVariants = {
		open: {
			opacity: 1,
			transition: { staggerChildren: 0.5, delayChildren: 0.2 },
		},
		closed: { opacity: 0 },
	};

	const imageVariants = {
		open: { opacity: 1, y: "0vh" },
		closed: { opacity: 0, y: "-10vh" },
	};

	const modalInfoVariants = {
		open: { opacity: 1, transition: { staggerChildren: 0.2 } },
		closed: { opacity: 0 },
	};

	const modalRowVariants = {
		open: { opacity: 1, x: 0 },
		closed: { opacity: 0, x: "10%" },
	};

	return (
		<Box
			sx={{
				position: "absolute",
				top: "50%",
				left: { xs: '46%', md: "50%" },
				transform: "translate(-50%, -50%)",
				width: { xs: "90%", md: 1100 },
				margin: { xs: "1rem", sm: 0 },
				bgcolor: "background.paper",
				boxShadow: 24,
				p: 4,
				borderRadius: 5,
			}}
			component={motion.div}
			variants={modalVariants}
			onClick={(e) => e.stopPropagation()}
		>
			<Box sx={{ display: { xs: "block", md: "flex" }, gap: "1.8rem", position: "relative", alignItems: "center", height: { xs: "400px", md: 'auto' }, overflowX: { xs: 'hidden', md: 'hidden' }, overflowY: { xs: 'scroll', md: 'hidden' } }}>
				<Box
					sx={{ width: { xs: "100%", md: "40%" }, height: { xs: "auto", md: "500px" }, overflow: { xs: "scroll", md: "hidden" } }}
					component={motion.div}
					variants={imageVariants}
				>
					<Box
						component={"img"}
						sx={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: 5 }}
						alt={data.name}
						src={data.imageurl}
					/>
				</Box>

				<Box sx={{ width: { xs: "100%", md: "60%" } }} component={motion.div} variants={modalInfoVariants}>
					<Box sx={{ mb: 4, textAlign: 'center' }} component={motion.div} variants={modalRowVariants}>

						{
							data.position === "MEDICAL ADVISOR" ?
								<Typography variant="caption">MEDICAL ADVISOR</Typography>
								: data.position === "SCIENTIFIC ADVISOR" ?
									<Typography variant="caption">SCIENTIFIC ADVISOR</Typography>
									: data.position === "EXECUTIVE MEMBER" ?
										<Typography variant="caption">EXECUTIVE MEMBER</Typography> :
										data.position === "BOARD OF DIRECTORY MEMBER" ?
											<Typography variant="caption">BOARD OF DIRECTORY MEMBER</Typography>
											: <Typography variant="caption">TEAM MEMBER</Typography>

						}
						<Typography sx={{ fontSize: "2rem", lineHeight: 0.9 }}
							dangerouslySetInnerHTML={{
								__html: `${data.name}`
							}}
						>
						</Typography>
						{data.position === "MEDICAL ADVISOR" && (
							<Box sx={{ mt: 2 }}>
								<Typography
									variant="subtitle1"
									sx={{ fontSize: "1.3rem", fontWeight: 700, lineHeight: 0.9 }}

									dangerouslySetInnerHTML={{
										__html: `${data.hospitalName}`
									}}
								>
									{/* {data.hospitalName} */}
								</Typography>
								<Typography
									dangerouslySetInnerHTML={{
										__html: `${data.address}`
									}}
								>
								</Typography>
								<Typography
									dangerouslySetInnerHTML={{
										__html: `${data.specialist}`
									}}
									sx={{ mt: 2 }}
								>
								</Typography>
							</Box>
						)}
					</Box>
					<Box
						whiteSpace='pre-line'
						className="modal__description-wrapper"
						component={motion.div}
						variants={modalRowVariants}
						textAlign="justify"
					>
						{data.position != "MEDICAL ADVISOR" && (
							<Typography className="modal__description"
								dangerouslySetInnerHTML={{
									__html: `${data.description}`
								}}
							></Typography>
						)}
					</Box>

					<IconButton
						sx={{ position: "absolute", cursor: "pointer", right: 0, top: 0 }}
						component={motion.button}
						whileHover={{ scale: 1.2 }}
						onClick={close}
					>
						<XCircleIcon height={"22px"} />
					</IconButton>
				</Box>
			</Box>
		</Box>
	);
};

export default ModalView;
