import React from "react";
import { Button, Grid, Link, Typography, Box } from "@mui/material";
import { Form, Formik } from "formik";
import TextInputField from "../Common/Form/TextInputField";

const VerifyOtp = ({ handleClose }) => {
  const handleResendOtp = () => {};

  return (
    <Formik
      initialValues={{ verifyOtp: "" }}
      onSubmit={(values) => {
        console.log("values");
      }}
    >
      {({ handleSubmit }) => (
        <Form>
          <Grid item container xs={12}>
            <Grid item xs={12} textAlign="center" my={2}>
              <Typography>
                We have sent you <strong>One Time Password</strong> to your SMS
              </Typography>
            </Grid>
            <Grid item xs={12} textAlign="center">
              <TextInputField
                name="verifyOtp"
                size="small"
                type={"text"}
                placeholder="Please Enter OTP"
                styles={{ width: { xs: "100%", md: "70%" } }}
              />
              <Box>
                <Link
                  sx={{ textDecoration: "none", cursor: "pointer" }}
                  onClick={handleResendOtp}
                >
                  Resend OTP
                </Link>
              </Box>
            </Grid>

            <Grid item xs={12} mt={4} textAlign="center">
              <Button
                disableElevation
                variant="outlined"
                sx={{ px: 1.5, m: 1, fontSize: { xs: "0.8rem", md: "1rem" } }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                disableElevation
                variant="contained"
                sx={{
                  px: 1.5,
                  m: 1,
                  color: "#fff",
                  fontSize: { xs: "0.8rem", md: "1rem" },
                }}
                onClick={handleSubmit}
              >
                Verify OTP
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default VerifyOtp;
