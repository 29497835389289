import React from "react";
import { Box, Grid, ListItemButton, ListItemIcon, ListItemText, Typography, useTheme } from "@mui/material";
import OverlapRadialProgress from "./OverlapRadialProgress";
import { format } from 'date-fns'
import { Link, useLocation } from "react-router-dom";
import useBreakpoints from "../../../hooks/useBreakpoints";
import {
  ChartPieIcon,
  // DocumentReportIcon,
  DocumentTextIcon,
  DownloadIcon,
} from "@heroicons/react/outline";

const ReportDetailsBar = ({
  username = "Dharam Vir Singh",
  testDate = "19th September, 2022",
  orderId = "GIC-856949",
  kitId = "GIC-856949",
  overallprogress = "60"
}) => {
  const location = useLocation();
  const theme = useTheme();
	const {isMediumScreen} = useBreakpoints();

  const reportOrderData = [
    //{ name: "Test Date", value: format(new Date(testDate), 'dd MMMM, yyyy'), icon: "", path : 'testdate' },
    //{ name: `Kit ID : ${kitId}`, value: `Test Date : ${format(new Date(testDate), 'dd MMMM, yyyy')}`, icon: "", path : 'kitid' },
    {
      name: "Goto",
      text: "Overall Health",
      icon: <ChartPieIcon height={"18px"} color={theme.palette.secondary.main} />,
      path: `overall-health`,
      value: '',
      selectedCheck: [0]
    },
    {
      name: "Goto",
      text: "Recommendations",
      icon: <DocumentTextIcon height={"18px"} color={theme.palette.secondary.main} />,
      path: `recommendations`,
      value: '',
      selectedCheck: [1]
    },
    // {
    //   name: "Download",
    //   text: "Download PDF",
    //   path: `download-pdf`,
    //   value: '',
    //   icon: <DownloadIcon height={"20px"} />,
    //   selectedCheck: [2]
    // }
  ];

  const indexByRoute =
    reportOrderData.findIndex((x) => x.path === location.pathname.split("/")[3]) === -1
      ? 0
      : reportOrderData.findIndex((x) => x.path === location.pathname.split("/")[3]);
  return (
    <Grid item xs={12}>
      <Grid
        item
        container
        xs={12}
        sx={{
          background: `linear-gradient(to right bottom, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
          px: { xs: 2, sm: 4 },
          pb: { xs: 2, sm: 2 },
          borderRadius: 3,
          pt: 2,
          display: "flex",
          alignItems: "center",
          flexDirection: { xs: "column", sm: "row" },
          gap: { xs: 1, sm: 0 },
        }}
      >
        <Grid item xs={12} sm={4} textAlign={{ xs: "center", sm: "unset" }}>
          <Typography
            sx={{
              fontSize: {
                xs: "1.2rem",
                sm: "1.4rem",
                md: "1.4rem",
                lg: "1.7rem",
              },
              lineHeight: 1,
              fontWeight: 700,
              color: "#fff",
              textTransform: "uppercase",
              letterSpacing: 0.8,
            }}
          >
            {username}
          </Typography>
          <Typography
            sx={{
              color: "#fff",
              fontSize: { xs: "0.8rem" },
            
            }}
           
          >
            {`Kit Id : ${kitId}`}
            <br/>
            {`Test Date : ${format(new Date(testDate), 'dd MMMM, yyyy')}`}
          </Typography>
        </Grid>
        <Grid xs={12} sm={4} >
          <Typography sx={{
            fontSize: {
              xs: "1.2rem",
              sm: "1.4rem",
              md: "1.4rem",
              lg: "1.8rem",
            },
            marginTop:"-2%",
            lineHeight: 1,
            fontWeight: 700,
            color: "#fff",
            // textTransform: "uppercase",
            letterSpacing: 0.8,
            padding:"4px",
          }} >
            GUT-iCARE Report
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          sx={{ mt: { xs: 0, sm: 0 }, display: "flex", alignItems: "center", justifyItems: "center" }}
        >
          <OverlapRadialProgress
            progress={overallprogress}
            size={55}
            progressColor={"#fff"}
            progressSize={{ xs: "1.6rem", sm: "2.5rem" }}
            showName
          />
        </Grid>
      </Grid>

      {isMediumScreen && (<Grid
        item
        container
        xs={12}
        sx={{
          boxShadow: "3px 3px 20px rgba(0, 0, 0, 0.07)",
          borderRadius: 3,
          py: 0,
          width: { xs: "100%", sm: "95%" },
          mx: "auto",
          mt: { xs: 1, sm: -1 },
          background: "#fff",
        }}
        style={{marginTop:"-15px"}}
      >
        {reportOrderData?.map(({ name, value, icon, path, text, selectedCheck }, index) => (
          <Grid key={index} item xs={4} sm={4} textAlign={"center"}>
            <Typography
              variant="caption"
              sx={{
                fontSize: {
                  xs: "0.9rem",
                  sm: "0.9rem",
                },
                color: "#000",
                textTransform: "capitalize",
                fontWeight: 400,
              }}
            >
              {value != "" && `${name}`}
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: "0.9rem",
                  sm: "0.9rem",
                },
                lineHeight: 1,
                fontWeight: 400,
                color: "#000",
                textTransform: "capitalize",
                letterSpacing: 0.8,
              }}
            >
              {value != "" && value}
              {value == "" && (
                <ListItemButton
                  component={Link}
                  to={`/reports/${orderId}/${path}`}
                  key={index}
                  sx={{ borderRadius: 2 }}
                  // selected={indexByRoute === index}
                  selected={selectedCheck.includes(indexByRoute)}
                  style={{
                    borderBottom: (selectedCheck.includes(indexByRoute) ? '1px solid #aaa' : 'unset'),
                    backgroundColor: (selectedCheck.includes(indexByRoute) ? 'rgb(0 255 28 / 13%) !important' : 'unset'),
                  }}
                // onClick={(event) => handleListItemClick(event, index)}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              )}

            </Typography>

          </Grid>
        ))}
      </Grid>)}
    </Grid>
  );
};

export default ReportDetailsBar;
