import * as React from "react";
// import PropTypes from 'prop-types';
import {LogoutIcon, QuestionMarkCircleIcon, MenuIcon} from "@heroicons/react/outline";

import {CssBaseline, Divider, Link, Menu, MenuItem, Typography, useTheme} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import {useDispatch, useSelector} from "react-redux";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import {Logo, LogoAlt} from "../../assets";
import ActionButton from "../Common/Buttons/ActionButton";
import {NavLink, Link as RouterLink, useLocation, useNavigate} from "react-router-dom";
import SideDrawer from "./SideDrawer";
import useBreakpoints from "../../hooks/useBreakpoints";
import CustomAvatar from "../Common/CustomAvatar";
import {logout, resetAuth} from "../../features/auth/authSlice";
import {resetUser} from "../../features/users/userSlice";
import {resetOrders} from "../../features/orders/orderSlice";
import {resetProfile} from "../../features/profile/profileSlice";
import image_constants from "../../shared/utils/images_constantsV2";

function ElevationScroll(props) {
	const {children, trigger} = props;

	// Note that you normally won't need to set the window ref as useScrollTrigger
	// will default to window.
	// This is only being set here because the demo is in an iframe.

	return React.cloneElement(children, {
		elevation: 0,
		style: {
			backgroundColor: trigger
				? "rgba(255, 255, 255, 0.5)"
				: props.transparent
				? "transparent"
				: "#fff",
			boxShadow: trigger ? "0px -11px 39px 0px #00000040" : "none",
			backdropFilter: trigger ? "blur(10px) saturate(100%) contrast(45%) brightness(130%)" : "none",
			position: "fixed",
			transition: "0.2s ease-out",
		},
	});
}

const pages = [
	{path: "/home", link: "home"},
	{path: "/about", link: "about"},
	{path: "/categoryblogs", link: "blogs"},
	{path: "/products", link: "products"},
	{path: "/orders", link: "orders"},
	{path: "/benefits", link: "benefits"},
	{path: "/contactus", link: "contact"},
];

// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const Appbar = (props) => {
	const {window} = props;
	const {currentUser} = useSelector((state) => state.user);
	const location = useLocation();
	const navigate = useNavigate();
	const theme = useTheme();
	const dispatch = useDispatch();

	const isLoggedIn = Boolean(localStorage.getItem("token"));

	const handleLoginClick = () =>{
		navigate('/auth/mobilelogin')
		window.location.reload();
	}

	const showLoginBtn =
		location.pathname !== "/auth/mobilelogin" &&
		location.pathname !== "/auth/register" &&
		location.pathname !== "/auth/emaillogin";

	const isThreshold =
		(location.pathname === "/" && isLoggedIn) ||
		(location.pathname === "/home" && isLoggedIn) ||
		location.pathname === "/about";

	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: isThreshold ? 590 : 2,
		target: window ? window() : undefined,
	});

	const {isMediumScreen} = useBreakpoints();
	const [open, setOpen] = React.useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);

	const openMenu = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	// const handleCloseUserMenu = () => {
	// 	setAnchorElUser(null);
	// };

	const handleLogout = () => {
		dispatch(logout());
		dispatch(resetAuth());
		dispatch(resetUser());
		dispatch(resetOrders());
		dispatch(resetProfile());
		navigate("/");
	};

	return (
		<Box>
			<CssBaseline />
			<ElevationScroll trigger={trigger} {...props}>
				<AppBar>
					<Container maxWidth="100%">
						<Toolbar
							disableGutters
							sx={{
								height: {md: trigger ? "80px" : "100px"},
								backgroundColor: "transparent",
								transition: "0.2s ease-out",
							}}>
							<Box
								component={RouterLink}
								to="/"
								sx={{
									mr: 2,
									display: {xs: "none", md: "flex"},
									textDecoration: "none",
									flexGrow: 1,
								}}>
								<Box
									sx={{
										width: "200px",
										maxWidth: "200px",
										overflow: "hidden",
									}}>
									<img
										src={
											!trigger && props.transparent
												? LogoAlt
												: Logo
										}
										alt="logo"
										style={{
											height: "auto",
											width: "180px",
											objectFit: "cover",
										}}
										loading="lazy"
									/>
								</Box>
							</Box>

							<Box sx={{flexGrow: {xs: 0, sm: 1}, display: {xs: "flex", md: "none"}}}>
								<IconButton
									size="small"
									aria-label="account of current user"
									aria-controls="menu-appbar"
									aria-haspopup="true"
									onClick={() => setOpen(true)}
									sx={{
										mr: 0.5,
									}}>
									<MenuIcon
										height={"18px"}
										color={!trigger && props.transparent ? "#fefefe" : "#000"}
									/>
								</IconButton>
								<SideDrawer open={open} setOpen={setOpen} showLoginButton={showLoginBtn} />
							</Box>

							<Box
								component={RouterLink}
								to="/"
								sx={{
									display: {xs: "flex", md: "none"},
									flexGrow: 1,
									textDecoration: "none",
									minWidth: 90,
								}}>
								{!trigger && props.transparent ? (
									<img
										src={LogoAlt}
										alt="logo"
										style={{
											height: "auto",
											width: isMediumScreen ? "200px" : "133px",
										}}
									/>
								) : (
									<img
										src={Logo}
										alt="logo"
										style={{
											height: "auto",
											width: isMediumScreen ? "200px" : "133px",
										}}
									/>
								)}
							</Box>

							<Box sx={{display: "flex", alignItems: "center"}}>
								<Box sx={{display: {xs: "none", md: "flex"}}}>
									{pages.map(({link, path}) => (
										<Box key={link} sx={{my: 2, mx: 2, display: "block"}}>
											<Link
												component={NavLink}
												to={path}
												sx={{
													textTransform: "capitalize",
													color:
														!trigger && props.transparent
															? "#fefefe"
															: theme.palette.secondary.main,
													textDecoration: "none",
													transition: ".2s ease-out",
													"&:hover": {
														transition: ".2s ease-out",
														color:
															!trigger && props.transparent
																? "#cbd5e1"
																: theme.palette.primary.light,
													},
													"&.active": {
														color:
															!trigger && props.transparent
																? "#cbd5e1"
																: theme.palette.primary.main,
													},
												}}>
												{link}
											</Link>
										</Box>
									))}
								</Box>

								{!isLoggedIn ? (
									<>
										{showLoginBtn && (
											<Box sx={{ml: {xs: 0, md: 4}}}>
												<ActionButton
													styles={{display: {xs: "none", sm: "inline"}}}
													component={RouterLink}
													onClick={handleLoginClick}
													to="/auth/mobilelogin">
													Login
												</ActionButton>
											</Box>
										)}
									</>
								) : (
									<>
										<Box sx={{ml: {xs: 0, md: 4}}}>
											<IconButton
												onClick={handleClick}
												aria-controls={openMenu ? "account-menu" : undefined}
												aria-haspopup="true"
												aria-expanded={openMenu ? "true" : undefined}>
												<CustomAvatar
													alt={currentUser?.firstName}
													// src={
													// 	'https://images.unsplash.com/photo-1499887142886-791eca5918cd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2080&q=80'
													// }
												/>
											</IconButton>
										</Box>
										<AccountMenu
											anchorEl={anchorEl}
											open={openMenu}
											handleClose={handleClose}
											username={currentUser?.userName}
											phone={currentUser?.phone}
											handleLogout={() => handleLogout()}
										/>
									</>
								)}
							</Box>
						</Toolbar>
					</Container>
				</AppBar>
			</ElevationScroll>
		</Box>
	);
};
export default Appbar;

const AccountMenu = ({anchorEl, open, handleClose, username, phone, handleLogout}) => {
	const theme = useTheme();
	const {isSmallScreen} = useBreakpoints();

	const listItems = [
		{item: "profile", path: "/profile"},
		{item: "orders", path: "/orders"},
	];

	const listWithIcons = [
		// {
		// 	item: 'settings',
		// 	icon: <CogIcon height={'22px'} color={theme.palette.common.greyDark} />,
		// 	path: '/account/settings',
		// },
		{
			item: "faqs",
			icon: <QuestionMarkCircleIcon height={"22px"} color={theme.palette.common.greyDark} />,
			path: "/faqs",
		},
	];

	return (
		<Menu
			anchorEl={anchorEl}
			id="account-menu"
			open={open}
			onClose={handleClose}
			onClick={handleClose}
			PaperProps={{
				elevation: 0,
				sx: {
					overflow: "visible",
					filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.2))",
					width: {sm: 250},
					mt: 1.5,
					"&:before": {
						content: '""',
						display: "block",
						position: "absolute",
						top: 0,
						right: 14,
						width: 10,
						height: 10,
						bgcolor: "background.paper",
						transform: "translateY(-50%) rotate(45deg)",
						zIndex: 0,
					},
				},
			}}
			transformOrigin={{horizontal: "right", vertical: "top"}}
			anchorOrigin={{horizontal: "right", vertical: "bottom"}}>
			<Box sx={{display: "block", px: 2, py: 1}}>
				<Typography
					sx={{
						fontSize: {xs: "0.9rem", sm: "1rem", md: "1.1rem"},
						fontWeight: 600,
						textTransform: "capitalize",
					}}>
					{username}
				</Typography>
				<Typography
					sx={{
						fontSize: {xs: "0.775rem", sm: "0.825rem"},
						color: theme.palette.common.greyDark,
					}}>
					{phone}
				</Typography>
			</Box>
			<Divider />
			{listItems.map(({item, path}, index) => (
				<MenuItem key={item} dense={!isSmallScreen} component={RouterLink} to={`${path}`}>
					<Typography sx={{textTransform: "capitalize", fontSize: {xs: "0.9rem", sm: "1rem"}}}>
						{item}
					</Typography>
				</MenuItem>
			))}

			<Divider />

			{listWithIcons.map(({item, icon, path}) => (
				<MenuItem key={item} dense={!isSmallScreen} component={RouterLink} to={`${path}`}>
					{icon}
					<Typography
						sx={{ml: 2, textTransform: "capitalize", fontSize: {xs: "0.9rem", sm: "1rem"}}}>
						{item}
					</Typography>
				</MenuItem>
			))}

			<Divider />
			<MenuItem dense={!isSmallScreen} onClick={handleLogout}>
				<LogoutIcon height={"22px"} color={theme.palette.common.greyDark} />
				<Typography sx={{ml: 2, textTransform: "capitalize", fontSize: {xs: "0.9rem", sm: "1rem"}}}>
					Logout
				</Typography>
			</MenuItem>
		</Menu>
	);
};
