import { Box } from '@mui/material';
import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import PolarChartResult from './PolarChartResult';

class PolarChart extends Component {

	constructor(props) {
		super(props);

		this.data = props.data.chartData;

		this.allowedEntities = props.data.allowedEntities;
		this.state = {
			series: [],
			options: {
				yaxis: {
					show: true,
					showAlways: true,
					showForNullSeries: true,
					logarithmic: false,
					tickAmount: 6,
					min: 0,
					max: 100,
				},
				chart: {
					type: 'polarArea',
					floating: true,
				},
				stroke: {
					colors: ['#fff'],
				},
				fill: {
					opacity: 0.8,
				},
				labels: [],
				legend: {
					position: 'right',
					fontSize: 13,
				},
				responsive: [
					{
						breakpoint: 480,
						options: {
							chart: {
								width: 250,
								// floating: true,
							},
							legend: {
								fontSize: 8,
								// show: false,
							},
						},
					},
					{
						breakpoint: 460,
						options: {
							chart: {
								width: 250,
							},
							legend: {
								show: false,
							},
						},
					},
					{
						breakpoint: 440,
						options: {
							chart: {
								width: 250,
							},
							legend: {
								show: false,
							},
						},
					},
					{
						breakpoint: 420,
						options: {
							chart: {
								width: 250,
							},
							legend: {
								show: false,
							},
						},
					},
					{
						breakpoint: 400,
						options: {
							chart: {
								width: 250,
							},
							legend: {
								show: false,
							},
						},
					},
					{
						breakpoint: 380,
						options: {
							chart: {
								width: 250,
							},
						},
					},
					{
						breakpoint: 320,
						options: {
							chart: {
								width: 250,
							},
						},
					},
					{
						breakpoint: 300,
						options: {
							chart: {
								width: 250,
							},
						},
					},
					{
						breakpoint: 280,
						options: {
							chart: {
								width: 280,
							},
						},
					},
				],
			},
		};
	}

	render() {

		const labels = [];

		const series = [];

		const goodValues = [];

		if (this.data.length > 0) {
			for (let i = 0; i < this.data.length; i++) {
				if (this.data.length > 0) {
					for (let j = 0; j < this.data[i].children.length; j++) {
						if (this.allowedEntities.includes(this.data[i].children[j].healthSection.displayName)) {
							labels.push(this.data[i].children[j].healthSection.displayName);
							series.push(this.data[i].children[j].score);
							if (this.data[i].children[j].score >= 50) {
								goodValues.push(this.data[i].children[j].score);
							}
						}
						if (this.data[i].children[j].children.length) {
							for (let k = 0; k < this.data[i].children[j].children.length; k++) {
								if (this.allowedEntities.includes(this.data[i].children[j].children[k].healthSection.displayName)) {
									labels.push(this.data[i].children[j].children[k].healthSection.displayName);
									series.push(this.data[i].children[j].children[k].score);
									if (this.data[i].children[j].children[k].score >= 50) {
										goodValues.push(this.data[i].children[j].children[k].score);
									}
								}
							}
						}
					}
				}
			}
		}

		this.state.series = series.length > 0 ? series : this.state.series;

		this.state.options.labels = labels.length > 0 ? labels : this.state.labels;

		return (
			<Box>
				<div style={{ height: { xs: '20%', md: "20%",} }}>
					<ReactApexChart options={this.state.options} series={this.state.series} type='polarArea' height="300" />
				</div>
				<PolarChartResult goodValues={goodValues} />
			</Box>
		);
	}
}

export default PolarChart;
