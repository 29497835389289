import React, { useEffect } from "react";
import { Alert, Button, Container, Fab, Grid, useTheme } from "@mui/material";
import { Formik, Form } from "formik";

import SectionPadding from "../components/Common/SectionPadding";
import { ChevronUpIcon } from "@heroicons/react/outline";
import ActionButton from "../components/Common/Buttons/ActionButton";
import Questionaries from "../components/ActivateKit/Questionaries";
import TitleWithBackBtn from "../components/Common/TitleWithBackBtn";
import { useNavigate, useParams } from "react-router-dom";
import ScrollTopButton from "../components/Common/ScrollTopButton";
import { useDispatch, useSelector } from "react-redux";
import { getOrderById, resetOrders, updateOrderKit } from "../features/orders/orderSlice";
import Loader from "../components/Common/Loader";
import { getAge } from "../shared/utils/ageCalc";
import { format } from "date-fns";
import { getAllQuestions } from "../features/kitActivation/kitActivationSlice";
import KitDetails from "../components/ActivateKit/KitDetails";
import OrderStatus from "../components/ActivateKit/OrderStatus";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid";
import ScrollToTop from "../components/hoc/ScrollToTop";

// program to sort array by property name

function compareAge(a, b) {
	return a.priorityId - b.priorityId;
}

const ActivateKit = () => {
	const theme = useTheme();
	const params = useParams();
	const navigate = useNavigate();

	const { questions, isQuestionLoading } = useSelector((state) => state.kitActivation);
	const { currentOrder, isOrderLoading } = useSelector((state) => state.order);
	const dispatch = useDispatch();

	const questionsArr = questions.slice().sort((a, b) => compareAge(a, b));

	const isKitActive = currentOrder?.order_status === "kit_activated";

	const isOrderAvailable = params.orderId !== currentOrder?._id;

	useEffect(() => {
		const data = { id: params.orderId };

		dispatch(getOrderById(data));
		dispatch(getAllQuestions());

		return () => dispatch(resetOrders());
	}, [dispatch, params.orderId]);


	const title = (
		<span>
			Activate Kit |{" "}
			<span
				style={{
					color: theme.palette.common.greyDark,
					fontSize: "1.2rem",
					fontWeight: 600,
					cursor: "pointer",
				}}
				onClick={() => navigate(`/orders/${params.orderId}`)}
			>
				#{currentOrder?.kit ? currentOrder?.kit?.linkedId : params.orderId}
			</span>
		</span>
	);

	let address;
	if (currentOrder?._id && currentOrder?.address?._id) {
		address = `${currentOrder?.address?.line1}, ${currentOrder?.address?.line2}, ${currentOrder?.address?.city}, ${currentOrder?.address?.state}, ${currentOrder?.address?.pincode}`;
	}

	const age = getAge(currentOrder?.person?.dob);

	// BMI Calculation (Formula weight (kg)/height (in cm)/height (in cm)*10000)
	const bmiValue = (currentOrder?.person?.weight / currentOrder?.person?.height / currentOrder?.person?.height) * 10000;
	const updateBniValue = bmiValue.toFixed(2);
	const validate = (values, props /* only available when using withFormik */) => {
		const errors = {};
		// console.log(errors);

		if (!values.kitId) {
			errors.kitId = "Required";
		}

		if (!values.selectDate) {
			errors.selectDate = "Required";
		}

		if (!values.gender) {
			errors.gender = "Required";
		}

		if (!values.residence) {
			errors.residence = "Required";
		}

		// if (!values.age) {
		// 	errors.age = "Required";
		// }

		if (!values.weight) {
			errors.weight = "Required";
		}

		if (!values.height) {
			errors.height = "Required";
		}

		values?.questions?.forEach((question, index) => {
			if (!Boolean(question?.selectedValue?.value) && !Boolean(question?.checkboxValue)) {
				errors[`questions[${index}].selectedValue`] = "Please select an option";
			} else if (Boolean(question?.selectedValue?.isCustomRequired) && !Boolean(question?.customValue) && question?.name != "sleepWell") {
				errors[`questions[${index}].selectedValue`] = "Additional Information is Required";
			} else if (Boolean(question?.selectedValue?.isCheckboxRequired) && !Boolean(question?.checkboxValue)) {
				errors[`questions[${index}].selectedValue`] = "Please select an option";
			}
		});

		return errors;
	};

	if (isOrderLoading || isQuestionLoading) {
		return <Loader open={true} />;
	}

	return (
		<>
			<ScrollToTop />
			<SectionPadding>
				<Container>
					<Grid container pb={{ md: 8 }} mt={{ xs: "2rem" }}>
						{isOrderAvailable ? (
							<Grid item xs={12}>
								<OrderStatus
									icon={<XCircleIcon height={"11rem"} color="#991b1b" />}
									primaryTxt={`There is no Orders with order id ${params.orderId}`}
									secondaryTxt={"You can place a new Order or activate an order from order page"}
									showButton
									buttonText="Orders"
									to={`/orders`}
								/>
							</Grid>
						) : (
							<Formik
								initialValues={{
									kitId: `${currentOrder?.kit ? currentOrder?.kit?.linkedId : params.orderId}`,
									selectDate: format(new Date(), "MM/dd/yyyy"),

									gender: currentOrder?.person?.gender,
									residence: address !== undefined && address,
									age: !isNaN(age) && age,
									weight: currentOrder?.person?.weight,
									height: currentOrder?.person?.height,
									bmi: !isNaN(updateBniValue) ? updateBniValue : undefined,
									questions: questionsArr?.map((q) => ({
										_id: q?._id,
										name: q.name,
										selectedValue: { value: "", isCustomRequired: false, optionId: "" },
										customValue: "",
										checkboxValue: ""
									})),
								}}
								onSubmit={(values) => {
									dispatch(updateOrderKit({ id: params.orderId, ...values }));
								}}
								validate={(values) => validate(values)}
							>
								{({ handleSubmit, errors }) => (
									<Form style={{ width: "100%" }}>
										<Grid
											item
											xs={12}
											sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
										>
											<TitleWithBackBtn title={title} />
											{!isKitActive && (
												<Button
													disableElevation
													variant="contained"
													onClick={handleSubmit}
													sx={{
														textTransform: "capitalize",
														background: theme.palette.secondary.main,
														color: "#fff",
														"&:hover": {
															background: theme.palette.secondary.light,
														},
													}}
												>
													Activate Kit
												</Button>
											)}
										</Grid>

										{!isKitActive ? (
											<>
												<KitDetails kitId={currentOrder?.kit ? currentOrder?.kit?.linkedId : params.orderId} />
												<Questionaries questions={questionsArr} />

												<Grid item xs={12} mt={4} textAlign="center">
													<ActionButton onClick={handleSubmit}>Activate Kit</ActionButton>
												</Grid>
												{Boolean(errors) && Object.keys(errors)?.length > 0 && (
													<Alert sx={{ my: "1rem" }} severity="error">
														Please fill all the mandatory fields
													</Alert>
												)}
											</>
										) : (
											<OrderStatus
												icon={<CheckCircleIcon height={"11rem"} color="#166534" />}
												primaryTxt="Your Kit has been successfully activated"
												secondaryTxt="Our customer care executive will contact you for sending samples back to us, Please Proceed to orders page to check your status"
												showButton
												buttonText="Order Summary"
												to={`/orders/${currentOrder?._id}`}
											/>
										)}
									</Form>
								)}
							</Formik>
						)}
					</Grid>
				</Container>
			</SectionPadding>
			<ScrollTopButton>
				<Fab
					size={"medium"}
					aria-label="scroll back to top"
					sx={{
						background: theme.palette.primary.main,
						"&:hover": {
							background: { xs: theme.palette.primary.main, md: theme.palette.primary.light },
						},
					}}
				>
					<ChevronUpIcon style={{ height: "1.2rem", color: "#fff" }} />
				</Fab>
			</ScrollTopButton>
		</>
	);
};

export default ActivateKit;
