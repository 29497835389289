import React, { useState } from "react";
import { Grid, Alert } from "@mui/material";
import TextInputField from "../../Common/Form/TextInputField";
import { Form, Formik } from "formik";
import MinimalButton from "../../Common/Buttons/MinimalButton";
import { SaveIcon } from "@heroicons/react/solid";
import Swal from "sweetalert2";
import axios from "../../../axios-instance";
import { checkUser } from "../../../features/users/userSlice";
import { logout, resetAuth } from "../../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { changepassword } from "../../../features/profile/profileSlice";
import { NavLink, Link as RouterLink, useLocation, useNavigate } from "react-router-dom";

const validate = (values, props /* only available when using withFormik */) => {

  const errors = {};

  if (!values.currentPassword) {
    errors.currentPassword = "Required";
  } else if (values.currentPassword.length < 6) {
    errors.currentPassword = "Password should contain 6 or above charaters";
  }

  if (!values.confirmPassword) {
    errors.confirmPassword = "Required";
  }

  if (values.password !== values.confirmPassword) {
    errors.confirmPassword =
      "New password and Confrim New Password doesnot Matched";
  }

  if (!values.password) {
    errors.password = "Required";
  } else if (values.password.length < 6) {
    errors.password = "Password should contain 6 or above charaters";
  }

  //...

  return errors;
};

const UpdatePassword = () => {
  const { errorData } = useSelector((state) => state.user);
  const [errorMsg, setErrorMsg] = useState("")
  const styles = {
    width: { xs: "100%" },
    mx: { xs: "auto" },
    // [`& fieldset`]: {
    // 	borderRadius: 3,
    // },
  };

  const { currentUser } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log('currentUser', currentUser?._id);
  return (
    <>
      <Formik
        initialValues={{
          currentPassword: "",
          password: "",
          confirmPassword: "",
          phone: currentUser?.phone,
        }}
        onSubmit={async (values) => {
          if (currentUser?.phone == "" || currentUser?.userName == null) {
          } else {
            console.log('valirr', values);
            const response = await axios.put(`/api/profile/changepassword/${currentUser?._id}`, values);
            console.log('response', response);
            if (response.data.success === true) {
              Swal.fire({
                position: "top-middle",
                icon: "success",
                text: "Password updated successfully!",
                showConfirmButton: false,
                timer: 1500,
              });
              navigate("/");
              dispatch(checkUser());
              dispatch(logout());
            } else {
              console.log('bbbb');
              Swal.fire({
                position: "top-middle",
                icon: "error",
                text: response.data.error,
                showConfirmButton: false,
                timer: 1500,
              });
            };
            console.log('response', response);
            // const passwordupdate = await dispatch(changepassword({
            //   password: values.currentPassword,
            //   password: values.password,
            //   confirmPassword: values.confirmPassword,
            //   phone: currentUser?.phone,
            // })
            // );
            // (passwordupdate?.error ? setErrorMsg(passwordupdate?.payload) : console.log("passwordnotupdateee", passwordupdate))

            // const response = await axios.post("/api/profile/changepassword", data);
            // const response = await axios.post(url, data);
            // console.log('pass', response);
            // if (response.data.message === "User Email Already Exists") {
            //   Swal.fire('User Email Already Exists')
            //   window.location.reload(false);
            // }
            // else {
            //   Swal.fire('Thank you for Subscribing')
            // };



          }
        }}
        validate={(values) => validate(values)}
      >
        {({ handleSubmit }) => (
          <Form>
            <Grid item container xs={12} columnSpacing={1}>
              <Grid item xs={12} md={6}>
                <TextInputField
                  name="currentPassword"
                  label="Current Password"
                  type="password"
                  margin="dense"
                  size="small"
                  styles={styles}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInputField
                  name="confirmPassword"
                  label="Confirm New Password"
                  type="password"
                  margin="dense"
                  size="small"
                  styles={styles}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInputField
                  name="password"
                  label="New Password"
                  type="password"
                  margin="dense"
                  size="small"
                  styles={styles}
                />
              </Grid>

              <Grid item xs={12} textAlign="center" mt={4}>
                {/* <Button
									variant='contained'
									sx={{color: '#fff', py: {sm: 1.5}}}
									onClick={handleSubmit}
									fullWidth
									disableElevation
								>
									Update Password
								</Button> */}
                {errorMsg != "" && (<Alert severity="error">{errorMsg}</Alert>)}
                <MinimalButton
                  maxWidth={true}
                  buttonText={"UPDATE"}
                  startIcon={<SaveIcon height={"20px"} />}
                  onClick={handleSubmit}
                  hideTextOnXs
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UpdatePassword;
