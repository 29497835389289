import axios from '../../axios-instance';

const getCountries = async (qStr) => {
	const response = await axios.get(`/api/countries`);
	return response.data;
};

const countryService = {
	getCountries
};

export default countryService;
