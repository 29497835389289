import React, { useEffect, useState } from "react";
import { Box, Grid, Link, Typography, useTheme, styled } from "@mui/material";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import TitleWithBackBtn from "../../Common/TitleWithBackBtn";
import OverallHealthResult from "../OverallHealth/OverallHealthResult";
import RecommendationsLists from "../Recommendations/RecommendationsLists";
import TestResultData from "../TestHealth/TestResultData";
import SubReportCard from "./SubReportCard";
import useBreakpoints from "../../../hooks/useBreakpoints";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import OverlapRadialProgress from './OverlapRadialProgress'
import ReportAccordionSummary from "./ReportAccordionSummary";

const sections = {
	generalRecommendation: "General Recommendation",
	lifestyleRecommendation: "Lifestyle Recommendation",
	foodRecommendation: "Food Tailored To The Microbiome",
};

const ReportTemplate = ({ title, showSubReports, reportData }) => {
	const theme = useTheme();
	const location = useLocation();
	const navigate = useNavigate();
	const params = useParams();
	const [searchParams] = useSearchParams();
	const { isMediumScreen } = useBreakpoints();
	const [renderReportData, setrenderReportData] = useState();
	const testHealthUrl = searchParams.get("reportpath");
	const recommendationsUrl = searchParams.get("recommendationsPath");

	// function flatten(into, node) {
	// 	if (node == null) return into;
	// 	if (Array.isArray(node)) return node.reduce(flatten, into);
	// 	into.push(node);
	// 	return flatten(into, node.children);
	// }

	// var out = flatten([], reports?.testHealthScore);

	// console.log(renderReportData);

	// TEST HEALTH REPORTS LOGIC
	const paths = testHealthUrl?.split("/").filter(Boolean);
	const query = paths?.toString().replaceAll(",", "/");

	// console.log('query', query);
	// console.log(paths);
	// console.log(reports);

	const sectionTitle = sections[recommendationsUrl];
	const section = recommendationsUrl != null && reportData && reportData?.kit?.report[recommendationsUrl];
	const commonFood =  recommendationsUrl != null && reportData && reportData?.kit?.report.commonFoodData;

	const renderReport = () => {
		const pathCount = paths?.length;
		var data = null;
		for (let index = 0; index < pathCount; index++) {
			// console.log('index', index);
			if (data === null && index === 0) {
				data = reportData && reportData?.kit?.report?.healthScores?.find(
					(item) => item?.healthSection?.name === paths[index]
				);
			} else {
				data = data?.children?.find((item) => item?.healthSection?.name === paths[index]);
			}
		}

		// return data;
		setrenderReportData(data);
	};

	const getRouteForCrumb = (path, index) => {
		const newPath = paths?.slice(0, index + 1);
		const pathname = `/reports/${params?.reportId || "demo"}/overall-health?reportpath=${newPath
			.toString()
			.replaceAll(",", "/")}`;
		navigate(pathname);
	};

	//   const renderReportData = renderReport();
	const getBreadCrumbs = () => {
		// const pathsArr = testHealthUrl?.split("/").filter(Boolean);
		const crumbs = paths?.map((path, index) => {
			let separator = "/";
			let fontWeight = 400;
			let color = "text.disabled";
			if (paths.length === index + 1) {
				separator = "";
				fontWeight = 700;
				color = "#000";
			}
			return (
				<Link
					key={index}
					sx={{ fontWeight: fontWeight, cursor: "pointer", color: color, textDecoration: "none" }}
					onClick={() => getRouteForCrumb(path, index)}>
					{path.replaceAll("_", " ") + " " + separator + " "}
				</Link>
			);
		});
		return <Typography>{crumbs}</Typography>;
	};

	useEffect(() => {
		renderReport();
		//if (!Boolean(renderReportData)) navigate(`/reports/overall-health`);
		// setbreadCrumbs(testHealthUrl?.replaceAll("_", " ").replaceAll("/", " > "));
		// console.log(breadCrumbs);

		return () => { };
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, renderReportData]);

	const subDocsArr = Boolean(testHealthUrl)
		? renderReportData?.children
		: reportData?.kit?.report?.healthScores;

		// console.log("reportData ",reportData?.kit?.report);
		//console.log("subDocsArr",reportData?.kit?.report?.healthScores);

	const getTitle = () => {
		let titleName = "";
		if (location.pathname.includes("overall-health") && testHealthUrl)
			titleName = renderReportData?.healthSection?.anotherDisplayName ? renderReportData?.healthSection?.anotherDisplayName : renderReportData?.healthSection?.displayName;
		else if (location.pathname.includes("overall-health") && !testHealthUrl) titleName = title;
		else if (location.pathname.includes("recommendations") && Boolean(sectionTitle))
			titleName = sectionTitle;
		else if (location.pathname.includes("recommendations") && !Boolean(sectionTitle))
			titleName = "Recommendations";
		else {
			titleName = title;
		}
		return titleName;
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [renderReportData, query, paths]);

	const [expanded, setExpanded] = React.useState(false);

	const handleChange =
		(panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
			setExpanded(newExpanded ? panel : false);
		};


	const Accordion = styled((props) => (
		<MuiAccordion disableGutters elevation={0} square {...props} />
	))(({ theme }) => ({
		// boxShadow: '3px 3px 20px rgba(0, 0, 0, 0.07)',
		//boxShadow: '3px 3px 20px rgba(0, 0, 0, 0.07)',
		borderRadius: '12px',
		paddingTop: "4px",
		paddingBottom: "4px",
		marginBottom: '16px',
		background: '#fff',
		overflow: "hidden",

		"&:not(:last-child)": {
			borderBottom: 0,
		},
		"&:before": {
			display: "none",
		},
	}));


	const AccordionSummary = styled((props) => (
		<MuiAccordionSummary
			expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "1.5rem" }} />}
			{...props}
		/>
	))(({ theme }) => ({
		backgroundColor: `rgba(255, 255, 255,1)`,
		
		flexDirection: "row",
		"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
			transform: "rotate(90deg)",
		},
		"& .MuiAccordionSummary-content": {
			marginLeft: theme.spacing(1),
		},
	}));

	const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
		padding: theme.spacing(2),
		borderTop: "1px solid rgba(0, 0, 0, .125)",
		border: "none",
	}));


	const { isSmallScreen } = useBreakpoints();
	return (
		<Grid
			item
			xs={12}
			sx={{
				borderTop: isMediumScreen ? "1px solid #000" : "none",
				boxShadow: "3px 3px 20px rgba(0, 0, 0, 0.07)",
				p: { xs: 2, sm: 3, md: 6 },
				borderBottomLeftRadius: 18,
				borderBottomRightRadius: 18,
			}}>
			<Grid
				container
				item
				xs={12}
				sx={{
					boxShadow: "3px 3px 20px rgba(0, 0, 0, 0.07)",
					borderRadius: 3,
					py: { xs: 1, md: 0 },
					px: { xs: 1, sm: 2, md: 4 },
					width: { xs: "100%", md: "80%" },
					mx: "auto",
					mt: { xs: 0, md: -9 },
					background: "#fff",
				}}>
				<Grid item container xs={12} textAlign="center" alignItems={"center"}>
					<Grid item xs={1}>
						<TitleWithBackBtn />
					</Grid>
					<Grid item xs={11}>
						<Typography
							sx={{
								fontSize: { xs: "0.925rem", sm: "1.2rem" },
								fontWeight: 900,
								textTransform: "uppercase",
							}}>
							{getTitle()}
						</Typography>
					</Grid>
				</Grid>
			</Grid>

			{Boolean(renderReportData) && <Box sx={{ my: 3 }}>{getBreadCrumbs()}</Box>}

			{Boolean(renderReportData) ? (
				<TestResultData
					title={(renderReportData?.healthSection?.progressBarName ? renderReportData?.healthSection?.progressBarName : renderReportData?.healthSection?.displayName)}
					reportDescription={renderReportData?.healthSection?.description}
					resultDescription={renderReportData?.healthSection?.result}
					bacterias={renderReportData?.bacteriaList}
					status={renderReportData?.healthSection?.anotherDisplayName ? renderReportData?.healthSection?.anotherDisplayName : renderReportData?.healthSection?.displayName}
					score={renderReportData?.score}
					tableData = {renderReportData?.healthSection?.tableData}
					additionalContent = {renderReportData?.healthSection?.additionalContent}
				/>
			) : location.pathname.includes("recommendations") ? (
				<RecommendationsLists
					currentSectionTitle={sectionTitle}
					sections={sections}
					currentSection={section}
					commonFood = {commonFood}
					reportId={params?.reportId}
				/>
			) : (
				<OverallHealthResult />
			)}

			{showSubReports && subDocsArr && subDocsArr?.length !== 0 && (
				<Grid item container xs={12} mt={4}>
					<Grid item xs={12} mb={1}>
						<Typography
							sx={{
								fontWeight: 600,
								fontSize: "1.2rem",
								textTransform: "uppercase",
								color: theme.palette.common.greyDark,
							}}>
							{!Boolean(renderReportData) ? "MAJOR SCORES" : "SUB SCORES"}
						</Typography>
					</Grid>

					{/* <ReportAccordionSummary subDocsArr={subDocsArr}  /> */}
					
					 {subDocsArr?.map((report, index) => {
						return (
							<SubReportCard
								key={report?._id}
								report={report}
								progress={report?.score}
								to={{
									pathname: `/reports/${params?.reportId || "demo"}/overall-health`,
									search: Boolean(testHealthUrl)
										? `?reportpath=${query}/${report?.healthSection?.name}`
										: `?reportpath=${report?.healthSection?.name}`,
								}}
							/>
						);
					})}
				</Grid>
			)}
		</Grid>
	);
};

export default ReportTemplate;
