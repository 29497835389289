import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "./authService";

// const initialState = {
//   currentUser: {
//     userId: "24398rh9294fbh2442",
//   },
//   isError: false,
//   isSuccess: false,
//   isLoading: false,
//   message: "",
// };

const initialState = {
  isAuthLoading: false,
  token:
    Boolean(localStorage.getItem("token")) &&
      localStorage.getItem("token") !== "undefined"
      ? localStorage.getItem("token")
      : null,
  AuthSuccessData: null,
  AuthFailData: null,
  organization:
    Boolean(localStorage.getItem("userOrganization")) &&
      localStorage.getItem("userOrganization") !== "undefined"
      ? localStorage.getItem("userOrganization")
      : null,
};

//...

export const register = createAsyncThunk(
  "auth/register",
  async (user, thunkAPI) => {
    try {
      const data = await authService.register(user);
      if (data) {
        //   const { dispatch } = thunkAPI;
        //   dispatch(checkuser());
        return data;
      }

      const message = "Registration Failed";

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const message = (error && error.message) || error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const emailLogin = createAsyncThunk("auth/email/login", async (user, thunkAPI) => {
  try {
    const data = await authService.emailLogin(user);
    if (data) {
      // const { dispatch } = thunkAPI;
      // dispatch(checkuser);
      return data;
    }
    const message = "Login Failed";
    return thunkAPI.rejectWithValue(message);
  } catch (error) {
    const message =
      error?.response?.data?.error ||
      (error && error.message) ||
      error.toString();

    return thunkAPI.rejectWithValue(message);
  }
}
);

export const mobileLogin = createAsyncThunk("auth/mobile/login", async (user, thunkAPI) => {
  try {
    const data = await authService.emailLogin(user);
    if (data) {
      // const { dispatch } = thunkAPI;
      // dispatch(checkuser);
      return data;
    }
    const message = "Login Failed";
    return thunkAPI.rejectWithValue(message);
  } catch (error) {
    const message =
      error?.response?.data?.error ||
      (error && error.message) ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
}
);

export const logout = createAsyncThunk("auth/logout", async (_, thunkAPI) => {
  try {
    const data = await authService.logout();
    if (data) {
      // const { dispatch } = thunkAPI;
      // dispatch(checkuser);
      localStorage.clear();
      return data;
    }
    const message = "Logout Failed";
    return thunkAPI.rejectWithValue(message);
  } catch (error) {
    const message = (error && error.message) || error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuth: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      // Register
      .addCase(register.pending, (state) => {
        state.isAuthLoading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.AuthSuccessData = action.payload;
        // state.token = action?.payload?.token;
        state.AuthFailData = null;
        state.isAuthLoading = false;
      })
      .addCase(register.rejected, (state, action) => {
        state.AuthSuccessData = false;
        state.AuthFailData = action.payload;
        state.isAuthLoading = false;
      })

      // Email Login
      .addCase(emailLogin.pending, (state) => {
        state.isAuthLoading = true;
      })
      .addCase(emailLogin.fulfilled, (state, action) => {
        console.log('action',action);
        state.AuthSuccessData = action.payload;
        state.token = action?.payload?.data?.token;
        state.organization = action?.payload?.data?.organization;
        state.AuthFailData = null;
        state.isAuthLoading = false;
      })
      .addCase(emailLogin.rejected, (state, action) => {
        console.log('action',action);
        state.AuthSuccessData = false;
        state.AuthFailData = action.payload;
        state.isAuthLoading = false;
      })

      // Mobile Login
      .addCase(mobileLogin.pending, (state) => {
        state.isAuthLoading = true;
      })
      .addCase(mobileLogin.fulfilled, (state, action) => {
        console.log('action',action);
        state.AuthSuccessData = action.payload;
        state.token = action?.payload?.data?.token;
        state.organization = action?.payload?.data?.organization;
        state.AuthFailData = null;
        state.isAuthLoading = false;
      })
      .addCase(mobileLogin.rejected, (state, action) => {
        console.log('action',action);
        state.AuthSuccessData = false;
        state.AuthFailData = action.payload;
        state.isAuthLoading = false;
      })

      // Logout
      .addCase(logout.pending, (state) => {
        state.isAuthLoading = true;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.AuthSuccessData = null;
        state.token = null;
        state.organization = null;
        state.AuthFailData = null;
        state.isAuthLoading = false;
      })
      .addCase(logout.rejected, (state, action) => {
        state.AuthSuccessData = false;
        state.AuthFailData = null;
        state.isAuthLoading = false;
      });
  },
});

export const { resetAuth } = authSlice.actions;
export default authSlice.reducer;
