import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./auth/authSlice";
import profileReducer from "./profile/profileSlice";
import productReducer from "./products/productSlice";
import reviewReducer from "./reviews/reviewSlice";
import orderReducer from "./orders/orderSlice";
import userReducer from "./users/userSlice";
import kitActivationReducer from "./kitActivation/kitActivationSlice";
import reportReducer from "./reports/reportSlice";
import paymentGatewayReducer from "./paymentgateway/paymentGatewaySlice";
import organizationReducer from "./organization/organizationSlice";
import blogReducer from "./blogs/blogSlice";
import contentManagementReducer from "./contentManagement/contentManagementSlice";
import contactUSReducer from "./contactUs/contactUsSlice";
import faqReducer from "./faqs/faqSlice";
import countriesReducer from "./countries/countrySlice";
// import commonReducer from './common/commonSlice';

const appReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  product: productReducer,
  payment_gateway: paymentGatewayReducer,
  review: reviewReducer,
  order: orderReducer,
  user: userReducer,
  kitActivation: kitActivationReducer,
  report: reportReducer,
  organization: organizationReducer,
  blog: blogReducer,
  contentManagement: contentManagementReducer,
  contactUs: contactUSReducer,
  faq: faqReducer,
  countries: countriesReducer
  // common: commonReducer,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
