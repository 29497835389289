import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import organizationService from "./organizationService";

const initialState = {
	organization: null,
	isOrganizationLoading: false,
	successMessage: "",
	errorMessage: "",
	isError: false,
	isSuccess: false,
    organizationMessage : ""
};

export const getOrganizationById = createAsyncThunk("organization/getById", async (data, thunkAPI) => {
	try {
		return await organizationService.getOrganization(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const getAllOrganization = createAsyncThunk("organization/get/all", async (_, thunkAPI) => {
	try {
		return await organizationService.getAllOrganization();
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});


const adminSlice = createSlice({
	name: "organization",
	initialState,
	reducers: {
		resetOrganization: (state) => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(getOrganizationById.pending, (state) => {
				state.isOrganizationLoading = true;
			})
			.addCase(getOrganizationById.fulfilled, (state, action) => {
				state.isOrganizationLoading = false;
				state.currentOrganization = action.payload.organization;
			})
			.addCase(getOrganizationById.rejected, (state, action) => {
				state.isOrganizationLoading = false;
				state.isError = true;
			})
			.addCase(getAllOrganization.pending, (state) => {
				state.isOrganizationLoading = true;
			})
			.addCase(getAllOrganization.fulfilled, (state, action) => {
				state.isOrganizationLoading = false;
                state.organization = action.payload.organization;
			})
			.addCase(getAllOrganization.rejected, (state, action) => {
				state.isOrganizationLoading = false;
				state.isError = true;
			});
	},
});

export const {resetOrganization} = adminSlice.actions;
export default adminSlice.reducer;
