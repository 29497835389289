import React, {useEffect} from 'react';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {Container, Grid, Typography} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import SectionPadding from '../Common/SectionPadding';
import Loader from '../../components/Common/Loader';
import {verifyUserEmail} from '../../features/users/userSlice';
import {CheckCircleIcon, XCircleIcon} from '@heroicons/react/solid';
// import { verifyUserEmail } from "../../features/auth/authSlice";

const VerifyEmail = () => {
	const [status, setstatus] = useState();
	// const [statusMessage, setStatusMessage] = useState();
	// const [statusDetail, setStatusDetail] = useState();
	const params = useParams();
	const dispatch = useDispatch();
	const {isUserLoading, successData, errorData} = useSelector((state) => state.user);

	useEffect(() => {
		if (Boolean(successData)) {
			// setStatusMessage("Email has been verified successfully!");
			// setStatusDetail("Please login to continue.");
			setstatus({
				primaryTxt: 'Email has been verified successfully!',
				secondaryTxt: 'Please login to continue.',
				icon: <CheckCircleIcon height={'11rem'} color='#166534' />,
			});
		}

		if (Boolean(errorData)) {
			// setStatusMessage("Something went wrong. Please try again later.");
			// setStatusDetail("Error details : " + errorData?.error);
			setstatus({
				primaryTxt: 'Token Expired!',
				secondaryTxt: 'Error details : ' + 'Invalid Token',
				icon: <XCircleIcon height={'11rem'} color='#991b1b' />,
			});
		}
	}, [successData, errorData]);

	useEffect(() => {
		// console.log(params);

		if (params?.verifytoken) dispatch(verifyUserEmail(params?.verifytoken));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (isUserLoading) return <Loader open={isUserLoading} />;

	return (
		<SectionPadding overRideStyles={{height: '90vh', display: 'grid', placeItems: 'center'}}>
			<Container>
				<Grid
					container
					justifyContent={'center'}
					alignItems='center'
					sx={{display: 'grid', placeItems: 'center'}}
				>
					<Grid item xs={12} textAlign='center'>
						{status?.icon}
					</Grid>
					<Grid item xs={12} textAlign='center'>
						<Typography sx={{fontSize: '1.6rem'}}>{status?.primaryTxt}</Typography>
						<Typography sx={{fontSize: '1.1rem'}}>{status?.secondaryTxt}</Typography>
					</Grid>
				</Grid>
			</Container>
		</SectionPadding>
	);
};

export default VerifyEmail;
