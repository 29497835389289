import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import reportService from "./reportService";
import reports from "../../shared/mocks/reports.json";

const initialState = {
	isReportLoading: false,
	isReportSuccess: false,
	isReportError: false,
	reportData: null || reports.data,
	reportMesage: "",
};

export const getReport = createAsyncThunk("get/report", async (data, thunkAPI) => {
	try {
		return await reportService.getReport(data);
	} catch (error) {
		const message = error?.response?.data?.error || (error && error.message) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const getDemoReport = createAsyncThunk("get/report/demo", async (_, thunkAPI) => {
	try {
		const data = reports.data;
		return data;
	} catch (error) {
		const message = "Couldn't Process the demo report";

		return thunkAPI.rejectWithValue(message);
	}
});

const reportSlice = createSlice({
	initialState,
	name: "report",
	reducers: {
		resetReport: (state) => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(getReport.pending, (state) => {
				state.isReportLoading = true;
			})
			.addCase(getReport.fulfilled, (state, action) => {
				state.isReportLoading = false;
				state.isReportSuccess = true;
				state.isReportError = false;
				state.reportData = action.payload.data;
			})
			.addCase(getReport.rejected, (state, action) => {
				state.isReportLoading = false;
				state.isReportError = true;
				state.isReportSuccess = false;
				state.reportMesage = action.payload;
			})
			.addCase(getDemoReport.pending, (state) => {
				state.isReportLoading = true;
			})
			.addCase(getDemoReport.fulfilled, (state, action) => {
				state.isReportLoading = false;
				state.isReportSuccess = true;
				state.isReportError = false;
				state.reportData = action.payload;
			})
			.addCase(getDemoReport.rejected, (state, action) => {
				state.isReportLoading = false;
				state.isReportError = true;
				state.isReportSuccess = false;
				state.reportMesage = action.payload;
			});
	},
});

export const resetReport = reportSlice.actions;
export default reportSlice.reducer;
