import React, {useEffect, useState} from "react";
import { Grid} from "@mui/material";
import MobileOverallHealthResult from "../components/Reports/OverallHealth/MobileOverallHealthResult";

const MobileReportsData = ({reports}) => {
	
	return (
		<>
			<Grid container>
				<MobileOverallHealthResult />
			</Grid>
		</>
	);
};

export default MobileReportsData;
