import React from "react";
import { Container, Grid, Typography, useTheme } from "@mui/material";
import SectionPadding from "../../Common/SectionPadding";
import ActionButton from "../../Common/Buttons/ActionButton";
import ResponsiveImage from "../../Common/ResponsiveImage";
import { useNavigate } from "react-router-dom";
import image_constants from "../../../shared/utils/images_constantsV2";

const AnalysisWork = ({ testContent }) => {
	const theme = useTheme();
	const navigate = useNavigate();
	const itemsSX = {
		//box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
		boxShadow: `rgba(0, 0, 0, 0.1) -4px 9px 25px -6px`,
		//backdropFilter: "blur(10px) saturate(100%) contrast(45%) brightness(130%)",
		borderRadius: 2,
		p: 4,
		backgroundColor: "rgba(255,255,255,0.7)",
	};
	return (
		<SectionPadding>
			<Container>
				<Grid container direction={{ md: "row" }} sx={itemsSX}>
					<Grid item xs={12} md={6} textAlign={{ xs: "center", md: "left" }} mt={{ xs: 2, md: 0 }}>
						<ResponsiveImage
							alt="who-can-take-the-test"
							img={testContent?.imageurl}
							// overRideStyles={{borderTopLeftRadius: '50px'}}
							width={500}
						/>
					</Grid>
					<Grid item xs={12} md={6} my="auto" textAlign={{ xs: "center", md: "right" }}>
						<Typography
							variant="h3"
							sx={{
								textTransform: "capitalize",
								fontSize: { xs: "1.2rem", md: "1.8rem" },
								fontWeight: 400,
								color: theme.palette.secondary.main,
							}}
							dangerouslySetInnerHTML={{
								__html: `${testContent?.title}`
							}}
						>
							{/* {testContent?.title} */}
						</Typography>
						<Typography
							variant="body2"
							color={theme.palette.common.greyDark}
							sx={{
								fontSize: { xs: "0.825rem", md: "1rem" },
								mt: 2,
								fontWeight: 600,
							}}
							dangerouslySetInnerHTML={{
								__html: `${testContent?.heading}`
							}}
						>
							{/* {testContent?.heading} */}
						</Typography>
						<Typography
							variant="body2"
							color={theme.palette.common.greyDark}
							sx={{ fontSize: { xs: "0.725rem", md: "1rem" }, mt: 2 }}
							dangerouslySetInnerHTML={{
								__html: `${testContent?.content}`
							}}
						>
							{/* {testContent?.content} */}
						</Typography>
						<ActionButton onClick={() => navigate("/Instructions")} styles={{ mt: 2 }}>
							Know More
						</ActionButton>
					</Grid>
				</Grid>
			</Container>
		</SectionPadding>
	);
};

export default AnalysisWork;
