import React, { useEffect, useState } from "react";
import { Container, Grid, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { ArrowRightIcon } from "@heroicons/react/solid";
import { SwiperSlide } from "swiper/react";
import useBreakpoints from "../../../hooks/useBreakpoints";
import SectionPadding from "../../Common/SectionPadding";
import Carousel from "../../Common/Carousel";
import { PsysicianWithPatient } from "../../../assets";
import Article from "../../Blogs/Common/Article";
import BlogGridTemplate from "../../Blogs/Common/BlogGridTemplate";
import ActionButton from "../../Common/Buttons/ActionButton";
import data from "../../Blogs/data.json";
import axios from "../../../axios-instance";

const TrendingBlogs = () => {
	const [blogs, setBlogsData] = useState([]);
	const [loader, setLoader] = useState(false);

	useEffect(() => {
		getAllBlogs();
	}, []);


	const getAllBlogs = () => {
		setLoader(true);
		axios.get("/api/blogs")
			.then((res) => {
				console.log('res', res?.data?.blog);
				if (res) {
					setBlogsData(res?.data?.blog);
					setLoader(false);
				}
			})
			.catch((error) => console.log(error));
		setLoader(false);
	};
	const theme = useTheme();
	const { isMediumScreen } = useBreakpoints();
	return (
		<SectionPadding>
			<Container>
				<Grid container sx={{ pb: { md: "6rem" } }}>
					<Grid item xs={12} textAlign={"center"}>
						<Typography
							variant="body2"
							color={theme.palette.common.greyDark}
							sx={{ fontSize: { xs: "0.775rem" }, mt: 2 }}>
							YOU MAY ALSO CHECK
						</Typography>
						<Typography
							variant="h3"
							sx={{
								textTransform: "capitalize",
								fontSize: { xs: "1.2rem", sm: "1.5rem", md: "2rem" },
								fontWeight: 400,
								color: theme.palette.secondary.main,
							}}>
							Trending Blogs
						</Typography>
					</Grid>
					<Grid item xs={12} mt={{ xs: 2, md: 4 }}>
						<BlogGridTemplate />
						{/* {isMediumScreen && <BlogGridTemplate />} */}
						{/* {!isMediumScreen && (
							<Carousel
								showArr={true}
								slidesPerView={1}
								spaceBetween={15}
								breakpoints={{
									600: {
										slidesPerView: 2,
										pagination: {
											clickable: true,
											dynamicBullets: true,
										},
									},
								}}
								pagination={false}>

								{blogs?.map((data, i) => (
									<SwiperSlide key={i}>
										<Article
											showBlogButton={false}
											title={data?.title}
											description={data?.description}
											img={data?.imageurl}
										/>
									</SwiperSlide>
								))}
							</Carousel>
						)} */}
					</Grid>
					<Grid item xs={12} textAlign={"center"} mt={{ xs: 2, md: 4 }}>
						<ActionButton component={Link} to="/categoryblogs" endIcon={<ArrowRightIcon height={"20px"} />}>
							View More Blogs
						</ActionButton>
					</Grid>
				</Grid>
			</Container>
		</SectionPadding>
	);
};

export default TrendingBlogs;
