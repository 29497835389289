import React from "react";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import useBreakpoints from "../../hooks/useBreakpoints";

const TitleWithBackBtn = ({ title, subTitle }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isMediumScreen, isSmallScreen, isXSmallScreen } = useBreakpoints();

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton onClick={() => navigate(-1)}>
          <ChevronLeftIcon height={isMediumScreen ? "50px" : "30px"} color={theme.palette.secondary.main} />
        </IconButton>
        <Box
          sx={{ borderLeft: subTitle && `4px solid ${theme.palette.grey[500]}`, pl: subTitle && { xs: 2, sm: 2.5 } }}
        >
          <Typography
            variant="h4"
            sx={{
              fontSize: { xs: "1.2rem", sm: "1.3rem", md: "1.8rem" },
              fontWeight: 700,
              color: theme.palette.secondary.main,
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <span>{title}</span>
          </Typography>
          {subTitle && (
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: { xs: "0.85rem", sm: "1rem", md: "1.2rem" },
                fontWeight: 700,
                color: theme.palette.grey[500],
                display: "flex",
                alignItems: "center",
                gap: 1,
                // textTransform: "capitalize",
              }}
            >
              {subTitle}
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export default TitleWithBackBtn;
