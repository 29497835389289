import React from "react";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Rating,
  Typography,
  useTheme,
} from "@mui/material";
import { DotsVerticalIcon, TrashIcon } from "@heroicons/react/outline";
import { useDispatch } from "react-redux";
import { deleteReview } from "../../features/reviews/reviewSlice";

const ReviewCard = ({ review, rating = 4, reviewId, username }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDelete = () => {
    const data = {
      id: reviewId,
    };

    dispatch(deleteReview(data));
  };

  //box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

  return (
    <Grid
      item
      container
      xs={12}
      rowGap={1}
      mb={2}
      sx={{
        px: { xs: 3, sm: 3, lg: 4 },
        py: 4,
        boxShadow: "3px 3px 20px rgba(0, 0, 0, 0.07)",
        borderRadius: 4,
        // height: "100%",
      }}
    >
      <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Avatar sx={{ width: 50, height: 50 }} />
          <Box>
            <Typography
              sx={{
                textTransform: "capitalize",
                fontSize: { xs: "0.725rem", md: "1rem" },
                fontWeight: 600,
              }}
            >
              {username}
            </Typography>
            <Rating value={rating} readOnly size="small" />
          </Box>
        </Box>
        {/* <Box>
          <IconButton onClick={(event) => handleClick(event)}>
            <DotsVerticalIcon height={"18px"} />
          </IconButton>
          <Actions anchorEl={anchorEl} open={open} handleClose={handleClose} deleteHandle={onDelete} />
        </Box> */}
      </Grid>
      <Grid item xs={12} px={1}>
        <Typography
          sx={{
            textTransform: "capitalize",
            fontSize: { xs: "0.725rem", md: "1rem" },
            color: theme.palette.common.greyDark,
          }}
        >
          {review}
        </Typography>
      </Grid>
    </Grid>
  );
};

const Actions = ({ anchorEl, open, handleClose, deleteHandle }) => {
  // const theme = useTheme();
  const list = [
    // {
    // 	name: 'Edit',
    // 	icon: <PencilIcon height={'16px'} />,
    // 	clicked: () => {
    // 		console.log('editing');
    // 	},
    // },
    {
      name: "Delete",
      icon: <TrashIcon height={"16px"} />,
      clicked: () => deleteHandle(),
    },
  ];
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      {list.map((item, index) => (
        <MenuItem
          key={index}
          onClick={() => {
            item.clicked();
            handleClose();
          }}
        >
          <ListItemIcon>{item.icon}</ListItemIcon>
          {item.name}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default ReviewCard;
