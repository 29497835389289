import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import paymentGatewayService from "./paymentGatewayService";

const initialState = {
  paymentgateway: null,
  currentPaymentGateway: null,
  isPaymentGatewayError: false,
  isPaymentGatewaySuccess: false,
  isPaymentGatewayLoading: false,
  paymentGatewayMessage: "",
};

export const getPaymentGateway = createAsyncThunk(
  "product/getById",
  async (data, thunkAPI) => {
    try {
      return await paymentGatewayService.getDefaultPayment(data);
    } catch (error) {
      const message = (error && error.message) || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const paymentGatewaySlice = createSlice({
  name: "paymentgateway",
  initialState,
  reducers: {
    resetPaymentGateway: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentGateway.pending, (state) => {
        state.isPaymentGatewayLoading = true;
      })
      .addCase(getPaymentGateway.fulfilled, (state, action) => {
        state.isPaymentGatewayLoading = false;
        state.isPaymentGatewaySuccess = true;
        state.currentPaymentGateway = action.payload;
      })
      .addCase(getPaymentGateway.rejected, (state, action) => {
        state.isPaymentGatewayLoading = false;
        state.isPaymentGatewayError = true;
        state.isPaymentGatewaySuccess = false;
        state.paymentGatewayMessage = action.payload;
      });
  },
});

export const { resetPaymentGateway } = paymentGatewaySlice.actions;
export default paymentGatewaySlice.reducer;
