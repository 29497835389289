import axios from "../../axios-instance";

const getAllOrganization = async () => {
  const response = await axios.get(`/api/organization/`);
  return response.data;
};

const getOrganization = async (data) => {
  const response = await axios.get(`/api/organization/${data.id}`);
  return response.data;
};

const OrganizationService = {
  getAllOrganization,
  getOrganization,
};

export default OrganizationService;
