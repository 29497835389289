import axios from "axios";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

// const backendUrl = 'http://40.81.235.35:5001/';

const instance = axios.create({
  // withCredentials: true,
  baseURL: `${backendUrl}`,
  header: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");

  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

export default instance;
