import React from "react";
import {
	Alert,
	FormControlLabel,
	Grid,
	InputAdornment,
	Switch,
	Typography,
	useTheme,
} from "@mui/material";
import TextInputField from "../../Common/Form/TextInputField";
import Dropdown from "../../Common/Form/Dropdown";
import {DesktopDatePicker, LocalizationProvider, MobileDatePicker} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import useBreakpoints from "../../../hooks/useBreakpoints";
import {useFormikContext} from "formik";
import {format} from "date-fns";

const ProfileForm = ({maxDate, minAge}) => {
	const {setFieldValue, values} = useFormikContext();
	const {isSmallScreen} = useBreakpoints();
	const theme = useTheme();

	// const [value, setValue] = React.useState(values.dob);

	const styles = {
		width: {xs: "100%"},
		mx: {xs: "auto"},
		// [`& fieldset`]: {
		// 	borderRadius: 3,
		// },
	};
	return (
		<Grid item container xs={12} columnSpacing={1}>
			{/* <Grid item xs={12}>
				<Switch
					checked={values.default}
					onChange={() => setFieldValue("default", !values.default)}
				/>
				<span>Set this person as default</span>
			</Grid> */}

			<Grid item xs={12} md={6}>
				<TextInputField
					name="firstName"
					label="First Name"
					type="text"
					margin="dense"
					size="small"
					styles={styles}
				/>
			</Grid>

			<Grid item xs={12} md={6}>
				<TextInputField
					name="lastName"
					label="Last Name"
					type="text"
					margin="dense"
					size="small"
					styles={styles}
				/>
			</Grid>

			<Grid item xs={12} md={6}>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					{!isSmallScreen && (
						<MobileDatePicker
							disableFuture
							label="DOB"
							value={values.dob}
							onChange={(newValue) =>
								setFieldValue("dob", format(new Date(newValue), "MM/dd/yyyy"), false)
							}
							renderInput={(params) => (
								<TextInputField name="dob" size="small" margin="dense" {...params} />
							)}
						/>
					)}
					{isSmallScreen && (
						<DesktopDatePicker
							disableFuture
							label="DOB"
							value={values.dob}
							onChange={(newValue) =>
								setFieldValue("dob", format(new Date(newValue), "MM/dd/yyyy"), false)
							}
							styles={{width: "auto"}}
							renderInput={(params) => (
								<TextInputField name="dob" size="small" margin="dense" {...params} />
							)}
							// maxDate={maxDate()}
						/>
					)}
				</LocalizationProvider>
				{/* <Alert sx={{fontSize: "0.8rem", py: 0, px: 1}} severity="info">
					Minimum age : {minAge} years
				</Alert> */}
			</Grid>

			<Grid item xs={12} my={2}>
				<Typography
					sx={{
						fontSize: {
							xs: "0.775rem",
							sm: "0.975rem",
							color: theme.palette.common.greyDark,
						},
					}}>
					In recent years, the science has shown that “GOOD HEALTH STARTS IN THE GUT”. We are a team
					of committed scientific professionals in understanding gut microbiome to positively impact
					your wellness. To serve you better, please take a moment to fill out THESE DETAILS which
					help us to provide precise report scores.
				</Typography>
			</Grid>
			<Grid item container xs={12} columnSpacing={1}>
				<Grid item xs={12} md={6}>
					<Dropdown
						name="gender"
						label="Gender"
						options={["Male", "Female"]}
						margin="dense"
						styles={styles}
						size="small"
					/>
				</Grid>
				<Grid item container xs={12} md={6} columnSpacing={1}>
					<Grid item xs={12} md={6}>
						<TextInputField
							name="weight"
							label="Weight"
							type="text"
							margin="dense"
							size="small"
							styles={styles}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end" sx={{cursor: "pointer"}}>
										<Typography sx={{color: theme.palette.common.greyLight}}>kg</Typography>
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextInputField
							name="height"
							label="Height"
							type="text"
							margin="dense"
							size="small"
							styles={styles}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end" sx={{cursor: "pointer"}}>
										<Typography sx={{color: theme.palette.common.greyLight}}>cm</Typography>
									</InputAdornment>
								),
							}}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default ProfileForm;
