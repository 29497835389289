import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import profileService from "./profileService";

const initialState = {
  persons: null,
  addresses: null,
  isProfileError: false,
  isProfileSuccess: false,
  isProfileLoading: false,
  profileMessage: "",
};

//...

export const getPersons = createAsyncThunk(
  "persons/getAll",
  async (_, thunkAPI) => {
    try {
      return await profileService.getAllPersons();
    } catch (error) {
      const message =
        error?.response?.data?.error ||
        (error && error.message) ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAddresses = createAsyncThunk(
  "address/getAll",
  async (_, thunkAPI) => {
    try {
      return await profileService.getAllAddress();
    } catch (error) {
      const message =
        error?.response?.data?.error ||
        (error && error.message) ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createPerson = createAsyncThunk(
  "person/create",
  async (data, thunkAPI) => {
    try {
      return await profileService.createPerson(data);
    } catch (error) {
      const message =
        error?.response?.data?.error ||
        (error && error.message) ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updatePerson = createAsyncThunk(
  "person/update",
  async (data, thunkAPI) => {
    try {
      return await profileService.updatePerson(data);
    } catch (error) {
      const message =
        error?.response?.data?.error ||
        (error && error.message) ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deletePerson = createAsyncThunk(
  "person/delete",
  async (data, thunkAPI) => {
    try {
      return await profileService.deletePerson(data);
    } catch (error) {
      const message =
        error?.response?.data?.error ||
        (error && error.message) ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createAddress = createAsyncThunk(
  "address/create",
  async (data, thunkAPI) => {
    try {
      return await profileService.createAddress(data);
    } catch (error) {
      const message =
        error?.response?.data?.error ||
        (error && error.message) ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateAddress = createAsyncThunk(
  "address/update",
  async (data, thunkAPI) => {
    try {
      return await profileService.updateAddress(data);
    } catch (error) {
      const message =
        error?.response?.data?.error ||
        (error && error.message) ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteAddress = createAsyncThunk(
  "address/delete",
  async (data, thunkAPI) => {
    try {
      return await profileService.deleteAddress(data);
    } catch (error) {
      const message =
        error?.response?.data?.error ||
        (error && error.message) ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateProfile = createAsyncThunk(
  "/profile/update",
  async (data, thunkAPI) => {
    try {
      return await profileService.updateProfile(data);
    } catch (error) {
      const message =
        error?.response?.data?.error ||
        (error && error.message) ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const personaldetails = createAsyncThunk(
  "/profile/personaldetails",
  async (data, thunkAPI) => {
    try {
      return await profileService.personaldetails(data);
    } catch (error) {
      const message =
        error?.response?.data?.error ||
        (error && error.message) ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const changepassword = createAsyncThunk(
  "/profile/changepassword",
  async (data, thunkAPI) => {
    try {
      return await profileService.changepassword(data);
    } catch (error) {
      const message =
        error?.response?.data?.error ||
        (error && error.message) ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    resetProfile: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPersons.pending, (state) => {
        state.isProfileLoading = true;
      })
      .addCase(getPersons.fulfilled, (state, action) => {
        state.isProfileLoading = false;
        state.isProfileSuccess = true;
        state.persons = action.payload.persons;
      })
      .addCase(getPersons.rejected, (state, action) => {
        state.isProfileLoading = false;
        state.isProfileError = true;
        state.isProfileSuccess = false;
        state.profileMessage = action.payload;
      })
      .addCase(getAddresses.pending, (state) => {
        state.isProfileLoading = true;
      })
      .addCase(getAddresses.fulfilled, (state, action) => {
        state.isProfileLoading = false;
        state.isProfileSuccess = true;
        state.addresses = action.payload.addresses;
      })
      .addCase(getAddresses.rejected, (state, action) => {
        state.isProfileLoading = false;
        state.isProfileError = true;
        state.isProfileSuccess = false;
        state.profileMessage = action.payload;
      })
      .addCase(createPerson.pending, (state) => {
        state.isProfileLoading = true;
      })
      .addCase(createPerson.fulfilled, (state, action) => {
        state.isProfileLoading = false;
        state.isProfileSuccess = true;
        state.persons = [...state?.persons, action.payload.person];
      })
      .addCase(createPerson.rejected, (state, action) => {
        state.isProfileLoading = false;
        state.isProfileError = true;
        state.isProfileSuccess = false;
        state.profileMessage = action.payload;
      })
      .addCase(updatePerson.pending, (state) => {
        state.isProfileLoading = true;
      })
      .addCase(updatePerson.fulfilled, (state, action) => {
        state.isProfileLoading = false;
        state.isProfileSuccess = true;
        state.persons = state?.persons.map((person) => {
          if (person?._id === action.payload.person?._id)
            return action.payload.person;
          return person;
        });
      })
      .addCase(updatePerson.rejected, (state, action) => {
        state.isProfileLoading = false;
        state.isProfileError = true;
        state.isProfileSuccess = false;
        state.profileMessage = action.payload;
      })
      .addCase(deletePerson.pending, (state) => {
        state.isProfileLoading = true;
      })
      .addCase(deletePerson.fulfilled, (state, action) => {
        state.isProfileLoading = false;
        state.isProfileSuccess = true;
        state.persons = state?.persons?.filter(
          (person) => person?._id !== action.payload.person?._id
        );
      })
      .addCase(deletePerson.rejected, (state, action) => {
        state.isProfileLoading = false;
        state.isProfileError = true;
        state.isProfileSuccess = false;
        state.profileMessage = action.payload;
      })
      .addCase(createAddress.pending, (state) => {
        state.isProfileLoading = true;
      })
      .addCase(createAddress.fulfilled, (state, action) => {
        state.addresses = [...state?.addresses, action.payload.address];
        state.isProfileSuccess = true;
        state.isProfileLoading = false;
      })
      .addCase(createAddress.rejected, (state, action) => {
        state.isProfileError = true;
        state.profileMessage = action.payload;
        state.isProfileSuccess = false;
        state.isProfileLoading = false;
      })
      .addCase(updateAddress.pending, (state) => {
        state.isProfileLoading = true;
      })
      .addCase(updateAddress.fulfilled, (state, action) => {
        state.isProfileLoading = false;
        state.isProfileSuccess = true;
        state.addresses = state?.addresses?.map((address) => {
          if (address?._id === action.payload.address?._id)
            return action.payload.address;
          return address;
        });
      })
      .addCase(updateAddress.rejected, (state, action) => {
        state.isProfileLoading = false;
        state.isProfileError = true;
        state.isProfileSuccess = false;
        state.profileMessage = action.payload;
      })
      .addCase(deleteAddress.pending, (state) => {
        state.isProfileLoading = true;
      })
      .addCase(deleteAddress.fulfilled, (state, action) => {
        state.isProfileLoading = false;
        state.isProfileSuccess = true;
        state.addresses = state?.addresses?.filter(
          (address) => address?._id !== action.payload.address?._id
        );
      })
      .addCase(deleteAddress.rejected, (state, action) => {
        state.isProfileLoading = false;
        state.isProfileError = true;
        state.isProfileSuccess = false;
        state.profileMessage = action.payload;
      })
      .addCase(updateProfile.pending, (state) => {
        state.isProfileLoading = true;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.isProfileLoading = false;
        state.isProfileSuccess = true;
        return state;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.isProfileLoading = false;
        state.isProfileError = true;
        state.isProfileSuccess = false;
        state.profileMessage = action.payload;
      });
  },
});

export const { resetProfile } = profileSlice.actions;
export default profileSlice.reducer;
