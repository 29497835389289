import React from 'react';
import {Box, Grid, Typography} from '@mui/material';
import {PlusIcon} from '@heroicons/react/outline';
import AddressView from './AddressView';
import AddEditAddress from './AddEditAddress';
import {useDispatch, useSelector} from 'react-redux';
import {getAddresses, resetProfile} from '../../../features/profile/profileSlice';
import {useEffect} from 'react';
import NoDataView from '../../Common/NoDataView';
import MinimalButton from '../../Common/Buttons/MinimalButton';
import useBreakpoints from '../../../hooks/useBreakpoints';

const newData = {
	_id: '',
	alias: '',
	line1: '',
	line2: '',
	phone: '',
	city: '',
	state: '',
	pincode: '',
};

const GicAddressList = () => {
	const {addresses} = useSelector((state) => state.profile);
	const dispatch = useDispatch();
	const [open, setOpen] = React.useState(false);
	const {isMediumScreen} = useBreakpoints();

	useEffect(() => {
		dispatch(getAddresses());

		return () => dispatch(resetProfile());
	}, [dispatch]);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<Grid item container xs={12}>
				{isMediumScreen && (
					<Grid
						container
						item
						xs={12}
						alignItems={'center'}
						sx={{
							backgroundColor: '#fff',
							boxShadow: '3px 3px 20px rgba(0, 0, 0, 0.07)',
							borderRadius: 3,
							p: 2,
						}}
					>
						<Grid item xs={9}>
							<Typography sx={{fontSize: '1.5rem', px: 2, fontWeight: 500, color: '#005249'}}>
								Addresses
							</Typography>
						</Grid>
						<Grid item xs={3}>
							<MinimalButton
								maxWidth={true}
								buttonText={'Add Address'}
								startIcon={<PlusIcon height={'20px'} />}
								onClick={handleClickOpen}
							/>
						</Grid>
					</Grid>
				)}

				<Grid item xs={12}>
					{addresses && addresses?.length !== 0 ? (
						<>
							{addresses?.map((address) => (
								<AddressView
									key={address?._id}
									addressId={address?._id}
									alias={address?.alias}
									address1={address?.line1}
									address2={address?.line2}
									phone={address?.phone}
									city={address?.city}
									state={address?.state}
									pincode={address?.pincode}
									addressData={address}
								/>
							))}
							{!isMediumScreen && (
								<Box>
									<MinimalButton
										maxWidth={true}
										buttonText={'Add Address'}
										startIcon={<PlusIcon height={'20px'} />}
										onClick={handleClickOpen}
									/>
								</Box>
							)}
						</>
					) : (
						<Grid item xs={12} mt={2}>
							<Box width={'100%'}>
								<NoDataView
									message={`No Addresses added yet!!`}
									btnClickHandler={handleClickOpen}
									btnText={'ADD ADDRESS'}
									showBtn={true}
								/>
							</Box>
						</Grid>
					)}
				</Grid>
			</Grid>
			<AddEditAddress addressData={newData} open={open} handleClose={handleClose} />
			{/* {!isMediumScreen && (
        <Fab sx={{ position: "fixed", right: 20, bottom: 20 }} size="large" color="secondary" aria-label="add">
          <AddIcon />
        </Fab>
      )} */}
		</>
	);
};

export default GicAddressList;
