import React from 'react';
import {Button, useTheme} from '@mui/material';
// import {styled} from '@mui/material/styles';

// const ButtonStyled = styled(Button)(({theme}) => ({
// 	'& .Mui-disabled': {
// 		// background: '#B5FE83',
// 		// background: `linear-gradient(to right bottom, ${theme.palette.primary.main} , ${theme.palette.secondary.main})`,
// 		opacity: 0.5,
// 		cursor: 'not-allowed',
// 	},
// }));

const ActionButton = ({children, color, styles, ...otherProps}) => {
	const theme = useTheme();
	return (
		// <span style={{ cursor: "not-allowed" }}>
		<Button
			sx={{
				//borderRadius: 1,
				//borderTopLeftRadius: 20,
				background: `linear-gradient(to right bottom, ${theme.palette.primary.main} , ${theme.palette.secondary.main})`,
				px: {xs: 2, sm: 2.5, md: 4},
				py: {xs: 1, sm: 1.5},
				color: '#fff',
				fontFamily: 'Lato, sans-serif',
				textTransform: 'capitalize',
				fontWeight: 400,
				fontSize: {xs: '0.8rem', md: '1rem'},
				letterSpacing: '1px',
				transition: '.3s ease-out',

				// '&:hover': {
				// 	transition: '.3s ease-out',
				// 	boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
				// },

				...styles,
			}}
			// disableRipple
			disableElevation
			{...otherProps}
		>
			{children}
		</Button>
		// </span>
	);
};

export default ActionButton;
