import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SectionPadding from "../components/Common/SectionPadding";
import { getAllContentManagements, resetContentManagement } from '../features/contentManagement/contentManagementSlice';


const PaymentSuccess = () => {
    const { footerContentManagements } = useSelector((state) => state.contentManagement);
    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        dispatch(getAllContentManagements());
        return () => dispatch(resetContentManagement());
    }, [dispatch]);

    const privacyPolicyData = footerContentManagements?.filter(item => item.type === 'privacy_policy');
    return (
        <SectionPadding
            overRideStyles={{
                paddingBottom: 0,
            }}
        >
            <Grid
                container
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ my: 10, px: 3 }}
            >
                <Grid
                    item
                    container
                    xs={12}
                    sm={6}
                    justifyContent={"center"}
                    alignItems={"center"}
                >

                    <Grid container>
                        <Grid container xs={12} mb={4}>
                            {privacyPolicyData?.map((data, i) => (
                                <Grid key={i} item xs={12}>
                                    <Typography variant={"h4"} mb={2}>
                                        {data?.name}
                                    </Typography>
                                    <Typography className="privacyPolicy" variant="body1"
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                `${data?.description}`
                                        }}
                                    ></Typography>
                                </Grid>
                            ))}
                            {/* {privacyPolicyData && (
                                <Grid item xs={12}>
                                    <Typography variant="body1"
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                `${data.description}`
                                        }}
                                    ></Typography>
                                </Grid>
                            )} */}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </SectionPadding>
    );
};

export default PaymentSuccess;
