import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import { Box, Button, Collapse, Grid, Typography } from '@mui/material';
import { defaultImage } from '../../../../assets';

const FoodCard = ({ url, name, anotherName, desc }) => {
	const [open, setOpen] = useState(false);
	console.log('url', url);
	return (
		<Grid
			item
			xs={12}
			sx={{
				boxShadow: '3px 3px 20px rgba(0, 0, 0, 0.07)',
				borderRadius: 3,
				// p: 2,
				cursor: 'pointer',
				// textAlign: 'center',
				overflow: 'hidden',
			}}
		>
			<Grid item xs={12}>
				<Box sx={{ width: '100%', height: '180px', maxHeight: '180px' }}>
					{url ? <img src={url} alt={name} style={{ width: '100%', height: '100%', objectFit: 'cover' }} /> : <img src={defaultImage} alt={name} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />}
				</Box>
			</Grid>

			<Grid item container xs={12} p={2}>
				<Grid item xs={12}>
					<Typography
						sx={{
							fontSize: { xs: '1rem', sm: '1.4rem' },
							fontWeight: 600,
							textTransform: 'upper',
						}}
					>
						{name}
						{anotherName && <small style={{
							fontSize: '13px',
							fontWeight: 600,
						}}> ({anotherName})</small>}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Collapse in={open} collapsedSize={50}>
						<Typography
							sx={{
								fontSize: { xs: '1rem', sm: '1rem' },
								fontWeight: 400,
								// overflow: 'hidden',
								// textOverflow: 'ellipsis',
								// display: '-webkit-box',
								// WebkitLineClamp: '2',
								// WebkitBoxOrient: 'vertical',
							}}
						>
							{desc}
						</Typography>
					</Collapse>
				</Grid>
				<Grid item xs={12} textAlign='right' mt={2}>
					<Button
						sx={{ textTransform: 'capitalize', fontSize: '0.725rem' }}
						endIcon={open ? <ChevronUpIcon height={'16px'} /> : <ChevronDownIcon height={'16px'} />}
						onClick={() => setOpen(!open)}
					>
						{open ? 'View Less' : 'View More'}
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default FoodCard;

/*

<Grid
	item
	xs={12}
	sx={{
		boxShadow: '3px 3px 20px rgba(0, 0, 0, 0.07)',
		borderRadius: 3,
		p: 2,
		cursor: 'pointer',
		textAlign: 'center',
	}}
>
	<Box sx={{width: '100%', height: '180px', maxHeight: '180px'}}>
		<img
			src={url}
			alt={name}
			style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: 3}}
		/>
	</Box>

	<Typography
		sx={{fontSize: {xs: '1rem', sm: '1.4rem'}, fontWeight: 600, textTransform: 'upper', p: 2}}
	>
		{name}
	</Typography>
</Grid>

*/
