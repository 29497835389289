import React from "react";
import { Box, Grid, Typography, Link, Divider, useTheme } from "@mui/material";
import { Link as LinkR } from "react-router-dom";

import AuthLayout from "./AuthLayout";
import useBreakpoints from "../../hooks/useBreakpoints";
import ResponsiveImage from "../Common/ResponsiveImage";
import EmailLoginForm from "./EmailLoginForm";
import { LoginImage } from "../../assets";

const EmailLogin = () => {
  const { isMediumScreen } = useBreakpoints();
  const theme = useTheme();

  // if (currentUser !== null && currentUser?._id !== null) return <Navigate replace to="/" />;

  return (
    <AuthLayout
      title={"Join us to learn more about gut \n microbiome diversity"}
      // direction={'row-reverse'}
    >
      {isMediumScreen && (
        <Grid item xs={12} md={6} sx={{ overflow: "hidden" }} my="auto">
          <ResponsiveImage img={LoginImage} alt="login-image" width="500px" />
        </Grid>
      )}
      <Grid item xs={12} md={6} my="auto" textAlign={"center"}>
        <Box sx={{ mb: "4rem" }}>
          <Typography
            variant="h5"
            sx={{
              //textTransform: 'capitalize',
              fontSize: { xs: "0.8rem", sm: "1.2rem", md: "1.4rem" },
              fontWeight: 400,
              background: `linear-gradient(to right bottom, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
              backgroundClip: "text",
              textFillColor: "transparent",
            }}
          >
            Please login using your E-Mail credentials
          </Typography>
          <Divider variant={"middle"} sx={{ width: { xs: "90%", md: "40%" }, mx: "auto", my: 2 }} />
        </Box>

        <EmailLoginForm />
        <Box>
          <Typography>
            No account? Let's{" "}
            <Link component={LinkR} to="/auth/register">
              Sign Up!
            </Link>
          </Typography>
          <Divider variant="middle" sx={{ width: { xs: "90%", md: "60%" }, mx: "auto", my: 2 }}>
            OR
          </Divider>
          <Typography>
            Sign in with{" "}
            <Link component={LinkR} to="/auth/mobilelogin">
              Mobile number
            </Link>
          </Typography>
        </Box>
      </Grid>
    </AuthLayout>
  );
};

export default EmailLogin;
