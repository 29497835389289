import React from 'react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { format } from 'date-fns'
import { ArrowNarrowRightIcon, CalendarIcon, HeartIcon } from '@heroicons/react/outline';

const BlogPostCard = ({
	handleNavigation,
	img,
	blogTitle,
	blogDescription,
	postDate,
	category,
	likeCount = 63,
}) => {
	const theme = useTheme();
	console.log('img', img);
	return (
		<Box
			sx={{
				boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
				borderRadius: 1,
				borderTopLeftRadius: 60,
				overflow: 'hidden',
				position: 'relative'
				// transition: '.2s ease-out',
				// '&:hover': {
				// 	transform: 'scale(0.975)',
				// },
			}}
		>
			<Box onClick={handleNavigation}>
				<img
					src={img}
					alt='product'
					style={{
						maxHeight: '200px',
						width: '100%',
						objectFit: 'cover',
						cursor: 'pointer',
					}}
				/>
			</Box>
			<Box sx={{ p: { xs: 1, sm: 2 } }}>
				<Box sx={{ mb: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
					<Typography
						sx={{ display: 'flex', alignItems: 'center', gap: 0.5, fontSize: '0.725rem' }}
						color={theme.palette.common.greyDark}
					>
						<CalendarIcon height={'18px'} color={theme.palette.common.greyDark} />
						<span>{format(new Date(postDate), 'dd MMMM, yyyy')}</span>
					</Typography>
					{/* <Typography>|</Typography> */}
					{/* <Typography
						sx={{display: 'flex', alignItems: 'center', gap: 0.5, fontSize: '0.725rem'}}
						color={theme.palette.common.greyDark}
					>
						<HeartIcon height={'18px'} color={theme.palette.common.greyDark} />
						<span>{category}</span>
					</Typography> */}
				</Box>
				<Box
					sx={{
						width: '70px',
						height: '2px',
						background: `linear-gradient(to right bottom, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
						mb: 0.5,
					}}
				/>
				<Typography
					sx={{
						color: theme.palette.secondary.main,
						fontSize: { xs: '0.975rem', sm: '1.2rem' },
						fontWeight: 600,
						cursor: 'pointer',
					}}
					onClick={handleNavigation}
					dangerouslySetInnerHTML={{
						__html:
							`${blogTitle}`
					}}
				>
					{/* {blogTitle} */}
				</Typography>
				<Typography
					sx={{
						fontSize: {
							xs: '0.725rem',
							md: '0.925rem',
						},
						color: theme.palette.common.greyDark,
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						display: '-webkit-box',
						WebkitLineClamp: '2',
						WebkitBoxOrient: 'vertical',
						mt: 1,
					}}
					dangerouslySetInnerHTML={{
						__html:
							`${blogDescription}`
					}}
				>
				</Typography>
			</Box>
			<Box textAlign='center' px={{ xs: 1, sm: 2 }}>
				<Button
					endIcon={<ArrowNarrowRightIcon height={'18px'} />}
					sx={{ textTransform: 'capitalize', my: 2 }}
					onClick={handleNavigation}
				>
					Read full blog
				</Button>
			</Box>
		</Box>
	);
};

export default BlogPostCard;
