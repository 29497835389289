import { Close, AlternateEmail, Phone, Place } from "@mui/icons-material";
import {
    Alert,
    Avatar,
    Box,
    Collapse,
    Grid,
    IconButton,
    InputAdornment,
    Stack, Button, Typography, TextField, Container
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import axios from "../../axios-instance";
import ActionButton from "../../components/Common/Buttons/ActionButton";
import TextInputField from "../../components/Common/Form/TextInputField";
import SectionPadding from "../../components/Common/SectionPadding";
import ScrollToTop from "../../components/hoc/ScrollToTop";
import HalfBannerPage from "../../PageTemplates/HalfBannerPage";
import image_constants from "../../shared/utils/images_constantsV2";
// import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { NavLink, Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

// import { Button, Typography, TextField, Container } from '@material-ui';


const CareerApply = () => {
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState(null);
    const [formData, setFormData] = useState({
        userName: '',
        email: '',
        mobileNumber: '',
        description: ''
    });

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };
    const [responseMessage, setResponseMessage] = useState({
        isError: false,
        info: "",
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleFormSubmit = async (event) => {
        event.preventDefault();
        console.log('selectedFile', selectedFile);
        if (selectedFile && formData.userName && formData.email && formData.mobileNumber && formData.description) {
            const form = new FormData();
            form.append('resumeurl', selectedFile);
            form.append('userName', formData.userName);
            form.append('email', formData.email);
            form.append('mobileNumber', formData.mobileNumber);
            form.append('description', formData.description);

            try {
                // Make a POST request to your backend API endpoint
                // const response = await axios.post("/api/subscribers", data);
                const response = await axios.post('/api/careers/apply', form, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                // Handle the response from the server if needed
                console.log('response', response);
                console.log('File uploaded successfully:', response.data);
                if (response.data.success === true) {
                    Swal.fire({
                        position: "top-middle",
                        icon: "success",
                        text: "Applied successfully!",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    navigate("/");
                } else {
                    console.log('bbbb');
                    Swal.fire({
                        position: "top-middle",
                        icon: "error",
                        text: "Applied Failed",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                };
            } catch (error) {
                // Handle errors
                console.error('Error uploading file:', error);
            }
        } else {
            // Handle case where any field is missing
            console.error('All fields are required.');
        }
    };


    return (
        <>
            <ScrollToTop />
            <SectionPadding className="dhanabal">
                <Grid container>
                    <Grid item xs={12} style={{ paddingTop: '50px' }}>
                        <Typography variant="h5" sx={{ fontWeight: 700, textAlign: 'center' }}>
                            Apply Here
                        </Typography>
                    </Grid>
                </Grid>
                <Container maxWidth="sm">
                    <form onSubmit={handleFormSubmit}>
                        <TextField
                            label="First Name"
                            name="userName"
                            value={formData.userName}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />

                        <TextField
                            label="Email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            type="number"
                            label="Mobile Number"
                            name="mobileNumber"
                            value={formData.mobileNumber}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Message"
                            name="description"
                            value={formData.description}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                            rows={4}
                            multiline
                        />
                        <Button
                            style={{ color: '#fff' }}
                            component="label"
                            variant="contained"
                            startIcon={<CloudUploadIcon />}
                        >
                            Upload Resume
                            <input
                                type="file"
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                        </Button>
                        <Typography variant="body1" style={{ marginTop: '20px' }}>
                            Selected File: {selectedFile ? selectedFile.name : 'None'}
                        </Typography>
                        <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
                            Submit
                        </Button>
                    </form>
                </Container>
            </SectionPadding>
        </>
    );
};

export default CareerApply;
