import { Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import { SwiperSlide } from "swiper/react";
import RadialGradient from "../../ChartsData/RadialGradient";
import Carousel from "../../Common/Carousel";
import BacteriaTile from "./BacteriasTile";
import parse from 'html-react-parser';


const TestResultData = ({ title, reportDescription, resultDescription, bacterias, status, score, tableData, additionalContent }) => {
	const theme = useTheme();

	var groupBy = function (arr, propertyName) {
		return arr?.reduce(function (acc, item) {
			const key = item[propertyName];
			if (!acc[key]) acc[key] = [];
			acc[key]?.push(item);
			return acc;
		}, {});
	};

	const renderSections = () => {
		//const bacteriaType = groupBy(bacterias, "type");
		const componentsArr = [];

		for (const [key, value] of Object.entries(bacterias[0])) {

			const component = (
				<React.Fragment key={key}>
					{value.length !== 0 && (
						<Grid item xs={12} mt={4}>
							{["good_bacteria", "bad_bacteria", "acetate_producers", "butyrate_producers", "propionate_producers"].includes(key) &&  
							(<Typography variant="caption" sx={{ textTransform: "uppercase", fontSize: "14px", fontWeight : "bold" }}>
								<b>{key.replaceAll("_", " ")}</b>
							</Typography>)}
							<Carousel
								slidesPerView={1}
								breakpoints={{
									600: {
										slidesPerView: 3,
									},
									900: {
										slidesPerView: 4,
									},
								}}
								spaceBetween={15}
								navigation={false}
								autoplay={{
									delay: 2500,
									disableOnInteraction: false,
									pauseOnMouseEnter: true,
								}}
								pagination={false}
								loop={false}
								rewind={true}
								className={"bacteria_tile"}>
								{value?.map((bac, index) => (
									<SwiperSlide key={bac?._id}>
										<BacteriaTile
											bacName={bac?.displayName}
											progress={bac?.score}
											status={bac?.scoreLevel?.displayName}
										/>
									</SwiperSlide>
								))}
							</Carousel>
						</Grid>
					)}
				</React.Fragment>
			);
			componentsArr.push(component);
		}

		return componentsArr;
	};

	return (
		<>
			<Grid item container xs={12} py={4}>
				<Grid item xs={12} md={4}>
					<RadialGradient key={score} status={status} score={score} title={title} />
				</Grid>
				<Grid item xs={12} md={8} my="auto" pl={{ md: 4 }} style={{textAlign: 'start', textJustify : 'inter-word'}} >
					<Typography>{parse(reportDescription)}</Typography>
				</Grid>
				
			</Grid>

			{Boolean(resultDescription) && (
				<Grid item container xs={12} sx={{marginBottom: "15px"}}>
					<Grid item xs={12} mb={1} >
						<Typography
							sx={{
								fontWeight: 600,
								fontSize: "1.2rem",
								textTransform: "uppercase",
								color: theme.palette.common.greyDark,
								
							}}>
							RESULT
						</Typography>
					</Grid>
					<Grid item xs={12} style={{textAlign: 'start', textJustify : 'inter-word'}}>
						<Typography sx={{ fontWeight: 400 }}>{resultDescription}</Typography>
					</Grid>
				</Grid>
			)}
			{additionalContent && <Grid item xs={12} md={12} my="auto"  style={{textAlign: 'start', textJustify : 'inter-word'}} >
					<Typography>{parse(additionalContent)}</Typography>
				</Grid>}
			{
				tableData && 
				<Grid item xs={12} my={4}><Typography>{parse(tableData)}</Typography></Grid>
			}
			{Boolean(bacterias) && Object.keys(bacterias).length !== 0 && (
				<Grid item xs={12} my={4}>
					{/* <Grid item xs={12} mb={1}>
						<Typography
							sx={{
								fontWeight: 600,
								fontSize: "1.2rem",
								textTransform: "uppercase",
								color: theme.palette.common.greyDark,
							}}>
							BACTERIA
						</Typography>
					</Grid> */}

					{renderSections()}
				</Grid>
			)}
		</>
	);
};

export default TestResultData;
