import React from "react";
import { Box, Container, Grid, Typography, useTheme } from "@mui/material";
import SectionPadding from "../../Common/SectionPadding";
import ResponsiveImage from "../../Common/ResponsiveImage";
import Loader from "../../../components/Common/Loader";

const WhoAreWe = ({ bgColor, secondContent }) => {
	const theme = useTheme();
	const textColorDark = theme.palette.common.greyDark;
	return (
		<>
			{!secondContent ? <Loader /> :
				<SectionPadding overRideStyles={{ paddingY: { xs: "2rem", sm: "4rem" }, backgroundColor: bgColor }}>
					<Container>
						<Grid container direction={{ xs: "column-reverse", md: "row" }}>
							<Grid item xs={12} md={6} textAlign={{ xs: "center", md: "left" }} mt={{ xs: 2, md: 0 }}>
								<ResponsiveImage
									img={secondContent?.imageurl}
									alt="who_are_we"
									width={500}
									overRideStyles={{
										borderRadius: "20px",
										boxShadow: `rgba(0, 0, 0, 0.1) -4px 9px 25px -6px`,
									}}
								/>
							</Grid>
							<Grid item container xs={12} md={6}>
								<Grid item xs={12} my="auto" justifyContent={"space-between"}>
									<Typography
										className="whoarewe"
										variant="h3"
										sx={{
											textTransform: "capitalize",
											fontSize: { xs: "1.2rem", md: "2rem" },
											fontWeight: 400,
											textAlign: { xs: "left", md: "right" },
										}}
										dangerouslySetInnerHTML={{
											__html: `${secondContent?.title}`
										}}
									>
										{/* {secondContent?.title} */}
									</Typography>
									<Box>
										<Typography
											variant="body2"
											color={textColorDark}
											sx={{
												fontSize: { xs: "0.725rem", md: "1.1rem" },
												mt: 2,
												textAlign: { xs: "justify", md: "right" },
											}}
											dangerouslySetInnerHTML={{
												__html: `${secondContent?.description}`
											}}
										>
											{/* {secondContent?.description} */}
										</Typography>
									</Box>
								</Grid>
							</Grid>
						</Grid>
					</Container>
				</SectionPadding>
			}
		</>
	);
};

export default WhoAreWe;
