import React from 'react';
import {Grid} from '@mui/material';
import AuthLayout from './AuthLayout';
import useBreakpoints from '../../hooks/useBreakpoints';
import ResponsiveImage from '../Common/ResponsiveImage';
import {RegisterImage} from '../../assets';
import RegisterForm from './RegisterForm';

const Register = () => {
	const {isMediumScreen} = useBreakpoints();

	// if (currentUser !== null && currentUser?._id !== null) return <Navigate replace to='/' />;

	return (
		<AuthLayout title={'Learn more about the gut functions of your gut by registering today'}>
			{isMediumScreen && (
				<Grid item xs={12} md={6} my='auto'>
					<ResponsiveImage
						img={RegisterImage}
						alt='register-image'
						// overRideStyles={{height: '600px'}}
						width='500px'
					/>
				</Grid>
			)}
			<Grid item xs={12} md={6} my={{xs: '4rem', md: 'auto'}}>
				<RegisterForm />
			</Grid>
		</AuthLayout>
	);
};

export default Register;
