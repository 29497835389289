import React from "react";
import {Box, Button, Grid, Stack, Typography, useTheme} from "@mui/material";

import MinimalButton from "../../Common/Buttons/MinimalButton";
import {Add} from "@mui/icons-material";

const Details = ({
	title,
	data,
	handleChangeDetails,
	handleAddDetails,
	btnTextForChange,
	btnTextForAdd,
	currentUser,
	isViewOnly,
}) => {
	const theme = useTheme();

	const checkProfile =
		Boolean(currentUser?.activeOrder?.person?._id) ||
		Boolean(currentUser?.activeOrder?.address?._id);

	return (
		<Grid item xs={12}>
			<Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
				<Typography
					sx={{
						color: theme.palette.grey[700],
						fontWeight: 700,
						fontSize: {xs: "1rem", sm: "1.2rem"},
					}}
				>
					{title}
				</Typography>
				{!isViewOnly && (
					<MinimalButton
						buttonText={btnTextForAdd}
						startIcon={<Add />}
						onClick={handleAddDetails}
					/>
				)}
			</Stack>

			<Box>{data}</Box>

			{/* <Box mt={4} display="flex" gap={1} justifyContent="center">
				<Button onClick={handleChangeDetails}>{btnTextForChange}</Button>
				<Button onClick={handleAddDetails}>{btnTextForAdd}</Button>
			</Box> */}
		</Grid>
	);
};

export default Details;
