import React from 'react';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Typography,
	Zoom,
	useTheme,
} from '@mui/material';
import useBreakpoints from '../../hooks/useBreakpoints';
// import {ExclamationIcon} from '@heroicons/react/outline';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom timeout={500} ref={ref} mountOnEnter unmountOnExit {...props} />;
});

const DialogModal = ({
	open,
	handleClose,
	buttonOne,
	buttonTwo = 'Save',
	title,
	content,
	children,
	btnSubmit,
	icon,
	handlePrimaryClick,
	handleButtonOneClick,
	...otherDialogProps
}) => {
	const theme = useTheme();
	const {isMediumScreen} = useBreakpoints();
	return (
		<Dialog
			fullScreen={!isMediumScreen}
			TransitionComponent={Transition}
			open={open}
			onClose={handleClose}
			aria-labelledby='responsive-dialog-title'
			maxWidth='md'
			keepMounted
			disableEnforceFocus
			{...otherDialogProps}
		>
			<DialogTitle id='responsive-dialog-title' mt={2} sx={{px: 4}}>
				<Typography
					sx={{
						// color: theme.palette.secondary.main,
						fontSize: {xs: '1.2rem', md: '1.4rem'},
						fontWeight: 700,
						// textTransform: 'capitalize',
						letterSpacing: 0.5,
						display: 'flex',
						alignItems: 'center',
						gap: 1,
						lineHeight: 1,
					}}
				>
					{icon && icon}
					<span>{title}</span>
				</Typography>
			</DialogTitle>
			<DialogContent>
				{content && <DialogContentText>{content}</DialogContentText>}
				{children && <Box p={{md: 2}}>{children}</Box>}
			</DialogContent>

			<DialogActions sx={{p: 2}}>
				<Button
					autoFocus
					onClick={handleButtonOneClick}
					sx={{color: theme.palette.error.dark, mr: 1}}
				>
					{buttonOne}
				</Button>
				<Button type={btnSubmit} onClick={handlePrimaryClick}>
					{buttonTwo}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DialogModal;
