import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import faqService from "./faqService";

const initialState = {
  faqs: null,
  currentFaq: null,
  isFaqError: false,
  isFaqSuccess: false,
  isFaqLoading: false,
  faqMessage: "",
};

export const getAllFaqs = createAsyncThunk(
  "faq/getall",
  async (_, thunkAPI) => {
    try {
      return await faqService.getFaqs();
    } catch (error) {
      const message = (error && error.message) || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getFaq = createAsyncThunk(
  "faq/getById",
  async (data, thunkAPI) => {
    try {
      return await faqService.getFaqById(data);
    } catch (error) {
      const message = (error && error.message) || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const faqSlice = createSlice({
  name: "faqs",
  initialState,
  reducers: {
    resetFaq: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllFaqs.pending, (state) => {
        state.isFaqLoading = true;
      })
      .addCase(getAllFaqs.fulfilled, (state, action) => {
        state.isFaqLoading = false;
        state.isFaqSuccess = true;
        state.faqs = action.payload;
      })
      .addCase(getAllFaqs.rejected, (state, action) => {
        state.isFaqLoading = false;
        state.isFaqError = true;
        state.isFaqSuccess = false;
        state.faqMessage = action.payload;
      })
      .addCase(getFaq.pending, (state) => {
        state.isFaqLoading = true;
      })
      .addCase(getFaq.fulfilled, (state, action) => {
        state.isFaqLoading = false;
        state.isFaqSuccess = true;
        state.currentFaq = action.payload;
      })
      .addCase(getFaq.rejected, (state, action) => {
        state.isFaqLoading = false;
        state.isFaqError = true;
        state.isFaqSuccess = false;
        state.faqMessage = action.payload;
      });
  },
});

export const { resetFaq } = faqSlice.actions;
export default faqSlice.reducer;
