import React from "react";
import {
	Box,
	Grid,
	IconButton,
	styled,
	Tooltip,
	tooltipClasses,
	Typography,
	useTheme,
	Zoom,
} from "@mui/material";
import {Link} from "react-router-dom";
import OverlapRadialProgress from "./OverlapRadialProgress";
import {ChevronRightIcon} from "@heroicons/react/outline";
import useBreakpoints from "../../../hooks/useBreakpoints";

const SubReportCard = ({report, progress, handleClick, to}) => {
	const theme = useTheme();
	const {isMediumScreen, isSmallScreen} = useBreakpoints();

	const subReportName = report?.healthSection?.anotherDisplayName ? report?.healthSection?.anotherDisplayName : report?.healthSection?.displayName;

	const toolTipInfo =
		report?.children?.length > 0 ? (
			<Box sx={{p: 1}}>
				{report?.children?.map((child, index) => (
					<Typography key={index}>- {child?.healthSection?.displayName}</Typography>
				))}
			</Box>
		) : (
			""
		);

	// const LightTooltip = styled(({className, ...props}) => (
	// 	<Tooltip {...props} classes={{popper: className}} />
	// ))(({theme}) => ({
	// 	[`& .${tooltipClasses.tooltip}`]: {
	// 		backgroundColor: theme.palette.common.white,
	// 		color: "rgba(0, 0, 0, 0.87)",
	// 		boxShadow: "3px 3px 20px rgba(0, 0, 0, 0.07)",
	// 		borderRadius: 3,
	// 		fontSize: 15,
	// 	},
	// }));

	return (
		<Tooltip title={toolTipInfo} placement="top" TransitionComponent={Zoom} arrow>
			<Grid
				component={Link}
				item
				container
				xs={12}
				sx={{
					boxShadow: "3px 3px 20px rgba(0, 0, 0, 0.07)",
					borderRadius: 3,
					py: 2,
					px: {xs: 1, sm: 4},
					mx: "auto",
					background: "#fff",
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					// flexDirection: {xs: 'column', sm: 'row'},
					cursor: "pointer",
					flexWrap: "nowrap",
					textDecoration: "none",
					mt: 2,

					"&:hover": {
						transition: "0.2s ease-out",
						background: "rgb(99, 187, 108, 0.05)",
					},
				}}
				to={to}
				onClick={handleClick}>
				<Grid item>
					<Typography
						sx={{
							color: theme.palette.secondary.main,
							textTransform: "uppercase",
							fontWeight: 900,
							fontSize: {xs: "1.1rem", sm: "1.3rem", md: "1.6rem"},
							overflow: "hidden",
							textOverflow: "ellipsis",
							display: "-webkit-box",
							WebkitLineClamp: "1",
							WebkitBoxOrient: "vertical",
						}}>
						{subReportName}
					</Typography>
				</Grid>
				<Grid item sx={{display: "flex", alignItems: "center", gap: 4}}>
					<Box>
						<OverlapRadialProgress
							progress={progress}
							size={!isSmallScreen ? 60 : 40}
							progressColor={theme.palette.secondary.main}
							progressSize={{xs: "1.2rem", sm: "1.4rem"}}
							showInProgress={!isSmallScreen}
						/>
					</Box>
					{isMediumScreen && (
						<IconButton>
							<ChevronRightIcon height={"1.8rem"} color={theme.palette.common.greyDark} />
						</IconButton>
					)}
				</Grid>
			</Grid>
		</Tooltip>
	);
};

export default SubReportCard;
