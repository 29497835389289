import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import instance from "../../axios-instance";
import { getDate } from "../../shared/utils/ageCalc";
import { SaveIcon } from "@heroicons/react/outline";
import MinimalButton from "../Common/Buttons/MinimalButton";
import { CopyAll } from "@mui/icons-material";

const PromoCodeList = () => {
  const theme = useTheme();
  const [promocodes, setPromocodes] = useState();
  const [copyPromocode, setCopyPromocode] = useState(false)
  useEffect(() => {
    let mounted = true;
    instance
      .get("/api/promocodes")
      .then((res) => {
        if (mounted) {
          setPromocodes(res.data.data);
        }
      })
      .catch((error) => {
        console.log("error fetching promo codes ", error);
      });
    return () => (mounted = false);
  }, []);

  return (
    <Grid item xs={12} py={3} sx={{ display: "flex", flexDirection: "column" }}>
      <Box>
        <Typography
          sx={{
            color: theme.palette.grey[700],
            fontWeight: 700,
            fontSize: { xs: "1rem", sm: "1.2rem" },
          }}
        >
          PROMO CODES
        </Typography>
      </Box>
      {Boolean(promocodes) && promocodes.length > 0 && (
        <Box sx={{ py: 2 }}>
          {(Boolean(copyPromocode)) && (
            <Typography sx={{ fontSize: "0.8rem", fontStyle: "italic", color: "green", paddingLeft: "10px" }}>
              Promocode has been copied!
            </Typography>
          )}
          {promocodes.map((promo, index) => {
            return (
              <Box key={index} sx={{ width: "100%", backgroundColor: "#bbf7d0", color: "#166534", borderRadius: 2, p: 2, mb: 1 }}>
                <div style={{ display: "flex" }} >
                  <Typography sx={{ fontSize: "1.2rem", fontWeight: 900 }} style={{ width: '100%' }}>{promo?.code}</Typography>
                  <Button
                    maxWidth={true}
                    buttonText={''}
                    startIcon={<CopyAll height={'20px'} />}
                    onClick={() => {
                      navigator.clipboard.writeText(promo?.code); setCopyPromocode(true); setTimeout(() => {
                        setCopyPromocode(false)
                      }, 2000);
                    }}
                    title="Copy Text"
                    hideTextOnXs
                  />
                </div>
                <Typography sx={{ fontSize: "1rem", fontWeight: 400 }}>Valid till {getDate(promo?.endDate)}</Typography>

              </Box>
            );
          })}
        </Box>
      )}
    </Grid>
  );
};

export default PromoCodeList;
