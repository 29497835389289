import React from "react";
import { Grid, Typography } from "@mui/material";
import { getDate } from "../../shared/utils/ageCalc";

const KitInfo = ({ currentOrder }) => {
  const renderDate = (dateString) => {
    const date = getDate(dateString, true);

    return (
      <>
        <span style={{ fontWeight: 700 }}>
          {date.split("at")[0]}{" "}
          {/* <span style={{fontWeight: 700, color: '#979797'}}>{`(${date.split('at')[1]})`}</span> */}
        </span>
      </>
    );
  };
  return (
    <Grid item container xs={12}>
      <Grid item xs={12} md={6}>
        <Typography variant="caption" sx={{ textTransform: "uppercase" }}>
          kit id
        </Typography>
        <Typography sx={{ fontWeight: 700 }}>
          {currentOrder?.kit?.enteredId}
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography variant="caption" sx={{ textTransform: "uppercase" }}>
          sample collection date
        </Typography>
        <Typography>
          {renderDate(currentOrder?.kit?.sampleCollectionDate)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default KitInfo;
