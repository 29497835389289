import React, { useEffect, useState } from "react";
import { Container, Grid, Typography, useTheme, styled } from "@mui/material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import TitleBodySection from "../components/Common/TitleBodySection";
import HalfBannerPage from "../PageTemplates/HalfBannerPage";
import image_constants from "../shared/utils/images_constantsV2";
// import { faqs } from "../shared/mocks/faqs";
import ScrollToTop from "../components/hoc/ScrollToTop";
import { getFaq, getAllFaqs, resetFaq } from "../features/faqs/faqSlice";
import { useDispatch, useSelector } from "react-redux";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  // boxShadow: '3px 3px 20px rgba(0, 0, 0, 0.07)',
  border: "1px solid rgba(0, 0, 0, 0.07)",
  borderRadius: 8,
  overflow: "hidden",

  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255,1)`,
  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  border: "none",
}));

const Faqs = () => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllFaqs());
    return () => dispatch(resetFaq());
  }, [dispatch]);

  const { faqs } = useSelector((state) => state.faq);
  console.log("faqssss", faqs);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  //   const getGroupedFaqs = () => {
  //     return faqs?.reduce((acc, qna) => {
  //       (acc[qna["category"]] = acc[qna["category"]] || []).push(qna);
  //       return acc;
  //     }, {});
  //   };

  const groupedFaqs = () => {
    // const faqCategory = getGroupedFaqs(faqs, "category");
    const faqCategory = faqs;
    const faqsGrouped = [];

    if (Boolean(faqCategory)) {
      faqs?.faqData.map((faq) => {
        const component = (
          <Grid item xs={12} key={faq?.name} mb={2}>
            <Accordion
              expanded={expanded === `panel${faq?.name}`}
              onChange={handleChange(`panel${faq?.name}`)}
            >
              <AccordionSummary
                aria-controls={`panel${faq?.name}d-content`}
                id={`panel${faq?.name}d-header`}
              >
                <Typography
                  sx={{
                    color: theme.palette.secondary.main,
                    textTransform: "uppercase",
                    fontWeight: 900,
                    fontSize: { xs: "1.1rem", sm: "1.3rem" },
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "1",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {faq.name}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {faq?.faq?.map((qna) => (
                  <Grid item xs={12} key={qna.id}>
                    <TitleBodySection title={qna.question}>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontSize: "1.2rem" }}
                        dangerouslySetInnerHTML={{
                          __html: `${qna.answer}`,
                        }}
                      ></Typography>
                    </TitleBodySection>
                  </Grid>
                ))}
              </AccordionDetails>
            </Accordion>
          </Grid>
        );
        faqsGrouped.push(component);
      });
    }
    return faqsGrouped;
  };

  return (
    <HalfBannerPage
      overlayTitle={"FREQUENTLY ASKED QUESTIONS"}
      overlaySubTitle={
        "You might be able to find answers to most of your queries here."
      }
      bannerImg={image_constants.FAQ_HERO}
    >
      <ScrollToTop />

      <Container sx={{ py: 4 }}>
        <Grid container my={4}>
          {/* <TitleWithBackBtn title={"FAQs"} /> */}
          {/* <Grid item xs={12} sx={{mt: 4}}>
						{faqs.map(({question, answer, id}) => (
							<TitleBodySection title={question} key={id}>
								<Typography variant="subtitle1" sx={{fontSize: "1.2rem"}}>
									{" "}
									{answer}
								</Typography>
							</TitleBodySection>
						))}
					</Grid> */}
          {groupedFaqs()}
        </Grid>
      </Container>
    </HalfBannerPage>
  );
};

export default Faqs;
