import {useMediaQuery, useTheme} from '@mui/material';

const useBreakpoints = (customBreakpoint = '(min-width:600px)') => {
	const theme = useTheme();

	const isXSmallScreen = useMediaQuery(theme.breakpoints.up('xs'));

	const isSmallScreen = useMediaQuery(theme.breakpoints.up('sm'));

	const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));

	const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

	const isXlargeScreen = useMediaQuery(theme.breakpoints.up('xl'));

	// * IMPORTANT => customBreakpoint='(min-width:600px)' always pass string
	const matchScreenTo = useMediaQuery(customBreakpoint);

	return {
		isXSmallScreen,
		isSmallScreen,
		isMediumScreen,
		isLargeScreen,
		isXlargeScreen,
		matchScreenTo,
	};
};

export default useBreakpoints;
