import React from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	Typography,
	useTheme,
	Zoom,
	Box
} from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom timeout={500} ref={ref} mountOnEnter unmountOnExit {...props} />;
});

const ReportModal = ({person_name, open, handleClose}) => {
	const theme = useTheme();
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			TransitionComponent={Transition}
			keepMounted
			maxWidth={"md"}
			disableEnforceFocus>
			<DialogTitle id="alert-dialog-title">
				<Grid item xs={12}>
				<Typography style={{ textAlign: 'center', padding: '10px' }} variant="h5">
						Hi Ms/Mr <span style={{ fontWeight: 700, textTransform: "capitalize" }}>{person_name}</span>
					</Typography>
				</Grid>
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					<Grid item xs={12}>
						<Typography variant="body1">
							Welcome to GUT-iCARE, your health and wellness is based on the microbiome count that
							you have in your gut and the nutrition diet that you follow. GUT-iCARE provides you
							with a unique comprehensive digital report that gives you the finger print of your gut
							microbiome, your exact health status. It will guide you to tune and optimize your gut
							health by providing recommendations on nutritional diet and necessary lifestyle
							changes.
						</Typography>
					</Grid>
					<Grid xs={12} item textAlign="center" my={4}>
					<Grid xs={12} item textAlign="center" my={4}>
						<Box
							sx={{
								p: { xs: 2, md: 3 },
								background:
									"linear-gradient(90deg, rgba(60, 170, 72, 0.8) 26.53%, rgba(26, 87, 53, 0.8) 79.58%)",
								// backdropFilter: "blur(4px)",
								borderRadius: 1,
							}}
						>
							<Typography
								variant="subtitle1"
								sx={{ fontWeight: 700, color: "#fff", fontSize: "1.4rem" }}>
								“Regular healthy eating habit is key to improve gut microbiome composition”
							</Typography>
						</Box>

					</Grid>
					</Grid>
					<Grid item xs={12} mt={4}>
						<Typography variant="body1" sx={{fontWeight: 700, color: theme.palette.secondary.main}}>
							"gutXplore is a home testing kit that help you reach your own health and wellness
							goals, implementing healthy lifestyle changes. It is not a substitute for medical
							advice or treatment for specific medical conditions. Please consult your physician for
							specified medical conditions."
						</Typography>
					</Grid>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Close</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ReportModal;
