import React, { useEffect, lazy, useRef } from "react";
import { Fab, Toolbar } from "@mui/material";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import gicReports from "./shared/mocks/reports.json";

// Redux Slice
import { checkUser } from "./features/users/userSlice";

// HOC Components
// import ScrollToTop from "./components/hoc/ScrollToTop";

// Components
import Appbar from "./components/navigation/Appbar";
import Register from "./components/Auth/Register";
import EmailLogin from "./components/Auth/EmailLogin";
import { NotFound } from "./components/hoc/NotFound";

// Pages
// import LandingPage from "./pages/LandingPage";
import Auth from "./pages/Auth";
import Homepage from "./pages/Homepage";
import Loader from "./components/Common/Loader";
import AboutUs from "./pages/AboutUs";
import ProtectedRoute from "./components/navigation/Routes/ProtectedRoute";
import Report from "./pages/Reports";
import ReportData from "./pages/ReportsData";
import Products from "./pages/Products/Products";
import ProductById from "./pages/Products/ProductById";
import Checkout from "./pages/Checkout";
import Orders from "./pages/Orders/Orders";
import OrderById from "./pages/Orders/OrderById";
import PaymentSuccess from "./pages/PaymentSuccess";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Careers from "./features/careers/career";
import CareersApply from "./features/careers/careersApply";
import ActivateKit from "./pages/ActivateKit";
import Blogs from "./pages/Blogs/Blogs";
import BlogById from "./pages/Blogs/BlogById";
import CategoryBlogs from "./pages/Blogs/CategoryBlogs";
import Guides from "./pages/Guides";
import Faqs from "./pages/Faqs";
import Footer from "./components/Common/Footer/Footer";
import ComingSoon from "./components/Common/ComingSoon";
import ProfileV2 from "./pages/ProfileV2";
import GicAddressList from "./components/GicProfile/Address/GicAddressList";
import MembersList from "./components/GicProfile/Members/MembersList";
import Account from "./components/GicProfile/AccountSettings/Account";
import ResetPassword from "./components/Auth/ResetPassword";
import ForgotPassword from "./components/Auth/ForgotPassword";
import AccountStatus from "./components/Auth/AccountStatus";
import VerifyEmail from "./components/Auth/VerfiyEmail";
import Instructions from "./pages/Instructions";
import Login from "./components/Auth/Login";
import Loadable from "./components/Common/Loadable";
import { WhatsApp } from "@mui/icons-material";
import Contact from "./pages/Contact";
import MobileReportsData from "./pages/MobileReportData";
// import HalfBannerPage from './PageTemplates/HalfBannerPage';

const LandingPage = Loadable(lazy(() => import("./pages/LandingPage")));

function App() {
  const { isAuthLoading, token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();
  const scrollRef = useRef(null);
  const navigate = useNavigate();
  useEffect(() => {
    const handlePopState = () => {
      console.log('window.history.state', window.history.state);
      console.log('window.history.state', window.history.state.idx);
      // Check if the page is being navigated back
      if (window.history.state === null) {
        console.log('load');
        // Refresh the page
        window.location.reload();
      } else {
        window.location.reload();
        // navigate('/');
        console.log('load2');
        // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    };

    // Add an event listener for the popstate event
    window.addEventListener("popstate", handlePopState);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);
  let showFooter =
    location.pathname !== "/auth/mobilelogin" &&
    location.pathname !== "/auth/register" &&
    location.pathname !== "/auth/emaillogin" &&
    location.pathname !== "/mobile/reports/:reportId/*";

  let enableHeaderFooter =
    window.location.pathname.includes("/mobile/reports/");

  const isTransparent =
    location.pathname === "/" || location.pathname === "/home";

  // const isLoggedIn = Boolean(localStorage.getItem("token"));

  useEffect(() => {
    if (Boolean(token)) {
      dispatch(checkUser());
    }
    // eslint-disable-next-line
  }, [token]);

  const handleScrollToSection = () => {
    // scrollRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  if (isAuthLoading) return <Loader open={true} />;

  return (
    <>
      {!enableHeaderFooter && (
        <>
          <Toolbar
            sx={{
              height: 0,
              width: 0,
              background: "transparent",
              position: "absolute",
              top: 0,
            }}
            id={"back-to-top-anchor"}
          />
          <Appbar transparent={isTransparent} />
        </>
      )}

      {/* {successMessage && (
				<Alert sx={{mt: 13}} severity='info'>
					{successMessage}
				</Alert>
			)} */}

      <Routes>
        {/* <Route index element={isLoggedIn ? <Homepage /> : <LandingPage />} />
        <Route path="/home" element={isLoggedIn ? <Homepage /> : <LandingPage />} /> */}

        <Route path="/" element={<LandingPage />} />
        <Route path="/home" element={<Homepage />} />

        {/* Authentication */}

        <Route path="auth" element={<Auth />}>
          <Route path="register" element={<Register />} />
          <Route path="mobilelogin" element={<Login />} />
          <Route path="emaillogin" element={<EmailLogin />} />
          <Route path="resetpassword/:resettoken" element={<ResetPassword />} />
          <Route path="verifyemail/:verifytoken" element={<VerifyEmail />} />
          <Route path="accountstatus" element={<AccountStatus />} />
          <Route path="forgotpassword" element={<ForgotPassword />} />
        </Route>
        <Route path="mobile">
          <Route
            path={"reports/:reportId/*"}
            element={<MobileReportsData reports={gicReports?.reports} />}
          />
        </Route>
        <Route path="about" element={<AboutUs scrollRef={scrollRef} />} />
        {/* <Route path='about' element={<HalfBannerPage />} /> */}

        <Route path="products" element={<Products />} />
        <Route path="products/:productId" element={<ProductById />} />

        <Route path="/categoryblogs/:categoryblogId" element={<Blogs />} />
        <Route path="/blogs/:blogId" element={<BlogById />} />

        <Route path="/categoryblogs" element={<CategoryBlogs />} />

        <Route path="/benefits" element={<Guides />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/instructions" element={<Instructions />} />
        <Route path="/contactus" element={<Contact />} />

        <Route
          path={"reports/demo/*"}
          element={<Report reports={gicReports?.reports} />}
        />

        <Route
          path="profile"
          element={
            <ProtectedRoute>
              <ProfileV2 />
            </ProtectedRoute>
          }
        >
          <Route index element={<Account />} />
          <Route path="account" element={<Account />} />
          <Route path="addresses" element={<GicAddressList />} />
          <Route path="members" element={<MembersList />} />
        </Route>

        <Route
          path="checkout/:id"
          element={
            <ProtectedRoute>
              <Checkout />
            </ProtectedRoute>
          }
        />

        <Route
          path="orders"
          element={
            <ProtectedRoute>
              <Orders />
            </ProtectedRoute>
          }
        />
        <Route
          path="orders/:orderId"
          element={
            <ProtectedRoute>
              <OrderById />
            </ProtectedRoute>
          }
        />
        <Route
          path="privacypolicy"
          element={
            <PrivacyPolicy />
          }
        />
        <Route
          path="careers"
          element={
            // <ProtectedRoute>
            <Careers />
            // </ProtectedRoute>
          }
        />
        <Route
          path="careers/apply"
          element={
            // <ProtectedRoute>
            <CareersApply />
            // </ProtectedRoute>
          }
        />
        <Route
          path="paymentSuccess/:orderId"
          element={
            <ProtectedRoute>
              <PaymentSuccess />
            </ProtectedRoute>
          }
        />

        <Route
          path="activatekit/:orderId"
          element={
            <ProtectedRoute>
              <ActivateKit />
            </ProtectedRoute>
          }
        />

        <Route
          path={"reports/:reportId/*"}
          element={
            <ProtectedRoute>
              <ReportData reports={gicReports?.reports} />
            </ProtectedRoute>
          }
        />

        <Route path="*" element={<NotFound />} />
      </Routes>

      {showFooter && !enableHeaderFooter && (
        <Footer
          scrollRef={scrollRef}
          handleScrollToSection={handleScrollToSection}
        />
      )}
      {!enableHeaderFooter && (
        <Fab
          size={"medium"}
          sx={{
            background: "#fff",

            position: "fixed",
            bottom: 80,
            right: 15,
          }}
          onClick={() =>
            window.open(
              "https://api.whatsapp.com/send/?phone=919442259590&text&type=phone_number&app_absent=0"
            )
          }
        >
          <WhatsApp />
        </Fab>
      )}
    </>
  );
}

export default App;
