import React from 'react';
import {
	FormControlLabel,
	FormControl,
	Box,
	FormHelperText,
	Radio,
	RadioGroup,
	FormLabel,
} from '@mui/material';
import {useField, useFormikContext} from 'formik';

const AddressGrouped = ({name, label, options, labelText, ...otherProps}) => {
	const {setFieldValue} = useFormikContext();
	const [field, meta] = useField(name);

	const handleChange = (event) => {
		const {value} = event.currentTarget;
		setFieldValue(name, value);
	};

	const configRadioButton = {
		...field,
		onChange: handleChange,
	};

	const configFormControl = {};

	if (meta && meta.touched && meta.error) {
		configFormControl.error = true;
	}

	return (
		<FormControl error={meta && meta.touched && meta.error}>
			<FormLabel>{labelText}</FormLabel>
			<RadioGroup
				// value={`${options[0]?.first_name} ${options[0]?.last_name}`}
				{...configRadioButton}
			>
				{options?.map((item, index) => (
					<Box key={item?._id} mt={1}>
						<FormControlLabel
							value={item?._id}
							control={<Radio />}
							label={`${item?.line1} \n ${item?.line2}`}
						/>
					</Box>
				))}
				{meta.error && <FormHelperText error={true}>{meta.error}</FormHelperText>}
			</RadioGroup>
		</FormControl>
	);
};

export default AddressGrouped;
