import React from "react";
import {Fab, useTheme} from "@mui/material";
import ScrollTopButton from "../components/Common/ScrollTopButton";
import {ChevronUpIcon} from "@heroicons/react/solid";
import Benefits from "../components/static/Benefits/Benefits";
import ScrollToTop from "../components/hoc/ScrollToTop";

const Guides = (props) => {
	const theme = useTheme();
	return (
		<>
			<ScrollToTop />

			<Benefits {...props} />
			<ScrollTopButton>
				<Fab
					size={"medium"}
					aria-label="scroll back to top"
					sx={{
						background: theme.palette.primary.main,
						"&:hover": {
							background: {xs: theme.palette.primary.main, md: theme.palette.primary.light},
						},
					}}
				>
					<ChevronUpIcon style={{height: "1.2rem", color: "#fff"}} />
				</Fab>
			</ScrollTopButton>
		</>
	);
};

export default Guides;
