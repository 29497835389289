import React, { memo } from "react";
import { MenuItem, TextField, styled } from "@mui/material";
import { useField, useFormikContext } from "formik";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const TextFieldWrapper = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "#fff",
  },
});

const StateDropdown = ({
  name,
  options,
  styles,
  renderValue,
  ...otherProps
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (e) => {
    const { value } = e.target;
    setFieldValue(name, value);
  };

  const configSelect = {
    ...field,
    ...otherProps,
    select: true,
    variant: "outlined",
    onChange: handleChange,
    placeholder: "Gender",
  };

  if (meta && meta.touched && meta.error) {
    configSelect.error = true;
    configSelect.helperText = meta.error;
  }

  return (
    <TextFieldWrapper
      sx={styles}
      {...configSelect}
      SelectProps={{
        renderValue: renderValue,
        MenuProps: MenuProps,
      }}
    >
      {options.map((item) => (
        <MenuItem key={item?.code} value={item?.name}>
          {item?.name}
        </MenuItem>
      ))}
    </TextFieldWrapper>
  );
};

export default memo(StateDropdown);
