export function getAge(dateString) {
  try {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  } catch (error) {
    return "NA";
  }
}

export const getDate = (dateString, includeTime) => {
  //parse date
  const dateObj = Date.parse(dateString);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  if (includeTime) {
    options.hour = "numeric";
    options.minute = "numeric";
  }

  if (dateObj !== "NaN")
    return new Date(dateString).toLocaleDateString("en-us", options);

  return "Not Available";
};
