import React, { useEffect, useState } from "react";
import { Alert, Grid, IconButton, InputAdornment, Link, useTheme } from "@mui/material";
import { Link as LinkR, useNavigate } from "react-router-dom";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import { Formik, Form } from "formik";
import TextInputField from "../Common/Form/TextInputField";
import ActionButton from "../Common/Buttons/ActionButton";
import { emailLogin, resetAuth } from "../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Common/Loader";

const validate = (values, props /* only available when using withFormik */) => {
  const errors = {};

  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.password) {
    errors.password = "Required";
  } else if (values.password.length < 6) {
    errors.password = "Password should contain 6 or above charaters";
  }

  //...

  return errors;
};

const EmailLoginForm = () => {
  const { isAuthLoading, AuthSuccessData, AuthFailData } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [error, seterror] = useState(null);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const styles = {
    width: { xs: "100%", sm: "70%" },
    mx: { xs: "auto" },
    // [`& fieldset`]: {
    // 	borderRadius: 3,
    // },
  };

  useEffect(() => {
    if (Boolean(AuthSuccessData)) {
      if (AuthSuccessData?.actionStatusCode === "VERIFICATION_REQUIRED") {
        navigate("/auth/accountstatus");
      } else if (AuthSuccessData?.actionStatusCode === "LOGIN_SUCCESS") {
      }
    } else if (Boolean(AuthFailData)) {
      //   console.log(AuthFailData.error);
      seterror(AuthFailData);
    }

    return () => {};
  }, [AuthFailData]);

  if (isAuthLoading) return <Loader open={isAuthLoading}></Loader>;

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validate={(values) => validate(values)}
        onSubmit={(values) => {
          // LOGIC FOR VERIFYING OTP
          resetAuth();

          dispatch(emailLogin(values));
        }}
      >
        {({ handleSubmit, dirty, isValid, touched, handleReset }) => (
          <Form>
            {error && <Alert severity="error">{error}</Alert>}
            <Grid item container xs={12} columnSpacing={1}>
              <Grid item xs={12} textAlign={{ xs: "center" }}>
                <TextInputField
                  name="email"
                  label="Email Address"
                  type="email"
                  margin="dense"
                  size="small"
                  styles={styles}
                />
              </Grid>

              <Grid item xs={12} textAlign={{ xs: "center" }}>
                <TextInputField
                  name="password"
                  label="Password"
                  margin="dense"
                  size="small"
                  styles={styles}
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClickShowPassword}>
                          {showPassword ? <EyeIcon height={"16px"} /> : <EyeOffIcon height={"16px"} />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} mt={0.5} textAlign={{ xs: "left", sm: "center" }}>
                <Link
                  component={LinkR}
                  to="/auth/forgotpassword"
                  sx={{
                    color: theme.palette.common.greyLight,
                    textDecoration: "none",
                    transition: "0.2s ease-out",
                    "&:hover": {
                      color: theme.palette.common.greyDark,
                      transition: "0.2s ease-out",
                    },
                  }}
                >
                  Forgot Password
                </Link>
              </Grid>
              <Grid item xs={12} my={2} textAlign="center">
                <ActionButton
                  onClick={handleSubmit}
                  type="submit"
                  // disabled={!(isValid && dirty)}
                  // styles={{width: {xs: '100%', sm: '70%'}}}
                >
                  Sign In
                </ActionButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EmailLoginForm;
