import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import countryService from "./countryService";

const initialState = {
  countries: null,
  isCountryLoading: false,
  countryMessage: "",
};

export const getAllCountries = createAsyncThunk("country/getall", async (_, thunkAPI) => {
  try {
    return await countryService.getCountries();
  } catch (error) {
    const message = (error && error.message) || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});


const countrySlice = createSlice({
  name: "countries",
  initialState,
  reducers: {
    resetCountries: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCountries.pending, (state) => {
        state.isCountryLoading = true;
      })
      .addCase(getAllCountries.fulfilled, (state, action) => {
        console.log('countryaction', action.payload);
        state.isCountryLoading = false;
        state.countries = action.payload.contriesData;
      })
      .addCase(getAllCountries.rejected, (state, action) => {
        state.isCountryLoading = false;
        state.countryMessage = action.payload;
      })

  },
});

export const { resetCountries } = countrySlice.actions;
export default countrySlice.reducer;
