import React from "react";
import {Grid, Typography} from "@mui/material";

const Policies = ({data}) => {
	return (
		<Grid container>
			<Grid  container xs={12} mb={4}>
					{data.description && (
						<Grid item xs={12} className="contentManagement">
							<Typography variant="body1"
							dangerouslySetInnerHTML={{
								__html:
								  `${data.description}`
							  }}
							></Typography>
						</Grid>
					)}
				</Grid>
		</Grid>
	);
};

export default Policies;
