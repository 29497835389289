import React from "react";
import { motion } from "framer-motion";
import { Box, Typography } from "@mui/material";



const CardList = ({ data, open, position, designation }) => {
	return (
		<motion.div
			onClick={open}
			// whileHover={{ scale: 1.1 }}
			style={{
				// width: '20%',
				// minWidth: '300px',
				// maxWidth: '400px',
				cursor: "pointer",
				height: "100%",
			}}>
			{/* <Box sx={{ boxShadow: "3px 3px 20px rgba(0, 0, 0, 0.07)", borderRadius: 3, height: "100%" }}> */}
			<Box sx={{ border: "1px solid rgba(0, 0, 0, 0.07)", borderRadius: 3, height: "450px" }}>
				<Box sx={{ textAlign: "center" }}>
					<img
						alt={data.imageurl}
						src={data.imageurl}
						style={{
							width: "100%",
							height: "350px",
							objectFit: "cover",
							borderRadius: "10px",
							borderBottomLeftRadius: 0,
							borderBottomRightRadius: 0,
						}}
					/>
				</Box>
				<Box sx={{ px: 2, py: 1.5 }}>
					<Box textAlign="center">
						<Typography
							sx={{
								fontSize: { xs: "1rem", sm: "1.2rem" },
								fontWeight: 600,
								textTransform: "capitalize",
							}}
							dangerouslySetInnerHTML={{
								__html: `${data?.name}`
							}}
						>
							{/* {data.name} */}
						</Typography>
					</Box>
					<Box textAlign="center">
						<Typography
							sx={{
								fontSize: { xs: "1rem", sm: "1rem" },

							}}
							dangerouslySetInnerHTML={{
								__html: `${designation}`
							}}
						>
							{/* - {designation} - */}
						</Typography>
					</Box>
				</Box>
			</Box>
		</motion.div>
	);
};

export default CardList;
