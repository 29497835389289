import { Alert, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import ActionButton from "../components/Common/Buttons/ActionButton";
import SectionPadding from "../components/Common/SectionPadding";
import { getOrderById, resetOrders } from "../features/orders/orderSlice";
import { Check } from '../assets';
import Loader from "../components/Common/Loader";

const PaymentSuccess = () => {
    const params = useParams();
    const navigate = useNavigate();
    const { currentOrder, isOrderLoading } = useSelector((state) => state.order);
    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        dispatch(getOrderById({ id: params.orderId }));
        return () => dispatch(resetOrders());
    }, [dispatch, params.orderId]);

    const viewOrder = () => {
        navigate(`/orders/${currentOrder?._id}`);
    };

    if (isOrderLoading) return <Loader open={isOrderLoading}></Loader>;
    return (
        <SectionPadding
            overRideStyles={{
                paddingBottom: 0,
            }}
        >
            <Grid
                container
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ my: 10, px: 3 }}
            >
                <Grid
                    item
                    container
                    xs={12}
                    sm={6}
                    justifyContent={"center"}
                    alignItems={"center"}
                >

                    <Grid item xs={12}>
                        <div style={{ textAlign: "center" }}>
                            <img src={Check} width={"20%"} />
                        </div>
                        <div style={{ textAlign: "center" }}>
                            <Typography variant={"h4"} mt={1}>
                                Payment Successful.
                            </Typography>
                            <Typography variant={"h5"} mt={1}>
                                Hello <span style={{ fontWeight: 'bold', color: '#1A5735' }}>{currentOrder?.person?.firstName} {currentOrder?.person?.lastName}</span> Your payment was successfully processed.
                            </Typography>
                            {/* <Typography variant={"h6"} mt={1}>
                                Payment ID: <span style={{ fontWeight: 'bold', color: '#1A5735' }}>{currentOrder?.payment?.paymentId}</span>
                            </Typography> */}
                            <Typography variant={"h6"} mt={1}>
                                Thank you for your purchase!
                            </Typography>
                        </div>

                    </Grid>
                    <Grid item xs={12}>
                        <Grid item xs={12} my={2} textAlign="center">
                            <ActionButton
                                onClick={() => {
                                    viewOrder();
                                }}
                            >
                                View order
                            </ActionButton>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </SectionPadding>
    );
};

export default PaymentSuccess;
