import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Alert,
  AlertTitle,
  Container,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import SectionPadding from "../components/Common/SectionPadding";
import Details from "../components/Checkout/Common/Details";
import CheckoutSummary from "../components/Checkout/CheckoutSummary";
import PaymentSummary from "../components/Checkout/PaymentSummary";
import Loader from "../components/Common/Loader";
import AddEditProfile from "../components/GicProfile/Members/AddEditProfile";
import AddEditAddress from "../components/GicProfile/Address/AddEditAddress";
import MembersList from "../components/Checkout/MembersList";
import AddressesList from "../components/Checkout/AddressesList";
// import {getAge} from '../shared/utils/ageCalc';
import NoDataView from "../components/Common/NoDataView";
import MemberView from "../components/GicProfile/Members/MemberView";
import PoliciesModal from "../components/Common/TandC/PoliciesModal";
import MemberSelect from "../components/Checkout/Common/MemberSelect";
import AddressSelect from "../components/Checkout/Common/AddressSelect";
import { useEffect } from "react";
import ScrollToTop from "../components/hoc/ScrollToTop";

import { getOrderById } from "../features/orders/orderSlice";

const memberData = {
  id: "",
  firstName: "",
  lastName: "",
  dob: new Date(),
  weight: "",
  height: "",
  gender: "",
  // default: false,
};

const addressData = {
  _id: "",
  alias: "",
  line1: "",
  line2: "",
  phone: "",
  city: "",
  state: "",
  pincode: "",
};

const states = ["tamil nadu"];

const Checkout = () => {
  const theme = useTheme();
  const params = useParams();
  const dispatch = useDispatch();
  const { currentUser, isUserLoading } = useSelector((state) => state.user);
  const { currentOrder, isOrderLoading } = useSelector((state) => state.order);
  const navigate = useNavigate();

  const [openMembers, setOpenMembers] = useState(false);
  const [openAddress, setOpenAddress] = useState(false);

  const [changeMembers, setChangeMembers] = useState(false);
  const [changeAddress, setChangeAddress] = useState(false);

  const [openTandC, setOpenTandC] = useState(false);

  const [access, setAccess] = useState({
    isAccessible: false,
    message: "",
  });

  useEffect(() => {
    dispatch(getOrderById({ id: params.id }));
  }, [params]);

  console.log('currentOrder', currentOrder);

  useEffect(() => {
    if (
      currentOrder?.address?.state &&
      currentOrder?.person?.firstName
    ) {
      return setAccess({
        isAccessible: true,
        message: "Proceed with Payment",
      });
    } else {
      return setAccess({
        isAccessible: false,
        message: "Fill necessary data",
      });
    }
  }, [currentOrder?.address?.state]);
  // `We do not provide services for the selected states. Allowed states are  ${(<strong>{states.toString()}</strong>)}`
  // const age = getAge(currentUser?.activeOrder?.person?.dob);

  // if (isOrderLoading) return <Loader open={isOrderLoading} />;

  console.log('isOrderLoading',isOrderLoading);
  const PersonDetails = () => {
    return (
      <>
        <MemberSelect orderId={currentOrder._id} personId={currentOrder?.person?._id} />
        {Boolean(currentOrder?.person) ? (
          <MemberView person={currentOrder?.person} hideActions />
        ) : (
          <Alert severity="error">PLEASE ADD/SELECT A MEMBER</Alert>
        )}
      </>
    );
  };

  const AddressDetails = () => {
    return (
      <>
        <AddressSelect orderId={currentOrder._id} addressId={currentOrder?.address?._id} />
        {Boolean(currentOrder?.address) ? (
          <>
            <Typography variant="h6" sx={{ textTransform: "capitalize" }}>
              {currentOrder?.address?.alias}
            </Typography>
            <Typography>
              {currentOrder?.address?.line1},{" "}
              {currentOrder?.address?.line2},{" "}
              {currentOrder?.address?.state},{" "}
              {currentOrder?.address?.city},{" "}
              {currentOrder?.address?.pincode}
            </Typography>
          </>
        ) : (
          <Alert severity="error">PLEASE ADD/SELECT AN ADDRESS</Alert>
        )}
      </>
    );
  };
  return (
    <>
      <ScrollToTop />

      <SectionPadding overRideStyles={{ mt: "1rem" }}>
        <Container>
          <Grid container my={{ xs: "2rem", sm: 0 }}>
            <Grid item xs={12}>
              <Typography
                variant="h1"
                sx={{
                  fontSize: { xs: "1.4rem", sm: "1.6rem", md: "1.8rem" },
                  fontWeight: 700,
                  color: theme.palette.secondary.main,
                  textTransform: "capitalize",
                }}
              >
                Checkout |{" "}
                {Boolean(currentOrder?._id) && (
                  <span
                    style={{ color: theme.palette.grey[400], fontSize: "1rem" }}
                  >
                    #{currentOrder?._id}
                  </span>
                )}
              </Typography>
            </Grid>

            {!access.isAccessible && (
              <Grid item xs={12}>
                <Alert severity="error" sx={{ mt: 2 }}>
                  <AlertTitle>{access.message}</AlertTitle>
                </Alert>
              </Grid>
            )}

            {!Boolean(currentOrder?._id) && (
              <Grid item xs={12} sx={{ my: 8, py: 4 }}>
                <NoDataView
                  message={
                    "No ACTIVE ORDER Found for check out. Please place an order by clicking the below button."
                  }
                  btnClickHandler={() => navigate("/products")}
                  btnText={"PRODUCTS"}
                  showBtn
                />
              </Grid>
            )}

            {Boolean(currentOrder?._id) && (
              <Grid
                container
                item
                xs={12}
                mt={4}
                justifyContent={{ md: "space-between" }}
              >
                <Grid item container xs={12} md={7.8}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      boxShadow: "3px 3px 20px rgba(0, 0, 0, 0.07)",
                      borderRadius: 3,
                      p: { xs: 2, sm: 4 },
                    }}
                  >
                    <CheckoutSummary activeOrder={currentOrder} />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sx={{
                      boxShadow: "3px 3px 20px rgba(0, 0, 0, 0.07)",
                      borderRadius: 3,
                      p: { xs: 2, sm: 4 },
                      mt: 2,
                    }}
                  >
                    <Details
                      title={"PERSON DETAILS"}
                      data={<PersonDetails />}
                      handleAddDetails={() => {
                        setOpenMembers(true);
                      }}
                      handleChangeDetails={() => setChangeMembers(true)}
                      btnTextForAdd={"ADD PERSONS"}
                      btnTextForChange={"CHANGE PERSONS"}
                      currentUser={currentOrder && currentOrder}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sx={{
                      boxShadow: "3px 3px 20px rgba(0, 0, 0, 0.07)",
                      borderRadius: 3,
                      p: { xs: 2, sm: 4 },
                      mt: 2,
                    }}
                  >
                    <Details
                      title={"ADDRESS DETAILS"}
                      data={<AddressDetails />}
                      handleAddDetails={() => setOpenAddress(true)}
                      handleChangeDetails={() => setChangeAddress(true)}
                      btnTextForAdd={"ADD ADDRESS"}
                      btnTextForChange={"CHANGE ADDRESS"}
                      currentUser={currentOrder && currentOrder}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{
                    boxShadow: "3px 3px 20px rgba(0, 0, 0, 0.07)",
                    borderRadius: 3,
                    p: { xs: 4 },
                    mt: { xs: 2, md: 0 },
                  }}
                >
                  <PaymentSummary currentUser={currentOrder && currentOrder} access={access} />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Container>
      </SectionPadding>

      {/* ADD DETAILS */}
      <AddEditProfile
        memberData={memberData}
        open={openMembers}
        handleClose={() => setOpenMembers(false)}
      />
      <AddEditAddress
        addressData={addressData}
        open={openAddress}
        handleClose={() => setOpenAddress(false)}
      />

      {/* CHANGE DETAILS */}
      <MembersList
        currentUser={currentOrder}
        changeMembers={changeMembers}
        handleClose={() => setChangeMembers(false)}
      />
      <AddressesList
        currentUser={currentOrder}
        changeAddress={changeAddress}
        handleClose={() => setChangeAddress(false)}
      />
      <PoliciesModal open={openTandC} handleClose={() => setOpenTandC(false)} />
    </>
  );
};

export default Checkout;

/*
  boxShadow: '3px 3px 20px rgba(0, 0, 0, 0.07)',
  borderRadius: 3,
  p: {xs: 2, sm: 4},
*/
