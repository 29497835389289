import React, { useState, useEffect } from "react";
import { Box, Grid, IconButton, InputAdornment, Link, Typography, Select, MenuItem, FormControl, InputLabel, ListItemIcon, Avatar } from "@mui/material";
import { Link as LinkR, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import TextInputField from "../Common/Form/TextInputField";
import ActionButton from "../Common/Buttons/ActionButton";
// import VerifyOtp from './VerifyOtp';
// import CustomModal from '../Common/CustomModal';
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import CustomCheckBox from "../Common/Form/CustomCheckbox";
import { useDispatch, useSelector } from "react-redux";
import { register, resetAuth } from "../../features/auth/authSlice";
import { getAllOrganization } from "../../features/organization/organizationSlice";
import { getAllCountries } from "../../features/countries/countrySlice";
import { OpenInNew } from "@mui/icons-material";
import PoliciesModal from "../Common/TandC/PoliciesModal";
import Swal from 'sweetalert2'

const validate = (values, props /* only available when using withFormik */) => {
	const errors = {};

	if (!values.userName) {
		errors.userName = "Required";
	}
	if (!values.organization) {
		errors.organization = 'Please select an organization';
	}
	if (!values.countryCode) {
		errors.countryCode = 'Required';
	}
	// else if (!/^(?=[a-zA-Z0-9._]{5,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/i.test(values.userName)) {
	// 	errors.userName = "Please provide valid username";
	// }

	if (!values.email) {
		errors.email = "Required";
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
		errors.email = "Invalid email address";
	}

	if (!values.phone) {
		errors.phone = "Required";
	} 
	// else if (!/^\d{10}$/i.test(values.phone)) {
	// 	errors.phone = "Invalid Phone Number";
	// }

	if (!values.password) {
		errors.password = "Required";
	} else if (values.password.length < 6) {
		errors.password = "Password should contain 6 or above charaters";
	}

	if (!values.confirmPassword) {
		errors.confirmPassword = "Required";
	} else if (values.confirmPassword.length < 6) {
		errors.confirmPassword = "Password should contain 6 or above charaters";
	}

	if (values.password !== values.confirmPassword) {
		errors.confirmPassword = "Passwords do not match";
	}

	if (!values.tandc) {
		errors.tandc = "Please accept our terms and conditions";
	}

	//...

	return errors;
};

const RegisterForm = () => {
	// const [open, setOpen] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [openTandC, setOpenTandC] = useState(false);
	const [orgVal, setOrgVal] = useState("");
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [errorMsg, setErrorMsg] = useState(false);
	const handleClickShowPassword = () => setShowPassword(!showPassword);
	const { organization } = useSelector((state) => state.organization);
	const { countries } = useSelector((state) => state.countries);

	// const handleClose = () => {
	// 	setOpen(false);
	// };

	// const handleGetOtp = () => {
	// 	// LOGIC TO OPEN THE MODAL AND GET OTP VIA SMS
	// 	setOpen(true);
	// };

	useEffect(() => {
		dispatch(getAllOrganization());
		dispatch(getAllCountries());
	}, [dispatch]);

	const registerUser = async (values) => {
		resetAuth();
		const result = await dispatch(register(values));
		return result;
	};

	const styles = {
		width: { xs: "100%", sm: "100%" },
		mx: { xs: "auto" },
		// [`& fieldset`]: {
		// 	borderRadius: 3,
		// },
	};

	const handleChange = (event) => {
		setOrgVal(event.target.value);
	};
	return (
		<>
			<Formik
				initialValues={{
					userName: "",
					email: "",
					phone: "",
					password: "",
					confirmPassword: "",
					tandc: false,
					organization: '',
					countryCode: ""
				}}
				validate={(values) => validate(values)}
				onSubmit={async (values, { setSubmitting }) => {
					// LOGIC FOR VERIFYING OTP
					//handleGetOtp();
					console.log('values', values);
					setSubmitting(false);
					const result = await registerUser(values);
					setErrorMsg(false);
					navigate("/auth/accountstatus");

				}}>
				{({ handleSubmit, dirty, isValid, values }) => (
					<Form autoComplete="off">
						<Grid item container xs={12} columnSpacing={1}>
							<Grid item xs={12} textAlign={{ xs: "center", sm: "left" }}>
								<TextInputField
									name="userName"
									label="Full Name"
									type="text"
									margin="dense"
									size="small"
									styles={styles}
								/>
							</Grid>

							<Grid item xs={12} textAlign={{ xs: "center", sm: "left" }}>
								<TextInputField
									name="email"
									label="Email Address"
									type="email"
									margin="dense"
									size="small"
									styles={styles}
								/>
							</Grid>
							{/* <Grid item xs={12} sm={6} textAlign={{ xs: "center", sm: "left" }}>
								<TextInputField
									name="phone"
									label="Mobile Number"
									type="number"
									margin="dense"
									size="small"
									styles={styles}
									InputProps={{
										startAdornment: <InputAdornment position="start">+91 | </InputAdornment>,
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6} textAlign={{ xs: "center", sm: "left", }}>
								<FormControl sx={{ width: "100%", marginTop: '8px' }} size="small">
									<InputLabel id="organization">Organization</InputLabel>
									<Field
										as={Select}
										id="organization"
										name="organization"
										label="Organization"
									// onChange={handleChange}
									>
										{organization?.map((item) => (
											<MenuItem
												key={item?._id}
												value={item?._id}
											>
												{item?.name}
											</MenuItem>
										))}
									</Field>
								</FormControl>
								<ErrorMessage name="organization" component="div" style={{ color: '#f87171', fontSize: "0.75rem" }} className="error" />
							</Grid>  */}


							 <Grid item xs={12} sm={4} textAlign={{ xs: "center", sm: "left" }}>
								<FormControl sx={{ width: "100%", marginTop: '8px' }} size="small">
									<InputLabel id="countryCode">Code</InputLabel>
									<Field
										className="countryCodeSelect"
										as={Select}
										id="countryCode"
										name="countryCode"
										label="Code"
									// onChange={handleChange}
									>
										{countries?.map((item) => (
											<MenuItem
												key={item?.number}
												value={item?.number}
											>
												<div style={{ display: 'flex', alignItems: 'center', height: '20px', justifyContent: "space-evenly" }} className="dhanabal">
													<ListItemIcon>
														<Avatar alt="Remy Sharp" src={item?.image} />
													</ListItemIcon>
													{item?.number}
												</div>
												  {/* {item?.name}  
												 <div>
													<Avatar alt="Remy Sharp" src={item?.image} />
												</div>  */}
											</MenuItem>
										))}
									</Field>
								</FormControl>
								<ErrorMessage name="countryCode" component="div" style={{ color: '#f87171', fontSize: "0.75rem" }} className="error" />
							</Grid> 
							<Grid item xs={12} sm={8} textAlign={{ xs: "center", sm: "left", }}>
								<TextInputField
									name="phone"
									label="Mobile Number"
									type="number"
									margin="dense"
									size="small"
									styles={styles}
								// InputProps={{
								// 	startAdornment: <InputAdornment position="start">+91 | </InputAdornment>,
								// }}
								/>
							</Grid>

							<Grid item xs={12} sm={12} textAlign={{ xs: "center", sm: "left" }}>
								<FormControl sx={{ width: "100%", marginTop: '8px' }} size="small">
									<InputLabel id="organization">Organization</InputLabel>
									<Field
										as={Select}
										id="organization"
										name="organization"
										label="Organization"
									// onChange={handleChange}
									>
										{organization?.map((item) => (
											<MenuItem
												key={item?._id}
												value={item?._id}
											>
												{item?.name}
											</MenuItem>
										))}
									</Field>
								</FormControl>
								<ErrorMessage name="organization" component="div" style={{ color: '#f87171', fontSize: "0.75rem" }} className="error" />
							</Grid>

							<Grid item xs={12} sm={6} textAlign={{ xs: "center", sm: "left" }}>
								<TextInputField
									name="password"
									label="Password"
									margin="dense"
									size="small"
									styles={styles}
									type={showPassword ? "text" : "password"}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton onClick={handleClickShowPassword}>
													{showPassword ? (
														<EyeIcon height={"16px"} />
													) : (
														<EyeOffIcon height={"16px"} />
													)}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6} textAlign={{ xs: "center", sm: "left" }}>
								<TextInputField
									name="confirmPassword"
									label="Confirm Password"
									type="password"
									margin="dense"
									size="small"
									styles={styles}
								/>
							</Grid>
							<Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
								<CustomCheckBox
									name="tandc"
									// checked={values.tandc}
									label="I've read and accept the Terms and Privacy policy."
								/>
								<IconButton onClick={() => setOpenTandC(true)}>
									<OpenInNew />
								</IconButton>
							</Grid>
							{errorMsg && (<Grid xs={12} style={{ color: "red", color: "white", background: "#ff7041" }} textAlign="center">
								<Typography>Please enter valid data</Typography>
							</Grid>)}

							<Grid item xs={12} my={2} textAlign="center">
								<ActionButton
									onClick={handleSubmit}
									type="submit"
									// disabled={!(isValid && dirty)}
									styles={{ width: { xs: "100%", sm: "70%" } }}>
									Register Now
								</ActionButton>
							</Grid>
							<Grid item xs={12} my={2} textAlign="center">
								<Box>
									<Typography>
										Already have an account?{" "}
										<Link component={LinkR} to="/auth/mobilelogin">
											Sign In!
										</Link>
									</Typography>
								</Box>
							</Grid>
						</Grid>
						<PoliciesModal open={openTandC} handleClose={() => setOpenTandC(false)} />
					</Form>
				)}
			</Formik>
			{/* <Formik
				initialValues={{ selectField: '' }}
				validate={(values) => {
					const errors = {};
					if (!values.selectField) {
						errors.selectField = 'Please select an option';
					}
					return errors;
				}}
				onSubmit={(values) => {
					// Handle form submission here
					console.log(values);
				}}
			>
				{() => (
					<Form>
						<div>
							<FormControl>
								<InputLabel>Select an option:</InputLabel>
								<Field
									as={Select}
									id="selectField"
									name="selectField"
									label="Select an option"
								>
									<MenuItem value="" disabled>
										Select an option
									</MenuItem>
									<MenuItem value="option1">Option 1</MenuItem>
									<MenuItem value="option2">Option 2</MenuItem>
									<MenuItem value="option3">Option 3</MenuItem>
								</Field>
							</FormControl>
							<ErrorMessage name="selectField" component="div" className="error" />
						</div>

						<button type="submit">Submit</button>
					</Form>
				)}
			</Formik> */}
			{/* <CustomModal open={open} title={'Register'}>
				<VerifyOtp handleClose={handleClose} />
			</CustomModal> */}
		</>
	);
};

export default RegisterForm;
