import React, { useState } from "react";
import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { PaymentRounded } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { razorpay } from "../../shared/utils/razorpay";
import Loader from "../Common/Loader";
import { useNavigate } from "react-router-dom";
import { getDate } from "../../shared/utils/ageCalc";

const PaymentDetails = ({ order }) => {
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <>
      <Grid item xs={12} sx={{ height: "100%", maxHeight: "100%" }}>
        <Typography
          sx={{
            color: theme.palette.grey[700],
            fontWeight: 700,
            fontSize: { xs: "1rem", sm: "1.2rem" },
          }}
        >
          PAYMENT DETAILS
        </Typography>
        <Grid
          item
          container
          xs={12}
          direction="row"
          justifyContent={{ md: "space-between" }}
          sx={{
            mt: 2,
            // height: "92%",
            maxHeight: "92%",
          }}
        >
          <Grid item xs={12}>
            {order?.payment?.paymentStatus != "pending" &&
              <PricingData
                title={"Transaction ID"}
                value={order?.payment?.paymentId}
                type={1}
              />
            }
            <PricingData
              title={"Payment Status"}
              value={order?.payment?.paymentStatus?.toUpperCase()}
              type={1}
            />
            <PricingData
              title={"Payment Date"}
              value={getDate(order?.payment?.updatedAt)}
              type={1}
            />
            {/* <PricingData title={"Shipping"} value={order?.shipmentPrice} type={1} />
            <PricingData
              title={"Total"}
              value={`INR ${order?.product?.price + order?.product?.tax}/-`}
              color={theme.palette.grey[300]}
              type={1}
            /> */}
          </Grid>
        </Grid>
      </Grid>
      <Loader open={isLoading} />
    </>
  );
};
const PricingData = ({ title, value, color, type }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "left",
        justifyContent: "space-between",
        flexDirection: type === 1 ? "column" : "row",
        p: 1,
        mt: 0.5,
        background: color,
      }}
    >
      {type === 1 ? (
        <Typography sx={{ fontSize: "0.7rem", color: "grey" }}>
          {title}
        </Typography>
      ) : (
        <Typography>{title}</Typography>
      )}
      <Typography sx={{ fontSize: "1.1rem", color: "grey" }}>
        {value}
      </Typography>
    </Box>
  );
};
export default PaymentDetails;
