import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import TextInputField from "../Common/Form/TextInputField";
import { Alert, Grid, IconButton, InputAdornment, Stack, Typography, Link } from "@mui/material";
import ActionButton from "../Common/Buttons/ActionButton";
import VerifyOtp from "./VerifyOtp";
import CustomModal from "../Common/CustomModal";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/solid";
import { emailLogin, mobileLogin, resetAuth } from "../../features/auth/authSlice";
import { Link as LinkR, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const validate = (values, props /* only available when using withFormik */) => {
	const errors = {};

	if (!values.phone) {
		errors.phone = "Required";
	} 
	// else if (!/^\d{10}$/i.test(values.phone)) {
	// 	errors.phone = "Invalid Phone Number";
	// }

	if (!values.password) {
		errors.password = "Required";
	} else if (values.password.length < 6) {
		errors.password = "Password should contain 6 or above charaters";
	}

	return errors;
};

const LoginForm = () => {
	const [open, setOpen] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { isAuthLoading, AuthSuccessData, AuthFailData } = useSelector((state) => state.auth);
	const [error, seterror] = useState(null);

	useEffect(() => {
		if (Boolean(AuthSuccessData)) {
			if (AuthSuccessData?.actionStatusCode === "VERIFICATION_REQUIRED") {
				//navigate("/auth/accountstatus");
			} else if (AuthSuccessData?.actionStatusCode === "LOGIN_SUCCESS") {
			}
		} else if (Boolean(AuthFailData)) {
			//   console.log(AuthFailData.error);
			seterror(AuthFailData);
		}

		return () => { };
	}, [AuthFailData]);

	const handleClickShowPassword = () => setShowPassword(!showPassword);

	const handleClose = () => {
		setOpen(false);
	};

	const handleGetOtp = () => {
		// LOGIC TO OPEN THE MODAL AND GET OTP VIA SMS
		setOpen(true);
	};

	const styles = {
		width: { xs: "100%", sm: "60%" },
		mx: { xs: "auto" },
	};

	return (
		<>
			<Formik
				initialValues={{ phone: "", password: "" }}
				validate={(values) => validate(values)}
				onSubmit={(values) => {
					// LOGIC FOR VERIFYING OTP
					dispatch(resetAuth());

					dispatch(mobileLogin(values));
					//   navigate("/auth/accountstatus");
				}}>
				{({ handleSubmit, isValid, dirty, values }) => (
					<Form>
						{error && <Alert severity="error">{error}</Alert>}
						<Grid item container xs={12}>
							<Grid item xs={12} textAlign={{ xs: "center" }}>
								<TextInputField
									name="phone"
									label="Mobile Number"
									type={"number"}
									margin="dense"
									size="small"
									styles={styles}
									// InputProps={{
									// 	startAdornment: <InputAdornment position="start">+91 | </InputAdornment>,
									// }}
								/>
							</Grid>
							<Grid item xs={12} textAlign={{ xs: "center" }}>
								<TextInputField
									name="password"
									label="Password"
									margin="dense"
									size="small"
									styles={styles}
									type={showPassword ? "text" : "password"}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton onClick={handleClickShowPassword}>
													{showPassword ? (
														<EyeIcon height={"16px"} />
													) : (
														<EyeOffIcon height={"16px"} />
													)}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</Grid>
							<Grid item xs={12} my={2} textAlign="center">
							<Typography>
								<Link component={LinkR}  to="/auth/forgotpassword" >
									Forgot password?
								</Link>
								</Typography>
							</Grid>
							<Grid item xs={12} my={2} textAlign="center">
								<ActionButton onClick={handleSubmit} type="submit" disabled={!(isValid && dirty)}>
									Login
								</ActionButton>
							</Grid>
						</Grid>
					</Form>
				)}
			</Formik>

			<CustomModal open={open} title={"Login"}>
				<VerifyOtp handleClose={handleClose} />
			</CustomModal>
		</>
	);
};

export default LoginForm;
