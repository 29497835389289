import React, { useState } from "react";
import { DotsVerticalIcon } from "@heroicons/react/outline";
import { Box, Grid, IconButton, Typography, useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import DeleteModal from "../../Common/DeleteModal";
import { deleteAddress } from "../../../features/profile/profileSlice";
import AddEditAddress from "./AddEditAddress";
import { Actions } from "./Actions";

const AddressView = ({
  alias,
  city,
  state,
  pincode,
  phone,
  address1,
  address2,
  addressId,
  addressData,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [deleted, setDeleted] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openAction = Boolean(anchorEl);

  //Action
  const handleClickAction = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAction = () => {
    setAnchorEl(null);
  };

  //Modal
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onDelete = () => {
    dispatch(deleteAddress({ id: addressId }));
    setDeleted(false);
  };

  return (
    <>
      <Grid
        item
        xs={12}
        // mt={2}
        p={4}
        my={2}
        sx={{
          boxShadow: "3px 3px 20px rgba(0, 0, 0, 0.07)",
          borderRadius: 3,
          // borderLeft: `6px solid ${theme.palette.primary.light}`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItem: "center",
            justifyContent: "space-between",
            mb: 1,
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "1rem", sm: "1.2rem", md: "1.2rem" },
              fontWeight: 700,
              color: theme.palette.secondary.main,
              textTransform: "capitalize",
            }}
          >
            {alias}
          </Typography>
          <Box>
            <IconButton onClick={(event) => handleClickAction(event)}>
              <DotsVerticalIcon height={"18px"} />
            </IconButton>
            <Actions
              anchorEl={anchorEl}
              open={openAction}
              handleClose={handleCloseAction}
              handleClickOpen={handleClickOpen}
              setDeleted={setDeleted}
            />
          </Box>
        </Box>

        <Typography
          sx={{
            fontSize: { xs: "0.9rem", sm: "1.1rem" },
            color: theme.palette.common.greyDark,
            mb: 1,
          }}
        >
          {address1}, {address2}, {city}, {state} {pincode}
        </Typography>

        <Typography
          sx={{
            fontSize: { xs: "0.9rem", sm: "1.1rem" },
            fontWeight: 500,
            color: theme.palette.common.greyDark,
            mt: 0.5,
          }}
        >
          +91 {phone}
        </Typography>
      </Grid>
      <AddEditAddress
        addressData={addressData}
        open={open}
        handleClose={handleClose}
      />

      <DeleteModal
        deleteText={
          "Do you really want to delete this address? This process cannot be undone."
        }
        handlePrimaryClick={onDelete}
        open={deleted}
        handleClose={() => setDeleted(false)}
      />
    </>
  );
};

export default AddressView;
