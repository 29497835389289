import React from "react";
import { Container, Grid, Typography, useTheme } from "@mui/material";
import SectionPadding from "../../Common/SectionPadding";
import ResponsiveImage from "../../Common/ResponsiveImage";

import Loader from "../../../components/Common/Loader";
import '../../../index.css'

const OurMission = ({ firstContent }) => {
	const theme = useTheme();
	// const colorMain = theme.palette.secondary.main;
	const textColorDark = theme.palette.common.greyDark;
	console.log('firstContent', firstContent);
	return (
		<>
			{!firstContent ? <Loader /> :
				<SectionPadding overRideStyles={{ paddingY: { xs: "2rem", sm: "4rem" } }}>
					<Container>
						<Grid container>
							<Grid item container xs={12} md={6}>
								<Grid item xs={12} my="auto" justifyContent={"space-between"}>
									<Typography
										className="mission"
										variant="h3"
										sx={{
											textTransform: "capitalize",
											fontSize: { xs: "1.2rem", md: "2rem" },
											fontWeight: 400,
										}}
										dangerouslySetInnerHTML={{
											__html: `${firstContent?.title}`
										}}
									>
										{/* {firstContent?.title} */}
									</Typography>
									<Typography
										variant="body2"
										color={textColorDark}
										sx={{ fontSize: { xs: "0.725rem", md: "1.1rem" }, mt: 2 }}
										// sx={{ fontSize: { xs: "0.725rem", md: "1.1rem" }, mt: 2, textAlign: isXSmallScreen ? 'justify' : 'left' }}
										dangerouslySetInnerHTML={{
											__html: `${firstContent?.description}`
										}}
									>
										{/* {firstContent?.description} */}
									</Typography>
								</Grid>
							</Grid>
							<Grid item xs={12} md={6} textAlign={{ xs: "center", md: "right" }} mt={{ xs: 2, md: 0 }}>
								<ResponsiveImage
									alt="our_mission"
									img={firstContent?.imageurl}
									overRideStyles={{
										borderRadius: "20px",
										filter: "brightness(75%)",
										boxShadow: `rgba(0, 0, 0, 0.1) -4px 9px 25px -6px`,
									}}
									width={500}
								/>
							</Grid>
						</Grid>
					</Container>
				</SectionPadding>
			}

		</>
	);
};

export default OurMission;
