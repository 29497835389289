import React, {useEffect, useRef, useState} from 'react';
import {Box, useTheme} from '@mui/material';
import {Swiper} from 'swiper/react';
import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/react/solid';

//Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import {
	Pagination,
	Navigation,
	Autoplay,
	A11y,
	Controller,
	Scrollbar,
	EffectCreative,
} from 'swiper';

const Carousel = ({children, showArr, ...otherProps}) => {
	const theme = useTheme();
	const [swiper, setSwiper] = useState();
	const prevRef = useRef(null);
	const nextRef = useRef(null);

	useEffect(() => {
		if (swiper) {
			swiper.params.navigation.prevEl = prevRef.current;
			swiper.params.navigation.nextEl = nextRef.current;
			swiper.navigation.init();
			swiper.navigation.update();
		}
	}, [swiper]);

	return (
		<Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
			{showArr && (
				<Box
					ref={prevRef}
					sx={{
						width: '50px',
						height: '50px',
						cursor: 'pointer',
						background: theme.palette.primary.light,
						opacity: 0.5,
						borderRadius: '50%',
						display: {xs: 'none', md: 'flex'},
						alignItems: 'center',
						justifyContent: 'center',
						p: 2,
						mx: 2,
					}}
				>
					<ChevronLeftIcon style={{color: '#fff', height: '4rem'}} />
				</Box>
			)}
			<Swiper
				// slidesPerView={1}
				// rewind={true}
				// spaceBetween={16}
				loop={true}
				pagination={{
					clickable: true,
					dynamicBullets: true,
				}}
				navigation={{
					prevEl: prevRef?.current,
					nextEl: nextRef?.current,
				}}
				modules={[Pagination, Navigation, Autoplay, A11y, Controller, Scrollbar, EffectCreative]}
				// className='mySwiper'
				onSwiper={setSwiper}
				updateOnWindowResize
				observer
				observeParents
				// onSlideChange={(swiper) => console.log(swiper.realIndex)}
				{...otherProps}
			>
				{children}
			</Swiper>
			{showArr && (
				<Box
					ref={nextRef}
					sx={{
						width: '50px',
						height: '50px',
						cursor: 'pointer',
						background: theme.palette.primary.light,
						opacity: 0.5,
						borderRadius: '50%',
						display: {xs: 'none', md: 'flex'},
						alignItems: 'center',
						justifyContent: 'center',
						p: 2,
						mx: 2,
					}}
				>
					<ChevronRightIcon style={{color: '#fff', height: '4rem'}} />
				</Box>
			)}
		</Box>
	);
};

export default Carousel;
