import axios from '../../axios-instance';

const getBlogs = async (qStr) => {
	const response = await axios.get(`/api/blogs`);
	return response.data;
};

const getBlogById = async (data) => {
	const response = await axios.get(`/api/blogs/${data.id}`);
	return response.data;
};

const getCategoryBlogs = async (data) => {
	const response = await axios.get(`/api/blogs/category/${data}`);
	return response.data;
};

const getAllCategoryBlogs = async (data) => {
	const response = await axios.get(`/api/admin/blogCategory`);
	return response.data;
};

const blogService = {
	getBlogs,
	getBlogById,
	getCategoryBlogs,
	getAllCategoryBlogs
};

export default blogService;
