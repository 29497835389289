import React, { useEffect } from 'react';
import { Container, Grid, Typography, useTheme } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { SwiperSlide } from 'swiper/react';
import { format } from 'date-fns'
import BlogImageCard from './Common/BlogImageCard';
import Carousel from '../Common/Carousel';
import { useNavigate } from 'react-router';


const RelatedPosts = (blogs) => {
	const theme = useTheme();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	// const { categoryBlogs } = useSelector((state) => state.blog);
	// useEffect(() => {
	// 	categoryKey &&
	// 		dispatch(getCategoryBlog(categoryKey?.categoryKey));
	// }, [dispatch]);
	return (
		<Container>
			<Grid container sx={{ pb: { md: '6rem' } }}>
				<Grid item xs={12} textAlign={'center'}>
					<Typography
						variant='body2'
						color={theme.palette.common.greyDark}
						sx={{ fontSize: { xs: '0.775rem' }, mt: 2 }}
					>
						YOU MAY ALSO CHECK
					</Typography>
					<Typography
						variant='h3'
						sx={{
							textTransform: 'capitalize',
							fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },
							fontWeight: 400,
							color: theme.palette.secondary.main,
						}}
					>
						Related Posts
					</Typography>
				</Grid>
				<Grid item xs={12} mt={{ xs: 2, md: 4 }}>
					<Carousel
						showArr={true}
						centeredSlides={true}
						autoplay={{
							delay: 2500,
							disableOnInteraction: false,
							pauseOnMouseEnter: true,
						}}
						slidesPerView={'auto'}
						spaceBetween={15}
						breakpoints={{
							600: {
								slidesPerView: 2,
							},
							1060: {
								slidesPerView: 3,
							},
						}}
					>
						{blogs?.blogs != null && blogs?.blogs?.map(({ title, img, _id, description, imageurl, createdAt }) => (
							<SwiperSlide key={_id}>
								<BlogImageCard
									date={format(new Date(createdAt), 'dd MMMM, yyyy')}
									title={title}
									blogDescription={description}
									img={imageurl}
									styles={{ mb: 4 }}
									handleNavigation={() => navigate(`/blogs/${_id}`)}
								/>
							</SwiperSlide>
						))}
					</Carousel>
				</Grid>
			</Grid>
		</Container>
	);
};

export default RelatedPosts;
