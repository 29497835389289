import { PlusIcon } from "@heroicons/react/solid";
import { Alert, Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MinimalButton from "../../Common/Buttons/MinimalButton";
import NoDataView from "../../Common/NoDataView";
import { useDispatch, useSelector } from "react-redux";
import MemberView from "./MemberView";
import {
  getPersons,
  resetProfile,
} from "../../../features/profile/profileSlice";
import AddEditProfile from "./AddEditProfile";
import useBreakpoints from "../../../hooks/useBreakpoints";

const newData = {
  id: "",
  firstName: "",
  lastName: "",
  dob: new Date(),
  weight: "",
  height: "",
  gender: "",
  // default: false,
};

const PERSONS_DATA_LENGTH = 4;

const MembersList = () => {
  const { isMediumScreen } = useBreakpoints();
  const [open, setOpen] = useState(false);

  const { persons } = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  const limitPersons = persons?.length < PERSONS_DATA_LENGTH;

  useEffect(() => {
    dispatch(getPersons());

    return () => dispatch(resetProfile());
  }, [dispatch]);

  const handleClickOpen = async (data = null) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid item container xs={12}>
        {isMediumScreen && (
          <Grid
            container
            item
            xs={12}
            alignItems={"center"}
            sx={{
              backgroundColor: "#fff",
              boxShadow: "3px 3px 20px rgba(0, 0, 0, 0.07)",
              borderRadius: 3,
              p: 2,
            }}
          >
            <Grid item xs={9}>
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  px: 2,
                  fontWeight: 500,
                  color: "#005249",
                }}
              >
                Members
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <span style={{ cursor: "not-allowed" }}>
                <MinimalButton
                  maxWidth={true}
                  buttonText={"Add Member"}
                  startIcon={<PlusIcon height={"20px"} />}
                  onClick={handleClickOpen}
                  disabled={!limitPersons}
                />
              </span>
            </Grid>
          </Grid>
        )}

        <Grid item xs={12} sx={{ mt: 2 }}>
          <Box mb={2}>
            {limitPersons ? (
              <Alert severity="info">
                You are allowed to add only {PERSONS_DATA_LENGTH} members
              </Alert>
            ) : (
              <Alert severity="error">
                You cannot add anymore persons, Limit is upto{" "}
                {PERSONS_DATA_LENGTH}
              </Alert>
            )}
          </Box>

          {persons && persons?.length > 0 ? (
            <>
              {persons?.map((person, index) => {
                return <MemberView key={index} person={person} elevate />;
              })}
              {!isMediumScreen && (
                <MinimalButton
                  maxWidth={true}
                  buttonText={"Add Member"}
                  startIcon={<PlusIcon height={"20px"} />}
                  onClick={handleClickOpen}
                />
              )}
            </>
          ) : (
            <Box width={"100%"}>
              <NoDataView
                message={`No Members added yet!!`}
                btnClickHandler={handleClickOpen}
                btnText={"ADD MEMBER"}
                showBtn={true}
              />
            </Box>
          )}
          {/* <Box width={"100%"}>
            <NoDataView message={`No Addresses added yet!!`} />
          </Box> */}
        </Grid>
      </Grid>
      <AddEditProfile
        memberData={newData}
        handleClose={handleClose}
        open={open}
      />
    </>
  );
};

export default MembersList;
