import React from "react";
import { Box, Container, Divider, Grid, Typography, useTheme } from "@mui/material";
import SectionPadding from "../../Common/SectionPadding";
import ActionButton from "../../Common/Buttons/ActionButton";
// import ResponsiveImage from "../../Common/ResponsiveImage";
import { ArchiveIcon, BeakerIcon, WifiIcon } from "@heroicons/react/solid";
import image_constants from "../../../shared/utils/images_constantsV2";
import { Link } from "react-router-dom";

const CollectConnectScience = ({ homeContentCard }) => {
	const theme = useTheme();

	const itemsSX = {
		boxShadow: `rgba(0, 0, 0, 0.1) -4px 9px 25px -6px`,
		borderRadius: 2,
		p: 4,
		backgroundColor: "#fff",
	};

	const colorMain = theme.palette.secondary.main;
	const textColorDark = theme.palette.common.greyDark;
	return (
		<SectionPadding overRideStyles={{ backgroundColor: "#fafafa" }}>
			<Container>
				{homeContentCard?.map((data, i) => (

					<Grid container key={i}>
						{console.log('data', data)}
						<Grid item xs={12} textAlign="center">
							<Typography
								variant="h2"
								sx={{
									textTransform: "capitalize",
									color: colorMain,
									fontSize: { xs: "1.2rem", md: "2rem" },
									fontWeight: 400,
								}}
								dangerouslySetInnerHTML={{
									__html: `${data?.cardTitle}`
								}}
							>
								{/* {data?.cardTitle} */}
							</Typography>
						</Grid>
						<Grid item textAlign="center" mt={2}>
							<Typography
								variant="body2"
								color={textColorDark}
								sx={{ fontSize: { xs: "0.725rem", md: "1rem" } }}
								dangerouslySetInnerHTML={{
									__html: `${data?.cardContent}`
								}}
							>
								{/* {data?.cardContent} */}
							</Typography>
						</Grid>
						<Grid item container xs={12} mt={6} alignItems="center">
							<Grid container item xs={12} spacing={2}>
								<Grid item xs={12} md={6} textAlign={{ xs: "center", md: "left" }}>
									<Box textAlign={{ xs: "center", md: "left" }} sx={itemsSX}>
										<Box
											sx={{
												display: "flex",
												alignItems: "center",
												mb: 2,
												justifyContent: { xs: "center", sm: "flex-start" },
											}}
										>
											<ArchiveIcon height={"30px"} style={{ marginY: "auto", color: colorMain }} />
											<Typography
												variant="h5"
												sx={{
													// textTransform: "capitalize",
													// fontWeight: 700,
													fontSize: { xs: "1rem", md: "1.3rem" },
													pl: 2,
												}}
												color={colorMain}
												dangerouslySetInnerHTML={{
													__html: `${data?.firstCardTitle}`
												}}
											>
												{/* {data?.firstCardTitle} */}
											</Typography>
										</Box>
										<Divider
											width="15%"
											sx={{
												mx: { xs: "auto", sm: "unset" },
												my: 2,
												fontWeight: 700,
												borderBottomWidth: "thick",
											}}
										/>
										<Typography
											variant="body2"
											color={textColorDark}
											sx={{ fontSize: { xs: "0.725rem", md: "1rem" } }}
											dangerouslySetInnerHTML={{
												__html:
													`${data?.firstCardContent}`
											}}
										>
											{/* {data?.firstCardContent} */}
										</Typography>
									</Box>
									<Box my={2} textAlign={{ xs: "center", md: "left" }} sx={itemsSX}>
										<Box
											sx={{
												display: "flex",
												alignItems: "center",
												mb: 2,
												justifyContent: { xs: "center", sm: "flex-start" },
											}}
										>
											<WifiIcon height={"30px"} style={{ marginY: "auto", color: colorMain }} />
											<Typography
												variant="h5"
												sx={{
													// textTransform: "capitalize",
													// fontWeight: 700,
													fontSize: { xs: "1rem", md: "1.3rem" },
													pl: 2,
												}}
												color={colorMain}
												dangerouslySetInnerHTML={{
													__html:
														`${data?.secondCardTitle}`
												}}
											>
												{/* {data?.secondCardTitle} */}
											</Typography>
										</Box>
										<Divider
											width="15%"
											sx={{
												mx: { xs: "auto", sm: "unset" },
												my: 2,
												fontWeight: 700,
												borderBottomWidth: "thick",
											}}
										/>
										<Typography
											variant="body2"
											color={textColorDark}
											sx={{ fontSize: { xs: "0.725rem", md: "1rem" } }}
											dangerouslySetInnerHTML={{
												__html:
													`${data?.secondCardContent}`
											}}
										>
											{/* {data?.secondCardContent} */}
										</Typography>
									</Box>
									<Box textAlign={{ xs: "center", md: "left" }} sx={itemsSX}>
										<Box
											sx={{
												display: "flex",
												alignItems: "center",
												mb: 2,
												justifyContent: { xs: "center", sm: "flex-start" },
											}}
										>
											<BeakerIcon height={"30px"} style={{ marginY: "auto", color: colorMain }} />
											<Typography
												variant="h5"
												sx={{
													// textTransform: "capitalize",
													// fontWeight: 700,
													fontSize: { xs: "1rem", md: "1.3rem" },
													pl: 2,
												}}
												color={colorMain}
												dangerouslySetInnerHTML={{
													__html:
														`${data?.thirdCardTitle}`
												}}
											>
												{/* {data?.thirdCardTitle} */}
											</Typography>
										</Box>
										<Divider
											width="15%"
											sx={{
												mx: { xs: "auto", sm: "unset" },
												my: 2,
												fontWeight: 700,
												borderBottomWidth: "thick",
											}}
										/>
										<Typography
											variant="body2"
											color={textColorDark}
											sx={{ fontSize: { xs: "0.725rem", md: "1rem" } }}
											dangerouslySetInnerHTML={{
												__html:
													`${data?.thirdCardContent}`
											}}
										>
											{/* {data?.thirdCardContent} */}
										</Typography>
									</Box>
								</Grid>
								<Grid
									item
									xs={12}
									md={6}
								// sx={{
								//   boxShadow: `rgba(0, 0, 0, 0.1) -4px 9px 25px -6px`,
								//   borderRadius: 2,
								//   //   p: 4,
								//   backgroundColor: "#fff",
								// }}
								>
									<img
										src={data?.imageurl}
										style={{
											height: "100%",
											maxWidth: "100%",
											margin: "0 auto",
											boxShadow: `rgba(0, 0, 0, 0.1) -4px 9px 25px -6px`,
											borderRadius: 10,
										}}
										loading="lazy"
										alt="connect-collect-science"
									/>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<ActionButton styles={{ mt: 2 }} component={Link} to={`/products`}>
									{data?.buttonName}
								</ActionButton>
							</Grid>
						</Grid>
					</Grid>
				))}
				{/* <Grid container>
					<Grid item xs={12} textAlign="center">
						<Typography
							variant="h2"
							sx={{
								textTransform: "capitalize",
								color: colorMain,
								fontSize: { xs: "1.2rem", md: "2rem" },
								fontWeight: 400,
							}}
						>
							You Collect, We Connect, Meet Science
						</Typography>
					</Grid>
					<Grid item textAlign="center" mt={2}>
						<Typography
							variant="body2"
							color={textColorDark}
							sx={{ fontSize: { xs: "0.725rem", md: "1rem" } }}
						>
							gutXplore is a unique and user-friendly at home sample collection kit. Your stool
							sample can provide crucial information on what is really happening in your
							gastrointestinal system. The most obvious reason to take this test is to identify
							whether the bacteria have a symbiotic or healthy relationship with you or not. Test
							will reveal the composition, balance and equilibrium of microbes present in your gut.
							If the results are different from the normal range, it may not be a problem as through
							our scientific analysis we provide you personalized diet recommendation that helps you
							to bring back your microbiome profile to normal range.
						</Typography>
					</Grid>
					<Grid item container xs={12} mt={6} alignItems="center">
						<Grid container item xs={12} spacing={2}>
							<Grid item xs={12} md={6} textAlign={{ xs: "center", md: "left" }}>
								<Box textAlign={{ xs: "center", md: "left" }} sx={itemsSX}>
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											mb: 2,
											justifyContent: { xs: "center", sm: "flex-start" },
										}}
									>
										<ArchiveIcon height={"30px"} style={{ marginY: "auto", color: colorMain }} />
										<Typography
											variant="h5"
											sx={{
												textTransform: "capitalize",
												fontWeight: 700,
												fontSize: { xs: "1rem", md: "1.3rem" },
												pl: 2,
											}}
											color={colorMain}
										>
											YOU COLLECT
										</Typography>
									</Box>
									<Divider
										width="15%"
										sx={{
											mx: { xs: "auto", sm: "unset" },
											my: 2,
											fontWeight: 700,
											borderBottomWidth: "thick",
										}}
									/>
									<Typography
										variant="body2"
										color={textColorDark}
										sx={{ fontSize: { xs: "0.725rem", md: "1rem" } }}
									>
										Your ordered kit contains all the necessary items and information for the sample
										collection. The sample has to be collected, as per the instructions attached in
										the kit, in your private space at your home and send back to GUT-iCARE with the
										prepaid envelope.
									</Typography>
								</Box>
								<Box my={2} textAlign={{ xs: "center", md: "left" }} sx={itemsSX}>
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											mb: 2,
											justifyContent: { xs: "center", sm: "flex-start" },
										}}
									>
										<WifiIcon height={"30px"} style={{ marginY: "auto", color: colorMain }} />
										<Typography
											variant="h5"
											sx={{
												textTransform: "capitalize",
												fontWeight: 700,
												fontSize: { xs: "1rem", md: "1.3rem" },
												pl: 2,
											}}
											color={colorMain}
										>
											WE CONNECT
										</Typography>
									</Box>
									<Divider
										width="15%"
										sx={{
											mx: { xs: "auto", sm: "unset" },
											my: 2,
											fontWeight: 700,
											borderBottomWidth: "thick",
										}}
									/>
									<Typography
										variant="body2"
										color={textColorDark}
										sx={{ fontSize: { xs: "0.725rem", md: "1rem" } }}
									>
										By DNA Screening technology the received sample will be analysed to quantify
										different types of microbes including the beneficial bacteria. By combining your
										lifestyle data with this microbiome profile it is also possible to know how
										these bacteria can affect your health.
									</Typography>
								</Box>
								<Box textAlign={{ xs: "center", md: "left" }} sx={itemsSX}>
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											mb: 2,
											justifyContent: { xs: "center", sm: "flex-start" },
										}}
									>
										<BeakerIcon height={"30px"} style={{ marginY: "auto", color: colorMain }} />
										<Typography
											variant="h5"
											sx={{
												textTransform: "capitalize",
												fontWeight: 700,
												fontSize: { xs: "1rem", md: "1.3rem" },
												pl: 2,
											}}
											color={colorMain}
										>
											MEET SCIENCE
										</Typography>
									</Box>
									<Divider
										width="15%"
										sx={{
											mx: { xs: "auto", sm: "unset" },
											my: 2,
											fontWeight: 700,
											borderBottomWidth: "thick",
										}}
									/>
									<Typography
										variant="body2"
										color={textColorDark}
										sx={{ fontSize: { xs: "0.725rem", md: "1rem" } }}
									>
										With the increased pace of technological advancement, our mobile application
										serves as a great channel to explore the detailed information of the test
										performed. It will also provide information on the current test score,
										explanation on why the scores look different and meets your health expectations
										by our diet recommendation.
									</Typography>
								</Box>
							</Grid>
							<Grid
								item
								xs={12}
								md={6}
							// sx={{
							//   boxShadow: `rgba(0, 0, 0, 0.1) -4px 9px 25px -6px`,
							//   borderRadius: 2,
							//   //   p: 4,
							//   backgroundColor: "#fff",
							// }}
							>
								<img
									src={image_constants.HOME_PAGE_COLLECT_CONNECT_SCIENCE}
									style={{
										height: "100%",
										maxWidth: "100%",
										margin: "0 auto",
										boxShadow: `rgba(0, 0, 0, 0.1) -4px 9px 25px -6px`,
										borderRadius: 10,
									}}
									loading="lazy"
									alt="connect-collect-science"
								/>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<ActionButton styles={{ mt: 2 }} component={Link} to={`/products`}>
								Order Kit
							</ActionButton>
						</Grid>
					</Grid>
				</Grid> */}
			</Container>
		</SectionPadding>
	);
};

export default CollectConnectScience;
