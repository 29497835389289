import { DownloadRounded } from "@mui/icons-material";
import FileOpenIcon from '@mui/icons-material/FileOpen';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Grid, useTheme, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import MinimalButton from "../Common/Buttons/MinimalButton";
import axios from "../../axios-instance";

// const statusColors = {
// 	initialized: '#000',
// 	order_placed: '#3730a3',
// 	accepted: '#15803d',
// 	Cancelled: '#be123c',
// 	kit_activated: '#7e22ce',
// 	processing: '#86198f',
// 	completed: '#3f6212',
// };

const OrderStatusButtons = ({ status, orderId, placedAt, currentOrder }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [showTimer, setShowTimer] = useState(true);
  const [loading, isSetLoading] = useState(false);
  const [pdfLink, setPdfLink] = useState();

  const dispatch = useDispatch();

  const downloadReportPDF = async () => {
    try {
      isSetLoading(true);
      const response = await axios.get(`api/orders/generateInvoice/${orderId}`);
      console.log('response', response);
      if (response) {
        isSetLoading(false);
        var path2 = response?.data?.path.replace(/\\/g, "/");
        setPdfLink(path2);
        console.log('path2', path2);
        // window.open(`http://localhost:5000/${path2}`);
        window.open(`${process.env.REACT_APP_BACKEND_URL}/${path2}`);
        // window.open(`http://40.81.235.35:5001/${path2}`);
      }
    } catch (error) {
      isSetLoading(false);
    }
  };

  return (
    <Grid
      item
      container
      spacing={2}
      xs={12}
      sm={6}
      justifyContent="flex-end"
      // spacing={{ xs: 1, sm: 2 }}
      sx={{ mt: { xs: "1rem", sm: "0rem" } }}
    >
      {Boolean(status) && status.toLowerCase() === "accepted" && (
        <Grid item xs={6} sm={6} md={6}>
          <MinimalButton
            maxWidth={true}
            buttonText={"ACTIVATE KIT"}
            btnColor="blue"
            startIcon={<FileOpenIcon height={"20px"} />}
            onClick={() => navigate(`/activatekit/${orderId}`)}
          />
        </Grid>
      )}

      {Boolean(status) && status.toLowerCase() === "completed" && (
        <Grid item xs={6} sm={6} md={6}>
          <MinimalButton
            maxWidth={true}
            buttonText={"VIEW REPORT"}
            startIcon={<VisibilityIcon height={"20px"} />}
            onClick={() => navigate(`/reports/${orderId}`)}
          />
          {/* <Button
            disableElevation
            sx={{
              textTransform: "capitalize",
              fontSize: { xs: "0.8rem", md: "1rem" },
              color: "#fff",
              backgroundColor: theme.palette.primary.main,
              width: { xs: "95%" },
            }}
            variant="contained"
            onClick={() => navigate(`/reports`)}
          >
            VIEW REPORT
          </Button> */}
        </Grid>
      )}

      {/* {Boolean(status) && status.toLowerCase() === "order_placed" && (
				<Grid item xs={6} sm={6} md={6}>
					<CancelCountdown placedAt={placedAt} orderId={orderId} isRefreshCurrentOrder={true} />
				</Grid>
			)} */}

      {Boolean(status) && status.toLowerCase() !== "order_placed" && (
        <Grid
          item
          xs={6}
          sm={6}
          md={6}
          sx={{
            display: "flex",
            // alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {" "}
          {/* <Button
            disableElevation
            sx={{
              textTransform: "capitalize",
              fontSize: { xs: "0.8rem", md: "1rem" },
              color: "#fff",
              backgroundColor: theme.palette.primary.main,
              width: { xs: "95%" },
            }}
            variant="contained"
            onClick={() => {}}
            endIcon={<DownloadRounded />}
          >
            INVOICE
          </Button> */}

          {loading ? (
            <MinimalButton
              maxWidth={true}
              buttonText={"Invoice"}
              startIcon={<CircularProgress size={24} />}
              onClick={downloadReportPDF}
            />
          ) : (
            <MinimalButton
              maxWidth={true}
              buttonText={"Invoice"}
              startIcon={<DownloadRounded height={"20px"} />}
              onClick={downloadReportPDF}
            />
          )}
          {/* <PDFDownloadLink
						document={<InvoicePDF currentOrder={currentOrder} />}
						fileName={currentOrder?._id}
						style={{textDecoration: "none"}}
					>
						{({loading}) => (
							<Tooltip title="Download">
								{loading ? (
									<CircularProgress size={24} color="inherit" />
								) : (
									<MinimalButton
										maxWidth={true}
										buttonText={"Invoice"}
										startIcon={<DownloadRounded height={"20px"} />}
										onClick={() => {}}
									/>
								)}
							</Tooltip>
						)}
					</PDFDownloadLink> */}
        </Grid>
      )}

      {/* {!['order_placed'].includes(status) && <Typography>No actions available</Typography>} */}
      {Boolean(status) && status.toLowerCase() === "order_placed" && (
        <Grid item xs={12} textAlign="center" sx={{}}>
          {/* {showTimer ? (
						<Typography variant="caption">
							Your order can be Cancelled within{" "}
							<CountdownTimer diff={countdown(placedAt)} setShowTimer={setShowTimer} />{" "}
							<Link
								sx={{
									cursor: "pointer",
									color: theme.palette.error.dark,
									fontWeight: 700,
									textDecoration: "none",
									fontSize: "1rem",
									ml: 1.5,
								}}
								onClick={() => {
									dispatch(cancelOrderUnderReview({id: orderId, isRefreshCurrentOrder: true}));
								}}
							>
								CANCEL
							</Link>
						</Typography>
					) : !showTimer && status === "cancelled" ? (
						<Typography variant="caption">Your order cancellation time has Expired</Typography>
					) : null} */}
        </Grid>
      )}
    </Grid>
  );
};

export default OrderStatusButtons;
