import React from 'react';
import {Box, Rating, Typography} from '@mui/material';
import {useFormikContext} from 'formik';
import TextInputField from '../Common/Form/TextInputField';

const ReviewForm = () => {
	const {values, setFieldValue} = useFormikContext();

	return (
		<Box>
			<Box>
				<Typography>Your review about this product:</Typography>
				<Rating
					name='simple-rating'
					value={values.rating}
					onChange={(event, newValue) => {
						setFieldValue('rating', newValue);
					}}
				/>
			</Box>
			<TextInputField
				name='review'
				label='Your Review'
				placeholder='Your Review Here...'
				type='text'
				margin='dense'
				size='small'
				styles={{
					width: {xs: '100%'},
					mx: {xs: 'auto'},
					'& fieldset': {
						background: '',
					},
				}}
				multiline
				rows={4}
				required
			/>
		</Box>
	);
};

export default ReviewForm;
