import React, {useEffect, useState} from "react";
import {Link, Outlet, Route, Routes, useLocation, useParams} from "react-router-dom";
import {InformationCircleIcon} from "@heroicons/react/outline";
import {Box, Container, Fab, FormControl, Grid, MenuItem, Select, useTheme} from "@mui/material";
import SectionPadding from "../components/Common/SectionPadding";
import useBreakpoints from "../hooks/useBreakpoints";

import ReportDetailsBar from "../components/Reports/Common/ReportDetailsBar";
import ReportSideBar from "../components/Reports/Common/ReportSideBar";
import OverallHealthResult from "../components/Reports/OverallHealth/OverallHealthResult";
import ReportTemplate from "../components/Reports/Common/ReportTemplate";

import RecommendationsLists from "../components/Reports/Recommendations/RecommendationsLists";
import ReportModal from "../components/Reports/Common/ReportModal";
import {useDispatch, useSelector} from "react-redux";
import {getDemoReport, getReport} from "../features/reports/reportSlice";
import Loader from "../components/Common/Loader";
import ScrollToTop from "../components/hoc/ScrollToTop";

const Reports = ({reports}) => {
	const theme = useTheme();
	const params = useParams();
	const {pathname} = useLocation();
	const {isMediumScreen} = useBreakpoints();
	const [changeTab, setChangeTab] = React.useState("Overall Health");
	const [open, setOpen] = useState(false);
	const {reportData, isReportLoading} = useSelector((state) => state.report);
	const dispatch = useDispatch();
	function handleOpen() {
		if (document.cookie.indexOf("modal_shown") >= 0) {
			setOpen(false);
		} else {
			document.cookie = "modal_shown=seen";
			setOpen(true);
		}
	}
	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		handleOpen();
	}, []);

	useEffect(() => {
		if (pathname.includes("demo")) {
			dispatch(getDemoReport());
		} else {
			dispatch(getReport({id: params?.reportId}));
		}
	}, [dispatch, params?.reportId, pathname]);

	const handleChange = (event) => {
		setChangeTab(event.target.value);
	};

	if (isReportLoading) {
		return <Loader open={true} />;
	}

	return (
		<>
			<ScrollToTop />

			<SectionPadding overRideStyles={{mt: {xs: 0, sm: 2}}}>
				<Container>
					<Grid container mt={"2rem"}>
						{/* <Grid item xs={12} mb={4}>
							<TitleWithBackBtn title={'Reports & Results'} />
						</Grid> */}

						<Grid item xs={12} mb={{xs: 2, md: 6}}>
							<ReportDetailsBar
								username={`${reportData?.person?.firstName} ${reportData?.person?.lastName}`}
								kitId = {`${reportData?.kit?.linkedId}`}
							/>
						</Grid>

						{!isMediumScreen && (
							<Box my={2} width="100%" sx={{display: "flex", justifyContent: "center"}}>
								<FormControl sx={{width: {xs: "100%", sm: "50%"}}}>
									<Select
										variant="standard"
										size="small"
										labelId="choose-tab"
										id="choose-tab"
										value={changeTab}
										onChange={handleChange}
									>
										<MenuItem
											component={Link}
											to={`/reports/${params?.reportId || "demo"}/overall-health`}
											value={"Overall Health"}
										>
											Overall Health
										</MenuItem>
										<MenuItem
											component={Link}
											to={`/reports/${params?.reportId || "demo"}/recommendations`}
											value={"Recommendations"}
										>
											Recommendations
										</MenuItem>
										<MenuItem
											component={Link}
											to={`/reports/${params?.reportId || "demo"}/download-pdf`}
											value={"Download PDF"}
										>
											Download PDF
										</MenuItem>
									</Select>
								</FormControl>
							</Box>
						)}

						<Grid item container xs={12} columnSpacing={2}>
							
							<Grid item xs={12} md={12}>
								<Routes>
									<Route
										index
										element={
											<ReportTemplate
												reportData={reportData}
												resultData={<OverallHealthResult />}
												title={"Overall Health"}
												showSubReports
											/>
										}
									/>
									<Route
										path="overall-health"
										element={
											<ReportTemplate
												reportData={reportData}
												resultData={<OverallHealthResult />}
												title={"Overall Health"}
												showSubReports
											/>
										}
									/>
									<Route
										path="recommendations"
										element={
											<ReportTemplate
												reportData={reportData}
												resultData={<RecommendationsLists />}
												title={"Recommendations"}
											/>
										}
									></Route>
								</Routes>

								<Outlet />
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</SectionPadding>

			<Grid container>
				<Fab
					size={"medium"}
					sx={{
						background: theme.palette.primary.main,
						"&:hover": {
							background: {xs: theme.palette.primary.main, md: theme.palette.primary.light},
						},
						position: "fixed",
						bottom: 15,
						right: 15,
					}}
					onClick={() => setOpen(true)}
				>
					<InformationCircleIcon style={{height: "1.2rem", color: "#fff"}} />
				</Fab>

				<ReportModal
					open={open}
					handleClose={handleClose}
					person_name={`${reportData?.person?.firstName} ${reportData?.person?.lastName}`}
				/>
			</Grid>
		</>
	);
};

export default Reports;
