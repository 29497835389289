import {Box, Button, Typography, useTheme} from "@mui/material";
import React from "react";
import MinimalButton from "./Buttons/MinimalButton";

const NoDataView = ({message, showBtn, btnText, btnClickHandler, disabled, btnElement}) => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				// borderColor: "#b0b0b0",
				// borderStyle: "dashed",
				// borderWidth: 1,
				backgroundColor: "#fbfbfb",
				padding: 6,
				// marginBottom: 5,
			}}
			textAlign="center">
			<Box marginTop={7} marginBottom={7}>
				{message && (
					<Typography color="#b0b0b0" mb={2}>
						{message}
					</Typography>
				)}
				{showBtn && (
					// <Button
					//   // color="success"
					//   // disabled={disabled}
					//   variant="outlined"
					//   onClick={btnClickHandler}
					//   size="large"
					//   sx={{
					//     width: { xs: "100%", sm: "20%" },
					//     marginTop: "1rem",
					//     background: `linear-gradient(to right bottom, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
					//     color: "#fff",
					//   }}
					// >
					//   {btnText}
					// </Button>
					<MinimalButton btnColor={"green"} buttonText={btnText} onClick={btnClickHandler} />
				)}
				{btnElement}
			</Box>
		</Box>
	);
};

export default NoDataView;
