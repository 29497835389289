import React from "react";
import { Box, Divider, Grid, Typography, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import { format } from "date-fns";
import { InformationCircleIcon } from "@heroicons/react/outline";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker, MobileDatePicker } from "@mui/x-date-pickers";

import useBreakpoints from "../../hooks/useBreakpoints";
import TextInputField from "../Common/Form/TextInputField";

const KitDetails = (kitId) => {
  const theme = useTheme();
  const { isSmallScreen } = useBreakpoints();
  const { values, setFieldValue } = useFormikContext();

  const styles = {
    width: { xs: "100%", md: "60%" },
    mx: { xs: "auto" },
    // [`& fieldset`]: {
    // 	borderRadius: 3,
    // },
  };
  return (
    <Grid
      item
      container
      xs={12}
      sx={{
        py: { xs: 4, sm: 6 },
        px: { xs: 4, sm: 6 },
        boxShadow: "3px 3px 20px rgba(0, 0, 0, 0.07)",
        borderRadius: 3,
        my: 4,
      }}
    >
      <Grid item xs={12} mb={1}>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "1.2rem",
            textTransform: "uppercase",
            color: theme.palette.common.greyDark,
          }}
        >
          Kit Details
        </Typography>
        <Box mt={2}>
          <Divider />
        </Box>
      </Grid>

      <Grid item xs={12} mt={2}>
        <TextInputField
          name="kitId"
          label="Kit Id"
          placeholder="11 Digit XXXXX-XXXXX"
          type="text"
          margin="dense"
          size="small"
          styles={styles}
          value={kitId.kitId}
        />
        <Typography
          sx={{
            color: theme.palette.info.light,
            display: "flex",
            alignItems: "center",
            gap: 1,
            mt: 1,
          }}
        >
          <InformationCircleIcon height={"24px"} />
          <span>
            Activate the kit with activation number which is printed on the
            backside of the stool collection kit at right corner.
          </span>
        </Typography>
      </Grid>

      <Grid item xs={12} mt={4}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          {!isSmallScreen && (
            <MobileDatePicker
              disableFuture
              label="Sample Collection Date"
              value={values.selectDate}
              onChange={(newValue) =>
                setFieldValue(
                  "selectDate",
                  format(new Date(newValue), "MM/dd/yyyy"),
                  false
                )
              }
              renderInput={(params) => (
                <TextInputField
                  styles={{ width: "30%" }}
                  name="selectDate"
                  size="small"
                  margin="dense"
                  {...params}
                />
              )}
            />
          )}

          {isSmallScreen && (
            <DesktopDatePicker
              disableFuture
              label="Sample Collection Date"
              value={values.selectDate}
              onChange={(newValue) =>
                setFieldValue(
                  "selectDate",
                  format(new Date(newValue), "MM/dd/yyyy"),
                  false
                )
              }
              renderInput={(params) => (
                <TextInputField
                  styles={{ width: "30%" }}
                  name="selectDate"
                  size="small"
                  margin="dense"
                  {...params}
                />
              )}
            />
          )}
        </LocalizationProvider>

        <Typography
          sx={{
            color: theme.palette.info.light,
            display: "flex",
            alignItems: "center",
            gap: 1,
            mt: 1,
          }}
        >
          <InformationCircleIcon height={"24px"} />
          <span>
            It should be the{" "}
            <span style={{ color: "green" }}>
              <strong>DATE OF YOUR SAMPLE COLLECTED</strong>
            </span>{" "}
            and{" "}
            <span style={{ color: "#7f1d1d" }}>
              <strong>NOT THE RECIEVED DATE OF YOUR KIT.</strong>
            </span>{" "}
            Every one of your samples must have a distinct date.
          </span>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default KitDetails;
