import { Alert, Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../../features/users/userSlice";
import ActionButton from "../Common/Buttons/ActionButton";
import TextInputField from "../Common/Form/TextInputField";
import Loader from "../Common/Loader";
import SectionPadding from "../Common/SectionPadding";

const validate = (values, props /* only available when using withFormik */) => {
  const errors = {};

  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  //...

  return errors;
};
const ForgotPassword = () => {
  const [status, setstatus] = useState();
  const { isUserLoading, successData, errorData } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();

  const styles = {
    width: { xs: "100%" },
    mx: { xs: "auto" },
    // [`& fieldset`]: {
    // 	borderRadius: 3,
    // },
  };

  useEffect(() => {
    if (Boolean(successData)) {
      // setStatusMessage("Email has been verified successfully!");
      // setStatusDetail("Please login to continue.");
      setstatus({
        primaryTxt:
          "Password reset email has been sent to the below address (if registered).",
        severity: "success",
        // secondaryTxt: "Please login to continue.",
        // icon: <CheckCircleIcon height={"11rem"} color="#166534" />,
      });
    }

    if (Boolean(errorData)) {
      // setStatusMessage("Something went wrong. Please try again later.");
      // setStatusDetail("Error details : " + errorData?.error);
      setstatus({
        primaryTxt:
          errorData?.error || "Something went wrong. Please try again later.",
        severity: "error",
        // secondaryTxt: "Error details : " + errorData?.error,
        // icon: <XCircleIcon height={"11rem"} color="#991b1b" />,
      });
    }
  }, [successData, errorData]);

  if (isUserLoading) return <Loader open={isUserLoading}></Loader>;
  return (
    <SectionPadding
      overRideStyles={{
        // paddingTop: { xs: "3rem", sm: "12rem" },
        paddingBottom: 0,
      }}
    >
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ my: 20, px: 3 }}
      >
        <Grid
          item
          container
          xs={12}
          sm={6}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Formik
            initialValues={{ email: "" }}
            validate={(values) => validate(values)}
            onSubmit={(values) => {
              const data = { email: values.email };

              dispatch(forgotPassword(data));
            }}
          >
            {({ handleSubmit, isValid, dirty, values }) => (
              <Form>
                <Grid
                  item
                  container
                  xs={12}
                  justifyContent={"center"}
                  spacing={5}
                >
                  <Grid item xs={12}>
                    <Grid item xs={12} textAlign={{ xs: "center" }}>
                      <Typography variant={"h5"}>FORGOT PASSWORD</Typography>
                    </Grid>

                    <Grid item xs={12} textAlign={{ xs: "center" }}>
                      <Typography>
                        Enter your REGISTERED EMAIL ID and we'll send you a
                        password reset email.
                      </Typography>
                    </Grid>
                    {Boolean(status) && (
                      <Grid item xs={12} sx={{ mt: 5 }}>
                        <Alert severity={status?.severity}>
                          {status?.primaryTxt}
                        </Alert>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item xs={12} textAlign={{ xs: "center" }}>
                      <TextInputField
                        name="email"
                        label="Registered Email ID"
                        type={"email"}
                        margin="dense"
                        size="small"
                        styles={styles}
                      />
                    </Grid>

                    <Grid item xs={12} my={2} textAlign="center">
                      <ActionButton
                        onClick={handleSubmit}
                        disabled={!(isValid && dirty)}
                      >
                        Reset password
                      </ActionButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </SectionPadding>
  );
};

export default ForgotPassword;
