import axios from '../../axios-instance';

const getQuestions = async () => {
	try {
		const response = await axios.get(`/api/kitactivation/questions`);
		return response.data;
	} catch (error) {
		console.log(error);
	}
};

const kitActivationService = {
	getQuestions,
	// getProductById,
};

export default kitActivationService;
