import {grey} from "@mui/material/colors";
import {createTheme} from "@mui/material/styles";

export const globalTheme = createTheme({
	components: {},

	typography: {
		fontFamily: ["Lato", "sans-serif"].join(", "),
	},

	palette: {
		primary: {
			main: "#3CAA48",
		},

		secondary: {
			main: "#1A5735",
		},

		success: {
			main: "#a3e635",
		},

		error: {
			main: "#f87171",
		},

		warning: {
			main: "#fbbf24",
		},

		common: {
			black: "#000",
			white: "#fff",
			greyLight: "#C4C4C4",
			greyDark: "#787878",
		},

		action: {
			disabled: "#ccc",
			disabledBackground: grey[600],
			disabledOpacity: 0.5,
		},
	},
});

/*
#C4C4C4
#787878

Primary
#3CAA48
rgb(42, 118, 50)
rgb(99, 187, 108)

Secondary
#1A5735
rgb(71, 120, 93)
rgb(18, 60, 37)
*/
