import { Box, TextField, Typography, useTheme, Alert } from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import axios from "../../../axios-instance";
import useBreakpoints from "../../../hooks/useBreakpoints";
import ActionButton from "../Buttons/ActionButton";
import Swal from "sweetalert2";
import { useState } from "react";

// import TextInputField from "../Form/TextInputField";

const EmailSubscribe = () => {
  const theme = useTheme();
  const { isSmallScreen } = useBreakpoints();
  const [subscribe, setSubscribe] = useState(false);
  const handleUnsubscribe = () => {
    // Your unsubscribe logic goes here
    setSubscribe(true);
    // Add your logic for handling the unsubscribe action
  };

  return (
    <>

      {!subscribe ?
        <>
          <Box
            sx={{
              background: "#fff",
              p: { xs: 2, md: 4, lg: 5 },
              borderRadius: 4,
              //borderTopLeftRadius: 50,
              my: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: { lg: "space-between" },
              flexDirection: { xs: "column", lg: "row" },
              zIndex: 500,
              //box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px",
              marginBottom: '10px'
            }}
          >
            <Typography
              variant="h5"
              sx={{
                color: theme.palette.primary.main,
                fontSize: { xs: "1rem", md: "1.6rem" },
                fontWeight: 700,
                mb: { xs: 2, lg: 0 },
              }}
            >
              Subscribe to Our Newsletter
            </Typography>

            <Box
              sx={{
                background: "#fff",
                width: { xs: "100%", lg: "70%" },
                textAlign: "right",
                borderRadius: 4,
                //borderTopLeftRadius: 30,
                filter: "drop-shadow(0px 10px 50px rgba(0, 0, 0, 0.1))",
                overflow: "hidden",
              }}
            >
              <Formik
                initialValues={{ subscribeEmail: "" }}
                onSubmit={async (values, { resetForm }) => {
                  console.log(values);
                  try {
                    const url = "http://localhost:5000/api/subscribers";
                    const data = {
                      email: values.subscribeEmail,
                    };
                    console.log(data);
                    const response = await axios.post("/api/subscribers", data);
                    // const response = await axios.post(url, data);
                    console.log(response);
                    if (response.data.message === "User Email Already Exists") {
                      Swal.fire('User Email Already Exists')
                      window.location.reload(false);
                    }
                    else {
                      Swal.fire('Thank you for Subscribing')

                    };
                    window.scrollTo(0, 0);
                    resetForm();
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                {({ handleSubmit, handleChange }) => (
                  <Form style={{ width: "100%" }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <TextField
                        sx={{
                          width: "70%",
                          ml: "1rem",
                          "& fieldset": {
                            border: "none",
                            outline: "none",
                          },
                        }}
                        placeholder="Email Address"
                        onChange={handleChange}
                        size="small"
                        type="email"
                        name="subscribeEmail"
                      />

                      {/* <input
								name="email"
								type="email"
								placeholder="Email Address"
								style={{
									width: "70%",
									border: "none",
									outline: "none",
									fontSize: "1rem",
									marginLeft: "1rem",
									"&::placeholder": {
										color: "#1A5735",
									},
									padding: "1rem 0",
								}}
								required
							/> */}
                      {/* <TextInputField
									type="email"
									name="email"
									styles={{
										width: "70%",
										ml: "1rem",
										"& fieldset": {
											border: "none",
											outline: "none",
										},
									}}
								/> */}

                      {isSmallScreen && (
                        <ActionButton
                          type="clear"
                          styles={{
                            backgroundColor: `linear-gradient(to right bottom, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                            "&:hover": {
                              backgroundColor: `linear-gradient(to right bottom, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                            },
                            px: { xs: 3, md: 5 },
                            py: 2,
                            fontSize: { xs: "0.8rem", lg: "1rem" },
                            //borderRadius: 4,
                            borderTopRightRadius: 4,
                            borderBottomRightRadius: 4,
                          }}
                          onClick={handleSubmit}
                        >
                          Subscribe Now
                        </ActionButton>
                      )}
                      {!isSmallScreen && (
                        <ActionButton style={{ margin: '0' }}
                          type="submit"
                          styles={{
                            backgroundColor: `linear-gradient(to right bottom, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                            "&:hover": {
                              backgroundColor: `linear-gradient(to right bottom, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                            },
                            mt: 2,
                            px: { xs: 3, md: 5 },
                            py: 2,
                            fontSize: { xs: "0.8rem", lg: "1rem" },
                            //borderRadius: 4,
                            borderRadius: 4,
                          }}
                          onClick={handleSubmit}
                        >
                          Subscribe Now
                        </ActionButton>
                      )}
                    </Box>

                  </Form>
                )}
              </Formik>

            </Box>


          </Box>

          <Typography
            variant="h6"
            sx={{
              color: '#fff',
              fontSize: { xs: "20px", md: "18px" },
              textAlign: 'right',
              cursor: 'pointer'
            }}
            onClick={handleUnsubscribe}
          >
            Do you want to Unsubscribe from Our Newsletter?
          </Typography>
        </>
        :
        <Box
          sx={{
            background: "#fff",
            p: { xs: 2, md: 4, lg: 5 },
            borderRadius: 4,
            //borderTopLeftRadius: 50,
            my: 5,
            display: "flex",
            alignItems: "center",
            justifyContent: { lg: "space-between" },
            flexDirection: { xs: "column", lg: "row" },
            zIndex: 500,
            //box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              color: theme.palette.primary.main,
              fontSize: { xs: "1rem", md: "1.6rem" },
              fontWeight: 700,
              mb: { xs: 2, lg: 0 },
            }}
          >
            Unsubscribe to Our Newsletter
          </Typography>

          <Box
            sx={{
              background: "#fff",
              width: { xs: "100%", lg: "70%" },
              textAlign: "right",
              borderRadius: 4,
              //borderTopLeftRadius: 30,
              filter: "drop-shadow(0px 10px 50px rgba(0, 0, 0, 0.1))",
              overflow: "hidden",
            }}
          >
            <Formik
              initialValues={{ subscribeEmail: "" }}
              onSubmit={async (values, { resetForm }) => {
                console.log(values);
                try {
                  const data = {
                    email: values.subscribeEmail,
                  };
                  console.log('submit', data);
                  const response = await axios.put("/api/subscribers", data);
                  console.log(response);


                  Swal.fire('UnSubscribed Successfully..!')

                  setSubscribe(false);
                  // window.scrollTo(0, 0);
                  resetForm();
                } catch (error) {
                  console.log(error);
                }
              }}
            >
              {({ handleSubmit, handleChange }) => (
                <Form style={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <TextField
                      sx={{
                        width: "70%",
                        ml: "1rem",
                        "& fieldset": {
                          border: "none",
                          outline: "none",
                        },
                      }}
                      placeholder="Email Address"
                      onChange={handleChange}
                      size="small"
                      type="email"
                      name="subscribeEmail"
                    />
                    {isSmallScreen && (
                      <ActionButton
                        type="clear"
                        styles={{
                          backgroundColor: 'linear-gradient(to right bottom, #ff0000 , #640f0c)',
                          "&:hover": {
                            backgroundColor: `linear-gradient(to right bottom, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                          },
                          px: { xs: 3, md: 5 },
                          py: 2,
                          fontSize: { xs: "0.8rem", lg: "1rem" },
                          //borderRadius: 4,
                          borderTopRightRadius: 4,
                          borderBottomRightRadius: 4,
                        }}
                        onClick={handleSubmit}
                      >
                        UnSubscribe Now
                      </ActionButton>
                    )}
                    {!isSmallScreen && (
                      <ActionButton style={{ margin: '0' }}
                        type="submit"
                        styles={{
                          backgroundColor: `linear-gradient(to right bottom, #ff0000 , #640f0c)`,
                          "&:hover": {
                            backgroundColor: `linear-gradient(to right bottom, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                          },
                          mt: 2,
                          px: { xs: 3, md: 5 },
                          py: 2,
                          fontSize: { xs: "0.8rem", lg: "1rem" },
                          //borderRadius: 4,
                          borderRadius: 4,
                        }}
                        onClick={handleSubmit}
                      >
                        Subscribe Now
                      </ActionButton>
                    )}
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      }

    </>

  );
};

export default EmailSubscribe;
