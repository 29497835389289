import React from 'react';
import {Backdrop, Box, Fade, Modal, Typography, useTheme} from '@mui/material';

const CustomModal = ({open, handleClose, children, title, ...otherModalProps}) => {
	const theme = useTheme();

	const style = {
		width: {xs: '100%', md: 'auto'},
		height: {xs: '100%', md: 'auto'},
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		bgcolor: 'background.paper',
		boxShadow: 24,
		p: 4,
		borderRadius: 2,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	};

	return (
		<Modal
			open={open}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
			keepMounted
			disableEnforceFocus
			{...otherModalProps}
		>
			<Fade in={open} mountOnEnter unmountOnExit>
				<Box p={2} sx={style}>
					<Box>
						{title !== null && (
							<Box textAlign='center'>
								<Typography
									sx={{
										color: theme.palette.secondary.main,
										fontSize: {xs: '1.4rem', md: '1.6rem'},
										fontWeight: 700,
									}}
								>
									{title}
								</Typography>
							</Box>
						)}
						{children}
					</Box>
				</Box>
			</Fade>
		</Modal>
	);
};

export default CustomModal;
