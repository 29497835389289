import { Box, Button, Grid, Paper, Typography, useTheme } from "@mui/material";
import React from "react";
import useBreakpoints from "../../hooks/useBreakpoints";

const TitleBodySection = ({
  children,
  title,
  actionBtnClickHandler,
  actionBtnText,
  showActionBtn,
  disableActionBtn,
  subTitle,
  subTitle2,
  showShadow = true,
  //addDividerLineToChildren,
}) => {
  const theme = useTheme();
  const { isSmallScreen, isXSmallScreen } = useBreakpoints();

  return (
    <Box
      elevation={0}
      sx={{
        //box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
        //box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
        boxShadow: showShadow && "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",
        my: { xs: 0, md: 4 },
        borderRadius: 2,
        overflow: "hidden",
      }}
    >
      <Box bgcolor={theme.palette.secondary.light} p={3}>
        <Grid container spacing={2}>
          <Grid item sm={8} marginY="auto">
            <Typography
              //variant="h6"
              sx={{ textTransform: "none" }}
              fontSize={{ xs: 15, sm: 18, md: 20 }}
              fontWeight={600}
              color={"#fff"}
            >
              {title}
            </Typography>
            {subTitle !== null && (
              <Box>
                <Typography
                  variant="subtitle1"
                  sx={{ color: isXSmallScreen ? "#fff" : theme.palette.secondary.main, fontSize: { xs: "0.8rem", md: "1rem" } }}
                >
                  {subTitle}
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item sm={4} textAlign="right">
            {showActionBtn && (
              <Button
                disableElevation
                disabled={disableActionBtn}
                variant="contained"
                sx={{
                  background: `linear-gradient(to right bottom, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                  py: 1,
                  color: "#fff",
                }}
                onClick={actionBtnClickHandler}
                type="button"
                size={"small"}
              >
                {actionBtnText}
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          p: {
            xs: 3,
            sm: 4,
            //border: "2px solid #f2f2f2",
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default TitleBodySection;
