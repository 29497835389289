import React, { useState } from "react";
import { Box, Button, Grid, Rating, Typography, useTheme } from "@mui/material";
import { CheckCircleIcon, ShoppingBagIcon } from "@heroicons/react/outline";
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper";
import useBreakpoints from "../../hooks/useBreakpoints";
import { useNavigate } from "react-router";
import { create } from "../../features/orders/orderSlice";
import { useDispatch } from "react-redux";
import ResponsiveImage from "../Common/ResponsiveImage";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

const ProductData = ({
  productName,
  productRating,
  productDescription,
  images,
  price,
  productId,
  imageTitle,
  isActiveOrder,
  setOpen,
  actualPrice,
  offerText
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { isLargeScreen } = useBreakpoints();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  let formattedRating;
  if (productRating % 1 === 0) {
    formattedRating = productRating.toFixed(0);
  } else {
    formattedRating = productRating.toFixed(1);
  };
  return (
    <Grid item container xs={12} spacing={0}>
      <Grid item xs={12} sm={12} md={6}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            {" "}
            <Swiper
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
              }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              loop={true}
              spaceBetween={30}
              navigation={true}
              thumbs={{ swiper: thumbsSwiper }}
              modules={[FreeMode, Navigation, Thumbs]}
              className="mySwiper2"
            >
              {images?.map((img, index) => {
                return (
                  <SwiperSlide key={index} style={{ paddingBottom: 2 }}>
                    <Box
                      sx={{
                        textAlign: "center",
                        width: "100%",
                        height: "100%",
                        overflow: "hidden",
                        borderRadius: 3,
                      }}
                    >
                      <img
                        src={img}
                        alt="product"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Grid>
          <Grid item xs={12}>
            <Swiper
              onSwiper={setThumbsSwiper}
              loop={true}
              rewind={true}
              spaceBetween={10}
              slidesPerView={4}
              // freeMode={true}
              watchSlidesProgress={true}
              modules={[FreeMode, Navigation, Thumbs]}
              className="productSwiper"
            >
              {images?.map((img, index) => {
                return (
                  <SwiperSlide key={index}>
                    <Box
                      sx={{
                        mt: 1,
                        textAlign: "center",
                        width: "100%",
                        height: "120px",
                        overflow: "hidden",
                        borderRadius: 2,
                      }}
                    >
                      <img
                        src={img}
                        alt="product"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        sx={{ px: { xs: 0.5, sm: 3, lg: 4 }, pt: { xs: 4, sm: 0 } }}
      >
        <Box
          sx={{
            px: { xs: 3, sm: 3, lg: 6 },
            py: 6,
            boxShadow: "3px 3px 20px rgba(0, 0, 0, 0.07)",
            height: "100%",
            borderRadius: 4,
          }}
        >
          {isLargeScreen && (
            <>
              <ResponsiveImage
                alt={productName}
                img={imageTitle}
                width={"450px"}
                overRideStyles={{ marginBottom: "20px" }}
              />

              <Typography
                color={theme.palette.primary.light}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              >
                <Rating name="half-rating" defaultValue={formattedRating} precision={0.5} readOnly />
                <span>{`${formattedRating} Ratings`}</span>
              </Typography>
              <Grid item container xs={12}>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  my="auto"
                  textAlign={{ xs: "center", sm: "left" }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "1.8rem", md: "2rem" },
                      fontWeight: 600,
                      color: theme.palette.primary.main,
                    }}
                  >
                    ₹{price}/-

                  </Typography>

                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  my="auto"
                  textAlign={{ xs: "center", sm: "left" }}
                >
                  <Typography
                    style={{ textDecoration: 'line-through' }}
                    sx={{
                      fontSize: { xs: "1rem", md: "1.3rem" },
                      color: theme.palette.common.greyDark,
                    }}
                  >
                    {actualPrice != price ? ` ₹${actualPrice}/-` : ''}

                  </Typography>

                </Grid>

              </Grid>
              {offerText &&
                <Grid item container xs={12} >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    textAlign={{ xs: "center", sm: "left" }}
                  >
                    <Typography
                      sx={{
                        fontSize: { xs: "1.1rem", sm: "1rem", md: "1.3rem" },
                        // fontWeight: 600,
                        // color: theme.palette.primary.main,
                      }}
                      dangerouslySetInnerHTML={{
                        __html: `${offerText}`,
                      }}
                    >
                    </Typography>

                  </Grid>

                </Grid>
              }
            </>
          )}
          <Typography
            sx={{
              fontSize: {
                xs: "0.875rem",
                sm: "0.9rem",
                md: "1.1rem",
              },
              color: theme.palette.common.greyDark,
              mt: 1,
              display: "flex",
              alignItems: "center",
              gap: 2,
              textAlign: "justify",
            }}
            dangerouslySetInnerHTML={{
              __html: `${productDescription}`,
            }}
          ></Typography>
          {/* 
          {productDescription
            ?.split("/")
            .filter(Boolean)
            .map((data, index) => (
              <Typography
                key={index}
                sx={{
                  fontSize: {
                    xs: "0.875rem",
                    sm: "0.9rem",
                    md: "1.1rem",
                  },
                  color: theme.palette.common.greyDark,
                  mt: 1,
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  textAlign: "justify",
                }}
              >
                 <span>
                  <CheckCircleIcon
                    height={isLargeScreen ? "20px" : "15px"}
                    color={theme.palette.primary.main}
                  />
                </span> 
                <span
                  dangerouslySetInnerHTML={{
                    __html: `${data}`,
                  }}
                ></span>
              </Typography>
            ))} */}
          <Box sx={{ mt: 4 }} textAlign={{ xs: "center", md: "unset" }}>
            {/* <Box
						mb={2}
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: {xs: 'center', sm: 'flex-start'},
						}}
					>
						<IconButton onClick={productCountRemoveHandler}>
							<MinusIcon height={'18px'} />
						</IconButton>
						<span style={{marginInline: '1rem'}}>{productCount}</span>
						<IconButton onClick={productCountAddHandler}>
							<PlusIcon height={'18px'} />
						</IconButton>
					</Box> */}

            <Button
              variant="contained"
              sx={{
                py: 1.5,
                px: 4,
                color: "#fff",
                background: theme.palette.primary.main,
                textTransform: "capitalize",
              }}
              startIcon={<ShoppingBagIcon height={"18px"} />}
              onClick={() => {
                const data = {
                  productId,
                  quantity: "1",
                };
                dispatch(create(data)).then((result) => {
                  navigate(`/checkout/${result?.payload?.order}`);
                });
              }}
              disableElevation
            >
              Order Kit
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ProductData;
