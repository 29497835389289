import React from 'react';
import {Grid, Container, Typography, useTheme} from '@mui/material';
import SectionPadding from '../Common/SectionPadding';
// import {useSelector} from 'react-redux';
// import {Navigate, useLocation} from 'react-router';

const AuthLayout = ({title, direction, children}) => {
	// const {currentUser} = useSelector((state) => state.auth);
	// const location = useLocation();

	const theme = useTheme();

	// if (currentUser !== null && currentUser?._id !== null) {
	// 	console.log('your already logged in');
	// 	return <Navigate replace to='/' state={{from: location}} />;
	// }

	return (
		<SectionPadding
			overRideStyles={{
				paddingTop: {xs: '3rem', sm: '6rem'},
				paddingBottom: 0,
			}}
		>
			<Container maxWidth='xl'>
				<Grid container p={4}>
					<Grid item xs={12} textAlign={'center'}>
						<Typography
							variant='h2'
							sx={{
								textTransform: 'capitalize',
								fontSize: {xs: '1.2rem', sm: '1.6rem', md: '2rem'},
								fontWeight: 400,
								background: `linear-gradient(to right bottom, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
								backgroundClip: 'text',
								textFillColor: 'transparent',
								// mx: '18rem'
							}}
						>
							{title}
						</Typography>
					</Grid>
					<Grid
						item
						container
						xs={12}
						direction={direction}
						mt={{xs: 2, md: 4}}
						sx={{height: '70vh'}}
					>
						{children}
					</Grid>
				</Grid>
			</Container>
		</SectionPadding>
	);
};

export default AuthLayout;
