import {Box} from '@mui/material';
import React from 'react';

function TabPanelWrapper(props) {
	const {children, value, index, otherStyles, ...other} = props;

	return (
		<Box
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{...otherStyles}}>{children}</Box>}
		</Box>
	);
}

export default TabPanelWrapper;
